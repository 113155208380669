import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import { OverviewActionTypes } from 'constants/action-types';
import { getCurrentHealthSystemInfo, buildTreeModel } from 'infrastructure/helpers/commonHelpers';
import { getAllHealthSystems } from 'api/healthSystems';
import { getTreeData } from 'api/organization';

// eslint-disable-next-line import/prefer-default-export
export const actionCreators = {
	toggleSwitchHealthSystemModal: () => ({ type: OverviewActionTypes.toggleSwitchHealthSystemModal }),
	toggleAssignDeviceModal: payload => dispatch => {
		dispatch({
			type: OverviewActionTypes.toggleAssignDeviceModal,
			payload,
		});
	},
	setStreamPermissionMessage: data => ({ type: OverviewActionTypes.setStreamPermissionMessage, payload: data }),
	addNewRoom: () => ({ type: OverviewActionTypes.addNewRoom }),
	createNewHospital: () => ({ type: OverviewActionTypes.createNewHospital }),
	setCurrentSectorLocation: data => ({ type: OverviewActionTypes.setCurrentSectorLocation, payload: data }),
	updateBreadcrumb: data => ({ type: OverviewActionTypes.updateBreadcrumb, payload: data }),
	setCurrentHealthSystemInfo: data => ({ type: OverviewActionTypes.setCurrentHealthSystemInfo, payload: data }),
	setTreeData: data => ({ type: OverviewActionTypes.setTreeData, payload: data }),
	setActiveMonitoringFeeds: data => ({ type: OverviewActionTypes.setActiveMonitoringFeeds, payload: data }),
	setCurrentHospitalId: id => ({ type: OverviewActionTypes.setCurrentHospitalId, payload: id }),
	getAllHealthSystems: () => async dispatch => {
		let response = await getAllHealthSystems();
		// TO DO: Don't set current HS in this function, make a new one!
		// TEMP Solution
		let currentHs = localStorage.getItem('currentHealthSystemInfo');
		if (currentHs) {
			currentHs = JSON.parse(currentHs);
		} else if (response.healthSystems.length > 0) {
			const [hs] = response.healthSystems;
			// @ts-ignore
			currentHs = {
				currentHealthSystemId: hs.id,
				currentHealthSystemName: hs.name,
				currentRegionId: hs.regions[0].id,
			};
		}
		if (currentHs && response.healthSystems.length > 0) {
			dispatch({ type: OverviewActionTypes.setAllHealthSystems, payload: { healthSystems: response.healthSystems, currentHealthSystem: currentHs } });
		}
	},
	createHospital: name => async () => {
		const { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals`;
		await gatewayApi.post(url, {
			regionId: currentRegionId,
			name: name,
		});
	},
	createDepartment: (hospitalId, name) => async () => {
		const { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments`;
		await gatewayApi.post(url, {
			name: name,
		});
	},
	createFloor: (hospitalId, departmentId, name) => async () => {
		const { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors`;
		await gatewayApi.post(url, {
			name: name,
		});
	},
	createRoom: (hospitalId, departmentId, floorId, name) => async () => {
		const { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms`;
		await gatewayApi.post(url, {
			name: name,
		});
	},
	getHospitalTree: hospitalId => async dispatch => {
		const { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments`;
		const response = await gatewayApi.get(url);
		dispatch({ type: OverviewActionTypes.setHospitalTree, payload: response.data.hospital });
	},
	getTreeData: () => async dispatch => {
		const { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		const hospitals = await getTreeData(currentHealthSystemId, currentRegionId);
		let treeData = buildTreeModel(hospitals);
		let preSelected = {};
		if (treeData.length) {
			dispatch({ type: OverviewActionTypes.setCurrentHospitalId, payload: treeData.firstHospitalId });
		}
		dispatch({
			type: OverviewActionTypes.setTreeData,
			payload: {
				tree: treeData.length ? treeData : [{}],
				preSelected: preSelected,
			},
		});
	},
};
