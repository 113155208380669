import React from 'react';

const Checkbox = props => (
	<label className='container'>
		<input type='checkbox' checked={props.checked} />
		<span className='checkmark'></span>
	</label>
);

export default Checkbox;
