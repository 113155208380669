import { UserPresenceActionTypes } from 'constants/action-types';
import { getUserPresence, putUserPresence } from 'api/userPresence';

function fetchingUserPresence() {
	return {
		type: UserPresenceActionTypes.FETCHING_USER_PRESENCE,
	};
}

export function fetchUserPresenceSucceeded(data) {
	return {
		type: UserPresenceActionTypes.FETCH_USER_PRESENCE_SUCCESS,
		data: data,
	};
}

function fetchUserPresenceFailed() {
	return {
		type: UserPresenceActionTypes.FETCH_USER_PRESENCE_FAILURE,
	};
}

export function fetchUserPresence() {
	return dispatch => {
		dispatch(fetchingUserPresence());
		const userId = localStorage.getItem('userId');

		return getUserPresence(userId)
			.then(userPresence => {
				dispatch(fetchUserPresenceSucceeded(userPresence));
			})
			.catch(() => {
				dispatch(fetchUserPresenceFailed());
			});
	};
}

function updatingUserPresence() {
	return {
		type: UserPresenceActionTypes.UPDATING_USER_PRESENCE,
	};
}

export function userPresenceUpdateSucceeded(userId, customMessage, presenceStatusTypeId) {
	return {
		type: UserPresenceActionTypes.UPDATE_USER_PRESENCE_SUCCESS,
		data: {
			customMessage: customMessage,
			presenceStatusTypeId: presenceStatusTypeId,
		},
	};
}

function userPresenceUpdateFailed() {
	return {
		type: UserPresenceActionTypes.UPDATE_USER_PRESENCE_FAILURE,
	};
}

export function updateUserPresence(userId, customMessage, presenceStatusTypeId) {
	return dispatch => {
		dispatch(updatingUserPresence());

		return putUserPresence(userId, customMessage, presenceStatusTypeId)
			.then(() => {
				localStorage.setItem('presenceStatusTypeId', presenceStatusTypeId);
				dispatch(userPresenceUpdateSucceeded(userId, customMessage, presenceStatusTypeId));
			})
			.catch(() => {
				dispatch(userPresenceUpdateFailed());
			});
	};
}
