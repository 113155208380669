import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CameraTiltDirection, ZoomDirection, CameraType, CameraZoomLevels, CameraTiltAction } from 'constants/enums';
import classNames from 'classnames/bind';

class Tilt extends Component {
	previousTiltAction;

	isMouseDown = false;

	sendTiltEvent = (direction, action) => {
		if (
			this.props.disabled ||
			(this.props.cameraType === CameraType.HELLO && this.props.disabledDirections[direction]) ||
			(this.previousTiltAction === CameraTiltAction.STOP && action === CameraTiltAction.STOP)
		) {
			return;
		}

		this.props.tiltHandler(direction, action);
		this.previousTiltAction = action;
	};

	onMouseDown = (direction, action) => {
		this.isMouseDown = true;
		this.sendTiltEvent(direction, action);
	};

	onMouseUp = (direction, action) => {
		this.isMouseDown = false;
		this.sendTiltEvent(direction, action);
	};

	onMouseOut = (direction, action) => {
		if (this.isMouseDown) {
			this.sendTiltEvent(direction, action);
		}
	};

	render() {
		const zoomUpperLimit = this.props.cameraType === CameraType.HELLO ? CameraZoomLevels.HELLO_MAX : CameraZoomLevels.HUDDLE_MAX;
		return (
			<div className='tilt_container'>
				<div className='tilt_figure'>
					<figure>
						<div
							className={classNames(
								'btn',
								'btn_up',
								this.props.disabled || (this.props.cameraType === CameraType.HELLO && this.props.disabledDirections[CameraTiltDirection.UP])
									? 'btn-disabled'
									: ''
							)}
							onMouseDown={() => {
								this.onMouseDown(CameraTiltDirection.UP, CameraTiltAction.START);
							}}
							onMouseUp={() => {
								this.onMouseUp(CameraTiltDirection.UP, CameraTiltAction.STOP);
							}}
							onMouseOut={() => {
								this.onMouseOut(CameraTiltDirection.UP, CameraTiltAction.STOP);
							}}
							onBlur={() => {}}
						/>
						<div
							className={classNames(
								'btn',
								'btn_right',
								this.props.disabled || (this.props.cameraType === CameraType.HELLO && this.props.disabledDirections[CameraTiltDirection.RIGHT])
									? 'btn-disabled'
									: ''
							)}
							onMouseDown={() => {
								this.onMouseDown(CameraTiltDirection.RIGHT, CameraTiltAction.START);
							}}
							onMouseUp={() => {
								this.onMouseUp(CameraTiltDirection.RIGHT, CameraTiltAction.STOP);
							}}
							onMouseOut={() => {
								this.onMouseOut(CameraTiltDirection.RIGHT, CameraTiltAction.STOP);
							}}
							onBlur={() => {}}
						/>
						<div
							className={classNames(
								'btn',
								'btn_left',
								this.props.disabled || (this.props.cameraType === CameraType.HELLO && this.props.disabledDirections[CameraTiltDirection.LEFT])
									? 'btn-disabled'
									: ''
							)}
							onMouseDown={() => {
								this.onMouseDown(CameraTiltDirection.LEFT, CameraTiltAction.START);
							}}
							onMouseUp={() => {
								this.onMouseUp(CameraTiltDirection.LEFT, CameraTiltAction.STOP);
							}}
							onMouseOut={() => {
								this.onMouseOut(CameraTiltDirection.LEFT, CameraTiltAction.STOP);
							}}
							onBlur={() => {}}
						/>
						<div
							className={classNames(
								'btn',
								'btn_down',
								this.props.disabled || (this.props.cameraType === CameraType.HELLO && this.props.disabledDirections[CameraTiltDirection.DOWN])
									? 'btn-disabled'
									: ''
							)}
							onMouseDown={() => {
								this.onMouseDown(CameraTiltDirection.DOWN, CameraTiltAction.START);
							}}
							onMouseUp={() => {
								this.onMouseUp(CameraTiltDirection.DOWN, CameraTiltAction.STOP);
							}}
							onMouseOut={() => {
								this.onMouseOut(CameraTiltDirection.DOWN, CameraTiltAction.STOP);
							}}
							onBlur={() => {}}
						/>
						<figcaption>
							{this.props.cameraType === CameraType.HUDDLE && (
								<a
									className={classNames('reset-camera', this.props.disabled ? 'disabled-reset' : '')}
									onClick={this.props.cameraType === CameraType.HUDDLE && !this.props.disabled ? this.props.onHuddleCamReboot : null}>
									<i className='material-icons-outlined'>refresh</i>
								</a>
							)}
						</figcaption>
					</figure>
				</div>
				<div className='tilt__zoom'>
					<button
						type='button'
						className={classNames('tilt__zoom--out', this.props.cameraZoomLevel === 0 || this.props.disabled ? 'disabled-zoom' : '')}
						onClick={() => {
							if (this.props.cameraZoomLevel === 0 || this.props.disabled) {
								return;
							}
							this.props.updateZoomLevel(ZoomDirection.DECREASE);
						}}>
						<i className='material-icons'>remove</i>
					</button>
					<p className='tilt__zoom--text'>Zoom {this.props.cameraZoomLevel !== 0 ? `x${this.props.cameraZoomLevel / 20}` : ''}</p>
					<button
						type='button'
						className={classNames('tilt__zoom--in', this.props.cameraZoomLevel === zoomUpperLimit || this.props.disabled ? 'disabled-zoom' : '')}
						onClick={() => {
							if (this.props.cameraZoomLevel === zoomUpperLimit || this.props.disabled) {
								return;
							}
							this.props.updateZoomLevel(ZoomDirection.INCREASE);
						}}>
						<i className='material-icons'>add</i>
					</button>
				</div>
			</div>
		);
	}
}

Tilt.defaultProps = {
	disabledDirections: {},
};

Tilt.propTypes = {
	tiltHandler: PropTypes.func,
	cameraZoomLevel: PropTypes.number,
	cameraType: PropTypes.string,
	updateZoomLevel: PropTypes.func,
	disabled: PropTypes.bool,
};

export default Tilt;
