import React, { Component } from 'react';
import { Modal, Form, Select, TreeView } from 'components';
import { SectorTypes } from 'constants/enums';
import { getTreeData, reAssignDevice } from 'api/organization';
import { buildTreeModel, getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';

class AssignDeviceModal extends Component {
	state = {
		currentHealthSystem: null,
		currentRegionId: null,
		healthSystems: [],
		currentRegions: [],
		isAddDeviceModalLoading: false,
		tree: [],
	};

	componentDidMount = async () => {
		await this.updateHealthSystemList();
	};

	componentDidUpdate = prevProps => {
		if (!prevProps.showAssignDeviceModalData.show && this.props.showAssignDeviceModalData.show) {
			this.updateHealthSystemList();
			const index = this.state.healthSystems.findIndex(({ id }) => getCurrentHealthSystemInfo().currentHealthSystemId === id);
			this.changeHealthSystem(this.state.healthSystems[index], index);
			this.getTree();
		}
	};

	transformHealthSystemArr = healthSystems => {
		let allHealthSystems = [];
		let currentHealthSystemIndex = 0;
		healthSystems.forEach((healthSystem, healthSystemIndex) => {
			if (healthSystem.id === this.state.currentHealthSystemId) {
				currentHealthSystemIndex = healthSystemIndex;
			}
			allHealthSystems.push({
				value: healthSystem.name,
				name: healthSystem.name,
				id: healthSystem.id,
				firstRegion: healthSystem.regions[0],
				regions: healthSystem.regions,
			});
		});
		return {
			allHealthSystems,
			currentHealthSystemIndex,
		};
	};

	updateHealthSystemList = () => {
		let healthSystems = this.transformHealthSystemArr(this.props.allHealthSystems);
		let regions = this.transformRegionsArr(this.props.allHealthSystems[healthSystems.currentHealthSystemIndex].regions);
		this.setState({
			healthSystems: healthSystems.allHealthSystems,
			currentHealthSystem: healthSystems.allHealthSystems[healthSystems.currentHealthSystemIndex],
			currentHealthSystemIndex: healthSystems.currentHealthSystemIndex,
			currentRegionId: healthSystems.allHealthSystems[healthSystems.currentHealthSystemIndex].regions[regions.currentRegionIndex].id,
			currentRegions: regions.currentRegions,
			currentRegionIndex: regions.currentRegionIndex,
		});
	};

	changeHealthSystem = (healthSystem, healthSystemIndex) => {
		let regions = this.transformRegionsArr(healthSystem.regions);
		this.setState(
			{
				currentHealthSystem: healthSystem,
				currentHealthSystemIndex: healthSystemIndex,
				currentRegions: regions.currentRegions,
				currentRegionId: healthSystem.firstRegion.id,
				currentRegionIndex: 0,
			},
			() => {
				this.getTree();
			}
		);
	};

	transformRegionsArr = regions => {
		let currentRegions = [];
		let currentRegionIndex = 0;
		regions.forEach((region, regionIndex) => {
			if (region.id === this.state.currentRegionId) {
				currentRegionIndex = regionIndex;
			}
			currentRegions.push({
				value: region.name,
				id: region.id,
			});
		});
		return {
			currentRegions,
			currentRegionIndex,
		};
	};

	changeRegion = (region, regionIndex) => {
		this.setState(
			{
				currentRegionId: region.id,
				currentRegionIndex: regionIndex,
			},
			() => {
				this.getTree();
			}
		);
	};

	getTree = async () => {
		const hospitals = await getTreeData(this.state.currentHealthSystem.id, this.state.currentRegionId);
		const tree = buildTreeModel(hospitals, true);
		this.setState({
			tree,
		});
	};

	onNewSector = async selection => {
		if (selection.type === SectorTypes.HOSPITAL) {
			await this.props.createHospital(selection.name);
		} else if (selection.type === SectorTypes.DEPARTMENT) {
			await this.props.createDepartment(selection.hospitalId, selection.name);
		} else if (selection.type === SectorTypes.FLOOR) {
			await this.props.createFloor(selection.hospitalId, selection.departmentId, selection.name);
		} else if (selection.type === SectorTypes.ROOM) {
			await this.props.createRoom(selection.hospitalId, selection.departmentId, selection.floorId, selection.name);
		}
		this.getTree();
		this.props.getTreeData();
	};

	assignDevice = async ({ hospitalId, departmentId, floorId, roomId }) => {
		this.setState({
			isAddDeviceModalLoading: true,
		});
		await reAssignDevice({ hospitalId, departmentId, floorId, roomId, deviceId: this.props.showAssignDeviceModalData.deviceId });
		this.setState({
			isAddDeviceModalLoading: false,
		});
		this.props.getTreeData();
		this.props.toggleAssignDeviceModal({ show: false, assignCompleted: true });
	};

	render() {
		return (
			<Modal
				modalSelector='reassignDeviceModal'
				display={this.props.showAssignDeviceModalData.show}
				isLoading={this.state.isAddDeviceModalLoading}
				onModalSubmit={() => null}
				position='right'
				submitButtonText=''
				onModalClose={() => this.props.toggleAssignDeviceModal({ show: false, assignCompleted: false })}>
				<Form title='Reassign device' onSubmit={evt => evt.preventDefault()}>
					<Select
						type='text'
						label='Select Health System'
						name='currentHealthSystemId'
						defaultValue={this.state.currentHealthSystemIndex}
						onSelect={this.changeHealthSystem}
						items={this.state.healthSystems}
						description='Choose the health system you want to manage.'
						placeholder='Select Health System'
					/>
					<Select
						type='text'
						label='Select Location'
						name='currentRegionId'
						defaultValue={this.state.currentRegionIndex}
						onSelect={this.changeRegion}
						items={this.state.currentRegions}
						description='Select a location from the health system above to manage the hospitals on it.'
						placeholder='Select Location'
					/>
					<p>Select room</p>
					<TreeView
						key={this.state.treeViewKey}
						onAddDevice={this.assignDevice}
						data={this.state.tree}
						preSelected={this.state.treeItems}
						onAdd={this.onNewSector}
					/>
				</Form>
			</Modal>
		);
	}
}

export default AssignDeviceModal;
