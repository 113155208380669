import React from 'react';
import Layout from 'components/Common/Layout';
import { Grid } from 'components';
import Header from 'components/Common/Header';
import PoolingFlow from 'containers/PoolingFlow/index';

const PoolingFlowWrapper = props => (
	<Layout>
		<Grid stretch='100%'>
			<main className='main-view'>
				<Header />
				<section>
					<Grid width='100%' vertAlign='start'>
						<PoolingFlow changePage={() => props.history.push('/health-system')} />
					</Grid>
				</section>
			</main>
		</Grid>
	</Layout>
);

export default PoolingFlowWrapper;
