import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import Grid from 'components/Grid';
import HelloFeatureBlock from 'components/HelloFeatureBlock';
import Modal from 'components/Modal';
import Form from 'components/Form';
import { withRouter } from 'react-router-dom';
import { SocketContext } from 'io-client/SocketContext';
import { DeviceStatus, MediaPermissions, MediaTypes, StreamError } from 'constants/enums';
import { If, Then, Else } from 'react-if';
import { findSectorById, askForPermission, checkForPermission, checkIfMediaDevicesPlugged } from 'infrastructure/helpers/commonHelpers';

class CallPatient extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			isDeviceUnavailableModalOpen: false,
		};

		this.camStatus = null;
		this.micStatus = null;
	}

	async componentDidMount() {
		this.camStatus = await checkForPermission(MediaTypes.CAMERA);
		this.micStatus = await checkForPermission(MediaTypes.MICROPHONE);
		this.camStatus.onchange = this.onDevicePermissionChange;
		this.micStatus.onchange = this.onDevicePermissionChange;
	}

	toggleDeviceUnavailable = () => {
		this.setState({
			isDeviceUnavailableModalOpen: !this.state.isDeviceUnavailableModalOpen,
		});
	};

	checkIfRoomAvailable = () => {
		const room = findSectorById(this.props.treeData.tree, this.props.match.params.roomId);
		if (!room) {
			return null;
		}
		if (room.status === DeviceStatus.OFFLINE) {
			this.setState({ isDeviceUnavailableModalOpen: true });
			return null;
		}
		return room;
	};

	redirect = ({ to, helloDeviceId, roomName }) => {
		let link = '';
		if (to === 'patient-feed') {
			link = `/patient-feed/${helloDeviceId}/${encodeURIComponent(roomName)}`;
		}
		if (to === 'audio') {
			link = `/call/audio/${helloDeviceId}/${encodeURIComponent(roomName)}/start`;
		}
		window.open(link, '_blank');
	};

	getCurrentRoom() {
		let room = findSectorById(this.props.treeData.tree, this.props.match.params.roomId);
		return {
			sectorName: room ? room.name : '',
			roomName: room ? room.name : '',
			helloDeviceId: room ? room.helloDeviceId : null,
		};
	}

	viewPatient = async () => {
		const room = this.checkIfRoomAvailable();
		if (!room) {
			return;
		}

		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (this.micStatus.state === MediaPermissions.PROMPT && pluggedDevices.microphone) {
			this.props.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
			await askForPermission({ audio: true });
		}
		this.props.setStreamPermissionMessage(null);
		this.redirect({
			to: 'patient-feed',
			roomName: room.name,
			helloDeviceId: room.helloDeviceId,
		});
	};

	talkToPatient = async () => {
		const room = this.checkIfRoomAvailable();
		if (!room) {
			return;
		}

		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (!pluggedDevices.camera || !pluggedDevices.microphone) {
			this.props.setStreamPermissionMessage({
				component: 'modal',
				type: !pluggedDevices.camera ? StreamError.CAMERA_NOT_FOUND.type : StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return;
		}

		if (this.micStatus.state === MediaPermissions.PROMPT) {
			this.props.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
		}
		let permissionRes = await askForPermission({ audio: true });
		if (permissionRes.error) {
			if (permissionRes.error.name === 'NotReadableError') {
				if (permissionRes.error.message.includes('audio')) {
					this.props.setStreamPermissionMessage({
						component: 'modal',
						type: StreamError.MICROPHONE_NOT_FOUND.type,
					});
				}
			} else {
				this.props.setStreamPermissionMessage({
					component: 'popup',
					type: StreamError.MICROPHONE_BLOCKED.type,
				});
			}
		} else if (this.micStatus.state === MediaPermissions.GRANTED) {
			this.redirect({
				to: 'audio',
				roomName: room.name,
				helloDeviceId: room.helloDeviceId,
			});
		}
	};

	onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			this.props.setStreamPermissionMessage(null);
		}
	};

	render() {
		const helloBlocksMessages = {
			patientView:
				"This option will enable you to see and hear the patient; the patient won't be able to see or hear you unless you enable it during the feed.",
			talkToPatient:
				"This option will initiate an audio call to the patient, they will be able to hear you and you will be able to hear them. During the call, you can switch your or patient's camera on, or even share your screen.",
		};
		const { roomName, helloDeviceId } = this.getCurrentRoom();
		return (
			<div className='room'>
				<div className='hello-device-description'>
					<h3>{roomName}</h3>
					<p>{this.props.description}</p>
				</div>
				<If condition={!!helloDeviceId}>
					<Then>
						<Grid columns='repeat(2, 1fr)' gridGap='15px'>
							<HelloFeatureBlock onClick={this.viewPatient} icon='remove_red_eye' title='View Patient' tooltip={helloBlocksMessages.patientView} />
							<HelloFeatureBlock onClick={this.talkToPatient} icon='call' title='Talk to Patient' tooltip={helloBlocksMessages.talkToPatient} />
						</Grid>
					</Then>
					<Else>
						<Grid width='100%' gridGap='15px'>
							<p style={{ margin: 0, padding: 0 }}>
								This room doesn't have any assigned devices.
								<br /> Please contact your administrator.
							</p>
						</Grid>
					</Else>
				</If>
				<Modal
					modalSelector='deviceUnavailableModal'
					display={this.state.isDeviceUnavailableModalOpen}
					position='center'
					submitButtonText='OK'
					closeButtonText='Discard'
					onModalClose={this.toggleDeviceUnavailable}
					onModalSubmit={this.toggleDeviceUnavailable}>
					<Form title='Device unavailable'>
						<p>Call could not be started from {roomName}. Device offline. Please try again later.</p>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(CallPatient));
CallPatient.contextType = SocketContext;
