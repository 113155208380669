import React, { Component, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import classNames from 'classnames/bind';
import { SectorTypes, CallTypes } from 'constants/enums';
import { SocketContext } from 'io-client/SocketContext';
import { treeSectorNameMaxCharacters } from 'constants/global-variables';

let sectors = {
	hospital: {
		subtype: 'department',
		icon: 'account_balance',
	},
	department: {
		subtype: 'floor',
		icon: 'money',
	},
	floor: {
		subtype: 'room',
		icon: 'meeting_room',
	},
};

const OptionName = props => {
	const { option } = props;
	const [tooltipStyle, setTooltipStyle] = useState(null);
	const roomStatusRef = useRef(null);
	const toggleTooltip = show => {
		const roomStatusEl = roomStatusRef.current?.getBoundingClientRect();
		if (show && roomStatusEl) {
			setTooltipStyle({
				display: 'block',
				top: roomStatusEl.top,
				left: roomStatusEl.left,
			});
		} else {
			setTooltipStyle(null);
		}
	};

	return (
		<>
			{option.name}
			{option.helloDeviceId && <i className={classNames('material-icons-round', 'room-status', option.status)}>fiber_manual_record</i>}
			{option.helloDeviceId && option.deviceBusy && (
				<>
					<i
						ref={roomStatusRef}
						onMouseEnter={() => toggleTooltip(true)}
						onMouseLeave={() => toggleTooltip(false)}
						className={classNames('material-icons-round', 'room-status', 'on-call')}>
						fiber_manual_record
					</i>
					<span className='fixed-tooltip' style={tooltipStyle}>
						{props.getDeviceBeingUsedMessage(option)}
					</span>
				</>
			)}
		</>
	);
};

const OptionsList = ({
	options,
	expandedOptions,
	onChange,
	onAdd,
	onLinkClick,
	onAddDevice,
	isMonitoring,
	selectedSectorId,
	setSelectedId,
	expandAll,
	onSendCheckDeviceActiveConferences,
}) => {
	const findProperId = item => {
		let prefix = item.type;
		return item[`${prefix}Id`];
	};

	const childrenHidden = option => {
		return option.subOptions.every(item => item.hidden === true);
	};

	const childrenMatchSearch = option => {
		return option.subOptions.some(item => item.matchesSearch === true);
	};

	const toggleOptions = option => {
		if ((childrenHidden(option) && !childrenMatchSearch(option)) || !childrenMatchSearch(option)) {
			option.subOptions.forEach(item => {
				item.hidden = !item.hidden;
			});
		} else {
			option.subOptions
				.filter(i => i.matchesSearch)
				.forEach(item => {
					item.hidden = !item.hidden;
				});
		}
	};

	const toggleExpand = (selectedOptionId, isAddingSection, option) => {
		if (expandedOptions[selectedOptionId] && !isAddingSection) {
			delete expandedOptions[selectedOptionId];
		} else {
			expandedOptions[selectedOptionId] = {};
		}
		if (expandAll) {
			toggleOptions(option);
		}
		onChange(expandedOptions);
	};

	const addNewOptionClick = selection => {
		if (selection.type === SectorTypes.ROOM) {
			onAddDevice(selection);
			return;
		}

		selection.subOptions.push({
			...selection,
			icon: sectors[selection.type].icon,
			type: sectors[selection.type].subtype,
			isNewOption: true,
			subOptions: [],
		});
		handleSubOptionsListChange();
		toggleExpand(findProperId(selection), true, selection);
	};

	const addNewOptionOnEnter = (event, selection) => {
		if (event.which === 13) {
			if (event.target.value.length > treeSectorNameMaxCharacters) {
				selection.error = `Cannot add ${selection.type} with more than ${treeSectorNameMaxCharacters} characters`;
				toggleExpand(findProperId(selection), false, selection);
				return;
			}
			selection.name = event.target.value;
			handleSubOptionsListChange();
			onAdd(selection);
		}
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		expandedOptions[optionId] = subSelections;
		onChange(expandedOptions);
	};

	const selectOption = (event, option) => {
		setSelectedId(option[`${option.type}Id`]);
		option.isSelected = true;
		if (onLinkClick) {
			onLinkClick(option, event);
		}
		toggleExpand(findProperId(option), true, option);
	};

	const checkDeviceActiveConferences = async option => {
		if (!option.helloDeviceId || !option.deviceBusy || (option.activeConferences && option.activeConferences.length > 0)) {
			return;
		}

		onSendCheckDeviceActiveConferences(+option.helloDeviceId);
	};

	const getDeviceBeingUsedMessage = option => {
		const { helloDeviceId, activeConferences } = option;
		if (!helloDeviceId || !activeConferences || !activeConferences.length) {
			return null;
		}

		let message = '';
		if (activeConferences.length === 1) {
			const [conference] = activeConferences;
			if (conference.callType === CallTypes.MONITORING) {
				message = 'Device being used by Virtual Sitter';
			} else if (!conference.isInvitedByHost) {
				message = 'Patient is calling VCP';
			} else {
				message = 'Device being used by Virtual Care Provider';
			}
		} else if (activeConferences.some(conference => !conference.isInvitedByHost)) {
			message = 'Device being used by Virtual Sitter and patient is calling VCP';
		} else {
			message = 'Device being used by Virtual Care Provider and Virtual Sitter';
		}

		return message;
	};

	const addSectorTooltip = option => {
		if (isMonitoring && option.activeConferences && option.activeConferences.some(conference => conference.callType === CallTypes.MONITORING)) {
			return null;
		}

		return option.tooltip;
	};

	return (
		<div>
			{options.map((option, index) => (
				<div key={index} className={classNames('tree', expandAll ? 'search-mode' : '', !option.name && !option.isNewOption ? 'hidden' : '')}>
					<div
						className={classNames('tree__child', option[`${option.type}Id`] === selectedSectorId ? 'selected' : '', option.hidden ? 'hidden' : '')}
						data-cy='healthSystemTree'>
						<If condition={!!option.isNewOption}>
							<Then>
								<>
									<div className='tree__new'>
										<i className='material-icons-outlined'>{option.icon}</i>
										<input
											onKeyUp={event => {
												addNewOptionOnEnter(event, option);
											}}
											type='text'
											placeholder={`Add ${option.type}`}
											autoFocus
										/>
									</div>
									<p>{option.error}</p>
								</>
							</Then>
							<Else>
								<div onMouseOver={() => checkDeviceActiveConferences(option)} onFocus={() => {}}>
									<i
										className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
										onClick={() => {
											toggleExpand(findProperId(option), false, option);
										}}>
										{expandedOptions[findProperId(option)] || (expandAll && !childrenHidden(option)) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
									</i>
									<i className='material-icons-outlined'>{option.icon}</i>
									<If condition={option.link && !isMonitoring}>
										<Then>
											<Link
												to={option.link}
												className='link'
												onClick={event => {
													selectOption(event, option);
												}}>
												<OptionName getDeviceBeingUsedMessage={getDeviceBeingUsedMessage} option={option} />
											</Link>
										</Then>
										<Else>
											<a
												className='link'
												onClick={event => {
													selectOption(event, option);
												}}>
												<OptionName getDeviceBeingUsedMessage={getDeviceBeingUsedMessage} option={option} />
											</a>
										</Else>
									</If>
									{option.tooltip && !option.isCreatingSector && (
										<a
											className='action'
											data-cy='newSector'
											onClick={() => {
												addNewOptionClick(option);
											}}
											data-tooltip={addSectorTooltip(option)}
											data-position='left'>
											<i className='material-icons-outlined' style={{ color: option.customActionIcon ? option.customActionIcon.iconColor : '' }}>
												{option.customActionIcon ? option.customActionIcon.icon : 'add_box'}{' '}
											</i>
										</a>
									)}
								</div>
							</Else>
						</If>
					</div>
					{option.subOptions && expandAll && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							onAdd={onAdd}
							onLinkClick={onLinkClick}
							onAddDevice={onAddDevice}
							isMonitoring={isMonitoring}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							expandAll={expandAll}
							onSendCheckDeviceActiveConferences={onSendCheckDeviceActiveConferences}
						/>
					)}
					{option.subOptions && expandedOptions[findProperId(option)] && !expandAll && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions ? expandedOptions[findProperId(option)] : {}}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							onAdd={onAdd}
							onLinkClick={onLinkClick}
							onAddDevice={onAddDevice}
							isMonitoring={isMonitoring}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							expandAll={expandAll}
							onSendCheckDeviceActiveConferences={onSendCheckDeviceActiveConferences}
						/>
					)}
				</div>
			))}
		</div>
	);
};

class TreeView extends Component {
	state = {
		selectedSectorId: this.props.selectedSectorId,
		expandedOptions: this.props.preSelected ? this.props.preSelected : {},
	};

	setSelectedId = sectorId => {
		this.setState({ selectedSectorId: sectorId });
	};

	render() {
		let { selectedSectorId, expandedOptions } = this.state;
		return (
			<OptionsList
				onAdd={this.props.onAdd}
				onAddDevice={this.props.onAddDevice}
				onLinkClick={this.props.onLinkClick}
				options={this.props.data}
				onChange={() => this.setState({ expandedOptions })}
				expandedOptions={this.state.expandedOptions}
				isMonitoring={this.props.isMonitoring}
				selectedSectorId={selectedSectorId}
				setSelectedId={this.setSelectedId}
				expandAll={this.props.expandAll}
				onSendCheckDeviceActiveConferences={this.props.onSendCheckDeviceActiveConferences}
			/>
		);
	}
}

TreeView.contextType = SocketContext;
export default TreeView;
