import React from 'react';
import Checkbox from 'components/Checkbox';
import Grid from 'components/Grid';
import SkeletonLoader from 'components/SkeletonLoader';
import { If, Then, Else } from 'react-if';

const Table = props => (
	<section className='table'>
		<section className='table__slot'>{props.children}</section>
		<header data-cy='tableHeader'>
			{props.isEditable ? <Checkbox /> : ''}
			{props.headers.map((header, index) => (
				<div key={index}>{header.title}</div>
			))}
		</header>
		<main data-cy='tableData'>
			<If condition={!!props.rows.length}>
				<Then>
					{props.rows.map((row, index) => (
						<div className={row.className} data-test-id='table-row' key={index}>
							{props.isEditable ? <Checkbox /> : ''}
							{Object.keys(row).map((key, index) => (
								<If condition={key !== 'id' && key !== 'className'}>
									<div key={index}>{row[key]}</div>{' '}
								</If>
							))}
						</div>
					))}
				</Then>
				<Else>
					<If condition={!!props.isLoading}>
						<Then>
							<Grid width='100%' horizAlign='center'>
								<SkeletonLoader rows={10} padding='35px 20px' />
							</Grid>
						</Then>
						<Else>
							<span data-test-id='table-empty'>{props.tableEmptyText || 'No data'}</span>
						</Else>
					</If>
				</Else>
			</If>
		</main>
	</section>
);

export default Table;
