import { OverviewActionTypes } from 'constants/action-types';

const initialState = {
	breadcrumb: [],
	allHealthSystems: [],
	currentHospitalId: null,
	currentHealthSystemId: null,
	currentHealthSystemName: '',
	currentRegionId: null,
	treeData: {
		tree: [],
		preSelected: [],
	},
	activeMonitoringFeeds: [],
	currentHelloDevice: null,
	currentSectorLocation: [],
	showAssignDeviceModalData: {
		show: false,
		deviceId: null,
		assignCompleted: false,
	},
	streamPermission: null,
};

const organization = (state, action) => {
	state = state || initialState;
	if (action.type === OverviewActionTypes.updateBreadcrumb) {
		return { ...state, breadcrumb: action.payload };
	}

	if (action.type === OverviewActionTypes.toggleAssignDeviceModal) {
		return {
			...state,
			showAssignDeviceModalData: {
				show: action.payload.show,
				deviceId: action.payload.deviceId,
				assignCompleted: action.payload.assignCompleted,
			},
		};
	}

	if (action.type === OverviewActionTypes.setAllHealthSystems) {
		return {
			...state,
			allHealthSystems: action.payload.healthSystems,
			currentHealthSystemId: action.payload.currentHealthSystem.currentHealthSystemId,
			currentRegionId: action.payload.currentHealthSystem.currentRegionId,
			currentHealthSystemName: action.payload.currentHealthSystem.currentHealthSystemName,
		};
	}

	if (action.type === OverviewActionTypes.setCurrentHealthSystemInfo) {
		return {
			...state,
			currentHealthSystemId: action.payload.currentHealthSystemId,
			currentRegionId: action.payload.currentRegionId,
			currentHealthSystemName: action.payload.currentHealthSystemName,
		};
	}

	if (action.type === OverviewActionTypes.createNewHospital) {
		state.treeData.tree.push({
			icon: 'business',
			type: 'hospital',
			isNewOption: true,
			subOptions: [],
		});

		const newTreeData = state.treeData.tree.slice();
		return {
			...state,
			treeData: {
				tree: newTreeData,
			},
		};
	}

	if (action.type === OverviewActionTypes.setStreamPermissionMessage) {
		return { ...state, streamPermission: action.payload };
	}

	if (action.type === OverviewActionTypes.setActiveMonitoringFeeds) {
		return { ...state, activeMonitoringFeeds: action.payload };
	}

	if (action.type === OverviewActionTypes.setCurrentHospitalId) {
		return { ...state, currentHospitalId: action.payload };
	}

	if (action.type === OverviewActionTypes.setHospitalTree) {
		return { ...state, hospitalTree: action.payload };
	}

	if (action.type === OverviewActionTypes.setTreeData) {
		return { ...state, treeData: action.payload };
	}

	if (action.type === OverviewActionTypes.setCurrentSectorLocation) {
		return { ...state, currentSectorLocation: action.payload };
	}

	return state;
};

export default organization;
