import { UserTypes, DeviceListLevel, SectorTypes, UserRoles, ParticipantState, MediaPermissions, MediaInputs, KeyCodes } from 'constants/enums';
import React from 'react';
import { getUserProfile, getUserRole } from 'infrastructure/auth';

export function isLoggedInUserAdmin() {
	const userProfile = getUserProfile();
	let isAdmin = userProfile.roles.some(role => {
		return role.id === UserTypes.ADMIN;
	});

	return isAdmin;
}

export function getCurrentHealthSystemInfo() {
	let currentHealthSystem = localStorage.getItem('currentHealthSystemInfo');
	return JSON.parse(currentHealthSystem);
}

export async function askForPermission(permissionFor) {
	try {
		const response = await navigator.mediaDevices.getUserMedia(permissionFor);
		return { permission: MediaPermissions.GRANTED, response };
	} catch (error) {
		return { error };
	}
}

/**
 * @returns {Promise<PermissionDescriptor | any >}
 */
export async function checkForPermission(permissionFor) {
	try {
		return await navigator.permissions.query({ name: permissionFor });
	} catch (error) {
		return { error };
	}
}

export async function checkIfMediaDevicesPlugged() {
	try {
		const devices = await navigator.mediaDevices.enumerateDevices();
		let pluggedDevices = {
			microphone: false,
			camera: false,
		};

		devices.forEach(device => {
			if (device.kind === MediaInputs.AUDIO_INPUT) {
				pluggedDevices.microphone = true;
			}

			if (device.kind === MediaInputs.VIDEO_INPUT) {
				pluggedDevices.camera = true;
			}
		});
		return pluggedDevices;
	} catch (error) {
		return { error };
	}
}

export function isMacintosh() {
	return navigator.platform.includes('Mac');
}

export function getHierarchyFromBreadcrumb(breadcrumb) {
	let hierarchy = '';
	breadcrumb.forEach(({ name }, index) => {
		if (index > 2) {
			hierarchy = hierarchy + ' - ' + name;
		} else {
			hierarchy = hierarchy + ', ' + name;
		}
	});
	return hierarchy.substring(2);
}

export function findSectorById(arr, id) {
	return arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}
		//comparing whether the id given as argument is equal to any of the items declared in the array.
		if ([item.regionId, item.hospitalId, item.departmentId, item.floorId, item.roomId, item.id, item.helloDeviceId].includes(id)) {
			return item;
		}
		if (item.subOptions) {
			return findSectorById(item.subOptions, id);
		}
	}, null);
}

export function findDeviceById(arr, id) {
	return arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}
		if (+item.helloDeviceId === +id) {
			return item;
		}
		if (item.subOptions) {
			return findDeviceById(item.subOptions, +id);
		}
	}, null);
}

export function sortArrayByProperty(array, property) {
	return array.sort((a, b) => a[property] - b[property]);
}

export function getHierarchyByHelloDeviceId(arr, id) {
	return arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}

		if (+item.helloDeviceId === +id) {
			return getHierarchyFromBreadcrumb(item.breadcrumb);
		}
		if (item.subOptions) {
			return getHierarchyByHelloDeviceId(item.subOptions, id);
		}
	}, null);
}

export function searchSectors(arr, name, sectors = []) {
	arr.forEach((item, index) => {
		if (item.isNewOption) {
			arr.splice(index, 1);
			return;
		}
		if (!name) {
			item.hidden = false;
			item.matchesSearch = false;
		} else if (item.name.toLowerCase().includes(name.toLowerCase())) {
			item.hidden = false;
			item.matchesSearch = true;
			sectors.push(item);
		} else {
			item.hidden = true;
			item.matchesSearch = false;
		}
		if (item.subOptions) {
			searchSectors(item.subOptions, name, sectors);
		}
	});
	return sectors;
}

export function showPath(tree, arr) {
	arr.forEach(item => {
		if (item.hospitalId) {
			let hospital = findSectorById(tree, item.hospitalId);
			hospital.hidden = false;
			hospital.matchesSearch = true;
		}

		if (item.departmentId) {
			let department = findSectorById(tree, item.departmentId);
			department.hidden = false;
			department.matchesSearch = true;
		}

		if (item.floorId) {
			let floor = findSectorById(tree, item.floorId);
			floor.hidden = false;
			floor.matchesSearch = true;
		}
	});
}

export function getInitialsColor(letter) {
	const obj = {};
	if (!letter) {
		obj.color = `4772f3`;
		obj.backgroundColor = `d0e2fd`;
		return obj;
	}
	switch (letter.toLowerCase().trim()) {
		case 'a':
		case 'n':
		case '0': {
			obj.color = `4772f3`;
			obj.backgroundColor = `d0e2fd`;
			break;
		}
		case 'b':
		case 'o':
		case '1': {
			obj.color = `e361c9`;
			obj.backgroundColor = `edd2fc`;
			break;
		}
		case 'c':
		case 'p':
		case '2': {
			obj.color = `eb605e`;
			obj.backgroundColor = `f9dcd2`;
			break;
		}
		case 'd':
		case 'q':
		case '3': {
			obj.color = `15a6ff`;
			obj.backgroundColor = `d2f0fc`;
			break;
		}
		case 'e':
		case 'r':
		case '4': {
			obj.color = `44ccb5`;
			obj.backgroundColor = `d2f7ec`;
			break;
		}
		case 'f':
		case 's':
		case '5': {
			obj.color = `dd995b`;
			obj.backgroundColor = `f7e4d2`;
			break;
		}
		case 'g':
		case 't':
		case '6': {
			obj.color = `e2619c`;
			obj.backgroundColor = `f9d2f8`;
			break;
		}
		case 'h':
		case 'u':
		case '7': {
			obj.color = `73c140`;
			obj.backgroundColor = `d2f7d6`;
			break;
		}
		case 'i':
		case 'v':
		case '8': {
			obj.color = `8a64dd`;
			obj.backgroundColor = `e2d2f9`;
			break;
		}
		case 'j':
		case 'w':
		case '9': {
			obj.color = `d3f4e8`;
			obj.backgroundColor = `41837a`;
			break;
		}
		case 'k':
		case 'x': {
			obj.color = `b94557`;
			obj.backgroundColor = `f4d3d3`;
			break;
		}
		case 'l':
		case 'y': {
			obj.color = `3c9045`;
			obj.backgroundColor = `d2f7d6`;
			break;
		}
		case 'm':
		case 'z': {
			obj.color = `70a8b5`;
			obj.backgroundColor = `e8f5f9`;
			break;
		}
		default:
	}
	return obj;
}

export function decodeHtml(txt) {
	const Entities = require('html-entities').AllHtmlEntities;

	const entities = new Entities();

	return entities.decode(txt);
}

export function mapSectionsToRender(hospitals, sector, toggleEditSectorModal, initDeleteOrg) {
	let result = {
		hospitals: [],
		departments: [],
		floors: [],
		rooms: [],
	};
	if (!hospitals || hospitals.length === 0) {
		return result;
	}
	let selectedSector = findSectorById(hospitals, sector[sector.sectorType + 'Id']);
	if (!selectedSector) {
		return result;
	}

	const userRole = getUserRole();
	if (sector.sectorType === SectorTypes.HOSPITAL) {
		selectedSector.subOptions.forEach(department => {
			result.departments.push({
				name: department.name,
				id: department.departmentId,
				edit: userRole === UserRoles.ADMIN && (
					<div className='wrapped align'>
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() =>
								toggleEditSectorModal({
									sectorName: department.name,
									sectorType: department.type,
									hospitalId: department.hospitalId,
									departmentId: department.departmentId,
								})
							}>
							edit
						</i>{' '}
						&nbsp;
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() => initDeleteOrg({ level: DeviceListLevel.DEPARTMENT, id: department.departmentId, name: department.name })}>
							delete
						</i>
					</div>
				),
			});
			department.subOptions.forEach(floor => {
				result.floors.push({
					name: floor.name,
					id: floor.floorId,
					edit: userRole === UserRoles.ADMIN && (
						<div className='wrapped align'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: floor.name,
										sectorType: floor.type,
										hospitalId: floor.hospitalId,
										departmentId: department.departmentId,
										floorId: floor.floorId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.FLOOR, id: floor.floorId, name: floor.name })}>
								delete
							</i>
						</div>
					),
				});
				floor.subOptions.forEach(room => {
					result.rooms.push({
						name: room.name,
						id: room.roomId,
						edit: userRole === UserRoles.ADMIN && (
							<div className='wrapped align'>
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() =>
										toggleEditSectorModal({
											sectorName: room.name,
											sectorType: room.type,
											hospitalId: floor.hospitalId,
											departmentId: department.departmentId,
											floorId: floor.floorId,
											roomId: room.roomId,
										})
									}>
									edit
								</i>{' '}
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
									delete
								</i>
							</div>
						),
					});
				});
			});
		});
	}

	if (sector.sectorType === SectorTypes.DEPARTMENT) {
		selectedSector.subOptions.forEach(floor => {
			result.floors.push({
				name: floor.name,
				id: floor.floorId,
				edit: userRole === UserRoles.ADMIN && (
					<div className='wrapped align'>
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() =>
								toggleEditSectorModal({
									sectorName: floor.name,
									sectorType: floor.type,
									hospitalId: floor.hospitalId,
									departmentId: floor.departmentId,
									floorId: floor.floorId,
								})
							}>
							edit
						</i>{' '}
						&nbsp;
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() => initDeleteOrg({ level: DeviceListLevel.FLOOR, id: floor.floorId, name: floor.name })}>
							delete
						</i>
					</div>
				),
			});
			floor.subOptions.forEach(room => {
				result.rooms.push({
					name: room.name,
					id: room.roomId,
					edit: userRole === UserRoles.ADMIN && (
						<div className='wrapped align'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: room.name,
										sectorType: room.type,
										hospitalId: floor.hospitalId,
										departmentId: room.departmentId,
										floorId: floor.floorId,
										roomId: room.roomId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
								delete
							</i>
						</div>
					),
				});
			});
		});
	}

	if (sector.sectorType === SectorTypes.FLOOR) {
		selectedSector.subOptions.forEach(room => {
			result.rooms.push({
				name: room.name,
				id: room.roomId,
				edit: userRole === UserRoles.ADMIN && (
					<div className='wrapped align'>
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() =>
								toggleEditSectorModal({
									sectorName: room.name,
									sectorType: room.type,
									hospitalId: room.hospitalId,
									departmentId: room.departmentId,
									floorId: room.floorId,
									roomId: room.roomId,
								})
							}>
							edit
						</i>{' '}
						&nbsp;
						<i className='material-icons-outlined boxed-icon' onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
							delete
						</i>
					</div>
				),
			});
		});
	}

	return result;
}

export function getParentSector(arr, element) {
	let parent = {};
	if (element.type === SectorTypes.ROOM) {
		parent = findSectorById(arr, element.floorId);
	}
	if (element.type === SectorTypes.FLOOR) {
		parent = findSectorById(arr, element.departmentId);
	}
	if (element.type === SectorTypes.DEPARTMENT) {
		parent = findSectorById(arr, element.hospitalId);
	}
	return parent;
}

export function isValidSector(currentSector, tree, sectorName) {
	let sameLevelSectors;
	if (currentSector.type === SectorTypes.HOSPITAL) {
		sameLevelSectors = tree.filter(s => s.isNewOption === undefined);
		let hospitalExists = Object.entries(sameLevelSectors[0]).length > 0;
		if (!hospitalExists) {
			return true;
		}
	} else {
		sameLevelSectors = getParentSector(tree, currentSector).subOptions.filter(s => s.isNewOption === undefined);
	}

	return !sameLevelSectors.some(s => s.name.toLowerCase() === sectorName.toLowerCase());
}

export function getSectorPath(sector) {
	let result = {};
	if (!sector) {
		return result;
	}
	if (sector.type === SectorTypes.HOSPITAL) {
		result[sector.regionId] = {};
	}
	if (sector.type === SectorTypes.DEPARTMENT) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
	}
	if (sector.type === SectorTypes.FLOOR) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId] = {};
	}
	if (sector.type === SectorTypes.ROOM) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId][sector.floorId] = {};
	}
	return result;
}

export function buildTreeModel(hospitals, assignMode) {
	let treeData = [];
	let baseurl = '/health-system';
	let isMonitoring;
	let userRole = getUserRole();
	if (window.location.pathname.includes('monitoring')) {
		isMonitoring = true;
		baseurl = '/monitoring';
	}

	function tooltipOptions(hasDevice) {
		if (isMonitoring && hasDevice) {
			return 'Add to monitoring view';
		}

		if (assignMode && !hasDevice) {
			return 'Assign device';
		}
		return '';
	}

	hospitals.forEach((hospital, hospitalIndex) => {
		if (hospitalIndex === 0) {
			treeData.firstHospitalId = hospital.id;
		}

		treeData.push({
			name: hospital.name,
			icon: 'business',
			link: `${baseurl}/${hospital.id}`,
			tooltip: userRole === UserRoles.ADMIN ? 'Add Department' : false,
			type: 'hospital',
			subOptions: [],
			hospitalId: hospital.id,
			breadcrumb: [
				{
					name: hospital.name,
					link: `${baseurl}/${hospital.id}`,
				},
			],
		});

		hospital.departments.forEach((department, departmentIndex) => {
			treeData[hospitalIndex].subOptions.push({
				name: department.name,
				icon: 'account_balance',
				link: `${baseurl}/${hospital.id}/department/${department.id}`,
				tooltip: userRole === UserRoles.ADMIN ? 'Add Floor' : false,
				type: 'department',
				subOptions: [],
				departmentId: department.id,
				hospitalId: hospital.id,
				breadcrumb: [
					{
						name: hospital.name,
						link: `${baseurl}/${hospital.id}`,
					},
					{
						name: department.name,
						link: `${baseurl}/${hospital.id}/department/${department.id}`,
					},
				],
				treeLocation: [
					{
						name: hospital.name,
						icon: 'business',
						type: 'hospital',
						subOptions: [
							{
								name: department.name,
								icon: 'account_balance',
								type: 'department',
								subOptions: [],
							},
						],
					},
				],
			});
			department.floors.forEach((floor, floorIndex) => {
				treeData[hospitalIndex].subOptions[departmentIndex].subOptions.push({
					name: floor.name,
					icon: 'money',
					link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}`,
					tooltip: userRole === UserRoles.ADMIN ? 'Add Room' : false,
					type: 'floor',
					subOptions: [],
					floorId: floor.id,
					departmentId: department.id,
					hospitalId: hospital.id,
					breadcrumb: [
						{
							name: hospital.name,
							link: `${baseurl}/${hospital.id}`,
						},
						{
							name: department.name,
							link: `${baseurl}/${hospital.id}/department/${department.id}`,
						},
						{
							name: floor.name,
							link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}`,
						},
					],
					treeLocation: [
						{
							name: hospital.name,
							icon: 'business',
							type: 'hospital',
							subOptions: [
								{
									name: department.name,
									icon: 'account_balance',
									type: 'department',
									subOptions: [
										{
											name: floor.name,
											icon: 'meeting_room',
											type: 'room',
											subOptions: [],
										},
									],
								},
							],
						},
					],
				});

				floor.rooms.forEach((room, roomIndex) => {
					treeData[hospitalIndex].subOptions[departmentIndex].subOptions[floorIndex].subOptions.push({
						name: room.name,
						icon: 'meeting_room',
						link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
						type: 'room',
						subOptions: [],
						roomId: room.id,
						roomIndex: roomIndex,
						floorId: floor.id,
						departmentId: department.id,
						hospitalId: hospital.id,
						tooltip: tooltipOptions(room.helloDeviceId),
						breadcrumb: [
							{
								name: hospital.name,
								link: `${baseurl}/${hospital.id}`,
							},
							{
								name: department.name,
								link: `${baseurl}/${hospital.id}/department/${department.id}`,
							},
							{
								name: floor.name,
								link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}`,
							},
							{
								name: room.name,
								link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
							},
						],
						status: room.isOnline ? 'online' : 'offline',
						deviceBusy: room.callState === ParticipantState.CONNECTED.type,
						helloDeviceId: room.helloDeviceId,
						treeLocation: [
							{
								name: hospital.name,
								icon: 'business',
								type: 'hospital',
								subOptions: [
									{
										name: department.name,
										icon: 'account_balance',
										type: 'department',
										subOptions: [
											{
												name: floor.name,
												icon: 'money',
												type: 'floor',
												subOptions: [
													{
														name: room.name,
														icon: 'meeting_room',
														link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
														type: 'room',
													},
												],
											},
										],
									},
								],
							},
						],
					});
				});
			});
		});
	});

	return treeData;
}

export function getLevelDevices(option) {
	let devices = [];
	switch (option.type) {
		case 'hospital': {
			const departments = option.subOptions;
			departments.forEach(department => {
				const floors = department.subOptions;
				floors.forEach(floor => {
					const rooms = floor.subOptions;
					rooms.forEach(room => {
						if (room.helloDeviceId !== undefined) {
							devices.push(room.helloDeviceId);
						}
					});
				});
			});
			return devices;
		}
		case 'department': {
			const floors = option.subOptions;
			floors.forEach(floor => {
				const rooms = floor.subOptions;
				rooms.forEach(room => {
					if (room.helloDeviceId !== undefined) {
						devices.push(room.helloDeviceId);
					}
				});
			});
			return devices;
		}
		case 'floor': {
			const rooms = option.subOptions;
			rooms.forEach(room => {
				if (room.helloDeviceId !== undefined) {
					devices.push(room.helloDeviceId);
				}
			});
			return devices;
		}
		default:
			return devices;
	}
}

export function getDeviceAssignedRoom(tree, deviceId) {
	const option = {};
	tree.forEach(region => {
		const hospitals = region.subOptions;
		hospitals.forEach(hospital => {
			const departments = hospital.subOptions;
			departments.forEach(department => {
				const floors = department.subOptions;
				floors.forEach(floor => {
					const rooms = floor.subOptions;
					rooms.forEach(room => {
						if (room.helloDeviceId === deviceId) {
							option.roomName = room.name;
							option.floorName = floor.name;
							option.departmentName = department.name;
							option.hospitalName = hospital.name;
							option.regionName = region.name;
						}
					});
				});
			});
		});
	});

	return option;
}

export function getLevelRoomsWithDevices(option) {
	let devices = [];
	switch (option.type) {
		case 'hospital': {
			const departments = option.subOptions;
			departments.forEach(department => {
				const floors = department.subOptions;
				floors.forEach(floor => {
					const rooms = floor.subOptions;
					rooms.forEach(room => {
						if (room.helloDeviceId) devices.push(room);
					});
				});
			});
			return devices;
		}
		case 'department': {
			const floors = option.subOptions;
			floors.forEach(floor => {
				const rooms = floor.subOptions;
				rooms.forEach(room => {
					if (room.helloDeviceId) devices.push(room);
				});
			});
			return devices;
		}
		case 'floor': {
			const rooms = option.subOptions;
			rooms.forEach(room => {
				if (room.helloDeviceId) devices.push(room);
			});
			return devices;
		}
		default:
			return devices;
	}
}

export function getNursePoolingStatus() {
	return localStorage.getItem('nursePoolingStatus');
}

export function sortByRoomName(array) {
	const collator = new Intl.Collator(undefined, {
		numeric: true,
		sensitivity: 'base',
	});
	return array.sort((a, b) => collator.compare(a.roomName, b.roomName));
}

export const getPort = url => {
	const parts = url.split(':');
	const port = parseInt(parts[parts.length - 1], 10);
	if (parts[0] === 'http' && (Number.isNaN(port) || parts.length < 3)) {
		return '';
	}
	if (parts[0] === 'https' && (Number.isNaN(port) || parts.length < 3)) {
		return '';
	}
	if (parts.length === 1 || Number.isNaN(port)) return '';
	return port;
};

export const handleOnKeyDownNumeric = (event, allowPeriod = false) => {
	if (
		[
			KeyCodes.MINUS,
			KeyCodes.NUMPAD_PERIOD,
			KeyCodes.NUMPAD_ADD,
			KeyCodes.NUMPAD_SUBTRACT,
			!allowPeriod && KeyCodes.PERIOD,
			KeyCodes.PLUS,
			KeyCodes.LETTER_E,
		].includes(event.which)
	) {
		event.preventDefault();
	}
};
