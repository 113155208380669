import React, { Component } from 'react';
import { DeviceCommands, AudioOutputDevice } from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { SocketContext } from 'io-client/SocketContext';
import CallButton from 'components/CallButton';
import ListGroup from 'components/ListGroup';

class AudioOutputs extends Component {
	constructor(props, socket) {
		super(props);
		this.socket = socket;
		this.audioOutputDeviceButtons = [
			{
				title: 'Banyan Bridge',
				icon: 'radio_button_unchecked',
				audioOutputDevice: AudioOutputDevice.HELLO,
			},
			{
				title: 'Pillow Speaker',
				icon: 'tv',
				audioOutputDevice: AudioOutputDevice.TV,
			},
		];
	}

	changeAudioOutput = (event, item) => {
		const { helloDeviceId, participantId, conferenceId } = this.props;
		const dynamicData = JSON.stringify({ audioOutputDevice: item.audioOutputDevice });
		this.socket.emit(SocketEvents.HelloDevice.COMMAND, {
			command: DeviceCommands.SWITCH_AUDIO_OUTPUT,
			helloDeviceId,
			conferenceId,
			participantId,
			dynamicData,
		});
	};

	render() {
		const callButtonIcon = this.props.selectedAudioOutputDevice === AudioOutputDevice.HELLO ? 'radio_button_unchecked' : 'tv';
		return (
			<CallButton icon={callButtonIcon} isActive={true} onClick={() => {}} tooltip='Audio Output' tooltipPosition='top' isDisabled={this.props.isDisabled}>
				<ListGroup lists={this.audioOutputDeviceButtons} onItemClick={this.changeAudioOutput} />
			</CallButton>
		);
	}
}

AudioOutputs.contextType = SocketContext;
export default AudioOutputs;
