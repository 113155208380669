import React, { Component } from 'react';
import classNames from 'classnames/bind';
import ReactDOM from 'react-dom';

class Dropdown extends Component {
	constructor(props) {
		super(props);

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.dropdownChildrenRef = React.createRef();
		this.dropdownRef = React.createRef();

		this.state = {
			isDropdownOpen: false,
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			if (
				this.state.isDropdownOpen &&
				((this.props.isPortal && this.dropdownChildrenRef && !this.dropdownChildrenRef.current.contains(event.target)) || !this.props.isPortal)
			) {
				this.toggleDropdown(false);
			}
		}
	}

	toggleDropdown = isDropdownOpen => {
		this.setState(
			{
				isDropdownOpen: isDropdownOpen === false ? isDropdownOpen : !this.state.isDropdownOpen,
			},
			() => {
				if (this.props.isDropdownOpen) {
					this.props.isDropdownOpen(isDropdownOpen === false ? isDropdownOpen : this.state.isDropdownOpen);
				}
			}
		);
	};

	onDropdownItemsClick = () => {
		if (this.props.closeDropdownOnItemClick) {
			this.toggleDropdown(false);
		}
	};

	getDropdownItems = style => {
		return (
			<div style={style} className='dropdown__items' data-cy='dropdownItems' onClick={this.onDropdownItemsClick} ref={this.dropdownChildrenRef}>
				{this.props.isDropdownTitleVisible && (
					<div className='dropdown-inner-header flex'>
						<p>{this.props.dropdownInnerTitle}</p>
						<i className='material-icons cursor-pointer' onClick={this.toggleDropdown.bind(this)}>
							close
						</i>
					</div>
				)}
				{this.props.children}
			</div>
		);
	};

	render() {
		const dropdownRefProperties = this.dropdownRef.current?.getBoundingClientRect();
		return (
			<div
				id={this.props.id ? this.props.id : ''}
				ref={this.setWrapperRef}
				data-test-id='drop-down-item'
				id={this.props.id}
				data-tooltip={this.props.tooltip}
				data-position={this.props.tooltipPosition}
				onClick={this.props.onClick}
				className={classNames(
					'dropdown',
					this.props.className,
					this.props.position,
					this.props.reverse ? 'dropdown--reverse' : '',
					this.state.isDropdownOpen ? 'dropdown--open' : ''
				)}>
				<a ref={this.dropdownRef} data-test-id='drop-down-clicker' onClick={this.toggleDropdown.bind(this)}>
					{this.props.imageUrl && <img src={this.props.imageUrl} alt='ddl-img' />}
					{this.props.title}
					{this.props.icon && <i className='material-icons-outlined'>{this.props.icon}</i>}
				</a>
				{this.props.isPortal &&
					this.state.isDropdownOpen &&
					ReactDOM.createPortal(
						this.getDropdownItems({
							zIndex: 999999,
							position: 'absolute',
							right: window.innerWidth - dropdownRefProperties?.right,
							...(dropdownRefProperties.top < window.innerHeight / 2 && { top: dropdownRefProperties?.top }),
							...(dropdownRefProperties.top > window.innerHeight / 2 && { bottom: window.innerHeight - dropdownRefProperties?.bottom + 30 }),
						}),
						document.getElementById('portal-root')
					)}
				{!this.props.isPortal && this.getDropdownItems()}
			</div>
		);
	}
}

export default Dropdown;
