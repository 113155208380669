import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { withRouter } from 'react-router-dom';
import { Button, Table, Tabs, DescriptionBox, Modal, Form, Input } from 'components';
import { deleteOrgUnit, uploadLogo, saveLogo, getLogoUrl } from 'api/organization';
import { DeviceListLevel, SectorTypes, DeviceCommands, UserRoles, AndroidVersions } from 'constants/enums';
import EditSectorModal from 'components/Common/EditSectorModal';
import { getDeviceList, deleteDevice, sendCommand, forceUpdateDevice, getLatestAppRelease } from 'api/devices';
import {
	mapSectionsToRender,
	findSectorById,
	getParentSector,
	getCurrentHealthSystemInfo,
	getHierarchyByHelloDeviceId,
	findDeviceById,
	sortByRoomName,
} from 'infrastructure/helpers/commonHelpers';
import { APP_CONFIG } from 'constants/global-variables';
import { CSVLink } from 'react-csv';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getUserRole } from 'infrastructure/auth';
import RebootLogExport from 'components/RebootLogExport';
import VoiceCommandCallingModal from 'components/Common/VoiceCommandCallingModal';
import PullDeviceLogs from 'components/PullDeviceLogs';
import _ from 'lodash';

class Hospital extends Component {
	constructor(props) {
		super(props);
		this.state = {
			devices: [],
			isHospitalModalOpen: false,
			selectedDevice: {},
			selectedOrg: {},
			isDeleteDeviceModalOpen: false,
			isRebootDeviceModalOpen: false,
			isUpdateDeviceModalOpen: false,
			isDeleteOrgUnitModalOpen: false,
			sectorData: {},
			rebootReason: '',
			parentSectorName: '',
			parentSectorType: '',
			isDeleteDeviceModalLoading: false,
			latestAppRelease: null,
			isVoiceCommandCallingModalOpen: false,
			isUploadLogoModalOpen: false,
			src: '',
			crop: {
				width: 200,
				height: 200,
			},
			hospitalLogo: '',
			isHospitalLogoModalLoading: false,
			currentSector: [],
		};
	}

	async componentDidMount() {
		this.loadHospital();
	}

	componentDidUpdate = prevProps => {
		const { hospitalId } = this.props.match.params;
		if (
			hospitalId !== prevProps.match.params.hospitalId ||
			(!prevProps.showAssignDeviceModalData.assignCompleted && this.props.showAssignDeviceModalData.assignCompleted)
		) {
			this.loadHospital();
		}
	};

	exportAsCsv() {
		return this.state.devices.map(({ roomName, serialNumber, macAddress, appVersion, osVersion, firmwareRevision, solHelloDeviceId, ipAddress }) => ({
			roomName,
			serialNumber,
			room: getHierarchyByHelloDeviceId(this.props.treeData.tree, solHelloDeviceId),
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
			androidVersion: this.getCodeNameByOsVersion(osVersion),
			ipAddress,
		}));
	}

	getCurrentHospital() {
		let hospitals = this.props.treeData.tree;
		let hospital = hospitals.find(item => item.hospitalId === this.props.match.params.hospitalId);
		return {
			sectorName: hospital ? hospital.name : '',
			sectorId: hospital ? hospital.hospitalId : '',
			hospitalName: hospital ? hospital.name : '',
			hospitalId: hospital ? hospital.hospitalId : '',
			sectorType: hospital ? hospital.type : '',
		};
	}

	getCodeNameByOsVersion = osVersion => {
		let version = AndroidVersions.find(({ apiLevel }) => apiLevel.includes(+osVersion));
		return version?.codeName ?? 'N/A';
	};

	loadHospital = async () => {
		const devices = await getDeviceList(DeviceListLevel.HOSPITAL, this.props.match.params.hospitalId);
		const latestAppRelease = await getLatestAppRelease(APP_CONFIG.deviceAppId);
		const currentSector = findSectorById(this.props.treeData.tree, this.props.match.params.hospitalId);
		let deviceList = [];
		if (currentSector?.subOptions) {
			deviceList = devices.map(d => {
				const roomName = findSectorById(this.props.treeData.tree, d.solHelloDeviceId.toString())?.name ?? '';
				const device = findDeviceById(currentSector.subOptions, d.solHelloDeviceId);
				if (device) {
					device.voiceCommandCalling = d.voiceCommandCalling;
				}
				return { ...d, roomName };
			});
		}

		this.setState({
			devices: sortByRoomName(deviceList),
			latestAppRelease,
			currentSector: currentSector ? [currentSector] : [],
		});
	};

	displayDevices = () => {
		if (!this.state.devices.length) {
			return [];
		}
		const userRole = getUserRole();
		return this.state.devices.map(
			({ roomName, serialNumber, solHelloDeviceId, firmwareRevision, appVersion, osVersion, macAddress, isOnline, voiceCommandCalling, ipAddress }) => {
				return {
					roomName: roomName ?? '',
					serialNumber: serialNumber ?? 'N/A',
					macAddress: macAddress ?? 'N/A',
					appVersion: <div className='app-version'>{appVersion || 'N/A'}</div>,
					firmwareRevision: firmwareRevision ?? 'N/A',
					osVersion: this.getCodeNameByOsVersion(osVersion),
					ipAddress,
					voiceCommandCalling: voiceCommandCalling ? 'Active' : 'Not active',
					actions: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								data-cy='rebootDeviceBtn'
								id={solHelloDeviceId}
								data-tooltip='Reboot device'
								data-position='top'
								style={{ background: '#718093' }}
								onClick={() => this.toggleRebootDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
								refresh
							</i>
							<PullDeviceLogs deviceId={solHelloDeviceId} isOnline={isOnline} />
							<RebootLogExport solHelloDeviceId={solHelloDeviceId} serialNumber={serialNumber} />
							{userRole === UserRoles.ADMIN && (
								<>
									<i
										className='material-icons boxed-icon'
										data-cy='reassignDeviceBtn'
										id={solHelloDeviceId}
										data-tooltip='Reassign Device'
										data-position='top'
										style={{ backgroundColor: 'var(--blue-2)' }}
										onClick={() => this.props.toggleAssignDeviceModal({ deviceId: solHelloDeviceId, show: true })}>
										swap_vert
									</i>
									<i
										className='material-icons-outlined boxed-icon'
										data-cy='unassignDeviceBtn'
										id={solHelloDeviceId}
										data-tooltip='Unassign device'
										data-position='top'
										onClick={() => this.toggleDeleteDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
										delete
									</i>
								</>
							)}
						</div>
					),
				};
			}
		);
	};

	initDeleteOrg = data => {
		this.setState({
			selectedOrg: data,
		});
		this.toggleDeleteOrgUnitModal();
	};

	unAssignDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			this.setState({
				isDeleteDeviceModalLoading: true,
			});

			await deleteDevice(deviceId);
		}

		this.loadHospital();
		this.toggleDeleteDeviceModal();
		this.props.getTreeData();

		this.setState({
			isDeleteDeviceModalLoading: false,
		});
	};

	rebootDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			await sendCommand(deviceId, DeviceCommands.REBOOT, this.state.rebootReason);
		}

		this.toggleRebootDeviceModal();
	};

	forceUpdateDevice = async () => {
		const {
			selectedDevice: { deviceId, appVersion },
			latestAppRelease: { install_url: appInstallUrl, short_version: latestAppVersion },
		} = this.state;

		if (appVersion && latestAppVersion && appVersion !== latestAppVersion) {
			await forceUpdateDevice({
				deviceId,
				dynamicData: appInstallUrl,
			});
		}

		this.toggleUpdateDeviceModal();
	};

	deleteOrgUnitSubmit = async () => {
		await deleteOrgUnit(this.state.selectedOrg.level, this.state.selectedOrg.id);
		this.toggleDeleteOrgUnitModal();
		this.props.getTreeData();
		if (this.state.selectedOrg.level === DeviceListLevel.HOSPITAL) {
			this.props.history.push('/health-system');
			this.props.updateBreadcrumb([]);
		}
	};

	toggleHospitalModal = () => {
		this.setState({
			isHospitalModalOpen: !this.state.isHospitalModalOpen,
		});
	};

	toggleEditSectorModal = data => {
		this.setState(
			{
				isEditSectorModalOpen: !this.state.isEditSectorModalOpen,
			},
			async () => {
				if (this.state.isEditSectorModalOpen) {
					if (!data) {
						return;
					}
					let sector = findSectorById(this.props.treeData.tree, data[`${data.sectorType}Id`]);
					this.props.setCurrentSectorLocation(sector.treeLocation);
					let currentHealthSystem = this.getCurrentHealthSystem(this.props.allHealthSystems, getCurrentHealthSystemInfo().currentHealthSystemId);
					let sectorParent =
						data.sectorType === SectorTypes.HOSPITAL
							? { type: SectorTypes.HEALTHSYSTEM, name: currentHealthSystem.name }
							: getParentSector(this.props.treeData.tree, sector);
					let hospitalLogo = await getLogoUrl(data.hospitalId);
					this.setState({
						sectorData: data,
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
						hospitalLogo,
					});
				} else {
					this.setState({ fileError: false });
				}
			}
		);
	};

	toggleVoiceCommandCallingModal = () => {
		this.setState(
			{
				isVoiceCommandCallingModalOpen: !this.state.isVoiceCommandCallingModalOpen,
			},
			() => {
				this.loadHospital();
			}
		);
	};

	getCurrentHealthSystem = (healthSystems, currentHealthSystemId) => {
		return healthSystems.find(({ id }) => id === currentHealthSystemId);
	};

	toggleDeleteDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isDeleteDeviceModalOpen: !this.state.isDeleteDeviceModalOpen,
		});
	};

	toggleRebootDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isRebootDeviceModalOpen: !this.state.isRebootDeviceModalOpen,
			rebootReason: '',
		});
	};

	toggleUpdateDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isUpdateDeviceModalOpen: !this.state.isUpdateDeviceModalOpen,
		});
	};

	toggleDeleteOrgUnitModal = () => {
		this.setState({
			isDeleteOrgUnitModalOpen: !this.state.isDeleteOrgUnitModalOpen,
		});
	};

	setInputValues = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	updateTree = async () => {
		this.props.getTreeData();
		this.toggleEditSectorModal();
	};

	onSelectFile = ({ target }) => {
		const input = target;
		const [file] = input.files;
		if (!file) {
			return;
		}

		if (file.size > 600000 || !file.type.includes('png')) {
			this.setState({ fileError: true });
			input.value = '';
			return;
		}

		const reader = new FileReader();
		reader.addEventListener('load', () => this.setState({ src: reader.result }));
		reader.readAsDataURL(file);

		this.setState({
			isUploadLogoModalOpen: true,
			isEditSectorModalOpen: false,
			file,
		});
	};

	onImageLoaded = image => {
		this.imageRef = image;
	};

	onCropChange = crop => {
		this.setState({ crop });
	};

	makeClientCrop = async () => {
		this.setState({
			isHospitalLogoModalLoading: true,
			crop: {
				width: 200,
				height: 200,
			},
			isEditSectorModalOpen: true,
		});
		if (this.imageRef && this.state.crop.width && this.state.crop.height) {
			const imageData = await this.uploadLogo();
			if (imageData) {
				const logoData = {
					picture: imageData.originalUrl,
				};
				saveLogo(this.state.sectorData.hospitalId, logoData);
			}
		}
	};

	uploadLogo = async () => {
		const { data, error } = await uploadLogo(this.state.file, this.getImageCrop());
		let errorStateParameters = {};

		if (error) {
			errorStateParameters = {
				fileError: error.request.status === 400,
				isEditSectorModalOpen: true,
			};
		}

		this.setState({
			isHospitalLogoModalLoading: false,
			isUploadLogoModalOpen: false,
			src: '',
			...errorStateParameters,
		});

		return data;
	};

	getImageCrop = () => {
		const { crop } = this.state;

		const image = this.imageRef;
		const xScale = image.naturalWidth > image.width ? image.naturalWidth / image.width : 1;
		const yScale = image.naturalHeight > image.height ? image.naturalHeight / image.height : 1;

		return {
			width: crop.width * xScale,
			height: crop.height * yScale,
			x: crop.x * xScale,
			y: crop.y * yScale,
		};
	};

	toggleHospitalLogoCrop = () => {
		this.setState({
			isUploadLogoModalOpen: !this.state.isUploadLogoModalOpen,
			crop: {
				width: 200,
				height: 200,
			},
		});
	};

	updateVCCList = (solHelloDeviceIds, isChecked) => {
		const currentSector = _.cloneDeep(this.state.currentSector);
		solHelloDeviceIds.forEach(solHelloDeviceId => {
			const device = findDeviceById(currentSector, solHelloDeviceId);
			if (device) {
				device.voiceCommandCalling = isChecked;
			}
		});
		this.setState({ currentSector });
	};

	render() {
		const hospital = this.getCurrentHospital();
		const sections = mapSectionsToRender(this.props.treeData.tree, hospital, this.toggleEditSectorModal, this.initDeleteOrg);
		const userRole = getUserRole();
		return (
			<div className='organization'>
				<h3>
					<span>{hospital.hospitalName}</span>
					{userRole === UserRoles.ADMIN && (
						<>
							<Button text='Edit Hospital' size='medium' onClick={() => this.toggleEditSectorModal(hospital)} />
							<Button
								text='Delete Hospital'
								icon='delete'
								size='medium'
								variant='red'
								onClick={() => this.initDeleteOrg({ level: DeviceListLevel.HOSPITAL, id: this.props.match.params.hospitalId, name: hospital.hospitalName })}
							/>
							{this.state.devices.length > 0 && (
								<Button
									text='Voice Command Calling'
									size='medium'
									icon='supervisor_account'
									variant='yellow'
									onClick={() => this.toggleVoiceCommandCallingModal()}
								/>
							)}
						</>
					)}

					{this.state.devices.length > 0 && (
						<CSVLink
							className='button medium white'
							data-cy='exportAsCSVBtn'
							data={this.exportAsCsv()}
							filename={`${hospital.hospitalName}-${(+new Date()).toString()}.csv`}>
							Export as CSV
						</CSVLink>
					)}
				</h3>
				<DescriptionBox
					data={[
						{ title: 'Departments in this hospital', description: sections.departments.length },
						{ title: 'Floors in this hospital', description: sections.floors.length },
						{ title: 'Rooms in this hospital', description: sections.rooms.length },
						{ title: 'Enrolled Devices', description: this.state.devices ? this.state.devices.length : 0 },
					]}
				/>
				<Tabs
					links={[{ link: 'Devices' }, { link: 'Rooms' }, { link: 'Floors' }, { link: 'Departments', active: true }]}
					components={[
						<Table
							headers={[
								{ title: 'Room Name' },
								{ title: 'Serial Number' },
								{ title: 'MAC Address' },
								{ title: 'App Version' },
								{ title: 'OS Version' },
								{ title: 'Android Version' },
								{ title: 'IP Address' },
								{ title: 'Voice Command Calling' },
								{ title: '' },
							]}
							rows={this.displayDevices()}
							isEditable={false}
						/>,
						<Table headers={[{ title: 'Room Names' }]} rows={sections.rooms} isEditable={false} />,
						<Table headers={[{ title: 'Floor Names' }]} rows={sections.floors} isEditable={false} />,
						<Table headers={[{ title: 'Department Names' }]} rows={sections.departments} isEditable={false} />,
					]}
				/>

				<Modal
					modalSelector='unassignDeviceModal'
					display={this.state.isDeleteDeviceModalOpen}
					isLoading={this.state.isDeleteDeviceModalLoading}
					position='center'
					submitButtonText='Unassign'
					onModalSubmit={this.unAssignDevice}
					onModalClose={this.toggleDeleteDeviceModal}>
					<Form title='Unassign device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to unassign device {this.state.selectedDevice.serialNumber}? Neither you or patient won't be able to make any calls.</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='rebootDeviceModal'
					display={this.state.isRebootDeviceModalOpen}
					position='center'
					submitButtonText='Reboot'
					onModalSubmit={this.rebootDevice}
					onModalClose={this.toggleRebootDeviceModal}>
					<Form title='Reboot device' onSubmit={event => event.preventDefault()}>
						<p>Why do you want to reboot device {this.state.selectedDevice.serialNumber}?</p>
						<Input
							type='text'
							value={this.state.rebootReason}
							validationOptions={{}}
							placeholder='Describe the reboot reason. . .'
							onChange={e => this.setState({ rebootReason: e.target.value })}
						/>
					</Form>
				</Modal>

				<Modal
					display={this.state.isUpdateDeviceModalOpen}
					position='center'
					submitButtonText='Update'
					onModalSubmit={this.forceUpdateDevice}
					onModalClose={this.toggleUpdateDeviceModal}>
					<Form title='Force update device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to force update device {this.state.selectedDevice.serialNumber}?</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='deleteSectorModal'
					display={this.state.isDeleteOrgUnitModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteOrgUnitSubmit}
					onModalClose={this.toggleDeleteOrgUnitModal}>
					<Form title='Warning' onSubmit={event => event.preventDefault()}>
						<p>
							Are you sure you want to delete {this.state.selectedOrg.name}? Any devices related to it will be deleted and neither Virtual Care Providers or
							patients won't be able to make any calls.
						</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='uploadHospitalLogo'
					className='crop-modal'
					display={this.state.isUploadLogoModalOpen}
					position='center'
					submitButtonText='Save'
					onModalSubmit={this.makeClientCrop}
					onModalClose={this.toggleHospitalLogoCrop}
					isLoading={this.state.isHospitalLogoModalLoading}>
					{this.state.src && (
						<div>
							<ReactCrop src={this.state.src} crop={this.state.crop} ruleOfThirds={true} onImageLoaded={this.onImageLoaded} onChange={this.onCropChange} />
						</div>
					)}
				</Modal>

				<EditSectorModal
					onEditSector={this.updateTree}
					onModalClose={this.toggleEditSectorModal}
					isEditSectorModalOpen={this.state.isEditSectorModalOpen}
					sectorData={this.state.sectorData}
					parentSectorName={this.state.parentSectorName}
					parentSectorType={this.state.parentSectorType}
					onSelectFile={this.onSelectFile}
					onFileInputFocus={() => this.setState({ fileError: false })}
					hospitalLogo={this.state.hospitalLogo}
					fileError={this.state.fileError}
				/>

				<VoiceCommandCallingModal
					currentSector={this.state.currentSector}
					isVoiceCommandCallingModalOpen={this.state.isVoiceCommandCallingModalOpen}
					toggleVoiceCommandCallingModal={this.toggleVoiceCommandCallingModal}
					updateVoiceCommandCallingList={this.updateVCCList}
				/>
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(Hospital));
