import React, { Component } from 'react';
import { AuthProvider } from 'providers/authProvider';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'routes/routes';
import Socket from 'io-client/Socket';
import { isAuthenticated, memberExists, setUserInfo, setTokens } from 'infrastructure/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { Loader, Grid, Button, Alert } from 'components';
import { getCurrentHealthSystemInfo, findSectorById } from 'infrastructure/helpers/commonHelpers';
import { onMessage } from 'infrastructure/helpers/notificationsChannel';
import { fetchNotificationCounter } from 'state/notifications/actions';
import { If, Then, Else } from 'react-if';
import IncomingCall from 'views/IncomingCall';
import { APP_CONFIG } from 'constants/global-variables';
import { SocketState } from 'constants/enums';
import queryString from 'query-string';
import UserIdleCheck from 'containers/UserIdleCheck';
import AppInsightsTelemetryProvider from 'components/AppInsightsTelemetryProvider';
import StreamPermissions from 'components/StreamPermissions';
import StreamPermissionsModal from 'components/StreamPermissionsModal';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startApplication: false,
			displayContentUpdatedAlert: false,
			displaySocketConnectionAlert: false,
			socketConnectionState: SocketState.CONNECTED,
		};

		this.versionTimeout = null;
		this.socketStateTimeout = null;
	}

	componentDidMount = async () => {
		if (isAuthenticated()) {
			if (memberExists()) {
				let healthSystemExists = await this.checkIfHealthSystemExists();
				if (!healthSystemExists && this.props.organization.allHealthSystems.length !== 0) {
					let currentHealthSystemInfo = {
						currentHealthSystemId: this.props.organization.allHealthSystems[0].id,
						currentRegionId: this.props.organization.allHealthSystems[0].regions[0].id,
					};
					localStorage.setItem('currentHealthSystemInfo', JSON.stringify(currentHealthSystemInfo));
				}
			}
			onMessage(() => {
				this.props.fetchNotificationCounter();
			});
			this.startVersionTimeout();
		}
		// If tokens are provided and the url contains call
		// then set tokens along with the other data on localStorage
		// in order to bypass identity auth and get directly to call
		const authResult = this.getTokensIfCall();
		if (authResult) {
			setTokens(authResult);
			await setUserInfo();
		}
		this.setState({
			startApplication: true,
		});
	};

	checkIfHealthSystemExists = async () => {
		await this.props.organizationActions.getAllHealthSystems();
		let currentHealthSystemInfo = getCurrentHealthSystemInfo();
		if (currentHealthSystemInfo) {
			let { currentHealthSystemId } = getCurrentHealthSystemInfo();
			return this.props.organization.allHealthSystems.some(healthSystem => {
				return healthSystem.id === currentHealthSystemId;
			});
		}
		return false;
	};

	startVersionTimeout = () => {
		if (this.versionTimeout) {
			clearTimeout(this.versionTimeout);
		}

		const versionTimeoutInterval = +APP_CONFIG.versionCheckInterval || 10;
		this.versionTimeout = setTimeout(async () => {
			this.compareVersions();
		}, 1000 * 60 * versionTimeoutInterval);
	};

	compareVersions = async () => {
		try {
			const response = await fetch('/env/react-config.js');
			let configText = await response.text();

			configText = configText.replace(/'/g, `"`);

			const { REACT_APP_BUILD_NUMBER } = JSON.parse(configText.substring(configText.indexOf('{'), configText.lastIndexOf('}') + 1));

			if (APP_CONFIG.buildNumber && REACT_APP_BUILD_NUMBER && APP_CONFIG.buildNumber !== REACT_APP_BUILD_NUMBER) {
				this.setState({
					displayContentUpdatedAlert: true,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}

		this.startVersionTimeout();
	};

	onConnectionStateChange = async newSocketConnectionState => {
		if (this.state.socketConnectionState.type === newSocketConnectionState.type) {
			return;
		}

		this.setState({
			displaySocketConnectionAlert: true,
			socketConnectionState: newSocketConnectionState,
		});
		if (newSocketConnectionState.type === SocketState.CONNECTED.type) {
			if (this.socketStateTimeout) {
				clearTimeout(this.socketStateTimeout);
			}
			this.socketStateTimeout = setTimeout(() => {
				this.setState({
					displaySocketConnectionAlert: false,
				});
			}, 2000);
			await this.props.organizationActions.getTreeData();
			this.props.organization.activeMonitoringFeeds.forEach(({ roomId }) => {
				this.changeRoomAddDeviceIcon(roomId);
			});
			this.props.organizationActions.setTreeData({ ...this.props.organization.treeData });
		}
	};

	changeRoomAddDeviceIcon = roomId => {
		const sector = findSectorById(this.props.organization.treeData.tree, roomId);
		sector.customActionIcon = {
			iconColor: '#4cd137',
			icon: 'check_circle',
		};
	};

	shouldDisableIncomingCalls = () => {
		const pathname = window.location.pathname.split('/');
		if (pathname.length > 1) {
			const isCallView = pathname[1] === 'call';

			return isCallView;
		}

		return false;
	};

	getTokensIfCall = () => {
		const { search, pathname } = window.location;
		const query = queryString.parse(search);
		if (query.accessToken && query.idToken && pathname.startsWith('/call/')) {
			return { accessToken: query.accessToken, idToken: query.idToken };
		}
		return undefined;
	};

	render() {
		const { startApplication, socketConnectionState, displaySocketConnectionAlert, displayContentUpdatedAlert } = this.state;
		const currentHealthSystem = getCurrentHealthSystemInfo();
		const currentHealthSystemId = currentHealthSystem ? currentHealthSystem.currentHealthSystemId : null;
		const { streamPermission } = this.props.organization;
		return (
			<If condition={startApplication === true || (!isAuthenticated() && !this.getTokensIfCall())}>
				<Then>
					<AuthProvider>
						<Socket onConnectionStateChange={this.onConnectionStateChange} shouldDisableIncomingCalls={this.shouldDisableIncomingCalls}>
							<IncomingCall
								setStreamPermissionMessage={this.props.organizationActions.setStreamPermissionMessage}
								src='https://static.solaborate.com/temp/banyan.png'
							/>
							<BrowserRouter basename='/'>
								<AppInsightsTelemetryProvider>{Routes}</AppInsightsTelemetryProvider>
							</BrowserRouter>
							<div className='build-number'>
								{APP_CONFIG.buildNumber}
								<Alert
									display={displayContentUpdatedAlert}
									fixed={true}
									persist={true}
									variant='dark'
									hideCloseButton={true}
									message={
										<div>
											New version of the app is available{' '}
											<Button
												onClick={() => {
													window.location.reload(true);
												}}
												size='small'
												text='Refresh'
											/>
										</div>
									}
									onClose={() => {
										this.setState({
											displayContentUpdatedAlert: false,
										});
									}}
								/>
							</div>
							<div className='reconnecting'>
								<Alert
									display={displaySocketConnectionAlert}
									fixed={true}
									persist={true}
									hideCloseButton={true}
									message={socketConnectionState.message}
									onClose={() => {
										this.setState({
											displaySocketConnectionAlert: false,
										});
									}}
								/>
							</div>
							<UserIdleCheck healthSystemId={currentHealthSystemId} />
							{streamPermission && streamPermission.component === 'popup' && <StreamPermissions reason={streamPermission} />}
							{streamPermission && streamPermission.component === 'modal' && (
								<StreamPermissionsModal reason={streamPermission} setStreamPermissionMessage={this.props.organizationActions.setStreamPermissionMessage} />
							)}
						</Socket>
					</AuthProvider>
				</Then>
				<Else>
					<Grid rows='auto' width='100%' stretch='100vh' vertAlign='center' horizAlign='center'>
						<Loader />
					</Grid>
				</Else>
			</If>
		);
	}
}

const mapStateToProps = state => {
	return {
		organization: state.organization,
		notifications: state.notifications,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		fetchNotificationCounter: () => dispatch(fetchNotificationCounter()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
