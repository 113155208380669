import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import v4 from 'uuid';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames/bind';

import { actionCreators } from 'state/organization/actions';
import { SocketContext } from 'io-client/SocketContext';
import CallManager from 'owt/p2p/CallManager';
import { ConferenceInfo, ConferenceParticipant, FromUser } from 'owt/base/conference';
import { Grid, Modal, Form, Alert } from 'components';
import VideoFeed from 'components/Monitoring/VideoFeed';
import Layout from 'components/Common/Layout';
import SectorList from 'components/Common/SectorList';
import Header from 'components/Common/Header';
import CameraControls from 'components/Common/CameraControls';
import StreamPermissions from 'components/StreamPermissions';
import {
	CallTypes,
	ObjectType,
	ParticipantState,
	SectorTypes,
	SocketState,
	ConferenceEndReason,
	RTCPeerConnectionEnum,
	CameraType,
	CameraEventTypes,
	CameraTiltDirection,
	CameraTiltAction,
	ZoomDirection,
	MediaPermissions,
	MediaTypes,
	StreamError,
	ParticipantRemoveReason,
	ToggleFailureReasonEnum,
	InviteParticipantFailureReason,
} from 'constants/enums';
import { findSectorById, sortArrayByProperty, askForPermission, checkForPermission, checkIfMediaDevicesPlugged } from 'infrastructure/helpers/commonHelpers';
import SocketEvents from 'constants/socket-events';
import { APP_CONFIG } from 'constants/global-variables';
import { getUserProfile } from 'infrastructure/auth';
import UnassignedNurse from './UnassignedNurse';

class Monitoring extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			videoFeeds: {},
			participants: {},
			conferenceInfo: null,
			isFeedZoomed: false,
			roomHasDeviceAssigned: true,
			currentRoomName: '',
			isSecondColumnCollapsed: false,
			showDeviceControlsLockedModal: false,
			socketState: SocketState.CONNECTED,
			hasActiveConference: false,
			conferenceEndReason: null,
			shouldShowSwitchToHelloCamError: false,
			showAllowMicrophoneModal: false,
			statAlarmError: '',
			deniedParticipant: null,
			initiatorInfoFailed: false,
		};
		this.lowerBitrateFeedCountThreshold = 4;
		this.micStatus = null;
		this.camStatus = null;
		this.previousParticipantId = null;
		this.callManager = new CallManager(this.context, {
			useCallStats: APP_CONFIG.useCallStats && APP_CONFIG.sendCallStatsOnMonitoring,
			sendCallStatsInterval: APP_CONFIG.sendCallStatsInterval,
		});
	}

	async componentDidMount() {
		this.camStatus = await checkForPermission(MediaTypes.CAMERA);
		this.micStatus = await checkForPermission(MediaTypes.MICROPHONE);
		this.micStatus.onchange = this.onDevicePermissionChange;
		this.callManager
			.on('peer-stream', data => {
				const { origin } = data.peerSrc;
				const { videoFeeds, conferenceInfo } = this.state;

				const feed = this.getVideoFeedByParticipantId(origin, videoFeeds);
				if (!feed) {
					return;
				}

				feed.conference = conferenceInfo;
				feed.src = data.peerSrc;
				feed.status = ParticipantState.CONNECTED.type;

				this.handleBitrateChange();
				this.setState({ videoFeeds });
			})
			.on('update-participant', data => {
				const { participants, videoFeeds } = this.state;
				data.forEach(p => {
					participants[p.id] = {
						id: p.id,
						objectId: p.objectId,
					};
					const feed = videoFeeds[p.objectId];
					if (!!feed) {
						feed.participantId = p.id;
					}
				});
				this.setState({ participants });
			})
			.on('call-started', data => {
				if (data.failedInvites) {
					const { videoFeeds } = this.state;
					Object.values(videoFeeds).forEach(v => {
						const newFeed = { ...v };
						const failedFeed = data.failedInvites.find(f => f.objectId === v.deviceId);
						if (failedFeed.reason === InviteParticipantFailureReason.INVITE_DENIED) {
							newFeed.status = ParticipantState.INVITE_DENIED.type;
						}
						if (failedFeed.reason === InviteParticipantFailureReason.FAILED_TO_GET_INFO) {
							newFeed.status = ParticipantState.FAILED_TO_GET_INFO.type;
						}
						videoFeeds[v.deviceId] = newFeed;
					});
				}
				this.setState({ conferenceInfo: data.conferenceInfo, hasActiveConference: false });
			})
			.on('participant-status', statusResponse => {
				const { status, objectId, reason } = statusResponse;
				const { videoFeeds } = this.state;

				const feed = videoFeeds[objectId];
				if (feed) {
					if (
						(feed.status === ParticipantState.LEFT_CALL.type && status !== ParticipantState.REMOVED.type) ||
						feed.status === ParticipantState.DISCONNECTED.type ||
						(status === ParticipantState.LEFT_CALL.type &&
							[ParticipantRemoveReason.DISCONNECTED, ParticipantRemoveReason.DISCONNECTED_PARTICIPANT_CLEANUP].includes(reason))
					) {
						return;
					}
					feed.status = status;
					feed.removeReason = reason;
					this.setState({ videoFeeds });
				}
			})
			.on('participant-busy', data => {
				const videoFeeds = { ...this.state.videoFeeds };
				const feed = videoFeeds[data.objectId];
				if (feed) {
					const { activeConferences } = data;
					if (!activeConferences || activeConferences.length === 0) {
						this.callManager.logger.warn('On participant busy - no active conference was found');
						return undefined;
					}

					const conference = activeConferences.find(ac => ac.initialCallType === CallTypes.MONITORING);
					if (!conference) {
						this.callManager.logger.warn('On participant busy - no active patient view or talk to patient conference was found');
						return undefined;
					}

					// there should be only one participant with ObjectType User
					const monitoringNurse = conference.participants.find(p => p.objectType === ObjectType.USER);
					if (!monitoringNurse) {
						this.callManager.logger.warn('On participant busy - monitoring nurse is missing from participants');
						return undefined;
					}

					feed.status = ParticipantState.BUSY.type;
					feed.onPatientBusyNurse = monitoringNurse;
					this.setState({ videoFeeds });
				}
			})
			.on('camera-response', this.cameraResponseListener)
			.on('initial-device-state', ({ helloDeviceId, isNightVision, zoomLevel, isHuddleCamConnected, cameraType, isCameraPrivacyOn, isMicPrivacyOn }) => {
				const { videoFeeds } = this.state;
				const feed = videoFeeds[helloDeviceId];

				if (!feed) {
					return;
				}

				feed.zoomLevel = zoomLevel;
				feed.cameraType = cameraType;
				feed.isNightVisionActive = isNightVision;
				feed.isHuddleCamConnected = isHuddleCamConnected;
				feed.isCameraPrivacyOn = isCameraPrivacyOn;
				feed.isMicPrivacyOn = isMicPrivacyOn;

				this.setState({ videoFeeds });
			})
			.on('socket-state', ({ socketState }) => {
				if (this.state.socketState.type === socketState.type) {
					return;
				}
				const newState = { socketState };
				switch (socketState.type) {
					case SocketState.DISCONNECTED.type:
						Object.assign(newState, {
							disableButtons: true,
						});
						break;
					case SocketState.CONNECTED.type:
						Object.assign(newState, {
							disableButtons: false,
						});
						break;
					default:
						break;
				}

				this.setState(newState);
			})
			.on('peer-connection-state', ({ peerConnectionState, participantObjectId }) => {
				const { videoFeeds } = this.state;

				const newVideoFeeds = { ...videoFeeds };
				newVideoFeeds[participantObjectId].peerConnectionState = peerConnectionState;
				switch (peerConnectionState) {
					case RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED:
						newVideoFeeds[participantObjectId].status = ParticipantState.CONNECTED.type;
						break;
					case RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED:
						newVideoFeeds[participantObjectId].status = ParticipantState.RECONNECTING.type;
						if (newVideoFeeds[participantObjectId]?.isAlarmActive) {
							newVideoFeeds[participantObjectId].isAlarmActive = false;
						}
						break;
					case RTCPeerConnectionEnum.CONNECTION_STATE.FAILED:
						newVideoFeeds[participantObjectId].status = ParticipantState.RECONNECTING.type;
						break;
					default:
						break;
				}

				this.setState({ videoFeeds: newVideoFeeds });
			})
			.on('end-call', data => {
				Object.keys(this.state.videoFeeds).forEach(key => {
					this.changeRoomAddDeviceIcon(false, this.state.videoFeeds[key].roomId);
				});
				const deniedParticipant = Object.values(this.state.videoFeeds).find(f => f.deviceId === data.deniedParticipant?.objectId);
				const initiatorInfoFailed = data.endReason === ConferenceEndReason.FAILED_TO_GET_INITIATOR_INFO || false;

				this.setState({
					videoFeeds: {},
					participants: {},
					conferenceInfo: null,
					isFeedZoomed: false,
					roomHasDeviceAssigned: true,
					isSecondColumnCollapsed: false,
					showDeviceControlsLockedModal: false,
					hasActiveConference: data.hasActiveConference,
					conferenceEndReason: data.endReason,
					initiatorInfoFailed,
					deniedParticipant,
				});
				this.setActiveVideoFeeds([]);
			})
			.on('local-audio-error', this.onLocalAudioError)
			.on('re-add-feed', this.deviceStatusChanged)
			.bindSocketEventListeners(false, false);

		this.bindWindowListeners();
		this.context.on(SocketEvents.Conference.ON_DEVICE_CONTROLS_LOCKED, this.onDeviceLockedEventHandler);
		this.context.on(SocketEvents.Conference.AUDIO_TRACK_TOGGLED, this.onAudioTrackToggled);
		this.context.on(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, this.onAlertPatientResponse);
	}

	componentDidUpdate = prevProps => {
		if (prevProps.currentHealthSystemId !== this.props.currentHealthSystemId) {
			this.changeRoomsAndDevicesIcons();
		}
	};

	componentWillUnmount() {
		this.callManager.unbindSocketEventListeners().unbindTimeouts();

		this.context.off(SocketEvents.Conference.ON_DEVICE_CONTROLS_LOCKED, this.onDeviceLockedEventHandler);
		this.context.off(SocketEvents.Conference.AUDIO_TRACK_TOGGLED, this.onAudioTrackToggled);
		this.context.off(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, this.onAlertPatientResponse);

		if (Object.keys(this.state.videoFeeds).length) {
			this.endMonitoring();
		}

		this.setActiveVideoFeeds([]);
	}

	setActiveVideoFeeds = videoFeeds => {
		const mappedIds = Object.keys(videoFeeds).map(key => ({ helloDeviceId: videoFeeds[key].deviceId, roomId: videoFeeds[key].roomId }));
		this.props.setActiveMonitoringFeeds(mappedIds);
	};

	onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			this.props.setStreamPermissionMessage(null);
		} else {
			const { videoFeeds } = this.state;
			Object.keys(videoFeeds).forEach(key => {
				if (videoFeeds[key].audioTrack?.enabled) {
					this.toggleMonitoringMic(videoFeeds[key]);
				}
			});
		}

		this.callManager.publishConferenceLog(`Microphone permission state is changed to ${res.target.state}.`);
	};

	beforeUnloadEvent = event => {
		if (this.state.conferenceInfo) {
			event.preventDefault();
			event.returnValue = '';
		}
	};

	bindWindowListeners() {
		window.addEventListener('beforeunload', this.beforeUnloadEvent);
		window.addEventListener('unload', () => {
			if (this.state.conferenceInfo) {
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_LEFT });
			}
		});

		window.addEventListener('message', message => {
			if (message.data === 'IN_CALL') {
				if (this.state.conferenceInfo) {
					this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_IDLE });
				}
				window.location.href = '/logout';
			}
		});
	}

	prepareStartConferenceInfo = async deviceId => {
		const userProfile = getUserProfile();
		const fromUser = new FromUser(`${userProfile.firstName} ${userProfile.lastName}`, userProfile.jobTitle, userProfile.profilePicture.url);
		const participant = new ConferenceParticipant(deviceId, ObjectType.HELLO_DEVICE);
		const devices = await navigator.mediaDevices.enumerateDevices();
		const inputDevices = {
			devices,
			permissions: {
				camera: this.camStatus.state,
				microphone: this.micStatus.state,
			},
		};

		return new ConferenceInfo(
			CallTypes.MONITORING,
			v4(),
			'Monitoring',
			null,
			fromUser,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			v4(),
			[participant],
			inputDevices,
			true
		);
	};

	hasPermissionForMonitoring = async () => {
		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (this.micStatus.error) {
			return false;
		}

		switch (this.micStatus.state) {
			case MediaPermissions.DENIED:
				await askForPermission({ audio: true });
				return true;
			case MediaPermissions.PROMPT && pluggedDevices.microphone:
				this.props.setStreamPermissionMessage({
					component: 'modal',
					type: StreamError.MICROPHONE_BLOCKED.type,
				});
				await askForPermission({ audio: true });
				this.props.setStreamPermissionMessage(null);
				return true;
			default:
				return true;
		}
	};

	getFailedFeed = (videoFeeds, helloDeviceId, failedParticipants) =>
		Object.values(videoFeeds).forEach(v => {
			const nFeed = { ...v };
			if (nFeed.deviceId === helloDeviceId) {
				const failedFeed = failedParticipants.find(f => f.objectId === helloDeviceId);
				if (failedFeed.reason === InviteParticipantFailureReason.INVITE_DENIED) {
					nFeed.status = ParticipantState.INVITE_DENIED.type;
				}
				if (failedFeed.reason === InviteParticipantFailureReason.FAILED_TO_GET_INFO) {
					nFeed.status = ParticipantState.FAILED_TO_GET_INFO.type;
				}
				videoFeeds[v.deviceId] = nFeed;
			}
		});

	addFeed = async data => {
		if (this.state.deniedParticipant) {
			this.setState({ conferenceEndReason: null, deniedParticipant: null });
		}
		const hasPermission = await this.hasPermissionForMonitoring();

		if (!hasPermission) {
			return;
		}

		const { videoFeeds, conferenceInfo } = this.state;

		let { name } = findSectorById(this.props.treeData.tree, data.hospitalId);

		if (videoFeeds[data.helloDeviceId]) {
			return;
		}

		if (Object.keys(videoFeeds).length === 16) {
			this.setState({
				showFeedsLimitModal: true,
			});
			return;
		}

		this.changeRoomAddDeviceIcon(true, data.roomId);

		const newFeed = {
			src: null,
			roomName: `${name} > ${data.name}`,
			deviceId: +data.helloDeviceId,
			hospitalId: data.hospitalId,
			departmentId: data.departmentId,
			floorId: data.floorId,
			roomId: data.roomId,
			status: ParticipantState.CONNECTING.type,
			hospitalName: name,
			isPeerSpeakerActive: false,
			peerConnectionState: RTCPeerConnectionEnum.CONNECTION_STATE.NEW,
			zoomLevel: 0,
			cameraType: CameraType.HELLO,
			disabledTiltDirections: {},
			isCameraPrivacyOn: false,
			isMicPrivacyOn: false,
			dateAdded: +new Date(),
			isOnline: true,
		};

		videoFeeds[newFeed.deviceId] = newFeed;

		if (!conferenceInfo) {
			if (Object.keys(videoFeeds).length === 1) {
				this.callManager.startMonitoring(await this.prepareStartConferenceInfo(+data.helloDeviceId));
			} else if (Object.keys(videoFeeds).length > 1) {
				this.changeRoomAddDeviceIcon(false, data.roomId);
				delete videoFeeds[newFeed.deviceId];
			}
		} else {
			const response = await this.callManager.addDeviceToMonitoring({
				conferenceId: this.state.conferenceInfo.conferenceId,
				participantId: this.state.conferenceInfo.participantId,
				participant: {
					objectType: ObjectType.HELLO_DEVICE,
					objectId: +data.helloDeviceId,
				},
			});
			if (response?.failedInvitationToParticipants?.length) {
				this.getFailedFeed(videoFeeds, +data.helloDeviceId, response.failedInvitationToParticipants);
			}
		}
		this.setState({
			videoFeeds,
			roomHasDeviceAssigned: !!data.helloDeviceId,
			currentRoomName: data.name,
			initiatorInfoFailed: false,
		});
		this.setActiveVideoFeeds(videoFeeds);
	};

	removeFeed = key => {
		let { videoFeeds, conferenceInfo } = this.state;

		const feed = videoFeeds[key];
		if (!feed) {
			return;
		}

		if (feed.audioTrack) {
			feed.audioTrack.stop();
		}

		const { roomId, participantId } = feed;
		this.changeRoomAddDeviceIcon(false, roomId);
		delete videoFeeds[key];

		this.callManager.removeDeviceFromMonitoring({
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: participantId,
		});

		if (Object.keys(videoFeeds).length === 0) {
			this.endMonitoring();
			conferenceInfo = null;
		}

		let { isFeedExpanded } = feed;

		this.setState({
			videoFeeds,
			conferenceInfo,
			isFeedZoomed: isFeedExpanded ? !isFeedExpanded : this.state.isFeedZoomed,
		});
		this.handleBitrateChange();
		this.setActiveVideoFeeds(videoFeeds);
	};

	endMonitoring = () => {
		this.callManager.endCall({ endReason: ConferenceEndReason.INITIATOR_LEFT });
	};

	useHighBitrate = deviceId => {
		const { videoFeeds, conferenceInfo } = this.state;
		const feed = videoFeeds[deviceId];

		if (!feed) {
			return;
		}

		const { participantId } = feed;
		this.callManager.requestToChangeBitrate({
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: participantId,
			settings: {
				minBitrate: 700,
				maxBitrate: 900,
			},
		});
	};

	useLowBitrate = deviceId => {
		const { videoFeeds, conferenceInfo } = this.state;
		const feed = videoFeeds[deviceId];
		if (!feed) {
			return;
		}

		const { participantId } = feed;

		this.callManager.requestToChangeBitrate({
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: participantId,
			settings: {
				minBitrate: 400,
				maxBitrate: 600,
			},
		});
	};

	handleBitrateChange = () => {
		let { videoFeeds } = this.state;
		let videoFeedsLength = Object.keys(videoFeeds).length;
		let expandedFeed = Object.values(videoFeeds).find(v => v.isFeedExpanded);

		if (expandedFeed) {
			Object.keys(videoFeeds).forEach(key => {
				if (expandedFeed.deviceId !== videoFeeds[key].deviceId) {
					this.useLowBitrate(videoFeeds[key].deviceId);
				} else {
					this.useHighBitrate(videoFeeds[key].deviceId);
				}
			});
		} else if (videoFeedsLength <= this.lowerBitrateFeedCountThreshold) {
			Object.keys(videoFeeds).forEach(key => {
				this.useHighBitrate(videoFeeds[key].deviceId);
			});
		} else if (videoFeedsLength > this.lowerBitrateFeedCountThreshold) {
			Object.keys(videoFeeds).forEach(key => {
				this.useLowBitrate(videoFeeds[key].deviceId);
			});
		}
	};

	changeRoomAddDeviceIcon = (deviceAdded, roomId) => {
		const treeData = { ...this.props.treeData };
		const sector = findSectorById(treeData.tree, roomId);

		if (deviceAdded) {
			sector.customActionIcon = {
				iconColor: '#4cd137',
				icon: 'check_circle',
			};
		} else if (sector) {
			delete sector.customActionIcon;
		}

		this.props.setTreeData(treeData);
	};

	changeRoomsAndDevicesIcons = () => {
		const deviceIds = Object.keys(this.state.videoFeeds);
		if (deviceIds.length === 0) {
			return;
		}
		const treeData = { ...this.props.treeData };
		treeData.tree.forEach(hospital => {
			hospital.subOptions.forEach(dep => {
				dep.subOptions.forEach(floor => {
					floor.subOptions.forEach(room => {
						if (deviceIds.includes(room.helloDeviceId)) {
							// eslint-disable-next-line no-param-reassign
							room.customActionIcon = {
								iconColor: '#4cd137',
								icon: 'check_circle',
							};
						}
					});
				});
			});
		});
		this.props.setTreeData(treeData);
	};

	toggleExpandFeed = feed => {
		let videoFeeds = { ...this.state.videoFeeds };
		let previousExpandedFeed = Object.values(videoFeeds).find(v => v.isFeedExpanded) || {};
		let currentFeed = Object.values(videoFeeds).find(f => feed.deviceId === f.deviceId);
		if (previousExpandedFeed.deviceId !== currentFeed.deviceId) {
			previousExpandedFeed.isFeedExpanded = false;
		}
		currentFeed.isFeedExpanded = !currentFeed.isFeedExpanded;
		this.setState(
			{
				isFeedZoomed: currentFeed.isFeedExpanded,
				videoFeeds: videoFeeds,
			},
			() => {
				this.handleBitrateChange();
			}
		);
	};

	toggleNightVision = async feed => {
		const { conferenceInfo } = this.state;
		const nightVisionMode = !feed.isNightVisionActive;

		try {
			const toggleResponse = await this.callManager.toggleNightVision(nightVisionMode, feed.deviceId, conferenceInfo.conferenceId, feed.participantId);

			if (toggleResponse.deviceControlsLocked) {
				this.setState({ showDeviceControlsLockedModal: true });
			}
		} catch (err) {
			this.callManager.logger.error(`Toggle nightvision failed ${err}`);
		}
	};

	togglePeerSpeaker = async feed => {
		const { videoFeeds } = this.state;

		this.callManager.callerParticipantId = feed.participantId;
		const response = await this.callManager.toggleParticipantTrack(CallTypes.AUDIO, feed.isPeerSpeakerActive);
		// check if peer(hello) is in another call
		if (response.deviceControlsLocked) {
			this.setState({ showDeviceControlsLockedModal: true });
			return;
		}

		videoFeeds[feed.deviceId].isPeerSpeakerActive = !videoFeeds[feed.deviceId].isPeerSpeakerActive;
		// if peer(hello) is not in another call than disable speaker to the previous hellos
		const previousFeeds = Object.values(videoFeeds).filter(videoFeed => videoFeed.participantId !== feed.participantId && videoFeed.isPeerSpeakerActive);
		if (previousFeeds && previousFeeds.length) {
			previousFeeds.forEach(previousFeed => {
				this.callManager.toggleParticipantTrack(CallTypes.AUDIO, true, previousFeed.participantId);
			});
		}

		this.setState({ videoFeeds });
	};

	toggleAlarm = feed => {
		const { conferenceInfo } = this.state;

		const currentFeed = Object.values(this.state.videoFeeds).find(f => feed.deviceId === f.deviceId);

		if (!currentFeed) {
			return;
		}

		this.callManager.togglePatientAtRiskAlert({
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: feed.participantId,
			data: !currentFeed.isAlarmActive,
		});
	};

	onAlertPatientResponse = result => {
		const videoFeeds = { ...this.state.videoFeeds };
		const currentFeed = Object.values(videoFeeds).find(f => result.participantId === f.participantId);
		if (!currentFeed) {
			return;
		}
		if (!result.isSuccessful) {
			this.setState({
				statAlarmError: `There was an error toggling stat alarm of ${currentFeed.roomName}. Please try again!`,
			});
			return;
		}

		currentFeed.isAlarmActive = result.data;
		this.setState({
			statAlarmError: '',
			videoFeeds: videoFeeds,
		});
	};

	toggleMyMicrophone = async (feed, enabled) => {
		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (!pluggedDevices.microphone) {
			this.props.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return false;
		}

		if (this.micStatus.state === MediaPermissions.DENIED) {
			await askForPermission({ audio: true });
			this.props.setStreamPermissionMessage({
				component: 'popup',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
			return false;
		}

		if (this.micStatus.state === MediaPermissions.PROMPT) {
			this.props.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
			await askForPermission({ audio: true });
			this.props.setStreamPermissionMessage(null);
			return false;
		}

		this.toggleMonitoringMic(feed, enabled);

		return true;
	};

	toggleMonitoringMic = async (feed, enabled) => {
		const selectedFeed = feed;

		if (selectedFeed.audioTrack) {
			if (this.prevFeed && this.prevFeed.audioTrack && this.prevFeed.audioTrack.id !== selectedFeed.audioTrack.id) {
				this.prevFeed.audioTrack.enabled = false;
				this.callManager.monitoringTrackToggled(false, this.prevFeed.participantId);
			}

			this.prevFeed = selectedFeed;
			selectedFeed.audioTrack.enabled = enabled;
			this.callManager.monitoringTrackToggled(selectedFeed.audioTrack.enabled, selectedFeed.participantId);
			this.setFeedAudioTrackAndDisablePrevious(selectedFeed);
			return;
		}

		this.callManager.callerParticipantId = selectedFeed.participantId;

		const audioStream = await this.callManager.toggleAudio();

		if (this.callManager.p2p.hasPeerConnectionChannelForRemoteId(selectedFeed.participantId)) {
			const pc = this.callManager.p2p.getChannel(selectedFeed.participantId).getRTCPeerConnection();
			pc.onconnectionstatechange = event =>
				this.callManager.onConnectionStateChange(
					event,
					selectedFeed.participantId,
					selectedFeed.audioTrack && selectedFeed.audioTrack.readyState === 'live' ? audioStream : null
				);
		}

		if (!audioStream || audioStream.error) {
			// Unsure what this part of functionality was added for, looks like we need to remove it
			// if (this.prevFeed && this.prevFeed.audioTrack?.enabled) {
			// 	const videoFeeds = { ...this.state.videoFeeds };
			// 	this.setState({
			// 		showDeviceControlsLockedModal: !audioStream,
			// 		videoFeeds: Object.keys(videoFeeds).reduce((acc, key) => {
			// 			const newFeed = { ...acc[key] };
			// 			if (newFeed.participantId === this.prevFeed.participantId) {
			// 				newFeed.audioTrack.enabled = false;
			// 			}
			// 			acc[key] = newFeed;
			// 			return acc;
			// 		}, videoFeeds),
			// 	});
			// } else {
			// this.setState({ showDeviceControlsLockedModal: !audioStream });
			// }

			if (audioStream?.error?.failureReason === ToggleFailureReasonEnum.DEVICE_LOCKED) {
				this.setState({ showDeviceControlsLockedModal: true });
			} else if (audioStream?.error) {
				this.props.setStreamPermissionMessage({
					component: 'modal',
					type: StreamError.MICROPHONE_NOT_FOUND.type,
				});
			}
			return;
		}

		this.prevFeed = selectedFeed;
		const audioTrack = audioStream.mediaStream.getAudioTracks()[0];
		selectedFeed.audioTrack = audioTrack;
		this.setFeedAudioTrackAndDisablePrevious(selectedFeed);
	};

	setFeedAudioTrackAndDisablePrevious = currentFeed => {
		const videoFeeds = { ...this.state.videoFeeds };

		const newVideoFeeds = Object.keys(videoFeeds).reduce((acc, key) => {
			const newFeed = { ...acc[key] };
			if (newFeed.participantId === currentFeed?.participantId) {
				newFeed.audioTrack = currentFeed.audioTrack;
			} else if (newFeed.participantId !== currentFeed?.participantId && newFeed.audioTrack?.enabled) {
				newFeed.audioTrack.enabled = false;
			}
			acc[key] = newFeed;

			return acc;
		}, videoFeeds);

		this.setState({
			videoFeeds: newVideoFeeds,
		});
	};

	setFeedColor = (feedColor, participantId) => {
		const { videoFeeds } = this.state;
		Object.values(videoFeeds).forEach(videoFeed => {
			if (videoFeed.participantId === participantId && feedColor) {
				if (!videoFeed.colors || videoFeed.colors.length === 0) {
					videoFeed.colors = [feedColor];
				} else {
					const foundIndex = videoFeed.colors.findIndex(item => item === feedColor);
					if (foundIndex === -1) {
						videoFeed.colors.push(feedColor);
					} else {
						videoFeed.colors.splice(foundIndex, 1);
					}
				}
			}
		});
		this.setState({ videoFeeds });
	};

	onTreeViewLinkClick = sector => {
		if (Object.values(this.state.videoFeeds).length) {
			return;
		}

		if (sector.type === SectorTypes.ROOM) {
			this.setState({
				roomHasDeviceAssigned: !!sector.helloDeviceId,
				currentRoomName: sector.name,
			});
		}
	};

	collapseSecondColumn = () => {
		this.setState({
			isSecondColumnCollapsed: !this.state.isSecondColumnCollapsed,
		});
	};

	onDeviceLockedEventHandler = async data => {
		const { deviceId } = data;
		const newVideoFeeds = { ...this.state.videoFeeds };
		const feed = newVideoFeeds[deviceId];
		let showDeviceControlsLockedModal = false;
		let stateToSet = {};

		if (feed?.audioTrack) {
			showDeviceControlsLockedModal = feed.audioTrack.enabled;
			this.callManager.removeTrackById(feed.participantId, feed.audioTrack.id);
			feed.audioTrack.stop();
			delete feed.audioTrack;
			stateToSet = { videoFeeds: newVideoFeeds };
		}

		if (feed?.isPeerSpeakerActive) {
			showDeviceControlsLockedModal = true;
			// handle only ui because track will be removed from hello side
			feed.isPeerSpeakerActive = false;
		}

		if (showDeviceControlsLockedModal) {
			this.setState({ ...stateToSet, showDeviceControlsLockedModal });
		}
	};

	hasHealthSystems = () => {
		return this.props.allHealthSystems.length > 0;
	};

	sendCameraEvent = async (eventType, data) => {
		let deviceControlsLocked;
		switch (eventType) {
			case SocketEvents.HelloDevice.MOVE_CAMERA:
				({ deviceControlsLocked } = await this.doSendPanTiltCameraEvent(data));
				break;
			case SocketEvents.HelloDevice.COMMAND:
				({ deviceControlsLocked } = await this.callManager.rebootHuddleCam(data));
				break;
			default:
				({ deviceControlsLocked } = await this.callManager.sendCameraEvent(eventType, data));
		}

		if (deviceControlsLocked) {
			this.setState({ showDeviceControlsLockedModal: true });
		}
	};

	doSendPanTiltCameraEvent = async ({ direction, action }) => {
		const { videoFeeds, conferenceInfo } = this.state;
		const feeds = Object.values(videoFeeds);
		let expandedFeed = feeds.length === 1 ? feeds[0] : feeds.find(feed => feed.isFeedExpanded);

		if (!expandedFeed) {
			return {};
		}

		const { conferenceId } = conferenceInfo;
		const { participantId, deviceId } = expandedFeed;

		return this.callManager.panTiltCamera({ direction, helloDeviceId: deviceId, action, conferenceId, participantId });
	};

	cameraResponseListener = ({ event, message, isSuccessful, objectId }) => {
		const { videoFeeds } = this.state;
		let feed = videoFeeds[objectId];

		if (!feed) {
			return;
		}

		switch (event) {
			case CameraEventTypes.SWITCH:
				if (isSuccessful) {
					feed.cameraType = message;
					feed.zoomLevel = 0;
				}
				break;
			case CameraEventTypes.ZOOM:
				feed.zoomLevel = +message;
				break;
			case CameraEventTypes.TILT: {
				const { disabledTiltDirections } = feed;

				if (isSuccessful) {
					if ([CameraTiltDirection.UP, CameraTiltDirection.DOWN].includes(message)) {
						disabledTiltDirections[CameraTiltDirection.UP] = false;
						disabledTiltDirections[CameraTiltDirection.DOWN] = false;
					} else {
						disabledTiltDirections[CameraTiltDirection.LEFT] = false;
						disabledTiltDirections[CameraTiltDirection.RIGHT] = false;
					}
				} else {
					disabledTiltDirections[message] = true;
					this.doSendPanTiltCameraEvent({ direction: message, action: CameraTiltAction.STOP });
				}

				feed.disabledTiltDirections = disabledTiltDirections;
				break;
			}
			case CameraEventTypes.HUDDLE_CONNECTED_STATE:
				feed.isHuddleCamConnected = isSuccessful;
				break;
			case CameraEventTypes.NIGHT_VISION:
				feed.isNightVisionActive = isSuccessful;
				break;
			case CameraEventTypes.HELLO_CAMERA_PRIVACY_STATE: {
				feed.isCameraPrivacyOn = isSuccessful;
				break;
			}
			case CameraEventTypes.HELLO_MIC_PRIVACY_STATE: {
				feed.isMicPrivacyOn = isSuccessful;
				break;
			}
			default:
		}

		this.setState({ videoFeeds });
	};

	onToggleCameraSwitch = feed => {
		const { participantId, deviceId, cameraType, isCameraPrivacyOn } = feed;
		const { conferenceId } = this.state.conferenceInfo;

		const notAllowedToSwitchToHelloCam = cameraType === CameraType.HUDDLE && isCameraPrivacyOn;
		if (notAllowedToSwitchToHelloCam) {
			this.setState({ shouldShowSwitchToHelloCamError: true });
			return;
		}

		this.sendCameraEvent(SocketEvents.HelloDevice.SWITCH_CAMERA, { participantId, conferenceId, helloDeviceId: deviceId });
	};

	getConferenceEndedText = conferenceEndReason => {
		if (conferenceEndReason === ConferenceEndReason.DROPPED) {
			return 'Failed to reconnect to the network.';
		}

		if (conferenceEndReason === ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR) {
			return 'This session was ended by a Banyan admin.';
		}

		if (conferenceEndReason === ConferenceEndReason.PARTICIPANT_INVITE_DENIED) {
			return "You don't have sufficient permissions to call this participant. Please contact your administrator.";
		}

		return undefined;
	};

	getVideoFeedByParticipantId = (participantId, videoFeeds) => {
		const participant = this.state.participants[participantId];
		if (!participant) {
			this.callManager.logger.warn('Participant not found for remote ID:', origin);
			return null;
		}

		const { objectId } = participant;
		if (!objectId) {
			this.callManager.logger.warn('objectId not set for participant!');
			return null;
		}

		const feed = videoFeeds[objectId];
		if (!feed) {
			this.callManager.logger.warn('Video feed or source not found  for objectId: ', objectId);
			return null;
		}

		return feed;
	};

	onAudioTrackToggled = data => {
		if (!data) {
			return;
		}

		const { participantId, hasAudio } = data;
		const { videoFeeds } = this.state;
		const feed = this.getVideoFeedByParticipantId(participantId, videoFeeds);
		if (!feed) {
			return;
		}

		feed.isPeerSpeakerActive = hasAudio;
		this.setState({ videoFeeds });
	};

	onLocalAudioError = ({ participantId, trackDeviceNotFound, inputDevices }) => {
		const { videoFeeds } = this.state;
		const feed = this.getVideoFeedByParticipantId(participantId, videoFeeds);
		if (!feed) {
			return;
		}

		if (feed.audioTrack?.enabled && (!trackDeviceNotFound || !inputDevices.length)) {
			this.props.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
		}

		delete feed.audioTrack;

		this.setState({ videoFeeds });
	};

	deviceStatusChanged = ({ objectId }) => {
		const { videoFeeds } = this.state;

		const feed = videoFeeds[objectId];
		if (!feed) {
			return;
		}

		if (feed.audioTrack) {
			feed.audioTrack.stop();
			delete feed.audioTrack;
		}
		feed.isPeerSpeakerActive = false;
		feed.status = ParticipantState.RECONNECTING.type;

		this.setState({ videoFeeds });
	};

	toggleSecondColumnButton = () => (
		<button type='button' className='collapse-second-column' onClick={this.collapseSecondColumn}>
			<i className='material-icons-outlined'>{this.state.isSecondColumnCollapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}</i>
		</button>
	);

	render() {
		if (!this.hasHealthSystems()) {
			return <UnassignedNurse />;
		}

		const { videoFeeds, conferenceInfo, roomHasDeviceAssigned, disableButtons, conferenceEndReason, hasActiveConference, deniedParticipant } = this.state;

		const allFeeds = Object.values(videoFeeds);
		const feeds = sortArrayByProperty(allFeeds, 'dateAdded');
		const shouldShowConferenceEnded = [ConferenceEndReason.DROPPED, ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR].includes(conferenceEndReason);
		return (
			<Layout twoColumns={true} isSecondColumnCollapsed={this.state.isSecondColumnCollapsed}>
				<aside className='hello-list monitoring-list'>
					<SectorList isMonitoring={true} addFeed={this.addFeed} onTreeViewLinkClick={this.onTreeViewLinkClick} />
				</aside>
				<div className='users-view'>
					<Grid stretch='100%'>
						<main className='main-view monitoring-view'>
							<Header isMonitoring={true} />
							<section data-cy='treeNavSection'>
								{!shouldShowConferenceEnded && (
									<>
										{!hasActiveConference && (
											<>
												{roomHasDeviceAssigned && (
													<>
														<Grid className={`monitoring-feeds feeds-${feeds.length} ${this.state.isFeedZoomed ? `zoomed-feed` : ``}`} gridGap='5px'>
															{feeds.map(feed => (
																<VideoFeed
																	className={classNames(
																		feed.isFeedExpanded ? 'expand-feed' : '',
																		feed.status === ParticipantState.CONNECTING.type ? 'feed-is-connecting' : ''
																	)}
																	key={feed.deviceId}
																	src={feed.src}
																	title={feed.roomName}
																	localAudioTrack={feed.audioTrack}
																	isPeerSpeakerActive={feed.isPeerSpeakerActive}
																	isNightVisionActive={feed.isNightVisionActive}
																	isFeedExpanded={feed.isFeedExpanded}
																	isMicPrivacyOn={feed.isMicPrivacyOn}
																	isCameraPrivacyOn={feed.isCameraPrivacyOn}
																	isAlarmActive={feed.isAlarmActive}
																	status={feed.status}
																	removeReason={feed.removeReason}
																	onPatientBusyNurse={feed.onPatientBusyNurse}
																	feedColors={feed.colors}
																	onCloseClick={() => this.removeFeed(feed.deviceId)}
																	onExpandClick={() => this.toggleExpandFeed(feed)}
																	onToggleNightVision={() => this.toggleNightVision(feed)}
																	onToggleMyMicrophone={_.debounce(enabled => this.toggleMyMicrophone(feed, enabled), 200)}
																	onToggleAlarm={_.debounce(() => this.toggleAlarm(feed), 500)}
																	onTogglePeerSpeaker={_.debounce(() => this.togglePeerSpeaker(feed), 500)}
																	onSetFeedColor={color => this.setFeedColor(color, feed.participantId)}
																	disableButtons={disableButtons}
																	peerConnectionState={feed.peerConnectionState}
																	conferenceId={conferenceInfo && conferenceInfo.conferenceId}
																	isAnyFeedExpanded={this.state.isFeedZoomed}>
																	{(feed.isFeedExpanded || (feeds.length === 1 && feeds[0].src)) &&
																		conferenceInfo &&
																		feed.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED &&
																		feed.status === ParticipantState.CONNECTED.type && (
																			<CameraControls
																				cameraType={feed.cameraType}
																				cameraZoomLevel={feed.zoomLevel}
																				isHuddleCamConnected={feed.isHuddleCamConnected}
																				disabledTiltDirections={feed.disabledTiltDirections}
																				isDisabled={disableButtons}
																				isCameraPrivacyOn={feed.isCameraPrivacyOn}
																				onToggleCameraSwitch={() => this.onToggleCameraSwitch(feed)}
																				onZoomCamera={direction => {
																					const level = direction === ZoomDirection.INCREASE ? feed.zoomLevel + 20 : feed.zoomLevel - 20;
																					this.sendCameraEvent(SocketEvents.HelloDevice.ZOOM_CAMERA, {
																						level,
																						participantId: feed.participantId,
																						conferenceId: conferenceInfo.conferenceId,
																						helloDeviceId: feed.deviceId,
																					});
																				}}
																				onPanTiltCamera={(direction, action) =>
																					this.sendCameraEvent(SocketEvents.HelloDevice.MOVE_CAMERA, { direction, action })
																				}
																				onRebootHuddleCam={() =>
																					this.sendCameraEvent(SocketEvents.HelloDevice.COMMAND, {
																						participantId: feed.participantId,
																						conferenceId: conferenceInfo.conferenceId,
																						helloDeviceId: feed.deviceId,
																					})
																				}
																			/>
																		)}
																</VideoFeed>
															))}
														</Grid>

														{!Object.values(videoFeeds).length && !this.state.deniedParticipant && (
															<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
																<div style={{ textAlign: 'center' }}>
																	<h3 style={{ textAlign: 'center' }}>{this.state.currentRoomName}</h3>
																	<p>
																		In order to monitor rooms, please <br />
																		open a hospital, choose the department, <br />
																		click on a floor, and select a room.
																	</p>
																</div>
															</Grid>
														)}

														{!!this.state.deniedParticipant && (
															<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
																<div style={{ textAlign: 'center' }}>
																	<h3 style={{ textAlign: 'center' }}>{this.state.currentRoomName}</h3>
																	<p>
																		You don&apos;t have sufficient permissions to call {deniedParticipant?.roomName}. <br />
																		Please contact your administrator.
																	</p>
																</div>
															</Grid>
														)}
													</>
												)}

												{!roomHasDeviceAssigned && (
													<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
														<div style={{ textAlign: 'center' }}>
															<h3 style={{ textAlign: 'center' }}>{this.state.currentRoomName}</h3>
															<p style={{ margin: 0, padding: 0 }}>
																This room doesn't have any assigned devices.
																<br /> Please contact your administrator.
															</p>
														</div>
													</Grid>
												)}
											</>
										)}

										{hasActiveConference && (
											<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
												<div style={{ textAlign: 'center' }}>
													<p>
														You&apos;re already in a call or monitoring patients. <br />
														Please try again after you end that session.
													</p>
												</div>
											</Grid>
										)}
									</>
								)}

								{shouldShowConferenceEnded && (
									<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
										<div style={{ textAlign: 'center' }}>
											<p>
												{this.getConferenceEndedText(conferenceEndReason)} <br />
											</p>
										</div>
									</Grid>
								)}
							</section>
							<a className='collapse-second-column' onClick={this.collapseSecondColumn}>
								<i className='material-icons-outlined'>{this.state.isSecondColumnCollapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}</i>
							</a>
							<Alert
								display={this.state.shouldShowSwitchToHelloCamError}
								onClose={() => {
									this.setState({ shouldShowSwitchToHelloCamError: false });
								}}
								persist={true}
								message={`You can't switch back to Banyan Bridge because the physical privacy buttons have been enabled. These buttons can be disabled only manually. Please contact your administrator.`}
								variant='error'
								position='top'
							/>
							<Alert
								display={this.state.statAlarmError}
								onClose={() => {
									this.setState({ statAlarmError: '' });
								}}
								persist={true}
								message={this.state.statAlarmError}
								variant='error'
								position='top'
							/>
							<Alert
								display={this.state.initiatorInfoFailed}
								onClose={() => {
									this.setState({ initiatorInfoFailed: false });
								}}
								persist={true}
								message='Failed to get your information. Please try again.'
								variant='error'
								position='top'
							/>
						</main>
					</Grid>
				</div>
				<Prompt when={!!Object.keys(videoFeeds).length} message='Monitoring session will end upon leaving this page. Are you sure you want to leave?' />
				<Modal
					display={this.state.showDeviceControlsLockedModal}
					position='center'
					closeButtonText='Dismiss'
					submitButtonText=''
					onModalClose={() => {
						this.setState({ showDeviceControlsLockedModal: false });
					}}>
					<Form title='Device unavailable'>
						<p>A Virtual Care Provider is in a call with this device. You will be able to use these controls as soon as the call ends.</p>
					</Form>
				</Modal>
				<Modal
					display={shouldShowConferenceEnded}
					position='center'
					onModalClose={() => {
						window.location.reload();
					}}
					hideActionButtons={true}>
					<Form title={this.getConferenceEndedText(conferenceEndReason)}>
						<p>Please refresh the page.</p>
					</Form>
				</Modal>
				{this.state.streamPermission && <StreamPermissions reason={this.state.streamPermission} />}
			</Layout>
		);
	}
}

Monitoring.contextType = SocketContext;

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(Monitoring);
