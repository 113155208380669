import React, { Component } from 'react';
import { If, Then, Else } from 'react-if';
import classNames from 'classnames/bind';

const ListGroups = props => {
	const onListClick = (event, item) => {
		if (props.onItemClick) {
			props.onItemClick(event, item);
		}
	};

	const onScrollClick = (...args) => {
		if (props.onScroll) {
			props.onScroll(...args);
		}
	};

	return (
		<ul
			className={classNames('list-group', props.className)}
			onScroll={e => {
				onScrollClick(e);
			}}>
			{props.lists.map((item, index) => (
				<li key={index}>
					<If condition={!!item.component}>
						<Then>{item.component}</Then>
						<Else>
							<a href={item.linkTo} onClick={e => onListClick(e, item)}>
								{item.icon && <i className='material-icons-outlined'>{item.icon}</i>}
								{item.title}
								{item.subLists && <ListGroups lists={item.subLists} onItemClick={props.onItemClick} />}
							</a>
						</Else>
					</If>
				</li>
			))}
		</ul>
	);
};

class ListGroup extends Component {
	render() {
		return <ListGroups lists={this.props.lists} className={this.props.className} onScroll={this.props.onScroll} onItemClick={this.props.onItemClick} />;
	}
}

export default ListGroup;
