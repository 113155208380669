import React from 'react';
import { AuthConsumer } from '../../providers/authProvider';
import Loader from 'components/Loader';
import Grid from 'components/Grid';

const Callback = () => (
	<AuthConsumer>
		{({ signinRedirectCallback }) => {
			const isRedirectCalled = localStorage.getItem('isRedirectCalled');
			// Temporary Fix. We need to check why this component is being rendered twice.
			if (!isRedirectCalled) {
				localStorage.setItem('isRedirectCalled', 'true');
				signinRedirectCallback();
			} else {
				localStorage.removeItem('isRedirectCalled');
			}
			return (
				<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			);
		}}
	</AuthConsumer>
);

export default Callback;
