import React, { Component } from 'react';
import { AppInsightsContext, withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter } from 'react-router-dom';
import { ai } from 'infrastructure/helpers/appInsightsTelemetry';
import { APP_CONFIG } from 'constants/global-variables';

class AppInsightsTelemetryProvider extends Component {
	state = {
		initialized: false,
	};

	componentDidMount() {
		const { history } = this.props;
		const { initialized } = this.state;
		const appInsightsInstrumentationKey = APP_CONFIG.aiInstrumentationKey;

		if (!initialized && !!appInsightsInstrumentationKey && !!history) {
			ai.initialize(appInsightsInstrumentationKey, history);
			this.setState({ initialized: true });
		}
	}

	render() {
		const { children } = this.props;
		return <AppInsightsContext.Provider value={ai.reactPlugin}>{children}</AppInsightsContext.Provider>;
	}
}

export default withRouter(withAITracking(ai.reactPlugin, AppInsightsTelemetryProvider));
