import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import queryString from 'query-string';

import Grid from 'components/Grid';
import Input from 'components/Common/FormElements/Input';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { sendPasswordRecoveredEmail } from 'api/users';
import { Link } from 'react-router-dom';
import { passwordRegEx } from 'constants/global-variables';
import { ChangePasswordUseCases } from 'constants/enums';

class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			loaded: false,
			error: false,
			email: '',
			c: '',
		};
	}

	componentDidMount() {
		const params = queryString.parse(window.location.search);
		this.setState({
			c: params.c,
			e: params.e,
		});
	}

	submitForm(values) {
		this.setState({ loading: true });
		sendPasswordRecoveredEmail(this.state.e, values.password, this.state.c)
			.then(() => {
				this.setState({
					loading: false,
					loaded: true,
				});
			})
			.catch(() => {
				this.setState({
					loading: false,
					error: true,
				});
			});
	}

	render() {
		return (
			<Grid className='login' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' minContent>
				<Grid width='500px' className='login__wrapper'>
					<div style={{ width: '300px' }}>
						<img src='https://static.solaborate.com/temp/banyanlogo.svg' alt='banyan-logo' />
					</div>
					<div className='login-form-wrapper validate-recover-password'>
						{this.state.loading && !this.state.loaded && (
							<Grid width='100%' stretch='100%' horizAlign='center' vertAlign='center' rows='auto'>
								<div style={{ textAlign: 'center' }}>
									<Loader />
								</div>
							</Grid>
						)}
						{this.state.loaded && !this.state.loading && (
							<div className='warning-message'>
								<span className='material-icons'>error_outline</span>
								<div>
									<span>
										<b>Your Banyan password was successfully changed. Please login again using your new password</b>
									</span>
									<Link to='/' style={{ display: 'block', color: '#0095da', paddingTop: '15px' }}>
										Click here to go to login page
									</Link>
								</div>
							</div>
						)}
						{!this.state.loaded && !this.state.loading && (
							<div>
								<div className='warning-message'>
									<span className='material-icons'>error_outline</span>
									<span>
										To verify your new password, please enter it once in each field below. Password are case-sensitive and must be at least 8 characters long. A
										good password should contain a mix of capital and lower-case letters, numbers and symbols.
									</span>
								</div>
								<div style={{ paddingTop: '25px' }}>
									<Formik
										initialValues={{ password: '', passwordConfirm: '' }}
										onSubmit={values => this.submitForm(values)}
										validationSchema={Yup.object().shape({
											password: Yup.string()
												.required('Password is required')
												.min(8, 'At least 8 characters!')
												.max(64, 'No more than 64 characters!')
												.matches(passwordRegEx, ChangePasswordUseCases.strongPasswordCheck),
											passwordConfirm: Yup.string()
												.required('Password confirmation is required')
												.oneOf([Yup.ref('password')], 'Must match password')
												.matches(passwordRegEx, ChangePasswordUseCases.strongPasswordCheck),
										})}
										render={() => (
											<Form>
												<Field name='password' type='password' label='Password' placeholder='Password' variant='filled' component={Input} />
												<Field
													name='passwordConfirm'
													type='password'
													label='Confirm Password'
													placeholder='Confirm Password'
													variant='filled'
													component={Input}
												/>
												<br />
												<Button type='submit' text='Continue' display='block' />
											</Form>
										)}
									/>
								</div>
							</div>
						)}
					</div>
					<br />
					<br />
				</Grid>
			</Grid>
		);
	}
}

export default ResetPassword;
