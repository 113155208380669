import React from 'react';
import classNames from 'classnames/bind';

const Badge = props => (
	<div className={classNames('badge', props.variant, props.size)}>
		{props.text}
		{props.icon && <i className='material-icons'>{props.icon}</i>}
	</div>
);

export default Badge;
