import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { SectorTypes } from 'constants/enums';

const OptionsList = ({ options, selectedOptions, onChange, onSelectLocation, selectedId }) => {
	const toggleExpand = (selectedOptionId, isAddingSection) => {
		if (selectedOptions[selectedOptionId] && !isAddingSection) {
			delete selectedOptions[selectedOptionId];
		} else {
			selectedOptions[selectedOptionId] = {};
		}
		onChange(selectedOptions);
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		selectedOptions[optionId] = subSelections;
		onChange(selectedOptions);
	};

	const selectOption = option => {
		onSelectLocation(option);
	};

	return (
		<div>
			{options.map((option, index) => (
				<div className={classNames('tree', !option.id && !option.isNewOption ? 'hidden' : '')}>
					<div className={classNames('tree__child', selectedId === option.id ? 'selected' : '')}>
						<div>
							<i
								className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
								onClick={() => {
									toggleExpand(option.id);
								}}>
								{selectedOptions[option.id] ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
							</i>
							<i className='material-icons-outlined'>{option.icon}</i>
							<a
								data-tooltip={option.type === SectorTypes.ROOM ? 'Unassignable Level' : undefined}
								data-position='bottom'
								style={{ cursor: 'pointer' }}
								className={classNames('link', option.type === SectorTypes.ROOM || option.type === SectorTypes.REGION ? 'unassignable' : '')}
								onClick={option.type !== SectorTypes.ROOM && option.type !== SectorTypes.REGION ? () => selectOption(option) : undefined}>
								{option.name}
								<i className={classNames('material-icons-outlined', 'room-status', option.status)}>fiber_manual_record</i>
							</a>
						</div>
					</div>
					{option.subOptions && selectedOptions[option.id] && (
						<OptionsList
							options={option.subOptions}
							selectedOptions={selectedOptions[option.id]}
							onChange={subSelections => handleSubOptionsListChange(option.id, subSelections)}
							onSelectLocation={onSelectLocation}
							selectedId={selectedId}
						/>
					)}
				</div>
			))}
		</div>
	);
};

class AssignDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOptions: this.props.preSelected,
		};
	}

	render() {
		var { selectedOptions } = this.state;
		return (
			<div className='assign-dropdown'>
				{this.props.data.length !== 0 ? (
					<div className='assign-dropdown'>
						<OptionsList
							onSelectLocation={this.props.onSelectLocation}
							options={this.props.data}
							onChange={selectedOptions => {
								this.setState({ selectedOptions });
							}}
							selectedOptions={selectedOptions}
							selectedId={this.props.selectedId}
						/>
					</div>
				) : (
					<div className='tree' style={{ wordBreak: 'normal', textAlign: 'center' }}>
						This health system does not have any created hospitals. Make sure to create them before assigning nurses!
					</div>
				)}
			</div>
		);
	}
}

export default AssignDropdown;
