import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

/**
 * @function getAllHealthSystems
 * @desc Gets the user's health systems
 */
export function getAllHealthSystems() {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems`;
		gatewayApi
			.get(url)
			.then(response => res(response.data))
			.catch(error => rej(error));
	});
}

/**
 * @function createHealthSystem
 * @desc Creates a new health system
 * @param {string} healthSystemName, Name of the new health system  example: "Florida Health System"
 * @param {Array} healthSystemRegions, Regions of new health systems   example:  [{name: "South Region"}, {name: "Norht Region"}]
 */
export async function createHealthSystem(healthSystemName, healthSystemRegions) {
	const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems`;
	await gatewayApi.post(url, {
		name: healthSystemName,
		regions: healthSystemRegions,
	});
}

export async function editHealthSystemName(healthSystemId, newHealthSystemName) {
	const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}`;
	await gatewayApi.put(url, {
		name: newHealthSystemName,
	});
}

export async function editRegionName(healthSystemId, regionId, newRegionName) {
	const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/regions/${regionId}`;
	await gatewayApi.put(url, {
		name: newRegionName,
	});
}

export async function createNewRegion(healthSystemId, newRegionName) {
	const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/regions`;
	let response = await gatewayApi.post(url, {
		name: newRegionName,
	});
	return response.data.region;
}
