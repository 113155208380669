export const GeneralErrorCodes = {
	NotFound: 1000,
	ServerError: 1001,
	ValidationError: 1003,
	BadRequest: 1004,
	Unauthorized: 1005,
	Forbidden: 1006,
};

export const OrganizationErrorCodes = {
	UserExists: 3000,
	CompanyNotFound: 3001,
	MemberNotFound: 3002,
	InsufficientPermissions: 3003,
	TeamNotFound: 3004,
	ChannelNotFound: 3005,
	ActionNotPermitted: 3006,
	TeamMemberNotFound: 3007,
	InviteNotFound: 3008,
	UserNotFound: 3009,
	TeamMemberExists: 3010,
	InviteCancelled: 3011,
	InviteAlreadyAccepted: 3012,
	InviteExpired: 3013,
};

export const CallTypes = {
	AUDIO: 1,
	VIDEO: 2,
	SCREENSHARE: 3,
	SECURITYCAM: 4,
	LIVEBROADCAST: 5,
	MONITORING: 6,
};

export const BanyanCallTypesMessage = {
	TALK_TO_PATIENT: 'Talk To Patient',
	TALK_TO_NURSE: 'Talk To Nurse',
	VIDEO_CALL: 'Video Call',
	PATIENT_VIEW: 'Patient View',
	MONITORING: 'Monitoring',
};

export const VirtualCareProviderRoles = {
	VirtualNurse: 1,
	VirtualCaseManager: 2,
	VirtualSitter: 3,
	VirtualIntensivist: 4,
};

export const ObjectType = {
	USER: 0,
	HELLO_DEVICE: 1,
	SIP_USER: 999,
};

export const MediaTypes = {
	CAMERA: 'camera',
	MICROPHONE: 'microphone',
};

export const MediaPermissions = {
	DENIED: 'denied',
	GRANTED: 'granted',
	PROMPT: 'prompt',
};

export const MediaInputs = {
	AUDIO_INPUT: 'audioinput',
	VIDEO_INPUT: 'videoinput',
};

export const CallStatus = {
	UNDEFINED: {
		type: 0,
		message: 'Undefined',
	},
	SUCCESSFUL: {
		type: 1,
		message: 'Successful',
	},
	PARTIALLYSUCCESSFUL: {
		type: 2,
		message: 'PartiallySuccessful',
	},
	FAILED: {
		type: 3,
		message: 'Failed',
	},
	DISRUPTED: {
		type: 4,
		message: 'Disrupted',
	},
	PARTIALLYDISRUPTED: {
		type: 5,
		message: 'Partially Disrupted',
	},
	MISSED: {
		type: 6,
		message: 'Missed',
	},
	ABORTED: {
		type: 7,
		message: 'Aborted',
	},
	DROPPED: {
		type: 8,
		message: 'Dropped',
	},
};

export const InviteStatuses = {
	SENT: {
		type: 1,
		message: 'Sent',
	},
	ACCEPTED: {
		type: 2,
		message: 'Accepted',
	},
	PENDING: {
		type: 3,
		message: 'Pending',
	},
	CANCELLED: {
		type: 4,
		message: 'Cancelled',
	},
	FAILED: {
		type: 5,
		message: 'Failed',
	},
};

export const InviteTypes = {
	USER: {
		type: 1,
		message: 'User',
	},
	GUEST: {
		type: 2,
		message: 'Guest',
	},
	ADMIN: {
		type: 3,
		message: 'Admin',
	},
	VIRTUALCAREPROVIDER: {
		type: 4,
		message: 'Virtual Care Provider',
	},
	VIRTUALSITTER: {
		type: 5,
		message: 'Virtual Sitter',
	},
	SUPERUSER: {
		type: 6,
		message: 'Super User',
	},
	ADUSER: {
		type: 7,
		message: 'AD User',
	},
};

export const SectorTypes = {
	HEALTHSYSTEM: 'health system',
	REGION: 'region',
	HOSPITAL: 'hospital',
	DEPARTMENT: 'department',
	FLOOR: 'floor',
	ROOM: 'room',
};

export const PresenceStatusType = {
	AVAILABLE: 1,
	UNAVAILABLE: 2,
};

export const PresenceStatusTypeLabels = {
	1: 'Available',
	2: 'Unavailable',
};

export const CallStatuses = {
	ACCEPTED: 0,
	DECLINED: 0,
};

export const UserTypes = {
	OWNER: 1,
	ADMIN: 2,
	VIRTUALCAREPROVIDER: 6,
	VIRTUALSITTER: 7,
	SUPERUSER: 8,
};

export const UserRoles = {
	ADMIN: 'Admin',
	NURSE: 'VirtualCareProvider',
	VIRTUALSITTER: 'VirtualSitter',
	SUPERUSER: 'SuperUser',
};

export const ZoomDirection = {
	INCREASE: '+',
	DECREASE: '-',
	RESET: 0,
};

export const CameraTiltDirection = {
	UP: '1',
	DOWN: '2',
	RIGHT: '3',
	LEFT: '4',
};

export const CameraTiltAction = {
	START: 'start',
	STOP: 'stop',
};

export const DeviceStatus = {
	ONLINE: 'online',
	OFFLINE: 'offline',
};

export const DeviceListLevel = {
	HEALTHSYSTEM: 0,
	REGION: 1,
	HOSPITAL: 2,
	DEPARTMENT: 3,
	FLOOR: 4,
	ROOM: 5,
	ORGANIZATION: 6,
};

export const SerialTVCommands = {
	INITIAL_TV_POWER: 0,
	POWER: {
		POWER_ON: 1,
		POWER_OFF: 2,
	},
	HDMI: {
		SWITCH_HDMI1: 3,
		SWITCH_HDMI2: 4,
		SWITCH_HDMI3: 5,
	},
};

export const ParticipantState = {
	CONNECTING: {
		type: 0,
		message: 'Connecting',
	},
	CONNECTED: {
		type: 1,
		message: 'Connected',
	},
	BUSY: {
		type: 2,
		message: 'Busy',
	},
	DECLINED: {
		type: 3,
		message: 'Declined',
	},
	OFFLINE: {
		type: 4,
		message: 'Offline',
	},
	LEFT_CALL: {
		type: 5,
		message: 'Left the call',
	},
	NOT_ANSWERING: {
		type: 6,
		message: 'Not answering',
	},
	CANT_CONNECT: {
		type: 7,
		message: 'Cant connect',
	},
	DISRUPTED: {
		type: 8,
		message: 'Disrupted',
	},
	RECONNECTING: {
		type: 9,
		message: 'Reconnecting',
	},
	DISCONNECTED: {
		type: 10,
		message: 'Disconnected',
	},
	REMOVED: {
		type: 11,
		message: 'Removed',
	},
	FAILED_TO_GET_INFO: {
		type: 98,
		message: 'Failed to get info',
	},
	INVITE_DENIED: {
		type: 99,
		message: 'Invite denied',
	},
};

export const ParticipantRemoveReason = {
	UNDEFINED: 0,
	PARTICIPANT_LEAVE: 1,
	DISCONNECTED: 2,
	PARTICIPANT_FORCED_LEAVE: 3,
	CLEAR_PARTICIPANT_OLD_CONFERENCES: 4,
	PARTICIPANT_CLEANUP: 5,
	PASSWORD_CHANGED: 6,
	IDLE: 7,
	CONFERENCE_TERMINATED_BY_ADMINISTRATOR: 8,
	DISCONNECTED_PARTICIPANT_CLEANUP: 9,
	SOMEONE_ELSE_ANSWERED: 10,
	HIGHER_PRIORITY_CALL: 11,
	FAILED_TO_GET_INFO: 12,
};

export const ConferenceEndReason = {
	UNDEFINED: 0,
	PARTICIPANT_OFFLINE: 1,
	PARTICIPANT_BUSY: 2,
	INITIATOR_LEFT: 3,
	PARTICIPANT_NOT_ANSWERING: 4,
	PARTICIPANT_DECLINED: 5,
	PARTICIPANT_LEFT: 6,
	OWNER_LEFT: 7,
	ABORTED: 8,
	DROPPED: 9,
	PARTICIPANT_PASSWORD_CHANGED: 10,
	PARTICIPANT_IDLE: 11,
	TERMINATED_BY_ADMINISTRATOR: 12,
	PARTICIPANT_INVITE_DENIED: 13,
	PARTICIPANT_INVITE_FAILED: 14,
	FAILED_TO_GET_INITIATOR_INFO: 991,
	REMOVED: 992,
	UNAUTHORIZED: 993,
	E2EE_FAILED: 994,
	ANSWERED_ELSEWHERE: 995,
	TRANSFERRED_TO_ANOTHER_CLIENT: 996,
	HAS_ACTIVE_CONFERENCE: 997,
	INITIATOR_BUSY: 998,
	CLEANUP: 999,
};

export const StartConferenceFailureReason = {
	EXPIRED_TOKEN: 'startConferenceFailureReason.expiredToken',
	GET_INITIATOR_INFO_FAILED: 'startConferenceFailureReason.getInitiatorInfoFailed',
};

export const InviteParticipantFailureReason = {
	INVITE_DENIED: 'inviteParticipant.denied',
	FAILED_TO_GET_INFO: 'inviteParticipant.failedToGetInfo',
};

export const ChangePasswordUseCases = {
	passwordInfo:
		'Passwords are case-sensitive and must be at least 8 characters. A strong password should contain a mix of capital and lower-case letters, numbers and symbols.',
	currentPassEmpty: 'Please enter your current password, cannot be left blank.',
	newPassEmpty: 'Please enter a new password, it cannot be left blank.',
	confirmNewPassEmpty: 'Please re-enter password, it cannot be left blank.',
	minLengthRequired: 'New password must contain minimum 8 characters.',
	newPasswordsDoNotMatch: 'Passwords do not match, please re-enter.',
	newPassSameAsCurrent: 'New password is same as the current password.',
	newPassSaved: 'Your password has been changed.',
	newPassSet: 'Your password has been set.',
	currentPasswordIncorrect: 'Your current password was incorrectly typed.',
	strongPasswordCheck: 'Password should have at least one uppercase letter, one lowercase letter, one number and one special character:',
};

export const DeviceStatusMessages = {
	deviceOnCall: 'Device on another call.',
	deviceOnCallWithNurse: 'This device is being monitored by',
	deviceOffline: 'Device offline',
	initiatingMonitoring: 'Initiating...',
	notAnswering: "Can't connect to this device. Please try again.",
	leftCall: 'Failed to reconnect.',
	disconnected: 'Patient is disconnected from the call.',
	reconnecting: 'Please wait…attempting to reconnect to the network.',
	removed: 'This session was ended by a Banyan admin.',
	participantInviteDenied: "You don't have sufficient permissions to call this participant. Please contact your administrator.",
	participantInviteFailed: 'Failed to get participant information. Please try again.',
	failedToGetInitiatorInformation: 'Failed to get your information. Please try again.',
};

export const FeedColors = {
	red: '#e84118',
	blue: '#00a8ff',
	orange: '#ff9f43',
	yellow: '#fbc531',
	pink: '#f368e0',
	black: '#000',
	cyan: '#6BDCFF',
	purple: '#852FC8',
	darkBlue: '#5e75aa',
	gray: '#d3d3d3',
	lightBlue: '#add8e6',
};

export const StreamError = {
	SCREEN_SHARE_CANCELED: {
		type: 0,
		message: 'Screen share canceled',
	},
	CANT_ACCESS_MEDIA_STREAM: {
		type: 1,
		message: `Can't access media stream`,
	},
	BROWSER_NOT_SUPPORTED: {
		type: 2,
		message: 'Browser not supported',
	},
	EXTENSION_NOT_INSTALLED: {
		type: 3,
		message: 'Extension not installed',
	},
	DEVICE_NOT_FOUND: {
		type: 4,
		message: 'Device not found',
	},
	STREAM_NOT_ALLOWED: {
		type: 5,
		message: 'Stream not allowed',
	},
	PERMISSION_DISMISSED: {
		type: 6,
		message: 'Permission dismissed',
	},
	NOT_READABLE_ERROR: {
		type: 7,
		message: 'Not readable error',
	},
	ONLY_SCREEN_FOUND: {
		type: 8,
		message: 'Only screen found',
	},
	SCREEN_SHARE_NOT_SUPPORTED: {
		type: 9,
		message: 'Screen share not supported',
	},
	CAMERA_BLOCKED: {
		type: 10,
		message: 'Camera permission is blocked',
	},
	MICROPHONE_BLOCKED: {
		type: 11,
		message: 'Microphone permission is blocked',
	},
	CAM_AND_MIC_BLOCKED: {
		type: 12,
		message: 'Camera and microphone permission is blocked',
	},
	MICROPHONE_NOT_FOUND: {
		type: 13,
		message: 'Microphone not found',
	},
	CAMERA_NOT_FOUND: {
		type: 14,
		message: 'Camera not found',
	},
};

export const SocketState = {
	CONNECTED: {
		type: 0,
		message: 'Connected',
	},
	RECONNECTING: {
		type: 1,
		message: 'Reconnecting...',
	},
	DISCONNECTED: {
		type: 2,
		message: 'Connection is lost',
	},
};

export const DeviceCommands = {
	REBOOT: 0,
	SHUTDOWN: 1,
	ENABLE_METRICS: 2,
	FACTORY_RESET: 3,
	FORCE_UPDATE: 4,
	PULL_LOGS: 5,
	REBOOT_HUDDLE_CAM: 6,
	SWITCH_AUDIO_OUTPUT: 7,
	REQUEST_ALLOWLIST_CHECK: 10,
};

export const ConferenceErrorType = {
	SIGNALING_ERROR: 0, // failures or errors during the signaling procedure
	MEDIA_SOURCE_ERROR: 1, // failure to get access to media device (mic, camera, screen-capture)
	SDP_GENERATION_ERROR: 2, // SDP generation error occur either when generating an offer or answer with incorrect configuration parameters
	NEGOTIATION_FAILURE: 3, // negotiation errors during SDP offer/answer, they mainly occur due ti differing media and network configuration between endpoints
	ICE_CONNECTION_FAILURE: 4, // ICE failures occur when NAT/firewall traversal fails
	TRANSPORT_FAILURE: 5, // call drops after the connection establishment
};

export const CameraType = {
	HELLO: 'Hello',
	HUDDLE: 'Banyan',
};

export const CameraZoomLevels = {
	HELLO_MAX: 100,
	HUDDLE_MAX: 400,
};

export const CameraEventTypes = {
	ZOOM: 'zoom',
	SWITCH: 'switch',
	TILT: 'tilt',
	HUDDLE_CONNECTED_STATE: 'huddleCamConnectionState',
	NIGHT_VISION: 'nightVision',
	HELLO_CAMERA_PRIVACY_STATE: 'helloCameraPrivacyState',
	HELLO_MIC_PRIVACY_STATE: 'helloMicPrivacyState',
};

export const RTCPeerConnectionEnum = {
	CONNECTION_STATE: {
		NEW: 'new',
		CONNECTING: 'connecting',
		CONNECTED: 'connected',
		DISCONNECTED: 'disconnected',
		FAILED: 'failed',
		CLOSED: 'closed',
	},
	SIGNALING_STATE: {
		STABLE: 'stable',
		HAVE_LOCAL_OFFER: 'have-local-offer',
		HAVE_REMOTE_OFFER: 'have-remote-offer',
		HAVE_LOCAL_PRANSWER: 'have-local-pranswer',
		HAVE_REMOTE_PRANSWER: 'have-remote-pranswer',
		CLOSED: 'closed',
	},
	ICE_CONNECTION_STATE: {
		NEW: 'new',
		CHECKING: 'checking',
		CONNECTED: 'connected',
		COMPLETED: 'completed',
		FAILED: 'failed',
		DISCONNECTED: 'disconnected',
		CLOSED: 'closed',
	},
	ICE_GATHERING_STATE: {
		NEW: 'new',
		GATHERING: 'gathering',
		COMPLETE: 'complete',
	},
};

export const JoinConferenceFailureReasonEnum = {
	UNHANDLED_EXCEPTION: 'joinConferenceFailureReason.unhandledException',
	NULL_CONFERENCE: 'joinConferenceFailureReason.nullConference',
	WRONG_PARTICIPANT_STATE: 'joinConferenceFailureReason.wrongParticipantState',
	FAILED_TO_GET_PARTICIPANT_INFO: 'joinConferenceFailureReason.failedToGetParticipantInfo',
};

export const ToggleFailureReasonEnum = {
	DEVICE_LOCKED: 'toggleFailureReason.deviceLocked',
};

export const TeamTypes = {
	HEALTHSYSTEM: 4,
	REGION: 5,
	HOSPITAL: 6,
	DEPARTMENT: 7,
	FLOOR: 8,
};

export const TerminateRequestFailureReasonEnum = {
	UNHANDLED_EXCEPTION: 'terminateRequestFailureReason.unhandledException',
	NULL_CONFERENCE: 'terminateRequestFailureReason.nullConference',
	TERMINATE_IN_PROGRESS: 'terminateRequestFailureReason.terminateInProgress',
	NULL_TERMINATE_REQUEST: 'terminateRequestFailureReason.nullTerminateRequest',
	NULL_CONNECTED_USER_PARTICIPANT: 'terminateRequestFailureReason.nullConnectedUserParticipant',
	NO_PERMISSION: 'terminateRequestFailureReason.noPermission',
};

export const TerminateRequestFailureReasonMessages = {
	[TerminateRequestFailureReasonEnum.UNHANDLED_EXCEPTION]: 'Something went wrong. Please retry again.',
	[TerminateRequestFailureReasonEnum.NULL_CONFERENCE]: 'This session has already been ended.',
	[TerminateRequestFailureReasonEnum.TERMINATE_IN_PROGRESS]: 'Termination for this session is initiated by another administrator.',
	[TerminateRequestFailureReasonEnum.NULL_CONNECTED_USER_PARTICIPANT]: 'Something went wrong. Please retry again.',
	[TerminateRequestFailureReasonEnum.NO_PERMISSION]: 'You have no permission to terminate this session.',
};

export const ClientTypes = {
	BANYAN: 2,
};

export const Roles = {
	ADMIN: { id: 2, value: 'Admin' },
	VIRTUALCAREPROVIDER: { id: 6, value: 'Virtual Care Provider' },
	VIRTUALSITTER: { id: 7, value: 'Virtual Sitter' },
	SUPERUSER: { id: 8, value: 'Super user' },
};

export const AudioOutputDevice = {
	HELLO: 0,
	TV: 1,
};

export const NursePoolRolePriorities = {
	PRIMARY: {
		type: 1,
		message: 'Primary',
	},
	SECONDARY: {
		type: 2,
		message: 'Secondary',
	},
};

export const ConfigHistoryTypes = {
	UserIdle: '1',
	Devices: '2',
	ADs: '3',
	TeamConfigurationProfiles: '4',
	NtpConfiguration: '5',
};

export const TVs = {
	LG: 'LG (MPI)',
	SAMSUNG: 'Samsung (Exlink)',
	TELEHEALTH_CONTROLLED: 'Telehealth-Controlled',
	PCARE: 'pCare',
	CEC: 'CEC',
};

export const TelehealthTv = {
	id: 3,
	label: 'Telehealth',
	value: TVs.TELEHEALTH_CONTROLLED,
};

export const StreamTypes = {
	AUDIO: 1,
	VIDEO: 2,
	SCREEN_SHARE: 3,
};

export const AndroidVersions = [
	{ codeName: 'No codename', versionNum: '1.0', apiLevel: [1] },
	{ codeName: 'No codename', versionNum: '1.1', apiLevel: [2] },
	{ codeName: 'Cupcake', versionNum: '1.5', apiLevel: [3] },
	{ codeName: 'Donut', versionNum: '1.6', apiLevel: [4] },
	{ codeName: 'Eclair', versionNum: '2.0 - 2.1', apiLevel: [5, 6, 7] },
	{ codeName: 'Froyo', versionNum: '2.2 - 2.2.3', apiLevel: [8] },
	{ codeName: 'Gingerbread', versionNum: '2.3 - 2.3.7', apiLevel: [9, 10] },
	{ codeName: 'Honeycomb', versionNum: '3.0 - 3.2.6', apiLevel: [11, 12, 13] },
	{ codeName: 'Ice Cream Sandwich', versionNum: '4.0 - 4.0.4', apiLevel: [14, 15] },
	{ codeName: 'Jelly Bean', versionNum: '4.1 - 4.3.1', apiLevel: [16, 17, 18] },
	{ codeName: 'KitKat', versionNum: '4.4 - 4.4.4', apiLevel: [19, 20] },
	{ codeName: 'Lollipop', versionNum: '5.0 - 5.1.1', apiLevel: [21, 22] },
	{ codeName: 'Marshmallow', versionNum: '6.0 - 6.0.1', apiLevel: [23] },
	{ codeName: 'Nougat', versionNum: '	7.0', apiLevel: [24] },
	{ codeName: 'Nougat', versionNum: '7.1.0 - 7.1.2', apiLevel: [25] },
	{ codeName: 'Oreo', versionNum: '8.0', apiLevel: [26] },
	{ codeName: 'Oreo', versionNum: '8.1', apiLevel: [27] },
	{ codeName: 'Pie', versionNum: '9.0', apiLevel: [28] },
	{ codeName: 'Android 10', versionNum: '10.0', apiLevel: [29] },
	{ codeName: 'Android 11', versionNum: '11', apiLevel: [30] },
];

export const NursePoolingOptions = {
	NO_ANSWER: 0,
	YES: 1,
	NO: 2,
};

export const DeviceSettings = {
	VoiceCommandCalling: 14, // Currently we only care about this
};

export const TeamConfigurationIntegrationType = {
	TELEHEALTH: 1,
	PCARE: 2,
};

export const KeyCodes = {
	PERIOD: 190,
	NUMPAD_PERIOD: 110,
	MINUS: 189,
	NUMPAD_SUBTRACT: 109,
	NUMPAD_ADD: 107,
	PLUS: 187,
	LETTER_E: 69,
	NUMPAD_MINUS: 109,
	LETTER_A: 65,
	LETTER_Z: 90,
	ENTER: 13,
	SPACE: 32,
	BACK_SPACE: 8,
	LEFT_ARROW: 37,
	UP_ARROW: 38,
	RIGHT_ARROW: 39,
	DOWN_ARROW: 40,
};

export const TvBrands = {
	UNKNOWN: -1,
	SAMSUNG: 0,
	LG: 1,
	CEC_TV: 2,
};

export const TvControlsEnums = {
	POWER: 'Power',
	VOLUME: 'Volume',
};
