import SocketEvents from 'constants/socket-events';

/**
 * @class SignalingChannel
 * @classDesc Signaling module for Open WebRTC Toolkit P2P chat
 */
export default function SignalingChannel(socket, logger) {
	this.onMessage = null;
	const wsServer = socket;

	const bindOn = (event, callback) => {
		wsServer.on(event, data => {
			logger.debug(`WS:ON:${event}`, { socketId: wsServer?.id }, data);
			callback(data);
		});
	};

	this.send = function(event, message) {
		return new Promise((resolve, reject) => {
			logger.debug(`WS:EMIT:${event}`, { socketId: wsServer?.id }, message);
			wsServer.emit(event, message, data => {
				if (data) {
					resolve(data);
				} else {
					reject();
				}
			});
		});
	};

	bindOn(SocketEvents.Conference.ON_NEW_ICE_CANDIDATE, _data => {
		const newData = {
			from: _data.participantId,
			data: {
				type: SocketEvents.Conference.SDP,
				data: {
					type: 'candidate',
					candidate: _data.candidate.sdp,
					sdpMid: _data.candidate.sdpMid,
					sdpMLineIndex: _data.candidate.sdpMLineIndex,
					streamInfo: null,
				},
			},
		};

		if (this.onMessage) this.onMessage(newData.from, newData.data);
	});

	bindOn(SocketEvents.Conference.ON_NEW_ANSWER, _data => {
		const newData = {
			from: _data.participantId,
			data: {
				type: SocketEvents.Conference.SDP,
				data: _data.sdp,
				streamInfo: _data.streamInfo,
			},
		};

		if (this.onMessage) {
			this.onMessage(newData.from, newData.data);
		}
	});

	bindOn(SocketEvents.Conference.ON_NEW_OFFER, _data => {
		const newData = {
			from: _data.participantId,
			data: {
				type: SocketEvents.Conference.SDP,
				data: _data.sdp,
				streamInfo: _data.streamInfo,
			},
		};

		if (this.onMessage) {
			this.onMessage(newData.from, newData.data);
		}
	});
}
