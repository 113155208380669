import React from 'react';

const DescriptionBox = props => (
	<ul className='description'>
		{props.data.map((data, index) => (
			<li key={index}>
				<span data-test-id='component-description-box-title'>{data.title && data.title + ':'}</span>
				{data.description}
			</li>
		))}
	</ul>
);

export default DescriptionBox;
