import { gatewayApi } from '../constants/global-variables';

export function getUserNotifications(userId, appType, pageNo, pageSize = 10) {
	return new Promise((res, rej) => {
		const url = `/v1/users/${userId}/notifications`;
		gatewayApi
			.get(url, {
				params: {
					pageSize: pageSize,
					pageIndex: pageNo,
					appType: appType,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getMissedCallsNotificationsCounter(userId, appType) {
	return new Promise((res, rej) => {
		const url = `/v1/users/${userId}/notifications/count`;
		gatewayApi
			.get(url, {
				params: {
					appType: appType,
				},
			})
			.then(response => {
				res(response.data.total);
			})
			.catch(e => rej(e));
	});
}

export function changeNotificationReadStatus(userId, unreadNotifications) {
	return new Promise((res, rej) => {
		const url = `/v1/users/${userId}/notifications/status`;
		gatewayApi
			.patch(url, unreadNotifications)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}
