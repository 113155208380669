import axios from 'axios';
import { signinSilent } from 'infrastructure/auth';

export const BanyanClientType = 0;
export const BanyanAppType = 3;
export const IdentityClientType = 2;

export const APP_CONFIG = { URL: {} };
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
	APP_CONFIG.URL.gatewayApiBasePath = process.env.REACT_APP_GATEWAY_API_BASE_URL;
	APP_CONFIG.URL.legacyApiBasePath = process.env.REACT_APP_LEGACY_API_BASE_URL;
	APP_CONFIG.URL.localApiBasePath = process.env.REACT_APP_SERVER_PUBLIC_URL;
	APP_CONFIG.companyId = process.env.REACT_APP_BANYAN_COMP_ID;
	APP_CONFIG.profilePicBaseUrl = process.env.REACT_APP_PROFILE_PIC_BASE_URL;
	APP_CONFIG.buildNumber = process.env.REACT_APP_BUILD_NUMBER;
	APP_CONFIG.releaseName = process.env.REACT_APP_RELEASE_NAME;
	APP_CONFIG.useCallStats = process.env.REACT_APP_USE_CALLSTATS === 'true';
	APP_CONFIG.sendCallStatsOnMonitoring = process.env.REACT_APP_SEND_CALLSTATS_ON_MONITORING === 'true';
	APP_CONFIG.sendCallStatsInterval = process.env.REACT_APP_SEND_CALLSTATS_INTERVAL;
	APP_CONFIG.versionCheckInterval = process.env.REACT_APP_VERSION_CHECK_INTERVAL;
	APP_CONFIG.deviceAppId = process.env.REACT_APP_DEVICE_APP_ID;
	APP_CONFIG.sendCallClientLogs = process.env.REACT_APP_SEND_CALL_CLIENT_LOGS;
	APP_CONFIG.isDevelopment = true;
} else {
	APP_CONFIG.URL.gatewayApiBasePath = window.__env__.REACT_APP_GATEWAY_API_BASE_URL;
	APP_CONFIG.URL.legacyApiBasePath = window.__env__.REACT_APP_LEGACY_API_BASE_URL;
	APP_CONFIG.URL.localApiBasePath = window.__env__.REACT_APP_PUBLIC_URL;
	APP_CONFIG.companyId = window.__env__.REACT_APP_BANYAN_COMP_ID;
	APP_CONFIG.profilePicBaseUrl = window.__env__.REACT_APP_PROFILE_PIC_BASE_URL;
	APP_CONFIG.buildNumber = window.__env__.REACT_APP_BUILD_NUMBER;
	APP_CONFIG.releaseName = window.__env__.REACT_APP_RELEASE_NAME;
	APP_CONFIG.useCallStats = window.__env__.REACT_APP_USE_CALLSTATS === 'true';
	APP_CONFIG.sendCallStatsOnMonitoring = window.__env__.REACT_APP_SEND_CALLSTATS_ON_MONITORING === 'true';
	APP_CONFIG.sendCallStatsInterval = window.__env__.REACT_APP_SEND_CALLSTATS_INTERVAL;
	APP_CONFIG.versionCheckInterval = window.__env__.REACT_APP_VERSION_CHECK_INTERVAL;
	APP_CONFIG.deviceAppId = window.__env__.REACT_APP_DEVICE_APP_ID;
	APP_CONFIG.aiInstrumentationKey = window.__env__.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;
	APP_CONFIG.sendCallClientLogs = window.__env__.REACT_APP_SEND_CALL_CLIENT_LOGS;
	APP_CONFIG.isDevelopment = false;
}

const opts = {
	baseURL: APP_CONFIG.URL.gatewayApiBasePath,
	timeout: 100000,
	transformRequest: [
		function(data, headers) {
			const accessToken = localStorage.getItem('access_token');
			if (accessToken) {
				headers['Authorization'] = `Bearer ${accessToken}`;
			}
			return JSON.stringify(data);
		},
	],
	headers: {
		'Content-Type': 'application/json',
	},
};

const legacyOpts = Object.assign({}, opts, { baseURL: APP_CONFIG.URL.legacyApiBasePath });
const localOpts = Object.assign({}, opts, { baseURL: APP_CONFIG.URL.localApiBasePath });

const successHandler = response => {
	return new Promise((resolve, reject) => {
		resolve(response);
	});
};

const errorHandler = async error => {
	if (error.response && error.response.status !== 401) {
		return new Promise((resolve, reject) => {
			reject(error);
		});
	}

	signinSilent();

	return new Promise((resolve, reject) => {
		reject(error);
	});
};

export const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\] ]){8,}/;
export const treeSectorNameMaxCharacters = 127;
export const defaultDocumentTitle = 'Banyan Medical Systems';
export const gatewayApi = axios.create(opts);
export const legacyApi = axios.create(legacyOpts);
export const localApi = axios.create(localOpts);

gatewayApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
legacyApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
localApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
