import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const NotesWrapper = styled.section`
	position: relative;
	width: 100%;
	height: 60px;

	${props =>
		props.isHidden &&
		css`
			display: none;
		`}

	&::before {
		content: '';
		position: absolute;
		top: -9px;
		left: 0;
		width: 100%;
		height: 10px;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.05), transparent);
	}

	textarea {
		resize: none;
		width: 100%;
		border: none;
		outline: none;
		padding: var(--spacing-m);
		color: var(--gray-5);
		box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
		height: 100%;
		padding-right: calc(var(--spacing-sssl) + var(--spacing-m));
	}

	textarea::placeholder {
		color: var(--gray-3);
	}

	textarea:focus {
		outline: none;
	}

	> div:first-of-type {
		position: absolute;
		top: var(--spacing-m);
		right: var(--spacing-m);

		button {
			margin-left: var(--spacing-m);
			padding: 0;
			background: transparent;
			border: none;

			i {
				font-size: 18px;
				cursor: pointer;
				color: var(--gray-4);
				user-select: none;
			}
		}
	}

	> div:last-of-type {
		position: absolute;
		right: var(--spacing-m);
		bottom: var(--spacing-s);
		font-size: 12px;
		color: var(--gray-3);
	}
`;

const Notes = props => {
	const [note, setNote] = useState('');
	const [noteFontSize, setNoteFontSize] = useState(14);

	const minFontSize = 10;
	const maxFontSize = 14;
	const maxCharacters = 250;
	return (
		<NotesWrapper isHidden={props.isHidden}>
			<textarea
				style={{ fontSize: `${noteFontSize}px` }}
				maxLength={maxCharacters}
				onChange={e => setNote(e.target.value)}
				value={note}
				name='note'
				rows={2}
				placeholder='Enter note here'
			/>

			<div>
				<button
					type='button'
					onClick={() => {
						if (noteFontSize > minFontSize) {
							setNoteFontSize(prevState => prevState - 1);
						}
					}}
					className={noteFontSize === minFontSize ? 'disabled' : ''}>
					<i className='material-icons-outlined'>text_decrease</i>
				</button>
				<button
					type='button'
					onClick={() => {
						if (noteFontSize < maxFontSize) {
							setNoteFontSize(prevState => prevState + 1);
						}
					}}
					className={noteFontSize === maxFontSize ? 'disabled' : ''}>
					<i className='material-icons-outlined'>text_increase</i>
				</button>
			</div>
			<div>{`${note.length}/${maxCharacters}`}</div>
		</NotesWrapper>
	);
};

Notes.propTypes = {
	isHidden: PropTypes.bool,
};

export default Notes;
