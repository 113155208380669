import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { Modal, Form, Input, Alert } from 'components';
import { findSectorById, getCurrentHealthSystemInfo, isValidSector } from 'infrastructure/helpers/commonHelpers';
import { SectorTypes } from 'constants/enums';
import { deleteRoomLocation, getRoomDetails, getRoomLocation, setRoomDetails, updateRoomLocation } from 'api/organization';
import { APP_CONFIG, gatewayApi, treeSectorNameMaxCharacters } from 'constants/global-variables';
import { Formik } from 'formik';
import * as Yup from 'yup';

const EditSector = props => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [sectorNameError, setSectorNameError] = useState('');
	const [adtRoomLocation, setAdtRoomLocation] = useState({ unit: '', room: '', bed: '' });
	const [adtRoomLocationError, setAdtRoomLocationError] = useState({ unit: '', room: '', bed: '' });
	const uploadLogoInputRef = useRef(null);

	useEffect(() => {
		const getRoomDetail = async () => {
			const { hospitalId, departmentId, floorId, roomId } = props.sectorData;
			const { currentHealthSystemId } = getCurrentHealthSystemInfo();
			if (!hospitalId) {
				return;
			}
			setIsLoading(true);
			const [roomDetails, roomLocation] = await Promise.all([
				getRoomDetails(currentHealthSystemId, hospitalId, departmentId, floorId, roomId),
				getRoomLocation(currentHealthSystemId, hospitalId, departmentId, floorId, roomId),
			]);
			const responseError = roomDetails.error || roomLocation.error;
			if (responseError) {
				setError(responseError.message);
				setIsLoading(false);
				return;
			}
			setAdtRoomLocation(prevState => ({
				...prevState,
				unit: roomLocation.result?.unit,
				room: roomLocation.result?.room,
				bed: roomLocation.result?.bed,
			}));
			setAdtRoomLocationError(prevState => ({ ...prevState, unit: '', room: '', bed: '' }));
			setIsLoading(false);
		};
		if (SectorTypes.ROOM === props.sectorData.sectorType) {
			getRoomDetail();
		}
	}, [props.sectorData]);

	const getLocationIdentificationInformation = values => ({
		unit: values.detailUnit,
		room: values.detailRoom,
		bed: values.detailBed,
	});

	const editRoomDetails = async values => {
		setAdtRoomLocationError(prevState => ({ ...prevState, unit: '', room: '', bed: '' }));
		const { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const { hospitalId, departmentId, floorId, roomId } = props.sectorData;
		const emptyUnit = !values.detailUnit || values.detailUnit?.trim() === '';
		const emptyRoom = !values.detailRoom || values.detailRoom?.trim() === '';
		const emptyBed = !values.detailBed || values.detailBed?.trim() === '';

		if (!emptyUnit || !emptyRoom || !emptyBed) {
			if (emptyUnit) {
				setAdtRoomLocationError(prevState => ({ ...prevState, unit: 'Unit cannot be empty' }));
			}
			if (emptyRoom) {
				setAdtRoomLocationError(prevState => ({ ...prevState, room: 'Room cannot be empty' }));
			}
			if (emptyBed) {
				setAdtRoomLocationError(prevState => ({ ...prevState, bed: 'Bed cannot be empty' }));
			}
			if (emptyUnit || emptyRoom || emptyBed) {
				return;
			}
		}

		setIsLoading(true);

		let response = null;

		response = await setRoomDetails(currentHealthSystemId, hospitalId, departmentId, floorId, roomId, { name: values.sectorName });

		if (!emptyUnit && !emptyRoom && !emptyBed) {
			response = updateRoomLocation(currentHealthSystemId, hospitalId, departmentId, floorId, roomId, getLocationIdentificationInformation(values));
		}

		if (emptyUnit && emptyRoom && emptyBed) {
			response = deleteRoomLocation(currentHealthSystemId, hospitalId, departmentId, floorId, roomId);
		}

		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		props.onEditSector();
		setIsLoading(false);
		setAdtRoomLocation(prevState => ({ ...prevState, unit: '', room: '', bed: '' }));
	};

	const editSectorName = async values => {
		const sector = findSectorById(props.treeData.tree, props.sectorData[`${props.sectorData.sectorType}Id`]);
		const isValid = isValidSector(sector, props.treeData.tree, values.sectorName);
		if (!isValid && values.sectorName !== props.sectorData.sectorName) {
			setSectorNameError(`${props.sectorData.sectorType} with this name already exists`);
			setIsLoading(false);
			return;
		}
		const { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		const { hospitalId, departmentId, floorId, roomId, sectorType } = props.sectorData;
		let url;
		if (sectorType !== SectorTypes.ROOM) {
			switch (sectorType) {
				case SectorTypes.HOSPITAL: {
					url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/regions/${currentRegionId}/hospitals/${hospitalId}`;
					break;
				}
				case SectorTypes.DEPARTMENT: {
					url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}`;
					break;
				}
				case SectorTypes.FLOOR: {
					url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}`;
					break;
				}
				case SectorTypes.ROOM: {
					url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}`;
					break;
				}
				default: {
					setError('Wrong Sector type');
				}
			}
			await gatewayApi.put(url, {
				name: values.sectorName,
			});
			props.onEditSector();
		}
		if (sectorType === SectorTypes.ROOM) {
			editRoomDetails(values);
		}
		setError('');
		setIsLoading(false);
	};

	const onModalClose = () => {
		setAdtRoomLocation(prevState => ({ ...prevState, unit: '', room: '', bed: '' }));
		setAdtRoomLocationError(prevState => ({ ...prevState, unit: '', room: '', bed: '' }));
		setError('');
		props.onModalClose();
	};

	const getInitialData = () => ({
		sectorName: props.sectorData.sectorName || '',
		detailUnit: adtRoomLocation.unit,
		detailRoom: adtRoomLocation.room,
		detailBed: adtRoomLocation.bed,
	});

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={getInitialData()}
				onSubmit={editSectorName}
				validationSchema={Yup.object().shape({
					sectorName: Yup.string()
						.required('Name is required')
						.max(treeSectorNameMaxCharacters, 'Maximum length exceeded')
						.trim(),
				})}>
				{formikProps => {
					const { values, errors, handleChange, handleSubmit } = formikProps;
					return (
						<Modal
							modalSelector='editSectorModal'
							className='edit-sector-modal'
							display={props.isEditSectorModalOpen}
							position='right'
							onModalSubmit={handleSubmit}
							onModalClose={onModalClose}
							isLoading={isLoading}>
							<Form title={props.sectorData.sectorName} onSubmit={event => event.preventDefault()}>
								<Input
									type='text'
									validationOptions={{}}
									label='Name'
									value={values.sectorName}
									onChange={handleChange}
									name='sectorName'
									bottomSpace='15px'
									placeholder='Write name'
									error={errors.sectorName || sectorNameError}
								/>
								{props.sectorData.sectorType === SectorTypes.HOSPITAL && (
									<div className='hospital-logo' data-cy='uploadHospitalLogo'>
										{props.hospitalLogo && <img src={props.hospitalLogo} alt='logo' />}
										<p>Upload hospital image</p>
										<input type='file' accept='.png' ref={uploadLogoInputRef} onFocus={props.onFileInputFocus} onChange={props.onSelectFile} />
										<p className={props.fileError ? '--red-color' : ''} data-cy='uploadFileAlert'>
											Picture size can't exceed 600KB and file type should be .PNG.
										</p>
									</div>
								)}
								<Input
									label='Location'
									validationOptions={{}}
									description={`The ${props.parentSectorType} this  ${props.sectorData.sectorType} is located at.`}
									bottomSpace={10}
								/>
								<main>
									{props.parentSectorType === 'floor' && <i className='material-icons-outlined'>money</i>}
									{props.parentSectorType === 'department' && <i className='material-icons-outlined'>account_balance</i>}
									{props.parentSectorType === 'hospital' && <i className='material-icons-outlined'>business</i>}
									{props.parentSectorName}
								</main>
								{props.sectorData.sectorType === SectorTypes.ROOM && (
									<>
										<p className='font-bold'>ADT Room Location</p>
										<div className='units-wrapper'>
											<Input
												type='text'
												onChange={handleChange}
												name='detailUnit'
												placeholder='Type unit'
												value={values.detailUnit}
												label='Unit'
												validationOptions={{ maxLength: 63, required: true }}
												error={adtRoomLocationError.unit}
											/>
											<Input
												type='text'
												onChange={handleChange}
												name='detailRoom'
												placeholder='Type room'
												value={values.detailRoom}
												label='Room'
												validationOptions={{ maxLength: 63, required: true }}
												error={adtRoomLocationError.room}
											/>
											<Input
												type='text'
												onChange={handleChange}
												name='detailBed'
												placeholder='Type bed'
												value={values.detailBed}
												label='Bed'
												validationOptions={{ maxLength: 63, required: true }}
												error={adtRoomLocationError.bed}
											/>
										</div>
									</>
								)}
							</Form>
						</Modal>
					);
				}}
			</Formik>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(EditSector);
