import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

import 'font-awesome/css/font-awesome.min.css';
import 'css/reset.css';
import 'css/typography.css';
import 'css/utilities.css';
import 'css/components.css';
import 'css/main.css';

const store = configureStore();
const rootElement = document.getElementById('root');

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	rootElement
);

unregisterServiceWorker();
