import React from 'react';
import { APP_CONFIG } from 'constants/global-variables';

export const ProfilePicture = props => {
	return (
		<img
			className='profile-picture'
			alt={props.fullName}
			src={
				!props.src || props.src === ''
					? 'https://www.solaborate.com/img/chat/colors/' + props.fullName.charAt(0) + '.jpg'
					: APP_CONFIG.profilePicBaseUrl + '/' + props.src
			}
		/>
	);
};
