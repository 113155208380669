import { NotificationsCounterActionTypes } from 'constants/action-types';
import { getMissedCallsNotificationsCounter } from 'api/notifications';
import { getUserProfile } from 'infrastructure/auth';
import { BanyanAppType } from 'constants/global-variables';

function fetchingNotificationCounter() {
	return {
		type: NotificationsCounterActionTypes.FETCHING_NOTIFICATIONS_COUNTER,
	};
}

function fetchNotificationCounterSucceeded(data) {
	return {
		type: NotificationsCounterActionTypes.FETCH_NOTIFICATIONS_COUNTER_SUCCESS,
		data: data,
	};
}

function fetchNotificationCounterFailed() {
	return {
		type: NotificationsCounterActionTypes.FETCH_NOTIFICATIONS_COUNTER_FAILURE,
	};
}

export function fetchNotificationCounter() {
	return dispatch => {
		dispatch(fetchingNotificationCounter());
		const userProfile = getUserProfile();

		return getMissedCallsNotificationsCounter(userProfile.userId, BanyanAppType)
			.then(notificationCounter => {
				dispatch(fetchNotificationCounterSucceeded(notificationCounter));
			})
			.catch(() => {
				dispatch(fetchNotificationCounterFailed());
			});
	};
}
