import React from 'react';
import { Table, Grid, Button, Modal } from 'components';
import Select from 'react-select';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations';
import { getUserRole } from 'infrastructure/auth';
import { ConfigHistoryTypes, UserRoles, Roles } from 'constants/enums';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { getActiveDirectories, getADGroupRoles, deleteActiveDirectory, deleteGroupRole } from 'api/activeDirectories';
import Pagination from 'components/Common/Pagination';
import moment from 'moment';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { Link } from 'react-router-dom';
import ActiveDirectoryForm from './ActiveDirectoryForm';

class ActiveDirectory extends React.Component {
	state = {
		isLoading: true,
		hospitals: [],
		formHospitals: [],
		isModalHospitalsLoading: false,
		activeDirectories: [],
		totalADs: 0,
		groupRoles: [],
		totalGroupRoles: 0,
		editConfig: null,
		isModalOpen: false,
		isHospitalDisabled: true,
		isDeleteConfigModalOpen: false,
	};

	role = null;

	activeSubTab = {
		ADs: 0,
		GroupRoles: 1,
	};

	transformTypes = {
		WithValues: 1,
		WithLabels: 2,
	};

	activeDirectoriesHeaders = [
		{ title: 'Hospital' },
		{ title: 'Domain' },
		{ title: 'Valid Group Name' },
		{ title: 'Created by' },
		{ title: 'Date Created' },
		{ title: '' },
	];

	groupRoleHeaders = [{ title: 'Hospital' }, { title: 'Role' }, { title: 'Group' }, { title: 'Created by' }, { title: 'Date Created' }, { title: '' }];

	componentDidMount = async () => {
		this.role = getUserRole();
		this.getConfigurations();
	};

	componentDidUpdate = prevProps => {
		if (
			this.props.pageIndex !== prevProps.pageIndex ||
			this.props.pageSize !== prevProps.pageSize ||
			this.props.selectedHealthSystem !== prevProps.selectedHealthSystem ||
			this.props.selectedHospitalId !== prevProps.selectedHospitalId ||
			this.props.activeSubTab !== prevProps.activeSubTab
		) {
			this.getConfigurations();
		}
	};

	getHospitals = async healthSystem => {
		let hospitals;
		if (healthSystem.value !== '0') {
			hospitals = await this.getHealthSystemHospitals(healthSystem.value);
			hospitals.unshift({ value: 0, label: 'All' });
		}
		this.setState({
			isHospitalDisabled: healthSystem.value === '0',
			hospitals,
		});
	};

	getConfigurations = async () => {
		if (this.props.isLoading) {
			return;
		}

		const { selectedHealthSystem, selectedHospitalId } = this.props;
		let hsId = null;
		if (selectedHealthSystem && selectedHealthSystem.value !== '0') {
			hsId = selectedHealthSystem.value;
		}

		const newState = {
			isLoading: false,
		};

		if (this.props.activeSubTab === this.activeSubTab.ADs) {
			const { activeDirectories, total } = await getActiveDirectories({
				pageSize: this.props.pageSize.value,
				pageIndex: this.props.pageIndex,
				healthSystemId: hsId,
				hospitalId: selectedHospitalId || null,
			});
			newState.activeDirectories = activeDirectories;
			newState.totalADs = total;
		} else {
			const { adGroupRoles, total } = await getADGroupRoles({
				pageSize: this.props.pageSize.value,
				pageIndex: this.props.pageIndex,
				healthSystemId: hsId,
				hospitalId: selectedHospitalId || null,
			});
			newState.groupRoles = adGroupRoles;
			newState.totalGroupRoles = total;
		}
		if (selectedHealthSystem) {
			await this.getHospitals(selectedHealthSystem);
		}
		this.setState(newState);
	};

	getHealthSystemHospitals = async healthSystemId => {
		const firstHSHospitals = await getHealthSystemHospitals(healthSystemId);
		return this.transformHealthSystemsForSelect(firstHSHospitals);
	};

	transformHealthSystemsForSelect = healthSystems => {
		return healthSystems.map(healthSystem => {
			return { label: healthSystem.name, value: healthSystem.id };
		});
	};

	onHospitalSelect = selection => {
		this.setState(
			{
				isLoading: true,
			},
			() => {
				this.props.setSelectedHospital(selection);
			}
		);
	};

	onHealthSystemSelect = healthSystem => {
		this.setState(
			{
				isLoading: true,
			},
			() => {
				this.props.setSelectedHealthSystem(healthSystem);
			}
		);
	};

	onHealthSystemChange = async e => {
		const { value } = e.target;
		this.setState({
			isModalHospitalsLoading: true,
		});
		const hsHospitals = value !== '0' ? await getHealthSystemHospitals(value) : [];
		const formHospitals = hsHospitals.map(hospital => ({ id: hospital.id, value: hospital.name }));
		this.setState({
			formHospitals,
			isModalHospitalsLoading: false,
		});
	};

	transformArray = (array, type, isHealthSystem, shouldAppendAll = true, propName = 'name') => {
		if (type === this.transformTypes.WithValues) {
			const newArray = array.map(item => {
				return { id: item.id, value: item[propName] };
			});
			if (shouldAppendAll) {
				if (isHealthSystem) {
					if (this.role === UserRoles.ADMIN) {
						newArray.unshift({ id: '0', value: 'All' });
					}
				} else {
					newArray.unshift({ id: '0', value: 'All' });
				}
			}

			return newArray;
		}
		if (type === this.transformTypes.WithLabels) {
			const newArray = array.map(item => {
				return { value: item.id, label: item[propName] };
			});
			if (shouldAppendAll) {
				if (isHealthSystem) {
					if (this.role === UserRoles.ADMIN) {
						newArray.unshift({ value: '0', label: 'All' });
					}
				} else {
					newArray.unshift({ value: '0', label: 'All' });
				}
			}
			return newArray;
		}
		return [];
	};

	toggleConfigModal = () => {
		this.setState({
			isModalOpen: !this.state.isModalOpen,
		});
	};

	openDeleteModal = editConfig => {
		this.setState({
			editConfig,
			isDeleteConfigModalOpen: true,
		});
	};

	getADButtons = config => {
		const password = `${config.password.slice(0, 3)}************`;

		const editConfig = {
			id: config.id,
			hospital: config.team.name,
			domain: config.domain,
			validGroupName: config.validGroupName,
			adClientId: config.username,
			adClientSecret: password,
			fullPassword: config.password,
		};

		return (
			<div className='wrapped'>
				<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.ADs}`} onClick={this.props.onHistoryClick}>
					<span className='material-icons-outlined cursor-pointer mr-20' data-cy='viewHistory' data-tooltip='View history' data-position='top'>
						list_alt
					</span>
				</Link>
				<span
					className='material-icons-outlined cursor-pointer mr-20'
					data-cy='edit'
					data-tooltip='Edit'
					data-position='top'
					onClick={() => this.openEditModal(editConfig)}>
					create
				</span>
				<i
					className='material-icons-outlined cursor-pointer'
					data-cy='deleteActiveDirectory'
					style={{ color: 'red', backgroundColor: 'white' }}
					data-tooltip='Delete'
					data-position='top'
					onClick={() => this.openDeleteModal(editConfig)}>
					delete
				</i>
			</div>
		);
	};

	getGroupRoleButtons = config => {
		const editConfig = {
			id: config.id,
			hospital: config.team.name,
			role: config.role.id,
			group: config.group,
		};

		return (
			<div className='wrapped'>
				<span
					className='material-icons-outlined cursor-pointer mr-20'
					data-cy='editGroupRole'
					data-tooltip='Edit'
					data-position='top'
					onClick={() => this.openEditModal(editConfig)}>
					create
				</span>
				<i
					className='material-icons-outlined cursor-pointer'
					data-cy='deleteGroupRole'
					style={{ color: 'red', backgroundColor: 'white' }}
					data-tooltip='Delete'
					data-position='top'
					onClick={() => this.openDeleteModal(editConfig)}>
					delete
				</i>
			</div>
		);
	};

	openEditModal = editConfig => {
		this.setState({
			editConfig,
			isModalOpen: true,
		});
	};

	setFormHospitals = async id => {
		this.setState({
			isModalHospitalsLoading: true,
		});
		const hsHospitals = await getHealthSystemHospitals(id);
		const formHospitals = hsHospitals.map(hospital => ({ id: hospital.id, value: hospital.name }));
		this.setState({
			formHospitals,
			isModalHospitalsLoading: false,
		});
	};

	transformActiveDirectories = () => {
		if (this.props.activeSubTab === this.activeSubTab.ADs) {
			return this.state.activeDirectories.map(item => {
				return {
					hospital: item.team.name,
					domain: item.domain,
					validGroupName: item.validGroupName,
					createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
					dateCreated: moment(utcToLocalTime(item.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
					edit: this.getADButtons(item),
				};
			});
		}
		return this.state.groupRoles.map(item => {
			return {
				hospital: item.team.name,
				role: item.role.name,
				group: item.group,
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: moment(utcToLocalTime(item.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
				edit: this.getGroupRoleButtons(item),
			};
		});
	};

	onPaginationChange = (pageSize, pageIndex) => {
		this.setState(
			{
				isLoading: true,
			},
			() => {
				this.props.onPaginationChange(pageSize, pageIndex);
			}
		);
	};

	onTabChange = tabIndex => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.onSubTabChange(tabIndex);
			}
		);
	};

	deleteActiveDirectory = async () => {
		this.setState({
			isLoading: true,
			isDeleteConfigModalOpen: false,
		});
		if (this.props.activeSubTab === this.activeSubTab.ADs) {
			await deleteActiveDirectory(this.state.editConfig.id);
		} else if (this.props.activeSubTab === this.activeSubTab.GroupRoles) {
			await deleteGroupRole(this.state.editConfig.id);
		}
		this.getConfigurations();
	};

	render() {
		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};

		return (
			<div>
				<Table
					isLoading={this.state.isLoading}
					headers={this.props.activeSubTab === this.activeSubTab.ADs ? this.activeDirectoriesHeaders : this.groupRoleHeaders}
					rows={this.state.isLoading ? [] : this.transformActiveDirectories()}>
					<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
						<Select
							value={this.props.selectedHealthSystem}
							placeholder='All'
							classNamePrefix='custom-select'
							options={this.transformArray(this.props.allHealthSystems, this.transformTypes.WithLabels, true)}
							components={{ DropdownIndicator }}
							onChange={this.onHealthSystemSelect}
							isDisabled={this.role === UserRoles.SUPERUSER}
						/>
						<Select
							value={this.props.selectedHospitalId ? this.state.hospitals.find(x => x.value === this.props.selectedHospitalId) : null}
							isDisabled={this.state.isHospitalDisabled}
							classNamePrefix='custom-select'
							options={this.state.hospitals}
							components={{ DropdownIndicator }}
							onChange={this.onHospitalSelect}
						/>
						<Button
							text={this.props.activeSubTab === this.activeSubTab.ADs ? 'Add AD' : 'Add Group Role'}
							horizAlign='end'
							onClick={() => this.toggleConfigModal()}
						/>
					</Grid>

					<div>
						<ul className='tabs active-directory-tabs' data-cy='activeDirectoryTabs'>
							<li className={this.props.activeSubTab === this.activeSubTab.ADs ? 'active' : ''}>
								<span onClick={() => this.onTabChange(this.activeSubTab.ADs)}>AD </span>
							</li>
							<li className={this.props.activeSubTab === this.activeSubTab.GroupRoles ? 'active' : ''}>
								<span onClick={() => this.onTabChange(this.activeSubTab.GroupRoles)}>Group roles </span>
							</li>
						</ul>
					</div>
				</Table>
				<Pagination
					totalCount={this.props.activeSubTab === this.activeSubTab.ADs ? this.state.totalADs : this.state.totalGroupRoles}
					pageSize={this.props.pageSize}
					pageIndex={this.props.pageIndex}
					onChange={(pageSize, pageIndex) => this.onPaginationChange(pageSize, pageIndex)}
				/>
				<ActiveDirectoryForm
					activeTab={this.props.activeSubTab}
					isModalOpen={this.state.isModalOpen}
					initialValues={this.state.editConfig}
					roles={this.transformArray(Object.values(Roles), this.transformTypes.WithValues, false, false, 'value')}
					isSuperUser={this.role === UserRoles.SUPERUSER}
					healthSystems={this.transformArray(this.props.allHealthSystems, this.transformTypes.WithValues, true, false)}
					toggleModal={() => this.setState({ isModalOpen: !this.state.isModalOpen, editConfig: null })}
					onSucceeded={this.getConfigurations}
					hospitals={this.state.formHospitals}
					isHospitalLoading={this.state.isModalHospitalsLoading}
					onHealthSystemChange={this.onHealthSystemChange}
				/>
				<Modal
					modalSelector='deleteActiveDirectoryModal'
					display={this.state.isDeleteConfigModalOpen}
					position='center'
					onModalSubmit={() => this.deleteActiveDirectory()}
					onModalClose={() => this.setState({ isDeleteConfigModalOpen: false, editConfig: null })}>
					<form>
						<h3>Warning</h3>
						<p>{`Are you sure you want to delete this active directory ${this.props.activeSubTab === this.activeSubTab.ADs ? '' : 'group role'} ? `}</p>
					</form>
				</Modal>
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(ActiveDirectory);
