import { IDENTITY_CONFIG, METADATA_OIDC } from 'constants/auth-constants';
import { localApi, APP_CONFIG, gatewayApi } from 'constants/global-variables';
// @ts-ignore
import { Log, Logger, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { getRoles } from 'api/roles';
import { getHealthSystems } from 'api/users';
import { UserRoles, UserTypes } from 'constants/enums';

Log.setLogger(console);
// @ts-ignore
Log.setLevel(Log.DEBUG);

const userManager = new UserManager({
	...IDENTITY_CONFIG,
	userStore: new WebStorageStateStore({ store: window.localStorage }),
	metadata: {
		...METADATA_OIDC,
	},
});

function clearLocalStorage() {
	localStorage.removeItem('access_token');
	localStorage.removeItem('id_token');
	localStorage.removeItem('userId');
	localStorage.removeItem('userProfile');
	localStorage.removeItem('userRole');
	localStorage.removeItem('memberExists');
	localStorage.removeItem('userRoleId');
	localStorage.removeItem('currentHealthSystemInfo');
	localStorage.removeItem('isRedirectCalled');
	localStorage.removeItem('nursePoolingStatus');
}

function setUser(data, userInfo, userRole, memberExistsInCompany, userRoleId) {
	localStorage.setItem('userId', data.sub);
	localStorage.setItem('userProfile', JSON.stringify(userInfo));
	localStorage.setItem('userRole', userRole);
	localStorage.setItem('userRoleId', userRoleId);
	localStorage.setItem('memberExists', memberExistsInCompany);
}

export function setTokens(authResult) {
	localStorage.setItem('access_token', authResult.accessToken);
	localStorage.setItem('id_token', authResult.idToken);
}

async function setCurrentHealthSystem() {
	let allHealthSystems = await getHealthSystems();
	if (allHealthSystems.length !== 0) {
		let currentHealthSystemInfo = {
			currentHealthSystemId: allHealthSystems[0].id,
			currentRegionId: allHealthSystems[0].regions[0].id,
		};
		localStorage.setItem('currentHealthSystemInfo', JSON.stringify(currentHealthSystemInfo));
	}
}

function navigateToApp() {
	const redirectUri = localStorage.getItem('redirectUri') ? localStorage.getItem('redirectUri') : '/';
	window.location.replace(redirectUri);
}

async function fetchUserProfile() {
	try {
		const response = await localApi.get('/api/profile');
		return { data: response.data, err: false };
	} catch (ex) {
		return { err: true };
	}
}

function swapToken(token) {
	return new Promise((res, rej) => {
		localApi
			.post('/api/swap-token', { token: token })
			.then(response => {
				res(response.data);
			})
			.catch(e => {
				Logger.info(e);
				rej(e);
			});
	});
}

function checkMember(email) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/${APP_CONFIG.companyId}/members/${email}/exists`)
			.then(response => res(response.data.exists))
			.catch(e => rej(e));
	});
}

export async function signinRedirectCallback() {
	try {
		await userManager.signinRedirectCallback();
		console.log('Successfully signed in');
	} catch (ex) {
		console.error('Signin redirect call failed, ', ex);
		if (ex.message.includes('iat is in the future') || ex.message.includes('exp is in the past')) {
			window.location.href = '/time-error';
			return;
		}
		navigateToApp();
	}
}

export function logout() {
	userManager.signoutRedirect({
		id_token_hint: localStorage.getItem('id_token'),
	});
	userManager.clearStaleState();
}

export function signoutRedirectCallback() {
	userManager.signoutRedirectCallback().then(() => {
		clearLocalStorage();
		window.location.replace(IDENTITY_CONFIG.public_uri);
	});

	userManager.clearStaleState();
}

function parseJwt(token) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
}

export function isAuthenticated() {
	const accessToken = localStorage.getItem('access_token');
	return !!accessToken;
}

export function memberExists() {
	const val = localStorage.getItem('memberExists');
	return val === 'true';
}

export function signinRedirect(isJWTRoute = false) {
	const localAzJwt = localStorage.getItem('azure_jwt');

	if (isJWTRoute || (!isJWTRoute && localAzJwt)) {
		// We don't the redirectUri to get overridden
		if (!(!window.location.hash && localAzJwt)) {
			// In case we are dealing with AZURE JWT, thats the case we need the reidrectUri to get set.
			localStorage.setItem('redirectUri', window.location.pathname);
		}
	} else {
		// For every other case, just override the redirectUri
		localStorage.setItem('redirectUri', window.location.pathname);
	}

	if (window.location.hash || localAzJwt) {
		const hash = window.location.hash.substring(1);
		let jwt;
		if (hash) {
			[, jwt] = hash.split('=');
		} else {
			jwt = localAzJwt;
		}
		swapToken(jwt).then(response => {
			const args = {};
			const claims = parseJwt(jwt);
			if (claims.preferred_username) {
				args.login_hint = claims.preferred_username;
			}
			if (response) {
				args.acr_values = `sol_ref:${response}`;
			}
			localStorage.removeItem('azure_jwt'); // Remove JWT so we can logout without getting redirected
			userManager.signinRedirect(args);
		});
	} else {
		userManager.signinRedirect({});
	}
}

// NOTE: This function works with Sol.Identity authentication as well.
export function isAzureAuthentication() {
	if (isAuthenticated()) {
		const localAzJwt = localStorage.getItem('azure_jwt');
		if (window.location.hash || localAzJwt) {
			let jwt;
			const hash = window.location.hash.substring(1);
			if (hash) {
				[, jwt] = hash.split('=');
			} else {
				jwt = localAzJwt;
			}
			const userToken = parseJwt(jwt);
			const userInfo = JSON.parse(localStorage.getItem('userProfile'));
			const shouldLogout = userInfo.email !== userToken.preferred_username; // Different user
			if (shouldLogout) {
				localStorage.setItem('azure_jwt', jwt);
				logout();
				return false;
			}
		}
		window.location.hash = '';
		if (window.location.href.endsWith('#')) {
			window.location.href = window.location.href.slice(0, -1);
		}
		return true; // Same user
	}
	return false;
}

export function signinSilentCallback() {
	userManager.signinSilentCallback();
}
export function createSigninRequest() {
	return userManager.createSigninRequest();
}

export async function getUser() {
	const user = await userManager.getUser();
	if (!user) {
		return userManager.signinRedirectCallback();
	}
	return user;
}

export function getAccessToken() {
	if (!localStorage.getItem('access_token')) {
		throw new Error('Attempted to access unloaded access token');
	}

	return localStorage.getItem('access_token');
}

export function getUserId() {
	if (!localStorage.getItem('userId')) {
		throw new Error('Attempted to access unloaded userId');
	}

	let userId = localStorage.getItem('userId');
	return userId;
}

export function hasUserProfile() {
	return !!localStorage.getItem('userProfile');
}

export function getUserProfile() {
	if (!localStorage.getItem('userProfile')) {
		throw new Error('Attempted to access unloaded userInfo');
	}

	let userInfo = localStorage.getItem('userProfile');
	return JSON.parse(userInfo);
}

export function setUserProfile(newProfile) {
	localStorage.setItem('userProfile', JSON.stringify(newProfile));
}

export function getUserRole() {
	if (!localStorage.getItem('userRole')) {
		throw new Error('Attempted to access non-existing userRole');
	}

	const userRole = localStorage.getItem('userRole');
	switch (userRole) {
		case UserRoles.ADMIN:
			return UserRoles.ADMIN;
		case UserRoles.NURSE:
			return UserRoles.NURSE;
		case UserRoles.VIRTUALSITTER:
			return UserRoles.VIRTUALSITTER;
		case UserRoles.SUPERUSER:
			return UserRoles.SUPERUSER;
		default:
			throw new Error('Invalid user role');
	}
}

export function getUserRoleId() {
	return localStorage.getItem('userRoleId');
}

export async function setUserInfo() {
	let accessToken = localStorage.getItem('access_token');
	const data = parseJwt(accessToken);

	const { data: userProfile, err } = await fetchUserProfile();

	if (err) {
		localStorage.setItem('memberExists', 'false');
	} else {
		let exists = await checkMember(userProfile.email);
		let userRole = UserRoles.NURSE;
		let userRoleId = UserTypes.VIRTUALCAREPROVIDER;

		if (exists) {
			let userRoles = await getRoles(APP_CONFIG.companyId, data.sub);
			userRoles.forEach(role => {
				if (role.name === UserRoles.ADMIN) {
					userRole = UserRoles.ADMIN;
					userRoleId = UserTypes.ADMIN;
				} else if (role.name === UserRoles.VIRTUALSITTER) {
					userRole = UserRoles.VIRTUALSITTER;
					userRoleId = UserTypes.VIRTUALSITTER;
				} else if (role.name === UserRoles.SUPERUSER) {
					userRole = UserRoles.SUPERUSER;
					userRoleId = UserTypes.SUPERUSER;
				}
			});
		}

		setUser(data, userProfile, userRole, exists, userRoleId);
		if (exists && !localStorage.getItem('currentHealthSystemInfo')) {
			await setCurrentHealthSystem();
		}
	}
}

export function signinSilent() {
	userManager
		.signinSilent({ scope: IDENTITY_CONFIG.scope, response_type: IDENTITY_CONFIG.response_type })
		.then(user => {
			Logger.info('signed in', user);
		})
		.catch(err => {
			Logger.error('signinSilent error thrown.', err);
			if (err.message === 'login_required') {
				Logger.info('Session on identity has expired. Login is required');
				Logger.info('Invoking signoutRedirectCallback()...');
				signoutRedirectCallback();
			} else if (err.message === 'IFrame timed out without a response') {
				Logger.info('Frame window timed out');
				Logger.info('Invoking signoutRedirectCallback()...');
				signoutRedirectCallback();
			} else if (err.message.includes('iat is in the future') || err.message.includes('exp is in the past')) {
				const isRedirectCalled = localStorage.getItem('isRedirectCalled');
				// Temporary Fix. We need to check why this component is being rendered twice.
				if (!isRedirectCalled) {
					localStorage.setItem('isRedirectCalled', 'true');
					window.location.href = '/time-error';
				} else {
					localStorage.removeItem('isRedirectCalled');
				}
			}
		});
}

userManager.events.addUserLoaded(user => {
	localStorage.setItem('access_token', user.access_token);
	localStorage.setItem('id_token', user.id_token);

	setUserInfo().then(() => {
		if (window.location.href.indexOf('signin-oidc') !== -1) {
			navigateToApp();
		}
	});
});

userManager.events.addSilentRenewError(e => {
	Logger.info('silent renew error', e.message);
});

userManager.events.addAccessTokenExpired(() => {
	Logger.info('token expired');
	signinSilent();
});

userManager.events.addAccessTokenExpiring(() => {
	Logger.info('token expiring');
	signinSilent();
});
