import React from 'react';
import Grid from 'components/Grid';

const AnotherNursePickedUp = () => (
	<div style={{ textAlign: 'center' }}>
		<Grid className='incoming-calls' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
			<div>
				<p data-test-id='callState-busy' className='incoming-call-text'>
					Another nurse picked up this call.
				</p>
			</div>
		</Grid>
	</div>
);

export default AnotherNursePickedUp;
