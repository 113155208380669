import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import { DeviceCommands, DeviceSettings } from 'constants/enums';

export function getUnassociatedDevices() {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/unassociated-devices`)
			.then(response => {
				res(response.data.devices);
			})
			.catch(e => rej(e));
	});
}

export function getDeviceList(level, id) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/level/${level}/id/${id}/devices`)
			.then(response => {
				res(response.data.devices);
			})
			.catch(e => rej(e));
	});
}

export function getAllowList(solHelloId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`v1/devices/${solHelloId}/allowlist/logs`)
			.then(response => {
				res(response.data.result.data);
			})
			.catch(e => rej(e));
	});
}

export function deleteDevice(deviceId) {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/organizations/${APP_CONFIG.companyId}/devices/${deviceId}`)
			.then(response => {
				res(response.data.devices);
			})
			.catch(e => rej(e));
	});
}

export function sendCommand(deviceId, command, reason = null) {
	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/devices/${deviceId}/command`, {
				command,
				reason,
			})
			.then(response => {
				res(response);
			})
			.catch(e => rej(e));
	});
}

export function getLatestAppRelease(appId) {
	// DO NOT REMOVE. Returning default value until we decide to include force update in production
	return new Promise((res, rej) => {
		res({
			id: 0,
			short_version: '',
			version: '',
			uploaded_at: '',
			install_url: '',
			min_os: '',
		});
	});
	// return new Promise((res, rej) => {
	// 	gatewayApi
	// 		.get(`v1/device-apps/apps/${appId}/version/latest`)
	// 		.then(response => {
	// 			res(response.data);
	// 		})
	// 		.catch(e => rej(e));
	// });
}

/**
 * Send device force update command to Hello
 * @method forceUpdateDevice
 * @param {object} data
 * @param {number} data.deviceId
 * @param {string} data.dynamicData
 */
export function forceUpdateDevice(data) {
	// DO NOT REMOVE. Commented until we decide to include force update in production
	// return new Promise((res, rej) => {
	// 	gatewayApi
	// 		.post(`/v1/devices/${data.deviceId}/command`, {
	// 			command: DeviceCommands.FORCE_UPDATE,
	// 			...data,
	// 		})
	// 		.then(response => {
	// 			res(response);
	// 		})
	// 		.catch(e => rej(e));
	// });
}

export function getDeviceRebootLogs(deviceId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/devices/${deviceId}/logs/type/command`)
			.then(response => {
				res(response.data.deviceCommandLogs);
			})
			.catch(e => rej(e));
	});
}

export const addDeviceSettings = async settings => {
	try {
		const { data } = await gatewayApi.post(`/v1/organizations/${APP_CONFIG.companyId}/device-settings`, {
			settings,
			settingTypeId: DeviceSettings.VoiceCommandCalling,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceLogsCommand = async deviceId => {
	try {
		const { data } = await gatewayApi.post(`/v1/devices/${deviceId}/command`, {
			command: DeviceCommands.PULL_LOGS,
			dynamicData: 'logcat',
			deviceId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
