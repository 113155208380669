import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/mainLayout/actions';
import LeftNavigation from 'components/LeftNavigation';
import Grid from 'components/Grid';
import { getUserRole } from 'infrastructure/auth';
import { UserRoles } from 'constants/enums';
import { getNursePoolingStatus } from 'infrastructure/helpers/commonHelpers';

class Layout extends Component {
	onLeftNavigationItemClicked = (item, event) => {
		if (item.role === 'toggleLeftNavigation') {
			event.preventDefault();
			this.props.toggleLeftNavigation();
		}
	};

	getLeftNavigationItems() {
		let leftNavigationItems = [];

		const isPoolingPage = getUserRole() === UserRoles.NURSE && !getNursePoolingStatus();

		switch (getUserRole()) {
			case UserRoles.SUPERUSER: {
				leftNavigationItems = [
					{
						icon: 'panorama_fish_eye',
						linkTo: '/health-system',
						title: 'Organization',
					},
					{
						icon: 'person',
						linkTo: '/users',
						title: 'User Management',
					},
					{
						icon: 'assessment',
						linkTo: '/calllogs',
						title: 'Call logs',
					},
					{
						icon: 'unfold_more',
						linkTo: '#',
						title: 'Collapse',
						role: 'toggleLeftNavigation',
					},
				];
				break;
			}
			case UserRoles.ADMIN: {
				leftNavigationItems = [
					{
						icon: 'panorama_fish_eye',
						linkTo: '/health-system',
						title: 'Organization',
					},
					{
						icon: 'person',
						linkTo: '/users',
						title: 'User Management',
					},
					{
						icon: 'assessment',
						linkTo: '/calllogs',
						title: 'Call logs',
					},
					{
						icon: 'build',
						linkTo: '/configurations',
						title: 'Configurations',
					},
					{
						icon: 'unfold_more',
						linkTo: '#',
						title: 'Collapse',
						role: 'toggleLeftNavigation',
					},
				];
				break;
			}
			case UserRoles.NURSE:
				leftNavigationItems = [
					...(!isPoolingPage
						? [
								{
									icon: 'panorama_fish_eye',
									linkTo: '/health-system',
									title: 'Organization',
								},
								{
									icon: 'remove_red_eye',
									linkTo: '/monitoring',
									title: 'Monitoring',
								},
						  ]
						: []),
					{
						icon: 'unfold_more',
						linkTo: '#',
						title: 'Collapse',
						role: 'toggleLeftNavigation',
					},
				].filter(Boolean);

				break;
			case UserRoles.VIRTUALSITTER: {
				leftNavigationItems = [
					{
						icon: 'remove_red_eye',
						linkTo: '/monitoring',
						title: 'Monitoring',
					},
					{
						icon: 'unfold_more',
						linkTo: '#',
						title: 'Collapse',
						role: 'toggleLeftNavigation',
					},
				];
				break;
			}
			default: {
				leftNavigationItems = [];
			}
		}

		return leftNavigationItems;
	}

	render() {
		return (
			<Grid columns={this.props.isLefNavigationExpanded ? '250px 1fr' : '75px 1fr'} stretch='100vh'>
				<LeftNavigation expanded={this.props.isLefNavigationExpanded} links={this.getLeftNavigationItems()} onItemClicked={this.onLeftNavigationItemClicked} />
				<div className='app-wrapper'>
					<Grid
						className={this.props.isSecondColumnCollapsed ? 'collapsed-second-column' : ''}
						columns={this.props.twoColumns ? '1fr 3fr' : '1fr'}
						stretch='100%'>
						{this.props.children}
					</Grid>
				</div>
			</Grid>
		);
	}
}

export default connect(
	state => state.mainLayout,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(Layout);
