import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';
import Select from 'components/Common/FormElements/Select';
import { Form, Modal, Alert } from 'components';
import { addUserIdleConfiguration } from 'api/userIdleConfigurations';

export default class UserIdleForm extends React.Component {
	state = {
		isSuccessfulAlertOn: false,
		isErrorsAlertOn: false,
	};

	handleSubmitMyForm = async (values, { resetForm, setSubmitting }) => {
		setSubmitting(true);
		try {
			const healthSystemIds = values.healthSystem === '0' ? this.props.healthSystems.filter(x => x.id !== '0').map(x => x.id) : [values.healthSystem];
			const roleId = values.role === '0' ? null : parseInt(values.role, 10);
			const { minutes } = values;

			const res = await addUserIdleConfiguration(healthSystemIds, roleId, minutes);

			if (res.hasSucceeded) {
				this.setState({
					isSuccessfulAlertOn: true,
				});
				this.props.onSucceeded();

				if (!this.props.initialValues) {
					resetForm();
				}
			}
		} catch (e) {
			this.setState({
				isErrorsAlertOn: true,
			});
		}
		setSubmitting(false);
	};

	onCloseModal = () => {
		this.setState({
			isSuccessfulAlertOn: false,
			isErrorsAlertOn: false,
		});
		this.props.toggleModal();
	};

	render() {
		return (
			<Formik
				enableReinitialize={true}
				initialValues={
					this.props.initialValues
						? {
								healthSystem: this.props.healthSystems.find(x => x.id === this.props.initialValues.healthSystem) ? this.props.initialValues.healthSystem : '',
								role: this.props.initialValues.role,
								minutes: this.props.initialValues.minutes,
						  }
						: { healthSystem: this.props.isSuperUser ? this.props.healthSystems[0].id : '', role: '', minutes: '' }
				}
				validationSchema={Yup.object().shape({
					healthSystem: Yup.string().required('Health system is required'),
					role: Yup.string().required('Role is required'),
					minutes: Yup.number()
						.positive('Minutes must be greater than 0')
						.max(480)
						.required('Minutes are required'),
				})}
				onSubmit={this.handleSubmitMyForm}>
				{props => {
					const { handleSubmit, isSubmitting } = props;
					return (
						<Modal
							modalSelector='setTimeoutModal'
							display={this.props.isModalOpen}
							position='right'
							onModalSubmit={handleSubmit}
							onModalClose={this.onCloseModal}
							isLoading={isSubmitting}>
							<Form>
								<h3>User needs to sign in again after a period of inactivity</h3>
								<Field
									name='healthSystem'
									type='select'
									label='Select Health System'
									placeholder='Select Health System'
									description='Select health system or choose All to apply period of inactivity'
									items={this.props.healthSystems}
									component={Select}
								/>
								<Field
									name='role'
									type='select'
									label='Choose Role'
									placeholder='Select Role'
									description='Select role or choose All to apply period of inactivity'
									items={this.props.roles}
									component={Select}
								/>
								<Field
									name='minutes'
									type='number'
									label='Set period of inactivity'
									placeholder='3 minutes'
									description={`User will be notified for 30 seconds, if they don't confirm presence they will be auto logged out`}
									component={Input}
								/>

								<Alert
									alertSelector='setTimeoutAlertMessage'
									display={this.state.isSuccessfulAlertOn}
									message='Configuration successfully added'
									variant='success'
									onClose={() => this.setState({ isSuccessfulAlertOn: false })}
								/>
								<Alert
									alertSelector='setTimeoutErrorMessage'
									display={this.state.isErrorsAlertOn}
									message='Something went wrong! Please try again. '
									variant='error'
									onClose={() => this.setState({ isErrorsAlertOn: false })}
								/>
							</Form>
						</Modal>
					);
				}}
			</Formik>
		);
	}
}
