import React from 'react';
import Layout from 'components/Common/Layout';
import { Grid, Tabs } from 'components';
import Header from 'components/Common/Header';
import UserIdleTimeout from 'components/Configurations/UserIdleTimeout';
import DeviceConfig from 'components/Configurations/DeviceConfig';
import { getHealthSystems } from 'api/users';
import { getUserRole } from 'infrastructure/auth';
import queryString from 'query-string';
import ActiveDirectory from 'components/Configurations/ActiveDirectory';
import { Roles } from 'constants/enums';
import NetworkConfig from 'components/Configurations/NetworkConfig';

class Configurations extends React.Component {
	state = {
		isLoading: true,
		activeTab: 0,
		healthSystems: [],
		selectedHealthSystem: { value: '0', label: 'All' },
		selectedHospitalId: null,
		selectedDepartmentId: null,
		selectedRole: { value: '0', label: 'All' },
		pageSize: { value: 10, label: '10' },
		pageIndex: 0,
		activeSubTab: 0,
	};

	role = null;

	componentDidMount = async () => {
		const result = await getHealthSystems();
		this.role = getUserRole();
		const queryParams = queryString.parse(this.props.location.search);
		this.setState(
			{
				healthSystems: result,
			},
			() => {
				this.setStateFromQueryString(queryParams);
			}
		);
	};

	componentDidUpdate(prevProps) {
		const currentParams = queryString.parse(this.props.location.search);
		const prevParams = queryString.parse(prevProps.location.search);
		if (this.hasQueryChanged(currentParams, prevParams)) {
			this.setStateFromQueryString(currentParams);
		}
	}

	hasQueryChanged = (currentParams, prevParams) => {
		return (
			currentParams.pageIndex !== prevParams.pageIndex ||
			currentParams.pageSize !== prevParams.pageSize ||
			currentParams.activeTab !== prevParams.activeTab ||
			currentParams.healthSystem !== prevParams.healthSystem ||
			currentParams.hospital !== prevParams.hospital ||
			currentParams.role !== prevParams.role
		);
	};

	setStateFromQueryString = queryParams => {
		const { pageIndex, activeTab, healthSystem, hospital, department, role, activeSubTab, pageSize } = queryParams;
		const hs = healthSystem ? this.state.healthSystems.find(x => x.id === healthSystem) : null;
		const selectedRole = role ? Object.values(Roles).find(x => x.id === parseInt(role, 10)) : null;

		const validPageSize = { value: 10, label: '10' };
		if (pageSize === '25' || pageSize === '50') {
			validPageSize.value = parseInt(pageSize, 10);
			validPageSize.label = pageSize;
		}

		this.setState({
			pageIndex: pageIndex ? parseInt(pageIndex, 10) : 0,
			pageSize: validPageSize,
			activeTab: activeTab ? parseInt(activeTab, 10) : 0,
			selectedHealthSystem: hs ? { value: hs.id, label: hs.name } : { value: '0', label: 'All' },
			selectedRole: selectedRole ? { value: selectedRole.id, label: selectedRole.value } : { value: '0', label: 'All' },
			selectedHospitalId: hospital,
			selectedDepartmentId: department,
			activeSubTab: activeSubTab ? parseInt(activeSubTab, 10) : 0,
			isLoading: false,
		});
	};

	onHealthSystemChange = selectedHealthSystem => {
		this.setState({
			selectedHospitalId: null,
			selectedDepartmentId: null,
			selectedHealthSystem,
			pageIndex: 0,
		});
	};

	onRoleChange = selectedRole => {
		this.setState({
			selectedRole,
		});
	};

	onPaginationChange = (pageSize, pageIndex) => {
		this.setState(
			{
				pageSize,
				pageIndex,
			},
			() => {
				this.pushFiltersToQueryString();
			}
		);
	};

	onSubTabChange = activeSubTab => {
		this.setState(
			{
				activeSubTab,
				pageSize: { value: 10, label: '10' },
				pageIndex: 0,
			},
			() => {
				this.pushFiltersToQueryString();
			}
		);
	};

	onHospitalChange = selectedHospital => {
		this.setState({
			selectedHospitalId: selectedHospital.value,
			selectedDepartmentId: null,
			pageIndex: 0,
		});
	};

	onDepartmentChange = selectedDepartment => {
		this.setState({
			selectedDepartmentId: selectedDepartment.value,
		});
	};

	onTabChange = async index => {
		this.setState({
			isLoading: true,
		});
		const healthSystems = await getHealthSystems();
		this.setState(
			{
				healthSystems,
				selectedHospitalId: null,
				selectedDepartmentId: null,
				selectedRole: { value: '0', label: 'All' },
				pageSize: { value: 10, label: '10' },
				pageIndex: 0,
				activeSubTab: 0,
				activeTab: parseInt(index, 10),
			},
			() => {
				this.pushFiltersToQueryString();
				this.setState({
					isLoading: false,
				});
			}
		);
	};

	pushFiltersToQueryString = () => {
		const queryParams = {};
		if (this.state.pageIndex > 0) {
			queryParams.pageIndex = this.state.pageIndex.toString();
		}
		if (this.state.pageSize.value > 10) {
			queryParams.pageSize = this.state.pageSize.value.toString();
		}
		if (this.state.activeTab > 0) {
			queryParams.activeTab = this.state.activeTab.toString();
		}
		if (this.state.selectedHealthSystem !== null && this.state.selectedHealthSystem.value !== '0') {
			queryParams.healthSystem = this.state.selectedHealthSystem.value;
		}
		if (this.state.selectedHospitalId !== null && this.state.selectedHospitalId !== '0' && this.state.selectedHospitalId !== 0) {
			queryParams.hospital = this.state.selectedHospitalId;
		}
		if (this.state.selectedDepartmentId !== null && this.state.selectedDepartmentId !== '0' && this.state.selectedDepartmentId !== 0) {
			queryParams.department = this.state.selectedDepartmentId;
		}
		if (this.state.selectedRole !== null && this.state.selectedRole.value !== '0') {
			queryParams.role = this.state.selectedRole.value.toString();
		}
		if (this.state.activeSubTab > 0) {
			queryParams.activeSubTab = this.state.activeSubTab.toString();
		}
		this.props.history.push({ search: queryString.stringify(queryParams) });
	};

	tabs = {
		USER_IDLE_TIMEOUT: 0,
		DEVICE: 1,
		ACTIVE_DIRECTORY: 2,
		NETWORK: 3,
	};

	render() {
		return (
			<Layout>
				<div className='users-view test'>
					<Grid stretch='100%'>
						<main className='main-view'>
							<Header />
							<section>
								<Grid width='100%' vertAlign='start'>
									<h3>Configurations</h3>
									<Tabs
										links={[
											{ link: 'User idle timeout', active: this.state.activeTab === this.tabs.USER_IDLE_TIMEOUT },
											{ link: 'Device', active: this.state.activeTab === this.tabs.DEVICE },
											{ link: 'Active Directory', active: this.state.activeTab === this.tabs.ACTIVE_DIRECTORY },
											{ link: 'Network', active: this.state.activeTab === this.tabs.NETWORK },
										]}
										key={this.state.activeTab}
										components={[
											<UserIdleTimeout
												healthSystems={this.state.healthSystems}
												selectedHealthSystem={this.state.selectedHealthSystem}
												pageSize={this.state.pageSize}
												pageIndex={this.state.pageIndex}
												selectedRole={this.state.selectedRole}
												onHistoryClick={this.pushFiltersToQueryString}
												setSelectedHealthSystem={this.onHealthSystemChange}
												setSelectedRole={this.onRoleChange}
												onPaginationChange={this.onPaginationChange}
												isLoading={this.state.isLoading}
											/>,
											<DeviceConfig
												healthSystems={this.state.healthSystems}
												selectedHealthSystem={this.state.selectedHealthSystem}
												selectedHospitalId={this.state.selectedHospitalId}
												selectedDepartmentId={this.state.selectedDepartmentId}
												pageSize={this.state.pageSize}
												pageIndex={this.state.pageIndex}
												selectedRole={this.state.selectedRole}
												setSelectedHealthSystem={this.onHealthSystemChange}
												setSelectedHospital={this.onHospitalChange}
												setSelectedDepartment={this.onDepartmentChange}
												setSelectedRole={this.onRoleChange}
												pushFiltersToQueryString={this.pushFiltersToQueryString}
												onPaginationChange={this.onPaginationChange}
												activeSubTab={this.state.activeSubTab}
												onSubTabChange={this.onSubTabChange}
												isLoading={this.state.isLoading}
											/>,
											<ActiveDirectory
												healthSystems={this.state.healthSystems}
												selectedHealthSystem={this.state.selectedHealthSystem}
												selectedHospitalId={this.state.selectedHospitalId}
												pageSize={this.state.pageSize}
												pageIndex={this.state.pageIndex}
												activeSubTab={this.state.activeSubTab}
												setSelectedHealthSystem={this.onHealthSystemChange}
												setSelectedHospital={this.onHospitalChange}
												onHistoryClick={this.pushFiltersToQueryString}
												onPaginationChange={this.onPaginationChange}
												onSubTabChange={this.onSubTabChange}
												isLoading={this.state.isLoading}
											/>,
											<NetworkConfig
												healthSystems={this.state.healthSystems}
												selectedHealthSystem={this.state.selectedHealthSystem}
												selectedHospitalId={this.state.selectedHospitalId}
												pageSize={this.state.pageSize}
												pageIndex={this.state.pageIndex}
												onHistoryClick={this.pushFiltersToQueryString}
												setSelectedHealthSystem={this.onHealthSystemChange}
												setSelectedHospital={this.onHospitalChange}
												onPaginationChange={this.onPaginationChange}
											/>,
										]}
										onTabChange={this.onTabChange}
									/>
								</Grid>
							</section>
						</main>
					</Grid>
				</div>
			</Layout>
		);
	}
}
export default Configurations;
