import React from 'react';
import { Route } from 'react-router-dom';
import Loader from 'components/Loader';
import Grid from 'components/Grid';
import { memberExists } from 'infrastructure/auth';
import UserError from 'views/UserError';
import { AuthConsumer } from 'providers/authProvider';

const JWTAuthenticatedRoute = ({ component, ...rest }) => {
	const renderFn = Component => props => (
		<AuthConsumer>
			{({ isAzureAuthentication, signinRedirect }) => {
				if (!!Component && isAzureAuthentication()) {
					if (memberExists() || props.match.url === '/logout') {
						return <Component {...props} />;
					}
					return <UserError />;
				}

				signinRedirect(true);
				return (
					<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				);
			}}
		</AuthConsumer>
	);

	return <Route {...rest} render={renderFn(component)} />;
};

export default JWTAuthenticatedRoute;
