import React from 'react';
import { Table, Grid, Button, Modal, Form, Input, Select as SelectTag, Loader } from 'components';
import Select from 'react-select';
import Pagination from 'components/Common/Pagination';
import { getHealthSystemHospitals, getTeamConfigurations, addDeviceConfiguration, deleteTVDeviceConfiguration } from 'api/userIdleConfigurations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { UserRoles, TeamTypes, ConfigHistoryTypes, TVs, TeamConfigurationIntegrationType, TelehealthTv } from 'constants/enums';
import moment from 'moment';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { If, Then, Else } from 'react-if';
import _ from 'lodash';
import { getUserRole } from 'infrastructure/auth';
import { Link } from 'react-router-dom';
import {
	addTeamConfigurationProfile,
	deleteTeamConfigurationProfile,
	getTeamConfigurationProfiles,
	updateTeamConfigurationProfile,
} from 'api/teamConfigurationProfiles';
import { getHopsitalDepartments } from 'api/organization';

class DeviceConfig extends React.Component {
	initialConfigurationFormData = {
		healthSystems: this.props.healthSystems,
		hospitals: [],
		departments: [],
		hdmiPorts: [
			{ id: 1, value: 'HDMI 1' },
			{ id: 2, value: 'HDMI 2' },
			{ id: 3, value: 'HDMI 3' },
		],
		endCallSource: [
			{ id: 0, value: 'TV Channels' },
			{ id: 1, value: 'HDMI 1' },
			{ id: 2, value: 'HDMI 2' },
			{ id: 3, value: 'HDMI 3' },
		],
		tvs: [
			{ id: 1, value: TVs.LG },
			{ id: 2, value: TVs.SAMSUNG },
			{ id: 3, value: TelehealthTv.label },
			{ id: 4, value: TVs.PCARE },
			{ id: 5, value: TVs.CEC },
		],
		integrationTypes: [
			{ id: 0, value: 'TeleHealth' },
			{ id: 1, value: 'pCare' },
		],
		selectedHealthSystemValue: -1,
		selectedHealthSystem: null,

		selectedHospitalValue: -1,
		selectedHospital: null,

		selectedHdmiPortValue: -1,
		selectedTVValue: -1,

		selectedEndCallSource: -1,

		selectedDepartment: null,
		selectedDepartmentValue: -1,

		selectedTeamProfileValue: -1,
		selectedTeamProfile: null,

		ringtoneVolumeValue: 0,
		selectedId: null,
		selectedIntegrationTypeValue: 0,
		profileName: '',
		url: '',
		port: '',
		hsSecret: '',
		userName: '',

		isEdit: false,
		errors: {
			healthSystemError: null,
			hospitalError: null,
			departmentError: null,
			hdmiPortError: null,
			endCallSourceError: null,
			ringtoneVolumeError: null,
			profileNameError: null,
			urlError: null,
			portError: null,
			hsSecretError: null,
			teamProfileError: null,
			tvError: null,
		},

		isSecretChanged: false,
		newSecret: '',
		isHospitalLoading: false,
		isDepartmentLoading: false,
	};

	state = {
		isLoading: true,
		showConfigurationModal: false,
		hospitals: [],
		departments: [],
		teamProfiles: [],
		isHospitalDropdownDisabled: true,
		isDepartmentDropdownDisabled: true,
		configurations: [],
		healthSystemConfigs: [],
		isFormLoading: false,
		totalConfigs: null,
		isDeleteConfigModalOpen: false,
		deleteConfig: null,
		configurationFormData: _.cloneDeep(this.initialConfigurationFormData),
	};

	deviceConfigHeaders = [
		{ title: 'Health System' },
		{ title: 'Hospital' },
		{ title: 'Department' },
		{ title: 'HDMI Port' },
		{ title: 'Default Source' },
		{ title: 'Ringtone Volume' },
		{ title: 'TV' },
		{ title: 'Profile Name' },
		{ title: 'Created by' },
		{ title: 'Date Created' },
		{ title: '' },
	];

	healthSystemConfigHeaders = [
		{ title: 'Health System' },
		{ title: 'Profile Name' },
		{ title: 'URL' },
		{ title: 'Port' },
		{ title: 'Date Created' },
		{ title: '' },
	];

	role = null;

	transformTypes = {
		WithValues: 1,
		WithLabels: 2,
	};

	activeSubTab = {
		HealthSystem: 0,
		TV: 1,
	};

	endCallSource = {
		0: 'TV Channels',
		1: 'HDMI 1',
		2: 'HDMI 2',
		3: 'HDMI 3',
	};

	componentDidMount = () => {
		this.role = getUserRole();
		this.getTeamProfiles();
		this.getConfigurations();
	};

	componentDidUpdate = prevProps => {
		if (
			this.props.pageIndex !== prevProps.pageIndex ||
			this.props.selectedHealthSystem !== prevProps.selectedHealthSystem ||
			this.props.selectedHospitalId !== prevProps.selectedHospitalId ||
			this.props.selectedDepartmentId !== prevProps.selectedDepartmentId ||
			this.props.activeSubTab !== prevProps.activeSubTab
		) {
			this.getConfigurations();
			this.getTeamProfiles();
		}
	};

	getTeamProfiles = async () => {
		const response = await getTeamConfigurationProfiles({
			pageSize: 999, // TO DO: Apply pagination
			pageIndex: 0,
			teamTypeId: TeamTypes.HEALTHSYSTEM,
		});

		this.setState({
			teamProfiles: response.teamConfigurationProfiles.map(config => ({ id: config.id, value: config.profileName })),
		});
	};

	getHospitals = async healthSystem => {
		let hospitals;
		if (healthSystem.value !== '0') {
			hospitals = await this.getHealthSystemHospitals(healthSystem.value);
			hospitals.unshift({ value: 0, label: 'All' });
		}
		this.setState({
			isHospitalDropdownDisabled: healthSystem.value === '0',
			isDepartmentDropdownDisabled: healthSystem.value === '0' ? true : this.state.isDepartmentDropdownDisabled,
			hospitals,
		});
	};

	getDepartments = async (healthSystem, hospitalId) => {
		let departments;
		if (healthSystem.value !== '0' && hospitalId !== 0) {
			const { hospital } = await getHopsitalDepartments(healthSystem.value, hospitalId);
			departments = hospital.departments.map(department => ({ value: department.id, label: department.name }));
			departments.unshift({ value: 0, label: 'All' });
		}
		this.setState({
			isDepartmentDropdownDisabled: healthSystem.value === '0' || hospitalId === 0,
			departments,
		});
	};

	getConfigurations = async () => {
		if (this.props.isLoading) {
			return;
		}

		const { selectedHealthSystem, selectedHospitalId, selectedDepartmentId } = this.props;
		let hsId = null;
		if (selectedHealthSystem && selectedHealthSystem.value !== '0') {
			hsId = selectedHealthSystem.value;
		}

		if (this.props.activeSubTab === this.activeSubTab.TV) {
			const response = await getTeamConfigurations({
				healthSystemId: hsId,
				hospitalId: selectedHospitalId || null,
				departmentId: selectedDepartmentId || null,
				pageSize: this.props.pageSize.value,
				pageIndex: this.props.pageIndex,
				teamTypeId: TeamTypes.HOSPITAL,
			});
			if (selectedHealthSystem) {
				await this.getHospitals(selectedHealthSystem);
			}
			if (selectedHealthSystem && selectedHospitalId) {
				await this.getDepartments(selectedHealthSystem, selectedHospitalId);
			}

			this.getDeviceConfigurations(response.deviceConfigurations);
			this.setState({
				totalConfigs: response.total,
			});
		} else {
			const response = await getTeamConfigurationProfiles({
				pageSize: this.props.pageSize.Value,
				pageIndex: this.props.pageIndex,
				teamId: hsId,
				teamTypeId: TeamTypes.HEALTHSYSTEM,
			});

			this.getHealthSystemConfigs(response.teamConfigurationProfiles);
			this.setState({
				totalConfigs: response.total,
			});
		}
	};

	transformHealthSystemsForSelect = healthSystems => {
		return healthSystems.map(healthSystem => {
			return { label: healthSystem.name, value: healthSystem.id };
		});
	};

	getHealthSystemHospitals = async healthSystemId => {
		const firstHSHospitals = await getHealthSystemHospitals(healthSystemId);
		return this.transformHealthSystemsForSelect(firstHSHospitals);
	};

	onHospitalSelect = async selection => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.setSelectedHospital(selection);
			}
		);
	};

	onDepartmentSelect = async selection => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.setSelectedDepartment(selection);
			}
		);
	};

	getHospitalConfigurations = async (healthSystemId, hospitalId, pageSize, pageIndex = 0) => {
		const response = await getTeamConfigurations({
			healthSystemId: healthSystemId,
			teamId: hospitalId,
			pageSize: pageSize || 10,
			pageIndex: pageIndex,
			teamTypeId: TeamTypes.HOSPITAL,
		});
		return response;
	};

	onHealthSystemSelect = async selection => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.setSelectedHealthSystem(selection);
			}
		);
	};

	onFormHealthSystemSelect = async ({ id }) => {
		const { configurationFormData } = this.state;
		if (configurationFormData.isEdit) {
			return;
		}

		this.setState({
			configurationFormData: { ...configurationFormData, isHospitalLoading: true },
		});

		configurationFormData.selectedHealthSystemValue = configurationFormData.healthSystems.findIndex(hs => id === hs.id);
		configurationFormData.selectedHealthSystem = configurationFormData.healthSystems.find(hs => id === hs.id);
		configurationFormData.isHospitalLoading = false;
		if (this.props.activeSubTab === this.activeSubTab.TV) {
			const hsHospitals = await getHealthSystemHospitals(id);
			configurationFormData.hospitals = hsHospitals.map(hospital => ({ id: hospital.id, value: hospital.name }));
			configurationFormData.selectedHospital = null;
			configurationFormData.selectedHospitalValue = -1;
			configurationFormData.selectedDepartment = null;
			configurationFormData.selectedDepartmentValue = -1;
		}
		this.setState({
			configurationFormData,
		});
	};

	onFormHospitalSelect = async ({ id }) => {
		const { configurationFormData } = this.state;
		if (configurationFormData.isEdit) {
			return;
		}
		this.setState({
			configurationFormData: { ...configurationFormData, isDepartmentLoading: true },
		});
		configurationFormData.selectedHospitalValue = configurationFormData.hospitals.findIndex(hospital => id === hospital.id);
		configurationFormData.errors.hospitalError = null;

		const { hospital } = await getHopsitalDepartments(configurationFormData.selectedHealthSystem.id, id);

		configurationFormData.departments = hospital.departments.map(department => ({ id: department.id, value: department.name }));
		configurationFormData.selectedDepartment = null;
		configurationFormData.selectedDepartmentValue = -1;
		configurationFormData.isDepartmentLoading = false;
		this.setState({
			configurationFormData,
		});
	};

	onFormDepartmentSelect = ({ id }) => {
		const { configurationFormData } = this.state;
		if (configurationFormData.isEdit) {
			return;
		}

		configurationFormData.selectedDepartmentValue = configurationFormData.departments.findIndex(dep => id === dep.id);
		configurationFormData.selectedDepartment = configurationFormData.departments.find(hs => id === hs.id);
		configurationFormData.errors.departmentError = null;

		this.setState({
			configurationFormData,
		});
	};

	onFormTeamProfileSelect = ({ id }) => {
		const { configurationFormData, teamProfiles } = this.state;
		configurationFormData.selectedTeamProfileValue = teamProfiles.findIndex(profile => id === profile.id);
		configurationFormData.selectedTeamProfile = teamProfiles.find(profile => id === profile.id);
		configurationFormData.errors.teamProfileError = null;

		this.setState({
			configurationFormData,
		});
	};

	onFormHdmiPortSelect = event => {
		const { configurationFormData } = this.state;

		configurationFormData.selectedHdmiPortValue = +event.target.value;
		configurationFormData.errors.hdmiPortError = null;

		this.setState({
			configurationFormData,
		});
	};

	onEndCallSourceSelect = event => {
		const { configurationFormData } = this.state;

		configurationFormData.selectedEndCallSource = +event.target.value;
		configurationFormData.errors.endCallSourceError = null;

		this.setState({
			configurationFormData,
		});
	};

	onFormTVSelect = ({ id }) => {
		const { configurationFormData } = this.state;

		configurationFormData.selectedTVValue = configurationFormData.tvs.findIndex(tv => id === tv.id);
		configurationFormData.errors.tvError = null;

		this.setState({
			configurationFormData,
		});
	};

	onFormRingtoneVolumeChange = event => {
		const { configurationFormData } = this.state;

		configurationFormData.ringtoneVolumeValue = event.target.value;

		configurationFormData.errors.ringtoneVolumeError = null;

		this.setState({
			configurationFormData,
		});
	};

	addDeviceConfiguration = async () => {
		const { configurationFormData } = this.state;
		let hasError = false;

		if (this.props.activeSubTab === this.activeSubTab.TV) {
			if (!configurationFormData.isEdit) {
				if (configurationFormData.selectedHospitalValue === -1 && !configurationFormData.selectedHospital) {
					configurationFormData.errors.hospitalError = 'Please select a hospital.';
					hasError = true;
				}

				if (configurationFormData.selectedDepartmentValue === -1 && !configurationFormData.selectedDepartment) {
					configurationFormData.errors.departmentError = 'Please select a department.';
					hasError = true;
				}
			}

			if (configurationFormData.selectedHdmiPortValue === -1) {
				configurationFormData.errors.hdmiPortError = 'Please select a HDMI port.';
				hasError = true;
			}

			if (configurationFormData.selectedTVValue === -1) {
				configurationFormData.errors.tvError = 'Please select a TV.';
				hasError = true;
			}
			const ringtoneVolumeNumber = +configurationFormData.ringtoneVolumeValue;
			if (Number.isNaN(ringtoneVolumeNumber) || ringtoneVolumeNumber < 0 || ringtoneVolumeNumber > 100) {
				configurationFormData.errors.ringtoneVolumeError = 'Ringtone volume must be in between 0 and 100.';
				hasError = true;
			}

			if (configurationFormData.selectedEndCallSource === -1) {
				configurationFormData.errors.endCallSourceError = 'Please select a TV End Call Source.';
				hasError = true;
			}

			if (hasError) {
				this.setState({ configurationFormData });
				return;
			}

			const hdmiPort = configurationFormData.selectedHdmiPortValue;
			const departmentId = configurationFormData.selectedDepartment.id;
			const teamConfigurationProfileId = configurationFormData.selectedTeamProfile ? configurationFormData.selectedTeamProfile.id : null;
			const { id: defaultSource } = configurationFormData.endCallSource[configurationFormData.selectedEndCallSource];
			const tv =
				configurationFormData.selectedTVValue + 1 === TelehealthTv.id
					? TelehealthTv.value
					: configurationFormData.tvs[configurationFormData.selectedTVValue].value;

			this.setState({ isFormLoading: true });

			const { hasSucceeded } = await addDeviceConfiguration(departmentId, {
				teamTypeId: TeamTypes.DEPARTMENT,
				defaultSource,
				hdmiPort,
				ringtoneVolume: ringtoneVolumeNumber,
				teamConfigurationProfileId: teamConfigurationProfileId,
				tv,
			});

			if (!hasSucceeded) {
				this.setState({ isFormLoading: false });
				return;
			}

			await this.resetAddConfigurationModal();
		} else {
			if (!configurationFormData.isEdit) {
				if (configurationFormData.selectedHealthSystemValue === -1) {
					configurationFormData.errors.healthSystemError = 'Please select a health system.';
					hasError = true;
				}
			}
			if (configurationFormData.profileName === '') {
				configurationFormData.errors.profileNameError = 'Please set a profile name.';
				hasError = true;
			}
			if (configurationFormData.userName === '' && configurationFormData.selectedIntegrationTypeValue === 1) {
				configurationFormData.errors.userNameError = 'Please set a username.';
				hasError = true;
			}
			if (configurationFormData.url === '') {
				configurationFormData.errors.urlError = 'Please set a URL.';
				hasError = true;
			}

			let newSecretKey = configurationFormData.newSecret;

			if (!newSecretKey) {
				newSecretKey = '';
			}

			const secretKeyWoSpaces = newSecretKey.trim();

			if ((!configurationFormData.hsSecret && !secretKeyWoSpaces) || (configurationFormData.isSecretChanged && !secretKeyWoSpaces)) {
				configurationFormData.errors.hsSecretError = 'Please set a secret.';
				hasError = true;
			}

			let port = null;
			if (configurationFormData.port) {
				port = +configurationFormData.port;
			}
			if (!port) {
				configurationFormData.errors.portError = 'Port is required';
				hasError = true;
			}

			if (hasError) {
				this.setState({ configurationFormData });
				return;
			}

			this.setState({ isFormLoading: true });

			if (!configurationFormData.isEdit) {
				const response = await addTeamConfigurationProfile(configurationFormData.selectedHealthSystem.id, TeamTypes.HEALTHSYSTEM, {
					profileName: configurationFormData.profileName,
					url: configurationFormData.url,
					port: parseInt(configurationFormData.port, 10),
					secret: window.btoa(`${configurationFormData.userName}:${configurationFormData.newSecret}`),
					integrationTypeId:
						configurationFormData.selectedIntegrationTypeValue === 0 ? TeamConfigurationIntegrationType.TELEHEALTH : TeamConfigurationIntegrationType.PCARE,
				});

				if (!response.hasSucceeded || response.error) {
					this.setState({ isFormLoading: false });
					return;
				}
			} else {
				const { hasSucceeded } = await updateTeamConfigurationProfile(
					configurationFormData.selectedHealthSystem.id,
					TeamTypes.HEALTHSYSTEM,
					configurationFormData.selectedId,
					{
						profileName: configurationFormData.profileName,
						url: configurationFormData.url,
						port: parseInt(configurationFormData.port, 10),
						secret: configurationFormData.isSecretChanged
							? window.btoa(`${configurationFormData.userName}:${configurationFormData.newSecret}`)
							: window.btoa(`${configurationFormData.userName}:${configurationFormData.hsSecret}`),
						integrationTypeId:
							configurationFormData.selectedIntegrationTypeValue === 0 ? TeamConfigurationIntegrationType.TELEHEALTH : TeamConfigurationIntegrationType.PCARE,
					}
				);

				if (!hasSucceeded) {
					this.setState({ isFormLoading: false });
					return;
				}
			}
			await this.resetAddConfigurationModal();
		}
		this.getConfigurations();
	};

	resetAddConfigurationModal = () => {
		return new Promise(res => {
			this.setState(
				{
					configurationFormData: _.cloneDeep(this.initialConfigurationFormData),
					showConfigurationModal: false,
					isFormLoading: false,
				},
				() => res()
			);
		});
	};

	getDeviceConfigurations = configurations => {
		if (!configurations.length) {
			this.setState({
				configurations: [],
				isLoading: false,
			});
			return;
		}
		const transformConfigs = configurations.map(config => ({
			healthSystem: config.team.healthSystemName,
			hospitalName: config.team.hospitalName,
			hospital: config.team.name, // Department
			hdmiPort: config.hdmiPort,
			defaultSource: this.endCallSource[config.defaultSource],
			ringtoneVolume: config.ringtoneVolume,
			tv: config.tv === TelehealthTv.value ? TelehealthTv.label : config.tv,
			profileName: config.teamConfigurationProfile ? config.teamConfigurationProfile.profileName : '',
			createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
			dateCreated: moment(utcToLocalTime(config.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
			actions: (
				<div className='wrapped'>
					<Link to={`/configurations/${config.team.id}/type/${ConfigHistoryTypes.Devices}`} onClick={this.props.pushFiltersToQueryString}>
						<span className='material-icons-outlined cursor-pointer mr-20' data-cy='viewHistory' data-tooltip='View history' data-position='top'>
							list_alt
						</span>
					</Link>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-cy='edit'
						data-tooltip='Edit'
						data-position='top'
						onClick={() => this.toggleConfigurationModal(config)}>
						create
					</span>
					<i
						className='material-icons-outlined cursor-pointer'
						data-cy='deleteActiveDirectory'
						style={{ color: 'red', backgroundColor: 'white' }}
						data-tooltip='Delete'
						data-position='top'
						onClick={() => this.openDeleteModal(config)}>
						delete
					</i>
				</div>
			),
		}));
		this.setState({
			configurations: transformConfigs,
			isLoading: false,
		});
	};

	toggleConfigurationModal = async (config = null) => {
		const { showConfigurationModal, configurationFormData, teamProfiles } = this.state;
		if (!showConfigurationModal) {
			this.props.pushFiltersToQueryString();
			configurationFormData.isEdit = !!config;
			configurationFormData.healthSystems = this.props.allHealthSystems.map(hs => ({ id: hs.id, value: hs.name }));
			if (this.props.activeSubTab === this.activeSubTab.TV) {
				if (this.role === UserRoles.SUPERUSER) {
					this.onFormHealthSystemSelect(configurationFormData.healthSystems[0]);
				}

				if (configurationFormData.isEdit) {
					//
					configurationFormData.selectedDepartment = config.team;
					configurationFormData.selectedHdmiPortValue = configurationFormData.hdmiPorts.find(port => config.hdmiPort === port.id).id;
					configurationFormData.selectedEndCallSource = configurationFormData.endCallSource.find(ds => config.defaultSource === ds.id).id;
					configurationFormData.ringtoneVolumeValue = config.ringtoneVolume;
					configurationFormData.selectedTeamProfileValue = teamProfiles.findIndex(p => config.teamConfigurationProfileId === p.id);
					configurationFormData.selectedTeamProfile = teamProfiles.find(p => config.teamConfigurationProfileId === p.id);
					configurationFormData.selectedTVValue = configurationFormData.tvs.findIndex(t =>
						config.tv === TelehealthTv.value ? TelehealthTv.label === t.value : config.tv === t.value
					);
				}

				this.setState({
					configurationFormData,
					showConfigurationModal: !showConfigurationModal,
				});
			} else if (configurationFormData.isEdit) {
				const isBase64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
				if (config.secret.match(isBase64)) {
					const decodedSecret = window.atob(config.secret);
					const userData = decodedSecret.split(':');
					if (!userData || window.atob(config.secret).split(':').length < 2) {
						configurationFormData.newSecret = '';
						configurationFormData.hsSecret = '';
					} else {
						[configurationFormData.userName, configurationFormData.hsSecret] = userData;
						configurationFormData.newSecret = `${userData[1].slice(0, 3)}************`;
					}
				} else {
					configurationFormData.hsSecret = config.secret;
					configurationFormData.newSecret = `${config.secret.slice(0, 3)}************`;
				}
				configurationFormData.selectedHealthSystem = config.team;
				configurationFormData.profileName = config.profileName;
				configurationFormData.port = config.port;
				configurationFormData.url = config.url;
				configurationFormData.selectedId = config.id;
				configurationFormData.selectedIntegrationTypeValue = config.integrationTypeId === TeamConfigurationIntegrationType.TELEHEALTH ? 0 : 1;
				this.setState({
					configurationFormData,
					showConfigurationModal: !showConfigurationModal,
				});
			} else {
				this.setState({
					showConfigurationModal: !showConfigurationModal,
				});
			}
		} else {
			this.resetAddConfigurationModal();
		}
	};

	onPaginationChange = async (pageSize, pageIndex) => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.onPaginationChange(pageSize, pageIndex);
			}
		);
	};

	transformArray = (array, type, isHealthSystem) => {
		if (type === this.transformTypes.WithValues) {
			const newArray = array.map(item => {
				return { id: item.id, value: item.name };
			});
			if (isHealthSystem) {
				if (this.role === UserRoles.ADMIN) {
					newArray.unshift({ id: '0', value: 'All' });
				}
			} else {
				newArray.unshift({ id: '0', value: 'All' });
			}
			return newArray;
		}
		if (type === this.transformTypes.WithLabels) {
			const newArray = array.map(item => {
				return { value: item.id, label: item.name };
			});
			if (isHealthSystem) {
				if (this.role === UserRoles.ADMIN) {
					newArray.unshift({ value: '0', label: 'All' });
				}
			} else {
				newArray.unshift({ value: '0', label: 'All' });
			}
			return newArray;
		}
		return [];
	};

	onTabChange = tabIndex => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.onSubTabChange(tabIndex);
			}
		);
	};

	getTabsConfigurations = () => {
		if (this.props.activeSubTab === this.activeSubTab.HealthSystem) {
			return this.state.healthSystemConfigs;
		}
		return this.state.configurations;
	};

	onFormTextChange = event => {
		const { configurationFormData } = this.state;

		configurationFormData[event.target.name] = event.target.value;
		configurationFormData.errors[`${event.target.name}Error`] = null;

		this.setState({
			configurationFormData,
		});
	};

	openDeleteModal = deleteConfig => {
		this.props.pushFiltersToQueryString();
		this.setState({
			deleteConfig,
			isDeleteConfigModalOpen: true,
		});
	};

	getHealthSystemConfigs = configurations => {
		if (!configurations.length) {
			this.setState({
				healthSystemConfigs: [],
				isLoading: false,
			});
			return;
		}
		const transformConfigs = configurations.map(config => ({
			healthSystem: config.team.name,
			profileName: config.profileName,
			url: config.url,
			port: config.port,
			dateCreated: moment(utcToLocalTime(config.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
			actions: (
				<div className='wrapped'>
					<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.TeamConfigurationProfiles}`} onClick={this.props.pushFiltersToQueryString}>
						<span className='material-icons-outlined cursor-pointer mr-20' data-cy='viewHistory' data-tooltip='View history' data-position='top'>
							list_alt
						</span>
					</Link>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-cy='edit'
						data-tooltip='Edit'
						data-position='top'
						onClick={() => this.toggleConfigurationModal(config)}>
						create
					</span>
					<i
						className='material-icons-outlined cursor-pointer'
						data-cy='deleteActiveDirectory'
						style={{ color: 'red', backgroundColor: 'white' }}
						data-tooltip='Delete'
						data-position='top'
						onClick={() => this.openDeleteModal(config)}>
						delete
					</i>
				</div>
			),
		}));
		this.setState({
			healthSystemConfigs: transformConfigs,
			isLoading: false,
		});
	};

	deleteTeamProfile = async () => {
		this.setState({
			isLoading: true,
			isDeleteConfigModalOpen: false,
		});

		const { deleteConfig } = this.state;

		if (this.props.activeSubTab === this.activeSubTab.HealthSystem) {
			await deleteTeamConfigurationProfile(deleteConfig.team.id, deleteConfig.team.typeId, deleteConfig.id);
		} else if (this.props.activeSubTab === this.activeSubTab.TV) {
			await deleteTVDeviceConfiguration(deleteConfig.team.id, deleteConfig.id);
		}
		this.getConfigurations();
	};

	selectIntegrationType = ({ id }) => {
		const { configurationFormData } = this.state;
		configurationFormData.selectedIntegrationTypeValue = id;
		this.setState({
			configurationFormData,
		});
	};

	render() {
		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};

		const { configurationFormData, isFormLoading, isLoading } = this.state;

		return (
			<div className='device-config'>
				<Table
					isLoading={isLoading}
					headers={this.props.activeSubTab === this.activeSubTab.HealthSystem ? this.healthSystemConfigHeaders : this.deviceConfigHeaders}
					rows={isLoading ? [] : this.getTabsConfigurations()}>
					<Grid columns={`1fr ${this.props.activeSubTab === this.activeSubTab.TV ? '1fr 1fr' : ''} 2fr`} gridGap='10px' vertAlign='center'>
						<Select
							value={this.props.selectedHealthSystem}
							placeholder='All'
							classNamePrefix='custom-select'
							options={this.transformArray(this.props.healthSystems, this.transformTypes.WithLabels, true)}
							components={{ DropdownIndicator }}
							onChange={this.onHealthSystemSelect}
							isDisabled={this.role === UserRoles.SUPERUSER}
						/>
						{this.props.activeSubTab === this.activeSubTab.TV && (
							<>
								<Select
									value={this.props.selectedHospitalId ? this.state.hospitals.find(x => x.value === this.props.selectedHospitalId) : null}
									isDisabled={this.state.isHospitalDropdownDisabled}
									classNamePrefix='custom-select'
									options={this.state.hospitals}
									components={{ DropdownIndicator }}
									onChange={this.onHospitalSelect}
								/>
								<Select
									value={this.props.selectedDepartmentId ? this.state.departments.find(x => x.value === this.props.selectedDepartmentId) : null}
									isDisabled={this.state.isDepartmentDropdownDisabled}
									classNamePrefix='custom-select'
									options={this.state.departments}
									components={{ DropdownIndicator }}
									onChange={this.onDepartmentSelect}
								/>
							</>
						)}
						<Button
							text={this.props.activeSubTab === this.activeSubTab.HealthSystem ? 'Add Health System Configuration' : 'Add TV Configuration'}
							horizAlign='end'
							onClick={() => this.toggleConfigurationModal()}
						/>
					</Grid>
					<div>
						<ul className='tabs active-directory-tabs' data-cy='activeDirectoryTabs'>
							<li className={this.props.activeSubTab === this.activeSubTab.HealthSystem ? 'active' : ''}>
								<span onClick={() => this.onTabChange(this.activeSubTab.HealthSystem)}>Health System</span>
							</li>
							<li className={this.props.activeSubTab === this.activeSubTab.TV ? 'active' : ''}>
								<span onClick={() => this.onTabChange(this.activeSubTab.TV)}>TV </span>
							</li>
						</ul>
					</div>
				</Table>
				<Pagination
					totalCount={this.state.totalConfigs}
					pageSize={this.props.pageSize}
					pageIndex={this.props.pageIndex}
					onChange={(pageSize, pageIndex) => this.onPaginationChange(pageSize, pageIndex)}
				/>
				<Modal
					modalSelector='deviceConfigurationsModal'
					className='configuration-modal'
					display={this.state.showConfigurationModal}
					position='right'
					onModalSubmit={this.addDeviceConfiguration}
					onModalClose={() => this.toggleConfigurationModal()}
					isLoading={isFormLoading}>
					<Form title={this.props.activeSubTab === this.activeSubTab.HealthSystem ? 'Health System' : 'TV'} onSubmit={event => event.preventDefault()}>
						<If condition={this.props.activeSubTab === this.activeSubTab.TV}>
							<Then>
								<If condition={!configurationFormData.isEdit}>
									<Then>
										<If condition={this.role === UserRoles.SUPERUSER}>
											<Then>
												<Input
													type='text'
													label='Select Health System'
													name='currentHealthSystemId'
													description='Select Health System so you can choose the hospital to apply the configurations.'
													placeholder='Select Health System'
													value={
														configurationFormData.selectedHealthSystemValue > -1
															? configurationFormData.healthSystems[configurationFormData.selectedHealthSystemValue].value
															: ''
													}
													validationOptions={{}}
													disabled={true}
												/>
											</Then>
											<Else>
												<SelectTag
													type='text'
													defaultValue={configurationFormData.selectedHealthSystemValue}
													label='Select Health System'
													name='currentHealthSystemId'
													onSelect={this.onFormHealthSystemSelect}
													items={configurationFormData.healthSystems}
													description='Select Health System so you can choose the hospital to apply the configurations.'
													placeholder='Select Health System'
													error={configurationFormData.errors.healthSystemError}
												/>
											</Else>
										</If>
										<If condition={configurationFormData.isHospitalLoading}>
											<Then>
												<div style={{ textAlign: 'center' }}>
													<Loader />
												</div>
											</Then>
											<Else>
												<SelectTag
													type='text'
													defaultValue={configurationFormData.selectedHospitalValue}
													label='Choose Hospital'
													name='currentHospitalSystemId'
													onSelect={this.onFormHospitalSelect}
													items={configurationFormData.hospitals}
													description='Choose the hospital you want to apply changes to.'
													placeholder='Choose Hospital'
													error={configurationFormData.errors.hospitalError}
												/>
											</Else>
										</If>
										<If condition={configurationFormData.isDepartmentLoading}>
											<Then>
												<div style={{ textAlign: 'center' }}>
													<Loader />
												</div>
											</Then>
											<Else>
												<SelectTag
													type='text'
													defaultValue={configurationFormData.selectedDepartmentValue}
													label='Choose Department'
													name='currentDepartmentId'
													onSelect={this.onFormDepartmentSelect}
													items={configurationFormData.departments}
													description='Choose the department you want to apply changes to.'
													placeholder='Choose Department'
													error={configurationFormData.errors.departmentError}
												/>
											</Else>
										</If>
									</Then>
									<Else>
										<Input
											type='text'
											label='Choose Department'
											description='Choose the Department you want to apply changes to.'
											name='currentDepartmentId'
											placeholder='Choose Department'
											value={configurationFormData.selectedDepartment ? configurationFormData.selectedDepartment.name : ''}
											validationOptions={{}}
											disabled={true}
										/>
									</Else>
								</If>
								<SelectTag
									type='text'
									defaultValue={configurationFormData.selectedTVValue}
									label='Choose TV Setup'
									name='tv'
									onSelect={this.onFormTVSelect}
									items={configurationFormData.tvs}
									description='Choose the type of TV setup.'
									placeholder='Choose TV Setup'
									error={configurationFormData.errors.tvError}
								/>
								<div className='input-el'>
									<label>Select Banyan Bridge HDMI port</label>
									<p>Select the HDMI port where Banyan Bridge will be plugged into.</p>
									<main>
										{configurationFormData.hdmiPorts.map(item => (
											<>
												<input
													type='radio'
													id={`hdmi${item.id}`}
													name='hdmi'
													value={item.id}
													onChange={this.onFormHdmiPortSelect}
													checked={configurationFormData.selectedHdmiPortValue === item.id}
												/>
												<label htmlFor={`hdmi${item.id}`}>HDMI {item.id}</label>
											</>
										))}
									</main>
									<small>{configurationFormData.errors.hdmiPortError}</small>
								</div>
								<div className='input-el'>
									<label>TV Management</label>
									<p>Select the option where TV will go back after call ends.</p>
									<main>
										{configurationFormData.endCallSource.map(item => (
											<>
												<input
													type='radio'
													id={`endCallSource${item.id}`}
													name='endCallSource'
													value={item.id}
													onChange={this.onEndCallSourceSelect}
													checked={configurationFormData.selectedEndCallSource === item.id}
												/>
												<label htmlFor={`endCallSource${item.id}`}>{item.value}</label>
											</>
										))}
									</main>
									<small>{configurationFormData.errors.endCallSourceError}</small>
								</div>
								<SelectTag
									type='text'
									defaultValue={configurationFormData.selectedTeamProfileValue}
									label='Select Telehealth Profile'
									name='teamProfile'
									onSelect={this.onFormTeamProfileSelect}
									items={this.state.teamProfiles}
									description='Select the pre-configured telehealth profile. Leave empty if none.'
									placeholder='Select Telehealth Profile'
									error={configurationFormData.errors.teamProfileError}
								/>
								<div className='input-el'>
									<label>Banyan Bridge Ringtone</label>
									<p>This setting will be applied to all the devices under this hospital.</p>
									<div>
										<input
											type='range'
											min={0}
											step={1}
											max={100}
											value={configurationFormData.ringtoneVolumeValue}
											onChange={this.onFormRingtoneVolumeChange}
										/>
										<span>{configurationFormData.ringtoneVolumeValue || 0}</span>
									</div>
									<small>{configurationFormData.errors.ringtoneVolumeError}</small>
								</div>
							</Then>
							<Else>
								<If condition={this.role === UserRoles.SUPERUSER}>
									<Then>
										<Input
											type='text'
											label='Select Health System'
											name='currentHealthSystemId'
											description='Select Health System so you can choose the hospital to apply the configurations.'
											placeholder='Select Health System'
											value={
												configurationFormData.selectedHealthSystemValue > -1
													? configurationFormData.healthSystems[configurationFormData.selectedHealthSystemValue].value
													: ''
											}
											validationOptions={{}}
											disabled={true}
										/>
									</Then>
									<Else>
										<If condition={!configurationFormData.isEdit}>
											<Then>
												<SelectTag
													type='text'
													defaultValue={configurationFormData.selectedHealthSystemValue}
													label='Select Health System'
													name='currentHealthSystemId'
													onSelect={this.onFormHealthSystemSelect}
													items={configurationFormData.healthSystems}
													description='Select Health System so you can choose the hospital to apply the configurations.'
													placeholder='Select Health System'
													error={configurationFormData.errors.healthSystemError}
												/>
											</Then>
											<Else>
												<Input
													type='text'
													label='Select Health System'
													name='currentHealthSystemId'
													placeholder='Choose Hospital'
													value={configurationFormData.selectedHealthSystem ? configurationFormData.selectedHealthSystem.name : ''}
													validationOptions={{}}
													disabled={true}
												/>
											</Else>
										</If>
									</Else>
								</If>
								<SelectTag
									type='text'
									defaultValue={configurationFormData.selectedIntegrationTypeValue}
									label='Integration Type'
									name='integrationTypeId'
									onSelect={this.selectIntegrationType}
									items={configurationFormData.integrationTypes}
									placeholder='Select Integration Type'
								/>
								<Input
									type='text'
									label='Profile name'
									name='profileName'
									placeholder='Profile name'
									value={configurationFormData.profileName}
									onChange={this.onFormTextChange}
									validationOptions={{}}
									error={configurationFormData.errors.profileNameError}
									bottomSpace='20px'
								/>
								<Input
									type='text'
									label='URL'
									name='url'
									placeholder='URL'
									value={configurationFormData.url}
									onChange={this.onFormTextChange}
									validationOptions={{}}
									error={configurationFormData.errors.urlError}
									bottomSpace='20px'
								/>
								<Input
									type='number'
									label={`${configurationFormData.selectedIntegrationTypeValue === 0 ? 'Port' : 'Site Id'}`}
									name='port'
									placeholder={`${configurationFormData.selectedIntegrationTypeValue === 0 ? 'Port' : 'Site Id'}`}
									value={configurationFormData.port}
									onChange={this.onFormTextChange}
									validationOptions={{}}
									autoComplete={false}
									error={configurationFormData.errors.portError}
									bottomSpace='20px'
								/>
								{configurationFormData.selectedIntegrationTypeValue === 1 && (
									<Input
										type='text'
										label='Username'
										name='userName'
										placeholder='Username'
										value={configurationFormData.userName}
										onChange={this.onFormTextChange}
										validationOptions={{}}
										error={configurationFormData.errors.userNameError}
										bottomSpace='20px'
									/>
								)}
								<Input
									type={configurationFormData.isEdit && !configurationFormData.isSecretChanged ? 'text' : 'password'}
									label={`${configurationFormData.selectedIntegrationTypeValue === 0 ? 'Secret' : 'Password'}`}
									autoComplete='new-password'
									name='hsSecret'
									placeholder={`${configurationFormData.selectedIntegrationTypeValue === 0 ? 'Secret' : 'Password'}`}
									value={configurationFormData.newSecret}
									onChange={e => {
										const { value } = e.target;

										if (!configurationFormData.isSecretChanged) {
											this.setState({
												configurationFormData: {
													...configurationFormData,
													newSecret: value[value.length - 1] !== '*' ? value[value.length - 1] : '',
													isSecretChanged: true,
												},
											});
										} else {
											this.setState({
												configurationFormData: { ...configurationFormData, newSecret: value },
											});
										}
									}}
									validationOptions={{}}
									error={configurationFormData.errors.hsSecretError}
									bottomSpace='20px'
								/>
							</Else>
						</If>
					</Form>
				</Modal>
				<Modal
					modalSelector='deleteTeamProfileModal'
					display={this.state.isDeleteConfigModalOpen}
					position='center'
					onModalSubmit={() => this.deleteTeamProfile()}
					onModalClose={() => this.setState({ isDeleteConfigModalOpen: false, deleteConfig: null })}>
					<form>
						<h3>Warning</h3>
						<p>Are you sure you want to delete this configuration ?</p>
					</form>
				</Modal>
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(DeviceConfig);
