import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { SectorTypes } from 'constants/enums';
import CheckBox from 'components/Common/CheckBox';
import { getLevelDevices, getLevelRoomsWithDevices } from 'infrastructure/helpers/commonHelpers';

const OptionName = props => {
	const { option } = props;
	return (
		<>
			{option.name}
			{option.helloDeviceId && <i className={classNames('material-icons-outlined', 'room-status', option.status)}>fiber_manual_record</i>}
		</>
	);
};

const OptionsList = ({ options, expandedOptions, onChange, selectedSectorId, setSelectedId, handleCheckBoxChange, poolDevices }) => {
	const findProperId = item => {
		const prefix = item.type;
		return item[`${prefix}Id`];
	};

	const toggleExpand = (selectedOptionId, isAddingSection) => {
		if (expandedOptions[selectedOptionId] && !isAddingSection) {
			delete expandedOptions[selectedOptionId];
		} else {
			expandedOptions[selectedOptionId] = {};
		}
		onChange(expandedOptions);
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		expandedOptions[optionId] = subSelections;
		onChange(expandedOptions);
	};

	const allDevicesSelected = option => {
		const rooms = getLevelRoomsWithDevices(option);
		return rooms.every(x => x.voiceCommandCalling === true);
	};

	const getCheckBoxId = option => {
		switch (option.type) {
			case 'hospital':
				return option.hospitalId;
			case 'deaprtment':
				return option.departmentId;
			case 'floor':
				return option.floorId;
		}
	};

	return (
		<div className='pool-devices-selection'>
			{options.map((option, index) => (
				<div key={index} className={classNames('tree', !option?.name && !option?.isNewOption ? 'hidden' : '')}>
					<div className={classNames('tree__child', option?.[`${option?.type}Id`] === selectedSectorId ? 'selected' : '')}>
						<div>
							<i
								className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
								onClick={() => {
									toggleExpand(findProperId(option));
								}}>
								{expandedOptions[findProperId(option)] ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
							</i>
							<i className='material-icons-outlined'>{option.icon}</i>
							<a className='link'>
								<OptionName option={option} />
							</a>
							{option.type !== 'room' && getLevelDevices(option).length !== 0 && (
								<CheckBox name={''} id={getCheckBoxId(option)} checked={allDevicesSelected(option)} onChange={e => handleCheckBoxChange(option, e)} />
							)}
							{option.type === 'room' && option.helloDeviceId !== undefined && (
								<CheckBox name={''} id={option.helloDeviceId} checked={option.voiceCommandCalling} onChange={e => handleCheckBoxChange(option, e)} />
							)}
						</div>
					</div>
					{option.subOptions && expandedOptions[findProperId(option)] && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions ? expandedOptions[findProperId(option)] : {}}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							handleCheckBoxChange={handleCheckBoxChange}
							poolDevices={poolDevices}
						/>
					)}
				</div>
			))}
		</div>
	);
};

class DeviceSelection extends Component {
	state = {
		selectedSectorId: this.props.selectedSectorId,
		expandedOptions: this.props.preSelected ? this.props.preSelected : {},
	};

	setSelectedId = sectorId => {
		this.setState({ selectedSectorId: sectorId });
	};

	render() {
		const { selectedSectorId, expandedOptions } = this.state;
		return (
			<OptionsList
				options={this.props.data}
				onChange={() => this.setState({ expandedOptions })}
				expandedOptions={this.state.expandedOptions}
				selectedSectorId={selectedSectorId}
				setSelectedId={this.setSelectedId}
				handleCheckBoxChange={this.props.handleCheckBoxChange}
				poolDevices={this.props.poolDevices}
			/>
		);
	}
}

export default DeviceSelection;
