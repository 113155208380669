import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

export function getRoles(companyId, userId) {
	return new Promise((res, rej) => {
		const url = `/v1/companies/organizations/${companyId}/members/${userId}/roles`;
		gatewayApi
			.get(url)
			.then(response => {
				res(response.data.roles);
			})
			.catch(e => rej(e));
	});
}

export function updateRole(memberId, data) {
	return new Promise((res, rej) => {
		const url = `/v1/companies/${APP_CONFIG.companyId}/members/${memberId}/nurse-role`;
		gatewayApi
			.put(url, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}
