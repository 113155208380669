import React, { Component } from 'react';
import {
	signinRedirectCallback,
	logout,
	signoutRedirectCallback,
	isAuthenticated,
	signinRedirect,
	signinSilentCallback,
	createSigninRequest,
	isAzureAuthentication,
} from 'infrastructure/auth';

const AuthContext = React.createContext({
	signinRedirectCallback: () => ({}),
	logout: () => ({}),
	signoutRedirectCallback: () => ({}),
	isAuthenticated: () => ({}),
	signinRedirect: isAzureJWT => ({}),
	signinSilentCallback: () => ({}),
	createSigninRequest: () => ({}),
	isAzureAuthentication: () => ({}),
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
	authService;

	constructor(props) {
		super(props);

		this.authService = {
			signinRedirectCallback: signinRedirectCallback,
			logout: logout,
			signoutRedirectCallback: signoutRedirectCallback,
			isAuthenticated: isAuthenticated,
			signinRedirect: signinRedirect,
			signinSilentCallback: signinSilentCallback,
			createSigninRequest: createSigninRequest,
			isAzureAuthentication: isAzureAuthentication,
		};
	}

	render() {
		return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
	}
}
