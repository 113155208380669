import { gatewayApi } from '../constants/global-variables';

export async function getIceServers() {
	try {
		const { data } = await gatewayApi.get(`/v1/ice-server?provider=twilio`);
		if (data.hasSucceeded && data.result !== null) {
			return prepareICEList(data.result.iceServers);
		}
	} catch (error) {
		console.warn('Failed to get ice servers!', error);
	}
}

function prepareICEList(iceServers) {
	// example:
	// iceServers: [{
	//     urls: [
	//         'turn:global.turn.twilio.com:3478?transport=udp',
	//         'turn:global.turn.twilio.com:3478?transport=tcp',
	//         'turn:global.turn.twilio.com:443?transport=tcp'
	//     ],
	//     username: "3d7f3e4fafba9b1ab42bb9644ac9f33ce98fc99575bbcd5d56d643d491889488",
	//     credential: "ybTn5WxASjj7saN7aKRK8bOug/tiH8RITApVCIgwVL8=",
	// },
	// {
	//     urls: ["stun:global.stun.twilio.com:3478?transport=udp"]
	// }]
	return iceServers.map(server => {
		server.urls = server.urls.map(url => `${server.type}:${url}`);
		const { id, isDeleted, type, ...returnServer } = server;

		if (returnServer.password) {
			returnServer.credential = returnServer.password;
			delete returnServer.password;
		}
		return returnServer;
	});
}
