import { legacyApi } from 'constants/global-variables';

/**
 * @param {{ oldpassword: string; newpassword: string; }} data
 */
// eslint-disable-next-line import/prefer-default-export
export function changePassword(data) {
	return new Promise((res, rej) => {
		const url = `/v2.1/api/account/password`;
		legacyApi
			.post(url, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}
