import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const Toggle = props => {
	return (
		<div className={classNames('toggle', props.className, props.toggledOn ? 'toggle-on' : 'toggle-off', props.disabled ? 'disabled' : '')}>
			<a onClick={!props.disabled ? props.onToggle : null}>{props.toggleOnText}</a>
			<a onClick={!props.disabled ? props.onToggle : null}>{props.toggleOffText}</a>
		</div>
	);
};

Toggle.propTypes = {
	toggleOnText: PropTypes.string,
	toggleOffText: PropTypes.string,
	toggledOn: PropTypes.bool,
	className: PropTypes.string,
	onToggle: PropTypes.func,
	disabled: PropTypes.bool,
};
export default Toggle;
