import React from 'react';
import Grid from 'components/Grid';
import Avatar from 'components/Avatar';
import { ConferenceEndReason, ParticipantState } from 'constants/enums';

const CallEndReason = props => (
	<div style={{ textAlign: 'center' }}>
		<Grid className='incoming-calls' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
			<div>
				<Avatar src={props.url ? props.url : 'https://maxcdn.solaborate.com/media/profile-pictures/duser.jpg'} size='medium' fullName='' />

				{props.reason === ConferenceEndReason.PARTICIPANT_BUSY && (
					<p data-test-id='callState-busy' className='incoming-call-text'>
						{props.onPatientBusyNurse && (
							<>
								{props.onPatientBusyNurse.state === ParticipantState.CONNECTING.type && `This device has initiated a call. Try again later.`}
								{[ParticipantState.CONNECTED.type, ParticipantState.RECONNECTING.type].includes(props.onPatientBusyNurse.state) &&
									`This device is currently on a call with ${props.onPatientBusyNurse.name}. Try again later.`}
							</>
						)}
						{!props.onPatientBusyNurse && `This device is currently on a call. Try again later.`}
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_OFFLINE && (
					<p data-test-id='callState-offline' className='incoming-call-text'>
						This device is offline. Try again later.
					</p>
				)}

				{(props.reason === ConferenceEndReason.PARTICIPANT_LEFT ||
					props.reason === ConferenceEndReason.INITIATOR_LEFT ||
					props.reason === ConferenceEndReason.OWNER_LEFT) && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Call ended.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_DECLINED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Call has been declined.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_NOT_ANSWERING && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Can&apos;t connect to this device. Please try again.
					</p>
				)}

				{props.reason === ConferenceEndReason.ABORTED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Call has ended. Please try again.
					</p>
				)}

				{props.reason === ConferenceEndReason.DROPPED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Failed to reconnect to the network. Please try again.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_PASSWORD_CHANGED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						The password for this account has been changed. Please login using the new credentials.
					</p>
				)}
				{props.reason === ConferenceEndReason.PARTICIPANT_IDLE && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						You have been idle for too long. Call is ended.
					</p>
				)}

				{props.reason === ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						This session was ended by a Banyan admin.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_INVITE_DENIED && (
					<p data-test-id='callState-participantInviteDenied' className='incoming-call-text'>
						You don&apos;t have sufficient permissions to call this participant. Please contact your administrator.
					</p>
				)}

				{props.reason === ConferenceEndReason.FAILED_TO_GET_INITIATOR_INFO && (
					<p data-test-id='callState-failedToGetInitiatorInfo' className='incoming-call-text'>
						Failed to get your information. Please try again.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_INVITE_FAILED && (
					<p data-test-id='callState-failedToGetInitiatorInfo' className='incoming-call-text'>
						Failed to get participant information. Please try again.
					</p>
				)}
			</div>
		</Grid>
	</div>
);

export default CallEndReason;
