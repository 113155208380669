import React from 'react';

const Form = props => (
	<form onSubmit={props.onSubmit}>
		<h3>{props.title}</h3>
		{props.children}
	</form>
);

export default Form;
