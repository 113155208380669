import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from '../state/index';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';

export const middleware = [thunk, logger, promise];

export default function configureStore(initialState) {
	// In development, use the browser's Redux dev tools extension if installed
	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
	if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
		enhancers.push(window.devToolsExtension());
	}

	return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
