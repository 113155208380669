import React, { Component } from 'react';
import Avatar from 'components/Avatar';

class TopParticipant extends Component {
	constructor(props) {
		super(props);

		this.videoRef = null;
		this.videoSrcObjectHandler = this.videoSrcObjectHandler.bind(this);
	}

	componentDidMount() {
		this.videoSrcObjectHandler();
	}

	componentDidUpdate(prevProps) {
		const streamHasChanged =
			prevProps.topParticipantFeed && this.props.topParticipantFeed && prevProps.topParticipantFeed.id !== this.props.topParticipantFeed.id;

		if (streamHasChanged || (this.videoRef && this.videoRef.srcObject == null)) {
			this.videoSrcObjectHandler();
		}
	}

	videoSrcObjectHandler() {
		if (this.videoRef) {
			this.videoRef.srcObject = this.props.topParticipantFeed.mediaStream;
		}
	}

	render() {
		return (
			<div className='call-view-video'>
				{this.props.topParticipantFeed && this.props.isTopParticipantVideoActive ? (
					<video ref={video => (this.videoRef = video)} autoPlay muted />
				) : (
					<Avatar src='https://static.solaborate.com/temp/banyan.png' size='large' fullName='' />
				)}
			</div>
		);
	}
}

export default TopParticipant;
