import React, { Component } from 'react';
import Table from 'components/Table';
import { ObjectType } from 'constants/enums';

class CallDetails extends Component {
	isFeatureUsed(roomFeatureUsed, vcpFeatureUsed) {
		return (
			<div className='call-properties'>
				<span>
					<b>Patient: </b>
					{roomFeatureUsed ? 'Yes' : 'No'}
				</span>
				<span>
					<b>Nurse: </b>
					{vcpFeatureUsed ? 'Yes' : 'No'}
				</span>
			</div>
		);
	}

	getCallDetails() {
		let log = this.props.selectedConference;

		if (log.callType === 'Monitoring') {
			const headers = [{ title: 'Room' }, { title: 'Audio Used' }, { title: 'Video Used' }, { title: 'Screen Used' }];

			let nurse = log.participants.find(participant => participant.objectType === ObjectType.USER);
			let devices = log.participants.filter(participant => participant.objectType === ObjectType.HELLO_DEVICE);
			let devicesFeatures = devices.map(device => {
				return {
					name: device.name,
					audioUsed: this.isFeatureUsed(device.usedAudio, nurse.usedAudio),
					videoUsed: this.isFeatureUsed(device.usedVideo, nurse.usedVideo),
					screenUsed: this.isFeatureUsed(device.usedScreen, nurse.usedScreen),
				};
			});

			return <Table headers={headers} rows={devicesFeatures}></Table>;
		} else {
			const headers = [{ title: 'Audio Used' }, { title: 'Video Used' }, { title: 'Screen Used' }];

			let features =
				Object.entries(log).length !== 0 && log.constructor === Object
					? {
							audioUsed: this.isFeatureUsed(log.usedAudio.room, log.usedAudio.vcp),
							videoUsed: this.isFeatureUsed(log.usedVideo.room, log.usedVideo.vcp),
							screenUsed: this.isFeatureUsed(log.usedScreen.room, log.usedScreen.vcp),
					  }
					: {};

			return <Table headers={headers} rows={[features]}></Table>;
		}
	}

	render() {
		return (
			<form className='call-details'>
				<h3>Call Details</h3>
				{this.getCallDetails()}
			</form>
		);
	}
}

export default CallDetails;
