import React, { Component } from 'react';
import classNames from 'classnames/bind';

class CallButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDropdownOpen: false,
		};
		this.isCallButtonClicked = false;
	}
	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	setWrapperRef = node => {
		this.wrapperRef = node;
	};
	handleClickOutside = event => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			if (this.state.isDropdownOpen) {
				this.toggleDropdown();
			}
		}
	};
	toggleDropdown = () => {
		if (this.props.children) {
			this.setState({
				isDropdownOpen: !this.state.isDropdownOpen,
			});
		} else {
			// Delay toggle event for half a second before it can be clicked again
			if (!this.isCallButtonClicked) {
				this.isCallButtonClicked = true;
				setTimeout(() => {
					this.props.onClick(this.props.name);
					this.isCallButtonClicked = false;
				}, 500);
			}
		}
	};

	render() {
		return (
			<div
				ref={this.setWrapperRef}
				className={classNames(
					'call-button',
					this.props.className,
					this.props.isActive ? 'active ' : '',
					this.props.variant,
					this.props.isDisabled ? 'disabled' : '',
					this.state.isDropdownOpen ? 'tooltip-hidden' : ''
				)}
				name={this.props.name}
				onClick={() => this.toggleDropdown()}
				data-tooltip={this.props.tooltip}
				data-position={this.props.tooltipPosition}>
				<i className='material-icons' data-cy={this.props.buttonSelector} style={{ color: this.props.iconColor }}>
					{this.props.icon}
				</i>
				{this.state.isDropdownOpen && this.props.children}
			</div>
		);
	}
}

export default CallButton;
