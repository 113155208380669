import React from 'react';
import classNames from 'classnames/bind';

const Alert = props => (
	<div
		data-cy={props.alertSelector}
		className={classNames(
			'alert',
			props.variant,
			props.position,
			props.fixed ? 'fixed' : '',
			props.display && !props.persist ? 'show' : '',
			props.display && props.persist ? 'persist' : ''
		)}>
		<span>{props.message}</span>
		{!props.hideCloseButton && (
			<i onClick={props.onClose} className='material-icons close-icon' data-cy={props.alertSelector}>
				close
			</i>
		)}
	</div>
);

Alert.defaultProps = {
	persist: false,
};

export default Alert;
