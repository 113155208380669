import { gatewayApi } from 'constants/global-variables';

// eslint-disable-next-line import/prefer-default-export
export async function sendStats(data, retries = 5) {
	return new Promise((res, rej) => {
		const url = `v1/callmetrics`;
		gatewayApi
			.post(url, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => {
				console.error('error while sending call stats logs', e);
				if (retries > 0) {
					sendStats(data, retries - 1);
				}
				rej(e);
			});
	});
}
