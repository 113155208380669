import React from 'react';
import classNames from 'classnames/bind';
import { If, Then, Else } from 'react-if';

const buttonTypes = {
	SUBMIT: 'submit',
};

const Button = props => (
	<If condition={props.type === buttonTypes.SUBMIT}>
		<Then>
			<span className={classNames(props.className, props.isLoading ? 'submit-loading' : '')}>
				<input className={classNames('button', props.variant, props.size, props.display)} type='submit' value={props.text} />
			</span>
		</Then>
		<Else>
			<a
				className={classNames(
					'button',
					props.className,
					props.variant,
					props.size,
					props.display,
					props.isLoading ? 'loading' : '',
					props.isDisabled ? 'disabled' : ''
				)}
				onClick={props.onClick}
				style={{
					justifySelf: props.horizAlign,
					backgroundColor: props.background,
					borderColor: props.background,
					borderRadius: props.borderRadius,
					marginRight: props.marginRight,
				}}>
				{props.icon && <i className='material-icons-outlined'>{props.icon}</i>}
				{props.text}
			</a>
		</Else>
	</If>
);

export default Button;
