export const IDENTITY_CONFIG = {};

IDENTITY_CONFIG.automaticSilentRenew = true;
IDENTITY_CONFIG.loadUserInfo = false;
IDENTITY_CONFIG.response_type = 'code';
IDENTITY_CONFIG.response_mode = 'query';
IDENTITY_CONFIG.scope = 'openid profile email sol.user api.gateway API';
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
	IDENTITY_CONFIG.authority = process.env.REACT_APP_IDSRV_URL;
	IDENTITY_CONFIG.client_id = process.env.REACT_APP_IDSRV_CLIENT_ID;
	IDENTITY_CONFIG.redirect_uri = process.env.REACT_APP_IDSRV_REDIRECT_URL;
	IDENTITY_CONFIG.login = process.env.REACT_APP_IDSRV_URL + '/login';
	IDENTITY_CONFIG.silent_redirect_uri = process.env.REACT_APP_SILENT_REDIRECT_URL;
	IDENTITY_CONFIG.post_logout_redirect_uri = process.env.REACT_APP_LOGOUT_URL;
	IDENTITY_CONFIG.public_uri = process.env.REACT_APP_PUBLIC_URL;
	IDENTITY_CONFIG.client_secret = process.env.REACT_APP_IDSRV_CLIENT_SECRET;
} else {
	IDENTITY_CONFIG.authority = window.__env__.REACT_APP_IDSRV_URL;
	IDENTITY_CONFIG.client_id = window.__env__.REACT_APP_IDSRV_CLIENT_ID;
	IDENTITY_CONFIG.redirect_uri = window.__env__.REACT_APP_IDSRV_REDIRECT_URL;
	IDENTITY_CONFIG.login = window.__env__.REACT_APP_IDSRV_URL + '/login';
	IDENTITY_CONFIG.silent_redirect_uri = window.__env__.REACT_APP_SILENT_REDIRECT_URL;
	IDENTITY_CONFIG.post_logout_redirect_uri = window.__env__.REACT_APP_LOGOUT_URL;
	IDENTITY_CONFIG.public_uri = window.__env__.REACT_APP_PUBLIC_URL;
	IDENTITY_CONFIG.client_secret = window.__env__.REACT_APP_IDSRV_CLIENT_SECRET;
}

export const METADATA_OIDC = {};
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
	METADATA_OIDC.issuer = process.env.REACT_APP_IDSRV_URL;
	METADATA_OIDC.jwks_uri = process.env.REACT_APP_IDSRV_URL + '/.well-known/jwks';
	METADATA_OIDC.authorization_endpoint = process.env.REACT_APP_IDSRV_URL + '/connect/authorize';
	METADATA_OIDC.token_endpoint = process.env.REACT_APP_IDSRV_URL + '/connect/token';
	METADATA_OIDC.userinfo_endpoint = process.env.REACT_APP_IDSRV_URL + '/connect/userinfo';
	METADATA_OIDC.end_session_endpoint = process.env.REACT_APP_IDSRV_URL + '/connect/endsession';
	METADATA_OIDC.check_session_iframe = process.env.REACT_APP_IDSRV_URL + '/connect/checksession';
	METADATA_OIDC.revocation_endpoint = process.env.REACT_APP_IDSRV_URL + '/connect/revocation';
	METADATA_OIDC.introspection_endpoint = process.env.REACT_APP_IDSRV_URL + '/connect/introspect';
} else {
	METADATA_OIDC.issuer = window.__env__.REACT_APP_IDSRV_URL;
	METADATA_OIDC.jwks_uri = window.__env__.REACT_APP_IDSRV_URL + '/.well-known/jwks';
	METADATA_OIDC.authorization_endpoint = window.__env__.REACT_APP_IDSRV_URL + '/connect/authorize';
	METADATA_OIDC.token_endpoint = window.__env__.REACT_APP_IDSRV_URL + '/connect/token';
	METADATA_OIDC.userinfo_endpoint = window.__env__.REACT_APP_IDSRV_URL + '/connect/userinfo';
	METADATA_OIDC.end_session_endpoint = window.__env__.REACT_APP_IDSRV_URL + '/connect/endsession';
	METADATA_OIDC.check_session_iframe = window.__env__.REACT_APP_IDSRV_URL + '/connect/checksession';
	METADATA_OIDC.revocation_endpoint = window.__env__.REACT_APP_IDSRV_URL + '/connect/revocation';
	METADATA_OIDC.introspection_endpoint = window.__env__.REACT_APP_IDSRV_URL + '/connect/introspect';
}
