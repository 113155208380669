import React, { useState } from 'react';
import { Modal, Form, Loader } from 'components';
import DeviceSelection from 'components/NursePooling/DeviceSelection';
import { getLevelRoomsWithDevices } from 'infrastructure/helpers/commonHelpers';
import { addDeviceSettings } from 'api/devices';

const VoiceCommandCallingModal = props => {
	const [changedDevices, setChangedDevices] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [, setHasErrors] = useState(null);

	const handleCheckBoxChange = (option, e) => {
		const isChecked = !!e.target.checked;

		const newDevices = [...changedDevices];
		if (option.type === 'room') {
			const exists = newDevices.find(x => x.solHelloDeviceId === option.helloDeviceId);
			if (!exists) {
				newDevices.push({ solHelloDeviceId: option.helloDeviceId, settingValue: isChecked });
			} else {
				exists.settingValue = isChecked;
			}

			setChangedDevices(newDevices);
			props.updateVoiceCommandCallingList([option.helloDeviceId], isChecked);
		} else {
			const sectorDevices = [];
			const rooms = getLevelRoomsWithDevices(option);
			rooms.forEach(r => {
				if (r.voiceCommandCalling !== isChecked) {
					const exists = newDevices.find(x => x.solHelloDeviceId === r.helloDeviceId);
					sectorDevices.push({ solHelloDeviceId: r.helloDeviceId, settingValue: isChecked });
					if (!exists) {
						newDevices.push({ solHelloDeviceId: r.helloDeviceId, settingValue: isChecked });
					} else {
						exists.settingValue = isChecked;
					}
				}
			});

			setChangedDevices(newDevices);
			props.updateVoiceCommandCallingList(
				sectorDevices.map(x => x.solHelloDeviceId),
				isChecked
			);
		}
	};

	const changeVoiceCommandCalling = async () => {
		if (changedDevices.length) {
			setLoading(true);
			const settings = changedDevices.map(s => ({ solHelloDeviceId: +s.solHelloDeviceId, settingValue: `${s.settingValue}` }));
			const { error } = await addDeviceSettings(settings);
			if (error) {
				setHasErrors(true);
			}
		}

		setLoading(false);
		setHasErrors(false);

		props.toggleVoiceCommandCallingModal();
	};

	const toggleModal = () => {
		setChangedDevices([]);
		props.toggleVoiceCommandCallingModal();
	};

	return (
		<>
			<Modal
				modalSelector='voiceCommandCallingModal'
				display={props.isVoiceCommandCallingModalOpen}
				position='right'
				onModalSubmit={changeVoiceCommandCalling}
				isSubmitDisabled={changedDevices.length === 0}
				onModalClose={toggleModal}>
				<Form title='Voice command calling' onSubmit={event => event.preventDefault()}>
					<p>
						You can use this option to activate voice command calling in different levels of a hospital. Through this option patient will be able to call nurses
						by saying "Call Nurse" or "Call the nurse".
					</p>
					{isLoading && <Loader />}
					{!isLoading && (
						<div className='input'>
							<p className='label'>Devices</p>
							<p>Select the devices you wish to activate this feature</p>
							<DeviceSelection data={props.currentSector} handleCheckBoxChange={handleCheckBoxChange} />
						</div>
					)}
				</Form>
			</Modal>
		</>
	);
};

export default VoiceCommandCallingModal;
