import React, { Component } from 'react';
import { Button, Table, Tabs, Form, Modal, DescriptionBox, Input } from 'components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { getDeviceList, deleteDevice, sendCommand, getLatestAppRelease, forceUpdateDevice } from 'api/devices';
import { deleteOrgUnit } from 'api/organization';
import { DeviceListLevel, DeviceCommands, UserRoles, AndroidVersions } from 'constants/enums';
import EditSectorModal from 'components/Common/EditSectorModal';
import {
	findSectorById,
	mapSectionsToRender,
	getParentSector,
	getHierarchyByHelloDeviceId,
	findDeviceById,
	sortByRoomName,
} from 'infrastructure/helpers/commonHelpers';
import { APP_CONFIG } from 'constants/global-variables';
import { CSVLink } from 'react-csv';
import { getUserRole } from 'infrastructure/auth';
import RebootLogExport from 'components/RebootLogExport';
import VoiceCommandCallingModal from 'components/Common/VoiceCommandCallingModal';
import PullDeviceLogs from 'components/PullDeviceLogs';
import _ from 'lodash';

class Floor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			devices: [],
			preSelected: null,
			selectedDevice: {},
			selectedOrg: {},
			isDeleteDeviceModalOpen: false,
			isRebootDeviceModalOpen: false,
			isUpdateDeviceModalOpen: false,
			isDeleteOrgUnitModalOpen: false,
			isEditSectorModalOpen: false,
			sectorData: {},
			sectorLocation: [],
			parentSectorName: '',
			parentSectorType: '',
			rebootReason: '',
			isDeleteDeviceModalLoading: false,
			latestAppRelease: null,
			isVoiceCommandCallingModalOpen: false,
			currentSector: [],
		};
	}

	async componentDidMount() {
		this.loadFloor();
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { floorId } = this.props.match.params;
		if (
			floorId !== prevProps.match.params.floorId ||
			(!prevProps.showAssignDeviceModalData.assignCompleted && this.props.showAssignDeviceModalData.assignCompleted)
		) {
			this.loadFloor();
		}
	};

	exportAsCsv() {
		return this.state.devices.map(({ roomName, serialNumber, macAddress, appVersion, firmwareRevision, osVersion, solHelloDeviceId, ipAddress }) => ({
			roomName,
			serialNumber,
			room: getHierarchyByHelloDeviceId(this.props.treeData.tree, solHelloDeviceId),
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
			androidVersion: this.getCodeNameByOsVersion(osVersion),
			ipAddress,
		}));
	}

	getCodeNameByOsVersion = osVersion => {
		let version = AndroidVersions.find(({ apiLevel }) => apiLevel.includes(+osVersion));
		return version?.codeName ?? 'N/A';
	};

	getCurrentFloor() {
		let hospitals = this.props.treeData.tree;
		let floor = findSectorById(hospitals, this.props.match.params.floorId);
		return {
			sectorName: floor ? floor.name : '',
			sectorId: floor ? floor.floorId : '',
			floorName: floor ? floor.name : '',
			hospitalId: floor ? floor.hospitalId : '',
			departmentId: floor ? floor.departmentId : '',
			floorId: floor ? floor.floorId : '',
			sectorType: floor ? floor.type : '',
		};
	}

	loadFloor = async () => {
		const devices = await getDeviceList(DeviceListLevel.FLOOR, this.props.match.params.floorId);
		const latestAppRelease = await getLatestAppRelease(APP_CONFIG.deviceAppId);
		const currentSector = findSectorById(this.props.treeData.tree, this.props.match.params.floorId);
		let deviceList = [];
		if (currentSector?.subOptions) {
			deviceList = devices.map(d => {
				const roomName = findSectorById(this.props.treeData.tree, d.solHelloDeviceId.toString())?.name ?? '';
				const device = findDeviceById(currentSector.subOptions, d.solHelloDeviceId);
				if (device) {
					device.voiceCommandCalling = d.voiceCommandCalling;
				}
				return { ...d, roomName };
			});
		}
		this.setState({
			devices: sortByRoomName(deviceList),
			latestAppRelease,
			currentSector: currentSector ? [currentSector] : [],
		});
	};

	displayDevices = () => {
		if (!this.state.devices.length) {
			return [];
		}
		const userRole = getUserRole();
		return this.state.devices.map(
			({ roomName, serialNumber, solHelloDeviceId, firmwareRevision, appVersion, osVersion, macAddress, isOnline, voiceCommandCalling, ipAddress }) => {
				return {
					roomName: roomName ?? 'N/A',
					serialNumber: serialNumber ?? 'N/A',
					macAddress: macAddress ?? 'N/A',
					appVersion: <div className='app-version'>{appVersion || 'N/A'}</div>,
					firmwareRevision: firmwareRevision ?? 'N/A',
					osVersion: this.getCodeNameByOsVersion(osVersion),
					ipAddress,
					voiceCommandCalling: voiceCommandCalling ? 'Active' : 'Not active',
					actions: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								data-cy='rebootDeviceBtn'
								id={solHelloDeviceId}
								data-tooltip='Reboot device'
								data-position='top'
								style={{ background: '#718093' }}
								onClick={() => this.toggleRebootDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
								refresh
							</i>
							<PullDeviceLogs deviceId={solHelloDeviceId} isOnline={isOnline} />
							<RebootLogExport solHelloDeviceId={solHelloDeviceId} serialNumber={serialNumber} />
							{userRole === UserRoles.ADMIN && (
								<i
									className='material-icons boxed-icon'
									data-cy='reassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Reassign Device'
									style={{ backgroundColor: 'var(--blue-2)' }}
									data-position='top'
									onClick={() => this.props.toggleAssignDeviceModal({ deviceId: solHelloDeviceId, show: true })}>
									swap_vert
								</i>
							)}
							{userRole === UserRoles.ADMIN && (
								<i
									className='material-icons-outlined boxed-icon'
									data-cy='unassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Unassign device'
									data-position='top'
									onClick={() => this.toggleDeleteDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
									delete
								</i>
							)}
						</div>
					),
				};
			}
		);
	};

	toggleEditSectorModal = data => {
		this.setState(
			{
				isEditSectorModalOpen: !this.state.isEditSectorModalOpen,
			},
			() => {
				if (this.state.isEditSectorModalOpen) {
					if (!data) {
						return;
					}
					let sector = findSectorById(this.props.treeData.tree, data[data.sectorType + 'Id']);
					this.props.setCurrentSectorLocation(sector.treeLocation);
					let sectorParent = getParentSector(this.props.treeData.tree, sector);
					this.setState({
						sectorType: data.sectorType,
						sectorName: data.sectorName,
						sectorData: data,
						sectorLocation: sector.treeLocation,
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
					});
				}
			}
		);
	};

	initDeleteOrg = data => {
		this.setState({
			selectedOrg: data,
		});
		this.toggleDeleteOrgUnitModal();
	};

	unAssignDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			this.setState({
				isDeleteDeviceModalLoading: true,
			});

			await deleteDevice(deviceId);
		}

		this.loadFloor();
		this.toggleDeleteDeviceModal();
		this.props.getTreeData();

		this.setState({
			isDeleteDeviceModalLoading: false,
		});
	};

	rebootDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			await sendCommand(deviceId, DeviceCommands.REBOOT, this.state.rebootReason);
		}

		this.toggleRebootDeviceModal();
	};

	forceUpdateDevice = async () => {
		const {
			selectedDevice: { deviceId, appVersion },
			latestAppRelease: { install_url: appInstallUrl, short_version: latestAppVersion },
		} = this.state;

		if (appVersion && latestAppVersion && appVersion !== latestAppVersion) {
			await forceUpdateDevice({
				deviceId,
				dynamicData: appInstallUrl,
			});
		}

		this.toggleUpdateDeviceModal();
	};

	deleteOrgUnitSubmit = async () => {
		await deleteOrgUnit(this.state.selectedOrg.level, this.state.selectedOrg.id);
		this.moveToParentSector(this.state.selectedOrg.id);
		this.toggleDeleteOrgUnitModal();
		this.props.getTreeData();
	};

	moveToParentSector = currentSectorId => {
		let selectedSector = findSectorById(this.props.treeData.tree, currentSectorId);
		let parentSector = getParentSector(this.props.treeData.tree, selectedSector);
		parentSector.isSelected = true;
		this.props.history.push(parentSector.link);
		this.props.updateBreadcrumb(parentSector.breadcrumb);
	};

	toggleDeleteDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isDeleteDeviceModalOpen: !this.state.isDeleteDeviceModalOpen,
		});
	};

	toggleRebootDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isRebootDeviceModalOpen: !this.state.isRebootDeviceModalOpen,
			rebootReason: '',
		});
	};

	toggleUpdateDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isUpdateDeviceModalOpen: !this.state.isUpdateDeviceModalOpen,
		});
	};

	toggleDeleteOrgUnitModal = () => {
		this.setState({
			isDeleteOrgUnitModalOpen: !this.state.isDeleteOrgUnitModalOpen,
		});
	};

	setInputValues = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	updateTree = () => {
		this.props.getTreeData();
		this.toggleEditSectorModal();
	};

	toggleVoiceCommandCallingModal = () => {
		this.setState(
			{
				isVoiceCommandCallingModalOpen: !this.state.isVoiceCommandCallingModalOpen,
			},
			() => {
				this.loadFloor();
			}
		);
	};

	updateVCCList = (solHelloDeviceIds, isChecked) => {
		const currentSector = _.cloneDeep(this.state.currentSector);
		solHelloDeviceIds.forEach(solHelloDeviceId => {
			const device = findDeviceById(currentSector, solHelloDeviceId);
			if (device) {
				device.voiceCommandCalling = isChecked;
			}
		});
		this.setState({ currentSector });
	};

	render() {
		const floor = this.getCurrentFloor();
		const sections = mapSectionsToRender(this.props.treeData.tree, floor, this.toggleEditSectorModal, this.initDeleteOrg);
		const userRole = getUserRole();
		return (
			<div className='organization'>
				<h3>
					<span>{floor.floorName}</span>
					{userRole === UserRoles.ADMIN && (
						<>
							<Button text='Edit Floor' size='medium' onClick={() => this.toggleEditSectorModal(floor)} />
							<Button
								text='Delete Floor'
								size='medium'
								icon='delete'
								variant='red'
								onClick={() => this.initDeleteOrg({ level: DeviceListLevel.FLOOR, id: this.props.match.params.floorId, name: floor.floorName })}
							/>
							{this.state.devices.length > 0 && (
								<Button
									text='Voice Command Calling'
									size='medium'
									icon='supervisor_account'
									variant='yellow'
									onClick={() => this.toggleVoiceCommandCallingModal()}
								/>
							)}
						</>
					)}
					{this.state.devices.length > 0 && (
						<CSVLink
							className='button medium white'
							data-cy='exportAsCSVBtn'
							data={this.exportAsCsv()}
							filename={`${floor.floorName}-${(+new Date()).toString()}.csv`}>
							Export as CSV
						</CSVLink>
					)}
				</h3>
				<DescriptionBox
					data={[
						{ title: 'Rooms in this floor', description: sections.rooms.length },
						{ title: 'Enrolled Devices', description: this.state.devices ? this.state.devices.length : 0 },
					]}
				/>
				<Tabs
					onTabIconClick={this.props.toggleModal}
					links={[{ link: 'Devices' }, { link: 'Rooms', active: true }]}
					components={[
						<Table
							headers={[
								{ title: 'Room Name' },
								{ title: 'Serial Number' },
								{ title: 'MAC Address' },
								{ title: 'App Version' },
								{ title: 'OS Version' },
								{ title: 'Android Version' },
								{ title: 'IP Address' },
								{ title: 'Voice Command Calling' },
								{ title: '' },
							]}
							rows={this.displayDevices()}
							isEditable={false}
						/>,
						<Table headers={[{ title: 'Room Names' }, { title: '' }]} rows={sections.rooms} isEditable={false} />,
					]}
				/>

				<Modal
					modalSelector='unassignDeviceModal'
					display={this.state.isDeleteDeviceModalOpen}
					isLoading={this.state.isDeleteDeviceModalLoading}
					position='center'
					submitButtonText='Unassign'
					onModalSubmit={this.unAssignDevice}
					onModalClose={this.toggleDeleteDeviceModal}>
					<Form title='Unassign device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to unassign device {this.state.selectedDevice.serialNumber}? Neither you or patient won't be able to make any calls.</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='rebootDeviceModal'
					display={this.state.isRebootDeviceModalOpen}
					position='center'
					submitButtonText='Reboot'
					onModalSubmit={this.rebootDevice}
					onModalClose={this.toggleRebootDeviceModal}>
					<Form title='Reboot device' onSubmit={event => event.preventDefault()}>
						<p>Why do you want to reboot device {this.state.selectedDevice.serialNumber}?</p>
						<Input
							type='text'
							value={this.state.rebootReason}
							validationOptions={{}}
							placeholder='Describe the reboot reason. . .'
							onChange={e => this.setState({ rebootReason: e.target.value })}
						/>
					</Form>
				</Modal>

				<Modal
					display={this.state.isUpdateDeviceModalOpen}
					position='center'
					submitButtonText='Update'
					onModalSubmit={this.forceUpdateDevice}
					onModalClose={this.toggleUpdateDeviceModal}>
					<Form title='Force update device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to force update device {this.state.selectedDevice.serialNumber}?</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='deleteSectorModal'
					display={this.state.isDeleteOrgUnitModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteOrgUnitSubmit}
					onModalClose={this.toggleDeleteOrgUnitModal}>
					<Form title='Warning' onSubmit={event => event.preventDefault()}>
						<p>
							Are you sure you want to delete {this.state.selectedOrg.name}? Any devices related to it will be deleted and neither Virtual Care Providers or
							patients won't be able to make any calls.
						</p>
					</Form>
				</Modal>
				<EditSectorModal
					onEditSector={this.updateTree}
					onModalClose={this.toggleEditSectorModal}
					sectorLocation={this.state.sectorLocation}
					isEditSectorModalOpen={this.state.isEditSectorModalOpen}
					sectorData={this.state.sectorData}
					parentSectorName={this.state.parentSectorName}
					parentSectorType={this.state.parentSectorType}
				/>
				<VoiceCommandCallingModal
					currentSector={this.state.currentSector}
					isVoiceCommandCallingModalOpen={this.state.isVoiceCommandCallingModalOpen}
					toggleVoiceCommandCallingModal={this.toggleVoiceCommandCallingModal}
					updateVoiceCommandCallingList={this.updateVCCList}
				/>
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(Floor));
