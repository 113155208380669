import React from 'react';
import classNames from 'classnames/bind';
import { If, Then, Else } from 'react-if';
import { getInitialsColor } from 'infrastructure/helpers/commonHelpers';

const getInitialsFromFullName = fullName => {
	if (!!fullName.length) {
		let fullNameSplit = fullName.split(/\b\s+/);
		return `${fullNameSplit[0] ? fullNameSplit[0][0] : ''}${fullNameSplit[1] ? fullNameSplit[1][0] : ''}`;
	}
};

const Avatar = props => {
	const initialsColor = getInitialsColor(props.fullName[0]);
	return (
		<If condition={!!props.src}>
			<Then>
				<img data-test-id='avatar-img' src={props.src} className={classNames('avatar', props.size)} />
			</Then>
			<Else>
				<span
					className='avatar'
					data-test-id='avatar-name'
					style={{
						backgroundColor: `#${initialsColor.backgroundColor}`,
						color: `#${initialsColor.color}`,
					}}>
					{getInitialsFromFullName(props.fullName)?.toUpperCase()}
				</span>
			</Else>
		</If>
	);
};
export default Avatar;
