import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

/**
 * @typedef {React.ButtonHTMLAttributes & { audioTrack: any, toggle: (isActive: boolean) => void }} HTMLAttributesWithProps
 */
const LocalMicBtn = (/** @type {HTMLAttributesWithProps} */ { audioTrack, toggle, ...htmlAttributes }) => {
	const [isActive, setIsMyMicActive] = useState(false);

	useEffect(() => {
		setIsMyMicActive(audioTrack && audioTrack.enabled);
	}, [audioTrack, audioTrack?.enabled]);

	return (
		<button
			{...htmlAttributes}
			type='button'
			className={classNames('call-button', isActive ? 'active' : '')}
			data-custom-isactive={!isActive}
			onClick={() => toggle(!isActive)}>
			<i className='material-icons' data-cy='usersMicBtn'>
				{isActive ? 'mic' : 'mic_off'}
			</i>
		</button>
	);
};

export default LocalMicBtn;
