import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'primereact/chart';
import { Grid, SkeletonLoader, Table } from 'components';
import { getKpisForDashboard, getNurseCallsForDashboard } from 'api/calllogs';
import Select from 'react-select';
import { getHealthSystems, getNursesList, getOrgTeamChildren } from 'api/users';
import { getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { CallTypes, DeviceListLevel, UserRoles } from 'constants/enums';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { parseDate } from 'react-day-picker/moment';
import { formatDate, secondsToHHMMSS } from 'infrastructure/helpers/dateHelper';
import { getUserRole } from 'infrastructure/auth';

const Dashboard = () => {
	const getFirstDayOfMonth = (year, month) => new Date(year, month, 1);
	const date = new Date();
	const [isLoading, setIsLoading] = useState(true);
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: 'Talk to Patient',
				backgroundColor: '#42A5F5',
				data: [],
			},
			{
				label: 'View Patient',
				backgroundColor: '#CCC',
				data: [],
			},
			{
				label: 'Monitoring',
				backgroundColor: '#FFA726',
				data: [],
			},
		],
	});
	const [kpis, setKpis] = useState({
		totalCount: 0,
		vcpCount: 0,
		roomsObserved: 0,
		numberOfCalls: {
			talkToPatient: 0,
			monitoring: 0,
			viewPatient: 0,
		},
		averageDuration: {
			talkToPatient: 0,
			monitoring: 0,
			viewPatient: 0,
		},
	});
	const [healthSystems, setHealthSystems] = useState([]);
	const [regions, setRegions] = useState([]);
	const [hospitals, setHospitals] = useState([]);
	const [nurses, setNurses] = useState([]);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState(null);
	const [selectedHospital, setSelectedHospital] = useState(null);
	const [selectedNurse, setSelectedNurse] = useState(null);
	const [from, setFrom] = useState(new Date(getFirstDayOfMonth(date.getFullYear(), date.getMonth())));
	const [to, setTo] = useState(new Date());
	const [currentLevel, setCurrentLevel] = useState(null);
	const [searchedNurse, setSearchedNurse] = useState('');
	const toInput = useRef(null);

	useEffect(() => {
		const getHealthSystemOptions = hsList => {
			const healthSystemsOptions = hsList.map(hs => {
				return { value: hs.id, label: hs.name };
			});
			if (getUserRole() === UserRoles.ADMIN) {
				healthSystemsOptions.unshift({ label: 'All', value: null });
			}
			return healthSystemsOptions;
		};

		const fetchData = async () => {
			const hsList = await getHealthSystems();
			const healthSystemsOptions = getHealthSystemOptions(hsList);

			setHealthSystems(healthSystemsOptions);
			let current = null;
			if (getUserRole() === UserRoles.SUPERUSER) {
				[current] = healthSystemsOptions;
			} else {
				current = healthSystemsOptions.find(item => item.value === getCurrentHealthSystemInfo().currentHealthSystemId);
			}

			if (current) {
				setSelectedHealthSystem(current);
				setCurrentLevel(DeviceListLevel.HEALTHSYSTEM);
				const regionsList = await getOrgTeamChildren(current.value);
				if (regionsList.error) {
					return;
				}
				const regionsOptions = regionsList.map(region => ({ value: region.id, label: region.name }));
				regionsOptions.unshift({ label: 'All', value: null });
				setRegions(regionsOptions);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchNurses = async () => {
			const response = await getNursesList(searchedNurse);
			if (!response.error) {
				const nursesList = response.companyUsers.map(nurse => {
					return { value: nurse.userId, label: `${nurse.firstname} ${nurse.lastname}` };
				});
				nursesList.unshift({
					label: 'All',
					value: null,
				});
				setNurses(nursesList);
			}
		};
		if (searchedNurse) {
			fetchNurses();
		} else {
			setNurses([
				{
					label: 'All',
					value: null,
				},
			]);
		}
	}, [searchedNurse]);

	const getFilteredNursesBasedOnCallsCount = arr => {
		arr.sort((a, b) => {
			if (a.count < b.count) {
				return 1;
			}
			if (a.count > b.count) {
				return -1;
			}
			return 0;
		});
		return arr.filter((_item, i) => i < 10);
	};

	useEffect(() => {
		const getKpisTalkToPatientCallNumber = kpisResponse =>
			kpisResponse.callTypesCount.filter(x => [CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SCREENSHARE].includes(x.callType)).length
				? kpisResponse.callTypesCount
						.filter(x => [CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SCREENSHARE].includes(x.callType))
						.reduce((accumulator, object) => {
							return accumulator + object.count;
						}, 0)
				: 0;

		const getKpisTalkToPatientAverageDuration = kpisResponse =>
			kpisResponse.callTypesDuration.filter(x => [CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SCREENSHARE].includes(x.callType)).length
				? kpisResponse.callTypesDuration
						.filter(x => [CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SCREENSHARE].includes(x.callType))
						.reduce((accumulator, object) => {
							return accumulator + object.duration;
						}, 0)
				: 0;

		const getDashboardTalkToPatientData = filtered =>
			filtered.map(item =>
				item.callTypes.filter(x => [CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SCREENSHARE].includes(x.callType)).length
					? item.callTypes
							.filter(x => [CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SCREENSHARE].includes(x.callType))
							.reduce((accumulator, object) => {
								return accumulator + object.count;
							}, 0)
					: 0
			);

		const filterByLevel = async () => {
			setIsLoading(true);
			const params = {
				healthSystemId: selectedHealthSystem.value,
				regionId: selectedRegion ? selectedRegion.value : null,
				hospitalId: selectedHospital ? selectedHospital.value : null,
				nurseId: selectedNurse ? selectedNurse.value : null,
				start: Math.round(new Date(from).getTime()),
				end: Math.round(new Date(to).getTime()),
			};
			const [response, kpisResponse] = await Promise.all([getNurseCallsForDashboard(params), getKpisForDashboard(params)]);

			if (!response.error && !kpisResponse.error) {
				let labels = [];
				let datasets = [];

				const kpisObj = {
					totalCount: kpisResponse.totalCount,
					vcpCount: kpisResponse.vcpCount,
					roomsObserved: kpisResponse.roomsObserved,
					numberOfCalls: {
						talkToPatient: getKpisTalkToPatientCallNumber(kpisResponse),
						monitoring: kpisResponse.callTypesCount.find(item => item.callType === CallTypes.MONITORING)?.count || 0,
						viewPatient: kpisResponse.callTypesCount.find(item => item.callType === CallTypes.SECURITYCAM)?.count || 0,
					},
					averageDuration: {
						talkToPatient: getKpisTalkToPatientAverageDuration(kpisResponse),
						monitoring: kpisResponse.callTypesDuration.find(item => item.callType === CallTypes.MONITORING)?.duration || 0,
						viewPatient: kpisResponse.callTypesDuration.find(item => item.callType === CallTypes.SECURITYCAM)?.duration || 0,
					},
				};

				setKpis(kpisObj);

				if (response.data.length) {
					const filtered = getFilteredNursesBasedOnCallsCount(response.data);
					labels = filtered.map(item => item.name);
					datasets = [
						{
							label: 'Talk to Patient',
							backgroundColor: '#42A5F5',
							data: getDashboardTalkToPatientData(filtered),
						},
						{
							label: 'View Patient',
							backgroundColor: '#CCC',
							data: filtered.map(item => item.callTypes.find(x => x.callType === CallTypes.SECURITYCAM)?.count || 0),
						},
						{
							label: 'Monitoring',
							backgroundColor: '#FFA726',
							data: filtered.map(item => item.callTypes.find(x => x.callType === CallTypes.MONITORING)?.count || 0),
						},
					];
				} else {
					labels = [];
					datasets = [
						{
							label: 'Talk to Patient',
							backgroundColor: '#42A5F5',
							data: [],
						},
						{
							label: 'View Patient',
							backgroundColor: '#CCC',
							data: [],
						},
						{
							label: 'Monitoring',
							backgroundColor: '#FFA726',
							data: [],
						},
					];
				}

				setChartData({
					labels,
					datasets,
				});
			}
			setIsLoading(false);
		};
		if ([DeviceListLevel.HEALTHSYSTEM, DeviceListLevel.REGION, DeviceListLevel.HOSPITAL].includes(currentLevel)) {
			filterByLevel();
		}
	}, [currentLevel, selectedHealthSystem, selectedRegion, selectedHospital, selectedNurse, from, to]);

	const onHealthSystemSelected = async healthSystem => {
		setSelectedHealthSystem(healthSystem);
		setRegions([]);
		setSelectedRegion(null);
		setHospitals([]);
		setSelectedHospital(null);
		setCurrentLevel(DeviceListLevel.HEALTHSYSTEM);
		if (healthSystem.value) {
			const regionsList = await getOrgTeamChildren(healthSystem.value);
			if (regionsList.error) {
				return;
			}
			const regionsOptions = regionsList.map(region => ({ value: region.id, label: region.name }));
			regionsOptions.unshift({ label: 'All', value: null });
			setRegions(regionsOptions);
		}
	};

	const onRegionSelected = async region => {
		setSelectedRegion(region);
		setHospitals([]);
		setSelectedHospital(null);
		setCurrentLevel(region.value ? DeviceListLevel.REGION : DeviceListLevel.HEALTHSYSTEM);
		if (region.value) {
			const hospitalsList = await getOrgTeamChildren(region.value);
			if (hospitalsList.error) {
				return;
			}
			const hospitalOptions = hospitalsList.map(hospital => ({ value: hospital.id, label: hospital.name }));
			hospitalOptions.unshift({ label: 'All', value: null });
			setHospitals(hospitalOptions);
		}
	};

	const onHospitalSelected = hospital => {
		setSelectedHospital(hospital);
		setCurrentLevel(hospital.value ? DeviceListLevel.HOSPITAL : DeviceListLevel.REGION);
	};

	const onNurseSelected = nurse => {
		setSelectedNurse(nurse);
	};

	const DropdownIndicator = () => {
		return <i className='material-icons-outlined'>arrow_drop_down</i>;
	};

	const modifiers = { start: from, end: to };

	return (
		<div className='dashboard-wrapper'>
			<Table
				isLoading={false}
				headers={[
					{
						title: '',
					},
				]}
				rows={[
					{
						chart: !isLoading && (
							<Chart
								type='bar'
								data={chartData}
								options={{
									responsive: true,
									maintainAspectRatio: false,
									aspectRatio: 0.8,
									plugins: {
										legend: {
											labels: {
												color: '#495057',
											},
										},
									},
									elements: {
										bar: {
											borderWidth: 2,
										},
									},
									scales: {
										x: {
											ticks: {
												color: '#495057',
											},
											grid: {
												color: '#ebedef',
											},
										},
										y: {
											ticks: {
												color: '#495057',
											},
											grid: {
												color: '#ebedef',
											},
										},
									},
								}}
								height='40vh'
							/>
						),
					},
				]}>
				<div>
					<Grid columns='repeat(5, 1fr)' gridGap='10px'>
						<Select
							value={selectedHealthSystem}
							placeholder='Health System'
							classNamePrefix='custom-select'
							options={healthSystems}
							components={{ DropdownIndicator }}
							onChange={value => onHealthSystemSelected(value)}
						/>
						<Select
							value={selectedRegion}
							placeholder='Region'
							classNamePrefix='custom-select'
							options={regions}
							components={{ DropdownIndicator }}
							onChange={value => onRegionSelected(value)}
						/>
						<Select
							value={selectedHospital}
							placeholder='Hospital'
							classNamePrefix='custom-select'
							options={hospitals}
							components={{ DropdownIndicator }}
							onChange={value => onHospitalSelected(value)}
						/>
						<Select
							value={selectedNurse}
							placeholder='Nurse'
							classNamePrefix='custom-select'
							options={nurses}
							components={{ DropdownIndicator }}
							onChange={value => onNurseSelected(value)}
							onInputChange={value => {
								setSearchedNurse(value);
							}}
						/>
						<div>
							<div className='InputFromTo'>
								<i className='material-icons'>date_range</i>
								<DayPickerInput
									value={from}
									placeholder='--/--/----'
									format='LL'
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [from, { from, to }],
										disabledDays: { after: to },
										toMonth: to,
										modifiers,
										numberOfMonths: 2,
										onDayClick: () => toInput.current.getInput().focus(),
									}}
									onDayChange={e => setFrom(e)}
								/>{' '}
								-{' '}
								<span className='InputFromTo-to'>
									<DayPickerInput
										ref={toInput}
										value={to}
										placeholder='--/--/----'
										format='LL'
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											selectedDays: [from, { from, to }],
											disabledDays: { before: from },
											modifiers,
											month: from,
											fromMonth: from,
											numberOfMonths: 2,
										}}
										onDayChange={e => setTo(e)}
									/>
								</span>
								<i className='material-icons'>arrow_drop_down</i>
							</div>
						</div>
					</Grid>
					{isLoading && <SkeletonLoader rows={15} padding='35px 20px' />}
					{!isLoading && (
						<Grid columns='repeat(5, 1fr)' gridGap='10px' className='kpi-wrapper'>
							<div>
								Total number of nurses
								<p>{kpis.vcpCount}</p>
							</div>
							<div>
								Total Calls
								<p>{kpis.totalCount}</p>
							</div>
							<div>
								Number of rooms observed
								<p>{kpis.roomsObserved}</p>
							</div>
							<div>
								Number of Talk to patient calls
								<p>{kpis.numberOfCalls.talkToPatient}</p>
							</div>
							<div>
								Number of Monitoring calls
								<p>{kpis.numberOfCalls.monitoring}</p>
							</div>
							<div>
								Number of View patient calls
								<p>{kpis.numberOfCalls.viewPatient}</p>
							</div>
							<div>
								Average time of Talk to patient calls
								<p>{secondsToHHMMSS(kpis.averageDuration.talkToPatient)}</p>
							</div>
							<div>
								Average time of Monitoring calls
								<p>{secondsToHHMMSS(kpis.numberOfCalls.monitoring)}</p>
							</div>
							<div>
								Average time of View patient calls
								<p>{secondsToHHMMSS(kpis.numberOfCalls.viewPatient)}</p>
							</div>
						</Grid>
					)}
				</div>
			</Table>
		</div>
	);
};

export default Dashboard;
