import React, { Component } from 'react';
import v4 from 'uuid';
import _ from 'lodash';
import classNames from 'classnames/bind';
import { SocketContext } from 'io-client/SocketContext';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Grid from 'components/Grid';
import Dropdown from 'components/Dropdown';
import ListGroup from 'components/ListGroup';
import TopParticipant from 'components/TopParticipant';
import Participant from 'components/Participant';
import CallButton from 'components/CallButton';
import CallEndReason from 'components/CallEndReason';
import ActiveConference from 'components/Call/ActiveConference';
import AnotherNursePickedUp from 'components/Call/AnotherNursePickedUp';
import ConnectionStatus from 'components/Call/ConnectionStatus';
import StreamPermissions from 'components/StreamPermissions';
import Button from 'components/Button';
import Loader from 'components/Loader';
import TvControls from 'components/TvControls';
import CameraControls from 'components/Common/CameraControls';
import CallDuration from 'components/Common/CallDuration';
import NightVisionControl from 'components/Common/NightVisionControl';
import { stopOutgoingCallSound } from 'components/CallSounds';
import CallCorrelationInfo from 'components/CallCorrelationInfo';
import Alert from 'components/Alert';
import { getCurrentHealthSystemInfo, checkIfMediaDevicesPlugged, checkForPermission, askForPermission } from 'infrastructure/helpers/commonHelpers';
import { getUserProfile } from 'infrastructure/auth';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { fetchNotificationCounter } from 'state/notifications/actions';
import { APP_CONFIG } from 'constants/global-variables';
import SocketEvents from 'constants/socket-events';
import {
	CallTypes,
	ObjectType,
	ConferenceEndReason,
	RTCPeerConnectionEnum,
	CameraType,
	CameraEventTypes,
	CameraTiltDirection,
	CameraTiltAction,
	ZoomDirection,
	SocketState,
	SerialTVCommands,
	AudioOutputDevice,
	StreamError,
	MediaTypes,
	MediaPermissions,
} from 'constants/enums';
import CallManager from 'owt/p2p/CallManager';
import { ConferenceInfo, ConferenceParticipant, FromUser } from 'owt/base/conference';
import PeerStats from 'containers/PeerStats';

const TrackType = {
	AUDIO: 'audio',
	VIDEO: 'video',
	SCREEN: 'screen',
};

export class Call extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			callerObjectId: parseInt(props.match.params.patientId, 10),
			healthSystemPath: null,
			roomName: decodeURIComponent(props.match.params.roomName),
			localSrc: null,
			localScreenSrc: null,
			peerSrc: null,
			activeSrc: null,
			micActive: true,
			isMyVideoCamActive: true,
			isPeerVideoCamActive: true,
			isVideoCall: false,
			peerMicActive: true,
			showPTZ: false,
			conferenceId: null,
			participantId: null,
			initialCallType: null,
			conferenceEndReason: null,
			socketState: SocketState.CONNECTED,
			streamPermission: null,
			tvStatus: null,
			hdmiStatus: null,
			tvBrand: null,
			volumeStatus: null,
			nightVisionMode: false,
			cameraZoomLevel: 0,
			cameraType: CameraType.HELLO,
			isHuddleCamConnected: true,
			isCameraPrivacyOn: false,
			isMicPrivacyOn: false,
			disabledTiltDirections: {},
			callStartTime: null,
			peerConnectionState: RTCPeerConnectionEnum.CONNECTION_STATE.NEW,
			disableButtons: false,
			hasActiveConference: false,
			anotherNursePickedUp: false,
			onPatientBusyNurse: null, // this is the name of the nurse that the hello is talking already when we get the busy participant event
			showRightColumn: true,
			shouldShowSwitchToHelloCamError: false,
			audioOutputDevice: AudioOutputDevice.HELLO,
		};

		this.myAvatarUrl = '';
		this.userInfo = {};
		this.camStatus = null;
		this.micStatus = null;

		this.callManager = new CallManager(this.context, {
			useCallStats: APP_CONFIG.useCallStats,
			sendCallStatsInterval: APP_CONFIG.sendCallStatsInterval,
		});
	}

	async componentDidMount() {
		this.props.fetchNotificationCounter();
		const userProfile = getUserProfile();
		const avatarUrl = userProfile.profilePicture.url;
		this.userInfo = userProfile;
		this.myAvatarUrl = avatarUrl?.includes('duser') ? '' : avatarUrl;
		this.micStatus = await checkForPermission(MediaTypes.MICROPHONE);
		this.camStatus = await checkForPermission(MediaTypes.CAMERA);
		this.micStatus.onchange = this.onMicPermissionChange;
		this.camStatus.onchange = this.onCamPermissionChange;
		this.callManager
			.on('call-started', data => {
				let roomNamePath = this.getRoomNamePath(data);
				this.setState({
					conferenceId: data.conferenceId,
					participantId: data.participantId,
					...roomNamePath,
				});
			})
			.on('audio-call', data => {
				this.setState({
					localSrc: data.audioStream,
					activeSrc: data.audioStream,
					isMyVideoCamActive: false,
					isPeerVideoCamActive: false,
				});
			})
			.on('video-call', data => {
				this.setState({
					localSrc: data.videoStream,
					activeSrc: data.videoStream,
					isMyVideoCamActive: true,
					isPeerVideoCamActive: true,
				});
			})
			.on('screensharing', data => {
				let { localSrc, activeSrc, localScreenSrc } = data;
				const { peerSrc } = this.state;

				if (localScreenSrc === null && !!peerSrc.mediaStream.getVideoTracks().length) {
					activeSrc = peerSrc;
				}

				this.setState({
					localSrc: localSrc,
					activeSrc: activeSrc,
					localScreenSrc: localScreenSrc,
				});
			})
			.on('toggle-audio', () => {
				this.setState({ micActive: !this.state.micActive });
			})
			.on('toggle-video', data => {
				this.setState({ isVideoCall: data.hasVideoTrack ? true : this.state.isVideoCall, isMyVideoCamActive: data.hasVideoTrack }, () => {
					const { isMyVideoCamActive, isPeerVideoCamActive, peerSrc, localSrc } = this.state;

					if (!isMyVideoCamActive && isPeerVideoCamActive && peerSrc) {
						// Switch to patient if i have toggled my camera off
						this.activeSrcHandle(this.state.peerSrc.id);
					} else if (isMyVideoCamActive && localSrc && (this.isMyVideoSelected() || !isPeerVideoCamActive)) {
						// Show my video as active
						this.activeSrcHandle(this.state.localSrc.id);
					}
				});
			})
			.on('peer-stream', data => {
				const { peerSrc, activeSrc } = data;
				let newState = {};
				if (this.state.isPeerVideoCamActive) {
					Object.assign(newState, { peerSrc, activeSrc, isVideoCall: true });
				} else {
					Object.assign(newState, { peerSrc });
				}

				if (!this.state.callStartTime) {
					Object.assign(newState, { callStartTime: new Date() });
				}

				stopOutgoingCallSound();
				this.setState(newState, () => {
					if (this.audioRef) {
						this.audioRef.srcObject = null;
						this.audioRef.srcObject = peerSrc.mediaStream;
					}
				});
			})
			.on('end-call', data => {
				this.startCloseTabTimeout();
				this.setState({
					localSrc: null,
					localScreenSrc: null,
					peerSrc: null,
					activeSrc: null,
					conferenceEndReason: data.endReason,
					hasActiveConference: data.hasActiveConference,
					anotherNursePickedUp: data.anotherNursePickedUp,
					conferenceId: null,
				});
			})
			.on('stream-permission', status => {
				if (status && status.type === StreamError.STREAM_NOT_ALLOWED.type) {
					this.props.organizationActions.setStreamPermissionMessage({
						component: 'popup',
						type: StreamError.MICROPHONE_BLOCKED.type,
					});
					this.setState({ streamPermission: null });
				} else {
					this.setState({ streamPermission: status });
				}
			})
			.on('tv-commands', data => {
				if (data.tvState.isVolume) {
					this.setState({ volumeStatus: data });
					return;
				}

				switch (data.tvState.tvStatus) {
					case SerialTVCommands.INITIAL_TV_POWER:
					case SerialTVCommands.POWER.POWER_ON:
					case SerialTVCommands.POWER.POWER_OFF:
						this.setState({ tvStatus: data });
						break;
					case SerialTVCommands.HDMI.SWITCH_HDMI1:
					case SerialTVCommands.HDMI.SWITCH_HDMI2:
					case SerialTVCommands.HDMI.SWITCH_HDMI3:
						this.setState({ hdmiStatus: data });
						break;
					default:
						break;
				}
			})
			.on('initial-device-state', data => {
				if (!data) {
					return;
				}

				const {
					cameraType,
					zoomLevel: cameraZoomLevel,
					isNightVision: nightVisionMode,
					isHuddleCamConnected,
					isCameraPrivacyOn,
					isMicPrivacyOn,
					tvHdmiPort,
					audioOutputDevice,
					tvBrand,
				} = data;
				let hdmiStatus = null;

				if (tvHdmiPort) {
					hdmiStatus = { isSuccessful: true, tvState: { isVolume: false, tvStatus: SerialTVCommands.HDMI[`SWITCH_HDMI${tvHdmiPort}`] } };
				}

				this.setState({
					cameraType,
					cameraZoomLevel,
					nightVisionMode,
					isHuddleCamConnected,
					isCameraPrivacyOn,
					isMicPrivacyOn,
					hdmiStatus,
					audioOutputDevice,
					tvBrand,
				});
			})
			.on('audio-output-changed', data => {
				this.setState({ audioOutputDevice: data.audioOutputDevice });
			})
			.on('camera-response', this.cameraResponseListener)
			.on('socket-state', ({ socketState }) => {
				if (this.state.socketState.type === socketState.type) {
					return;
				}

				const newState = { socketState };
				switch (socketState.type) {
					case SocketState.DISCONNECTED.type:
						Object.assign(newState, {
							disableButtons: true,
						});
						break;
					case SocketState.CONNECTED.type:
						Object.assign(newState, {
							disableButtons: false,
						});
						break;
					default:
						break;
				}

				this.setState(newState, () => {
					this.setAudioSrcObject();
				});
			})
			.on('peer-connection-state', ({ peerConnectionState }) => {
				this.setState({ peerConnectionState }, () => {
					this.setAudioSrcObject();
				});
			})
			.on('participant-busy', data => {
				const { activeConferences } = data;
				if (!activeConferences || activeConferences.length === 0) {
					this.callManager.logger.warn('On participant busy - no active conference was found');
					return undefined;
				}

				const conference = activeConferences.find(ac => ac.initialCallType === CallTypes.SECURITYCAM || ac.initialCallType === CallTypes.AUDIO);
				if (!conference) {
					this.callManager.logger.warn('On participant busy - no active patient view or talk to patient conference was found');
					return undefined;
				}

				// conference participants are only the active participants(connected, connecting or reconnecting)
				// there should be only one active participant in patient view or talk to patient
				if (conference.participants.length > 1) {
					this.callManager.logger.warn('On participant busy - more then one participants were found in the active conference');
					return undefined;
				}

				const onPatientBusyNurse = conference.participants[0];
				this.setState({ onPatientBusyNurse });
			})
			.on('local-audio-error', this.onLocalAudioError)
			.on('local-video-error', this.onLocalVideoError)
			.bindSocketEventListeners();
		this.bindWindowListeners();
		let isStartConference = this.props.match.params.incoming === 'start';
		if (isStartConference) {
			const isVideo = this.props.match.params.callType && this.props.match.params.callType === 'video';
			if (isVideo) {
				this.setState({
					isVideoCall: false,
					isPeerVideoCamActive: true,
					initialCallType: CallTypes.VIDEO,
				});
			} else {
				this.setState({
					initialCallType: CallTypes.AUDIO,
					isPeerVideoCamActive: false,
				});
			}

			const devices = await navigator.mediaDevices.enumerateDevices();
			const inputDevices = {
				devices,
				permissions: {
					camera: this.camStatus.state,
					microphone: this.micStatus.state,
				},
			};

			const fromUser = new FromUser(`${userProfile.firstName} ${userProfile.lastName}`, userProfile.jobTitle, userProfile.profilePicture.url, undefined);
			const participants = [new ConferenceParticipant(parseInt(this.state.callerObjectId, 10), ObjectType.HELLO_DEVICE)];
			const startConferenceInfo = new ConferenceInfo(
				isVideo ? CallTypes.VIDEO : CallTypes.AUDIO,
				v4(),
				'Videocall',
				null,
				fromUser,
				true,
				false,
				true,
				false,
				false,
				false,
				isVideo,
				v4(),
				participants,
				inputDevices
			);
			this.callManager.startConference(startConferenceInfo);
		} else {
			const conferenceInfo = JSON.parse(localStorage.getItem('incomingConferenceInfo'));
			localStorage.removeItem('incomingConferenceInfo');
			if (!conferenceInfo) {
				this.startCloseTabTimeout();
				this.setState({ conferenceEndReason: ConferenceEndReason.ABORTED });
			} else {
				this.callManager.joinConference(conferenceInfo);
			}
		}
	}

	componentWillUnmount() {
		this.callManager.unbindSocketEventListeners();
		this.callManager.unbindTimeouts();
		if (this.closeTabTimeout) {
			clearTimeout(this.closeTabTimeout);
			this.closeTabTimeout = null;
		}
	}

	beforeUnloadEvent = event => {
		if (this.state.conferenceId) {
			event.preventDefault();
			event.returnValue = '';
		}
	};

	bindWindowListeners() {
		window.addEventListener('beforeunload', this.beforeUnloadEvent);

		window.addEventListener('unload', () => {
			if (this.state.conferenceId) {
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_LEFT });
			}
		});

		window.addEventListener('message', message => {
			if (message.data === 'IN_CALL') {
				window.removeEventListener('beforeunload', this.beforeUnloadEvent);
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_IDLE });
			}
		});
	}

	getRoomNamePath = data => {
		let healthSystemPath;
		let { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		this.props.organization.allHealthSystems.forEach(healthSystem => {
			if (healthSystem.id === currentHealthSystemId) {
				healthSystemPath = `${healthSystem.name} > `;

				healthSystem.regions.forEach(region => {
					if (currentRegionId === region.id) {
						healthSystemPath = `${healthSystemPath}${region.name}`;
					}
				});
			}
		});

		let roomName = data.helloDeviceName.replace(/[,]/g, ' >').replace(/[-]/g, '>');

		return {
			healthSystemPath: healthSystemPath,
			roomName: roomName,
		};
	};

	togglePeerCamera = () => {
		const { isPeerVideoCamActive, initialCallType, isMyVideoCamActive, localSrc, activeSrc, peerSrc } = this.state;
		this.callManager.toggleParticipantTrack(CallTypes.VIDEO, isPeerVideoCamActive);

		if (!isPeerVideoCamActive && initialCallType === CallTypes.AUDIO) {
			this.setState({
				isPeerVideoCamActive: !isPeerVideoCamActive,
				initialCallType: CallTypes.VIDEO,
				showPTZ: isPeerVideoCamActive ? false : this.state.showPTZ,
			});
		} else if (isPeerVideoCamActive && isMyVideoCamActive) {
			this.setState({
				isPeerVideoCamActive: !isPeerVideoCamActive,
				activeSrc: localSrc,
				showPTZ: isPeerVideoCamActive ? false : this.state.showPTZ,
			});
		} else {
			const changeActiveSource = !!peerSrc.mediaStream.getVideoTracks().length && !isPeerVideoCamActive && localSrc.id === activeSrc.id;
			this.setState({
				isPeerVideoCamActive: !isPeerVideoCamActive,
				activeSrc: changeActiveSource ? peerSrc : localSrc,
				showPTZ: isPeerVideoCamActive ? false : this.state.showPTZ,
			});
		}
	};

	showTopParticipant = () => {
		const { isMyVideoCamActive, isPeerVideoCamActive } = this.state,
			isMyVideoSelected = this.isMyVideoSelected();

		if (isMyVideoSelected && !isMyVideoCamActive) {
			return false;
		}

		if (!isMyVideoSelected && !this.isScreenSharingSelected() && !isPeerVideoCamActive) {
			return false;
		}

		return true;
	};

	onMicPermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			this.props.organizationActions.setStreamPermissionMessage(null);
		} else if (this.state.micActive && this.state.localSrc) {
			this.callManager.toggleAudio();
		}
		this.callManager.publishConferenceLog(`Microphone permission state is changed to ${res.target.state}.`);
	};

	onCamPermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			this.props.organizationActions.setStreamPermissionMessage(null);
		} else if (this.state.isMyVideoCamActive) {
			this.toggleVideo();
		}
		this.callManager.publishConferenceLog(`Camera permission state is changed to ${res.target.state}.`);
	};

	toggleMyMic = async () => {
		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (!pluggedDevices.microphone) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return;
		}

		if (this.micStatus.state === MediaPermissions.DENIED) {
			await askForPermission({ audio: true });
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'popup',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
		} else {
			const audioStream = await this.callManager.toggleAudio();
			if (audioStream && audioStream.error) {
				this.props.organizationActions.setStreamPermissionMessage({
					component: 'modal',
					type: StreamError.MICROPHONE_NOT_FOUND.type,
				});
			}
		}
	};

	toggleMyVideo = async () => {
		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (!pluggedDevices.camera) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return;
		}

		if (this.camStatus.state === MediaPermissions.DENIED) {
			await askForPermission({ video: true });
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'popup',
				type: StreamError.CAMERA_BLOCKED.type,
			});
		} else if (this.camStatus.state === MediaPermissions.PROMPT) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.CAMERA_BLOCKED.type,
			});
			const permissionRes = await askForPermission({ video: true });
			this.props.organizationActions.setStreamPermissionMessage(null);
			if (permissionRes.permission === MediaPermissions.GRANTED) {
				this.toggleVideo();
			}
		} else {
			this.toggleVideo();
		}
	};

	toggleMyStream = streamType => {
		if (streamType === TrackType.VIDEO) {
			this.toggleMyVideo();
		} else if (streamType === TrackType.SCREEN) {
			this.toggleMyScreenSharing();
		} else {
			this.toggleMyMic();
		}
	};

	debouncedToggleStream = _.debounce(this.toggleMyStream, 500, {
		leading: true,
		trailing: false,
	});

	toggleVideo = async () => {
		const videoStream = await this.callManager.toggleVideo();
		if (videoStream && videoStream.error) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.CAMERA_NOT_FOUND.type,
			});
		}
		return videoStream;
	};

	toggleMyScreenSharing = () => {
		this.callManager.screenShare();
	};

	endCall = () => {
		this.callManager.endCall({ endReason: ConferenceEndReason.INITIATOR_LEFT });
	};

	startCloseTabTimeout = () => {
		this.closeTabTimeout = setTimeout(() => {
			clearTimeout(this.closeTabTimeout);
			this.closeTabTimeout = null;
			window.close();
		}, 5000);
	};

	activeSrcHandle = mediaStreamId => {
		let { activeSrc } = this.state;
		if (activeSrc !== null && activeSrc.id === mediaStreamId) return;

		switch (mediaStreamId) {
			case this.state.localSrc.id:
				activeSrc = this.state.localSrc;
				break;
			case this.state.peerSrc.id:
				activeSrc = this.state.peerSrc;
				break;
			case this.state.localScreenSrc.id:
				activeSrc = this.state.localScreenSrc;
				break;
			case this.state.isMyVideoCamActive:
				activeSrc = this.state.peerSrc;
				break;
			default:
				break;
		}
		this.setState({ activeSrc });
	};

	isMyVideoSelected = () => {
		const { localSrc, activeSrc } = this.state;

		if (!localSrc) {
			return false;
		}

		if (activeSrc && activeSrc.id === localSrc.id) {
			return true;
		}

		return false;
	};

	isScreenSharingSelected = () => {
		const { localScreenSrc, activeSrc } = this.state;

		if (!localScreenSrc) return false;

		if (activeSrc && activeSrc.id === localScreenSrc.id) return true;

		return false;
	};

	buildListFromStates = (isPeerVideoCamActive, micActive) => {
		let lists = [
			{
				id: 'toggle_video',
				title: 'Camera Off',
				icon: 'videocam_off',
			},
			{
				id: 'toggle_audio',
				title: 'Mute Audio',
				icon: 'volume_up',
			},
		];
		if (!isPeerVideoCamActive) {
			lists[0].title = 'Camera On';
			lists[0].icon = 'videocam';
		}
		if (!micActive) {
			lists[1].title = 'Unmute Audio';
			lists[1].icon = 'volume_off';
		}
		return lists;
	};

	onMyMediaToggleClicked = (event, item) => {
		if (item.id === 'toggle_video') {
			this.setState(
				{
					isMyVideoCamActive: !this.state.isMyVideoCamActive,
				},
				() => {
					if (!this.state.isMyVideoCamActive && this.state.isPeerVideoCamActive && this.state.peerSrc) {
						this.activeSrcHandle(this.state.peerSrc.id);
					}
				}
			);
		} else if (item.id === 'toggle_audio') {
			this.setState({
				micActive: !this.state.micActive,
			});
		}
	};

	onPeerMediaToggleClicked = (event, item) => {
		if (this.state.disableButtons) {
			return;
		}

		if (item.id === 'toggle_video') {
			this.togglePeerCamera();
		}
		if (item.id === 'toggle_audio') {
			this.callManager.toggleParticipantTrack(CallTypes.AUDIO, this.state.peerMicActive);
			this.setState({
				peerMicActive: !this.state.peerMicActive,
			});
		}
	};

	togglePTZ = () => {
		this.setState({
			showPTZ: !this.state.showPTZ,
		});
	};

	shouldShowPatientCameraToggledOff = () => {
		const { isVideoCall, isPeerVideoCamActive } = this.state;

		if (this.isMyVideoSelected()) return false;

		if (this.isScreenSharingSelected()) return false;

		return isVideoCall && !isPeerVideoCamActive;
	};

	setAudioSrcObject = () => {
		if (
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED &&
			this.state.socketState.type === SocketState.CONNECTED.type &&
			this.audioRef
		) {
			this.audioRef.srcObject = null;
			this.audioRef.srcObject = this.state.peerSrc.mediaStream;
		}
	};

	shouldShowStateComponents = () => {
		return (
			this.state.hasActiveConference ||
			this.state.anotherNursePickedUp ||
			this.state.conferenceEndReason ||
			this.state.streamPermission ||
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED ||
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.FAILED
		);
	};

	shouldShowConnectionStatus = () => {
		return (
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED ||
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.FAILED
		);
	};

	sendPanTiltCameraEvent = (direction, action) => {
		const { participantId, conferenceId, callerObjectId } = this.state;
		this.callManager.panTiltCamera({ direction, helloDeviceId: callerObjectId, action, conferenceId, participantId });
	};

	cameraResponseListener = ({ event, message, isSuccessful }) => {
		switch (event) {
			case CameraEventTypes.SWITCH:
				if (isSuccessful) {
					this.setState({
						cameraType: message,
						cameraZoomLevel: 0,
					});
				}
				break;
			case CameraEventTypes.ZOOM:
				this.setState({
					cameraZoomLevel: +message,
				});
				break;
			case CameraEventTypes.TILT: {
				const { disabledTiltDirections } = this.state;

				if (isSuccessful) {
					if ([CameraTiltDirection.UP, CameraTiltDirection.DOWN].includes(message)) {
						disabledTiltDirections[CameraTiltDirection.UP] = false;
						disabledTiltDirections[CameraTiltDirection.DOWN] = false;
					} else {
						disabledTiltDirections[CameraTiltDirection.LEFT] = false;
						disabledTiltDirections[CameraTiltDirection.RIGHT] = false;
					}
				} else {
					disabledTiltDirections[message] = true;
					this.sendPanTiltCameraEvent(message, CameraTiltAction.STOP);
				}

				this.setState(disabledTiltDirections);
				break;
			}
			case CameraEventTypes.HUDDLE_CONNECTED_STATE:
				this.setState({
					isHuddleCamConnected: isSuccessful,
				});
				break;
			case CameraEventTypes.NIGHT_VISION:
				this.setState({ nightVisionMode: isSuccessful });
				break;
			case CameraEventTypes.HELLO_CAMERA_PRIVACY_STATE:
				this.setState({ isCameraPrivacyOn: isSuccessful });
				break;
			case CameraEventTypes.HELLO_MIC_PRIVACY_STATE:
				this.setState({ isMicPrivacyOn: isSuccessful });
				break;
			default:
		}
	};

	renderCallStates = () => {
		if (this.state.hasActiveConference) {
			return <ActiveConference />;
		}

		if (this.state.anotherNursePickedUp) {
			return <AnotherNursePickedUp />;
		}

		if (this.state.conferenceEndReason) {
			return <CallEndReason reason={this.state.conferenceEndReason} onPatientBusyNurse={this.state.onPatientBusyNurse} url={this.myAvatarUrl} />;
		}

		if (this.state.streamPermission) {
			return <StreamPermissions reason={this.state.streamPermission} />;
		}

		if (this.state.peerConnectionState) {
			return <ConnectionStatus url={this.myAvatarUrl} peerConnectionState={this.state.peerConnectionState} />;
		}
	};

	getPrivacyButtonsErrorMessage = () => {
		const { isMicPrivacyOn, isCameraPrivacyOn, cameraType } = this.state;

		if (isMicPrivacyOn && (!isCameraPrivacyOn || !this.state.isPeerVideoCamActive || cameraType !== CameraType.HELLO)) {
			return `You are not hearing the patient because the physical mic privacy button has been enabled on Banyan Bridge. This button can be disabled only manually. Please contact your administrator.`;
		}

		if (!isMicPrivacyOn && isCameraPrivacyOn && this.state.isPeerVideoCamActive && cameraType === CameraType.HELLO) {
			return `You are not seeing the patient because the physical camera privacy button has been enabled on Banyan Bridge. This button can be disabled only manually. Please contact your administrator.`;
		}

		if (isMicPrivacyOn && isCameraPrivacyOn && this.state.isPeerVideoCamActive && cameraType === CameraType.HELLO) {
			return `You are not seeing or hearing the patient because the physical privacy buttons have been enabled on Banyan Bridge. These buttons can be disabled only manually. Please contact your administrator.`;
		}
	};

	shouldShowPrivacyButtonsErrorMessage = () => {
		return this.state.isMicPrivacyOn || (this.state.isCameraPrivacyOn && this.state.isPeerVideoCamActive && this.state.cameraType === CameraType.HELLO);
	};

	toggleRightColumn = () => {
		this.setState({
			showRightColumn: !this.state.showRightColumn,
		});
	};

	onToggleCameraSwitch = () => {
		const { participantId, conferenceId, callerObjectId, cameraType, isCameraPrivacyOn } = this.state;

		const notAllowedToSwitchToHelloCam = cameraType === CameraType.HUDDLE && isCameraPrivacyOn;
		if (notAllowedToSwitchToHelloCam) {
			this.setState({ shouldShowSwitchToHelloCamError: true });
			return;
		}

		this.callManager.sendCameraEvent(SocketEvents.HelloDevice.SWITCH_CAMERA, { participantId, conferenceId, helloDeviceId: callerObjectId });
	};

	onLocalAudioError = ({ trackDeviceNotFound, inputDevices }) => {
		if (!trackDeviceNotFound || !inputDevices.length) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
		}

		this.setState({ micActive: false });
	};

	onLocalVideoError = ({ trackDeviceNotFound, inputDevices }) => {
		if (!trackDeviceNotFound || !inputDevices.length) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.CAMERA_NOT_FOUND.type,
			});
		}

		this.setState({ isMyVideoCamActive: false });
	};

	render() {
		const {
			activeSrc,
			peerSrc,
			localSrc,
			localScreenSrc,
			peerMicActive,
			micActive,
			participantId,
			conferenceId,
			isMyVideoCamActive,
			isVideoCall,
			isPeerVideoCamActive,
			showPTZ,
			callerObjectId,
			roomName,
			tvStatus,
			hdmiStatus,
			volumeStatus,
			audioOutputDevice,
			nightVisionMode,
			conferenceEndReason,
			streamPermission,
			disableButtons,
			cameraZoomLevel,
			cameraType,
			disabledTiltDirections,
			isHuddleCamConnected,
			isCameraPrivacyOn,
			showRightColumn,
		} = this.state;

		const shouldShowStateComponents = this.shouldShowStateComponents();
		const shouldShowPatientCameraToggledOff = this.shouldShowPatientCameraToggledOff();

		return (
			<Grid className={classNames('call-view', this.isScreenSharingSelected() ? 'is-screensharing' : '')} columns='1fr' stretch='100vh'>
				<main>
					{shouldShowStateComponents && this.renderCallStates()}

					{!shouldShowStateComponents && (
						<>
							{shouldShowPatientCameraToggledOff && (
								<Grid columns='1fr' stretch='100vh' rows='auto' backgroundColor='#0153B6' horizAlign='center' vertAlign='center'>
									<div style={{ textAlign: 'center' }}>
										<h3 style={{ color: 'white' }}>You have toggled patient's camera off.</h3>
										<Button onClick={this.togglePeerCamera} text='Turn camera on' />
									</div>
								</Grid>
							)}

							{!shouldShowPatientCameraToggledOff && (
								<TopParticipant
									topParticipantFeed={activeSrc}
									isTopParticipantVideoActive={this.showTopParticipant()}
									topParticipantAvatar={!isVideoCall ? 'https://static.solaborate.com/temp/banyan.png' : ''}
								/>
							)}

							<Alert
								alertSelector='privacyBtnsErrorMessage'
								message={this.getPrivacyButtonsErrorMessage()}
								variant='error'
								persist={true}
								display={this.shouldShowPrivacyButtonsErrorMessage()}
								hideCloseButton={true}
								position='top'
							/>
							<Alert
								display={this.state.shouldShowSwitchToHelloCamError}
								onClose={() => {
									this.setState({ shouldShowSwitchToHelloCamError: false });
								}}
								persist={true}
								message={`You can't switch back to Banyan Bridge because the physical privacy buttons have been enabled. These buttons can be disabled only manually. Please contact your administrator.`}
								variant='error'
								position='top'
							/>
							<CameraControls
								cameraType={cameraType}
								cameraZoomLevel={cameraZoomLevel}
								showPTZ={showPTZ}
								disabledTiltDirections={disabledTiltDirections}
								isHuddleCamConnected={isHuddleCamConnected}
								isDisabled={disableButtons}
								isCameraPrivacyOn={isCameraPrivacyOn}
								onToggleCameraSwitch={() => this.onToggleCameraSwitch()}
								onZoomCamera={direction => {
									const level = direction === ZoomDirection.INCREASE ? cameraZoomLevel + 20 : cameraZoomLevel - 20;
									this.callManager.sendCameraEvent(SocketEvents.HelloDevice.ZOOM_CAMERA, {
										level,
										helloDeviceId: callerObjectId,
										conferenceId,
										participantId,
									});
								}}
								onPanTiltCamera={this.sendPanTiltCameraEvent}
								onRebootHuddleCam={() =>
									this.callManager.rebootHuddleCam({
										conferenceId,
										participantId,
										helloDeviceId: callerObjectId,
									})
								}
							/>
							{!peerSrc && (
								<div className='loader__container'>
									<div className='center-loader'>
										<p>Initiating Call</p>
										<Loader />
									</div>
								</div>
							)}
						</>
					)}
				</main>

				{peerSrc && (
					<div>
						<aside className={showRightColumn ? '' : 'hide-col'}>
							<Participant
								name='You'
								avatarName={`${this.userInfo.firstName} ${this.userInfo.lastName}`}
								participantFeed={localSrc}
								videoCamActive={isVideoCall ? isMyVideoCamActive : false}
								onClick={this.activeSrcHandle}
								participantAvatar={this.myAvatarUrl}>
								<PeerStats callManager={this.callManager} isLocalSrc={true} />
							</Participant>
							<Participant
								name='Patient'
								// @ts-ignore
								avatarName={decodeURIComponent(this.props.match.params.roomName).replaceAll(' ', '')}
								participantFeed={peerSrc}
								videoCamActive={isVideoCall ? isPeerVideoCamActive : false}
								onClick={this.activeSrcHandle}>
								{!peerMicActive && <i className='material-icons-outlined mic_off-icon'>volume_off</i>}
								<Dropdown position='bottom' icon='more_horiz'>
									<ListGroup onItemClick={this.onPeerMediaToggleClicked} lists={this.buildListFromStates(isPeerVideoCamActive, peerMicActive)} />
								</Dropdown>
								{this.state.peerSrc && <PeerStats callManager={this.callManager} isMyMicOff={!micActive} isPeerMicPrivacyOn={this.state.isMicPrivacyOn} />}
								<audio ref={audio => (this.audioRef = audio)} autoPlay />
							</Participant>
							{localScreenSrc && (
								<Participant name='Presenting (You)' participantFeed={localScreenSrc} videoCamActive={localScreenSrc != null} onClick={this.activeSrcHandle} />
							)}
							<button className='toggle-right-column' type='button' onClick={this.toggleRightColumn}>
								<i className='material-icons'>{showRightColumn ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}</i>
							</button>
						</aside>

						<Grid className='call-view__footer' vertAlign='center' columns='33.33% 33.33% 33.33%'>
							<div className='call-view__footer--desc'>
								<h3 data-tooltip={`${this.state.healthSystemPath} > ${this.state.roomName}`} data-position='top'>
									<span>{roomName}</span>
								</h3>
								{!!conferenceId && <CallDuration callStartTime={this.state.callStartTime} />}
							</div>
							<div>
								<CallButton
									buttonSelector='screenshareBtn'
									icon={localScreenSrc ? 'stop_screen_share' : 'screen_share'}
									name='screenshare'
									isActive={localScreenSrc != null}
									onClick={() => this.debouncedToggleStream(TrackType.SCREEN)}
									tooltip={localScreenSrc ? 'Stop screensharing' : 'Start screensharing'}
									tooltipPosition='top'
									isDisabled={disableButtons}
								/>
								<CallButton
									buttonSelector='videoCamBtn'
									icon={isMyVideoCamActive ? 'videocam' : 'videocam_off'}
									name='camera'
									isActive={isMyVideoCamActive}
									onClick={() => this.debouncedToggleStream(TrackType.VIDEO)}
									tooltip={isMyVideoCamActive ? 'Turn off camera' : 'Turn on camera'}
									tooltipPosition='top'
									isDisabled={disableButtons}
								/>
								<CallButton
									buttonSelector='endCallBtn'
									icon='call_end'
									name='endcall'
									isActive={true}
									variant='end'
									onClick={this.endCall}
									tooltip='End call'
									tooltipPosition='top'
									isDisabled={disableButtons}
								/>
								<CallButton
									buttonSelector='usersMicBtn'
									icon={micActive ? 'mic' : 'mic_off'}
									name='mic'
									isActive={micActive}
									onClick={() => this.debouncedToggleStream(TrackType.AUDIO)}
									tooltip={micActive ? 'Turn off mic' : 'Turn on mic'}
									tooltipPosition='top'
									isDisabled={disableButtons}
								/>
								<CallButton
									buttonSelector='PTZBtn'
									icon={showPTZ ? 'control_camera' : 'control_camera'}
									onClick={this.togglePTZ}
									isActive={showPTZ}
									tooltipPosition='top'
									tooltip={showPTZ ? 'Hide PTZ' : 'Show PTZ'}
									isDisabled={!isPeerVideoCamActive || disableButtons}
								/>
							</div>
							<div>
								<NightVisionControl
									nightVisionMode={nightVisionMode}
									showButton={isPeerVideoCamActive}
									toggleNightvisionHandler={() => this.callManager.toggleNightVision(!nightVisionMode, callerObjectId, conferenceId, participantId)}
									isDisabled={this.state.cameraType === CameraType.HUDDLE || disableButtons}
								/>
								<TvControls
									tvStatus={tvStatus}
									volumeStatus={volumeStatus}
									hdmiStatus={hdmiStatus}
									tvBrand={this.state.tvBrand}
									audioOutputDevice={audioOutputDevice}
									callManagerInstance={this.callManager}
									helloDeviceId={callerObjectId}
									participantId={participantId}
									conferenceId={conferenceId}
									isDisabled={disableButtons}
								/>
							</div>
						</Grid>
					</div>
				)}
				<CallCorrelationInfo correlationId={conferenceId} className={(conferenceEndReason || streamPermission || !peerSrc) && 'out-of-call'} />
			</Grid>
		);
	}
}

Call.contextType = SocketContext;

const mapStateToProps = state => {
	return {
		organization: state.organization,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		fetchNotificationCounter: () => dispatch(fetchNotificationCounter()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Call);
