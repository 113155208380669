import React, { Component } from 'react';
import classNames from 'classnames/bind';

class SmallModal extends Component {
	constructor(props) {
		super(props);

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.props.onModalClose();
		}
	}

	render() {
		return (
			<div ref={this.setWrapperRef} className={classNames('small-modal')}>
				<section className='modal-main'>{this.props.children}</section>
			</div>
		);
	}
}

export default SmallModal;
