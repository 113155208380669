import React from 'react';

const Select = props => (
	<div className='input' style={{ marginBottom: props.bottomSpace }}>
		<p className='label'>{props.label}</p>
		<p>{props.description}</p>
		<select name={props.name} onChange={e => props.onSelect(props.items[e.target.selectedIndex - 1], e.target.selectedIndex - 1)} value={props.defaultValue}>
			<option data-test-id='select-placeholder' value={-1} disabled>
				{props.placeholder}
			</option>
			{props.items.map((item, index) => (
				<option data-test-id='select-option' key={index} value={index}>
					{item.value}
				</option>
			))}
		</select>
		<small>{props.error}</small>
	</div>
);

export default Select;
