import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
	dataTags: {
		customDataPrefix: 'data-custom-',
		aiBlobAttributeTag: 'customblob',
		parentDataTag: 'parent-group',
		donotTrackDataTag: 'dnt',
		captureAllMetaDataContent: true,
		useDefaultContentNameOrId: true,
	},
	defaultRightClickBhvr: 'rc',
};

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
	config: {
		maxBatchInterval: 0,
		disableFetchTracking: false,
		extensions: [reactPlugin, clickPluginInstance],
		extensionConfig: {
			[clickPluginInstance.identifier]: clickPluginConfig,
		},
	},
});

const createTelemetryService = () => {
	/**
	 * Initialize the Application Insights class
	 * @param {string} instrumentationKey - Application Insights Instrumentation Key
	 * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
	 * @return {void}
	 */
	const initialize = (instrumentationKey, browserHistory) => {
		if (!browserHistory) {
			throw new Error('Could not initialize Telemetry Service');
		}
		if (!instrumentationKey) {
			throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx');
		}

		appInsights.config.instrumentationKey = instrumentationKey;
		appInsights.config.extensionConfig = {
			...appInsights.config.extensionConfig,
			[reactPlugin.identifier]: {
				history: browserHistory,
			},
		};

		appInsights.loadAppInsights();

		const telemetryInitializer = envelope => {
			const { tags } = envelope;
			tags['ai.cloud.role'] = 'banyan.web';
		};
		appInsights.addTelemetryInitializer(telemetryInitializer);
	};

	return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
