import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Loader, Dropdown, ListGroup } from 'components';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ParticipantState, DeviceStatusMessages, FeedColors, ParticipantRemoveReason } from 'constants/enums';
import Notes from 'components/Monitoring/Notes';
import ReactDOM from 'react-dom';
import LocalMicBtn from 'components/Monitoring/LocalMicButton';

class VideoFeed extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFeedExpanded: false,
			showMicPortal: false,
			showCamPortal: false,
		};

		this.videoRef = null;
		this.micBtnRef = React.createRef();
		this.camBtnRef = React.createRef();
	}

	componentDidMount() {
		this.setVideoRefSource();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.src &&
			(!prevProps.src ||
				(prevProps.src && prevProps.src.id !== this.props.src.id) ||
				prevProps.peerConnectionState !== this.props.peerConnectionState ||
				prevProps.disableButtons !== this.props.disableButtons)
		) {
			this.setVideoRefSource();
		}
	}

	setVideoRefSource() {
		if (this.videoRef) {
			this.videoRef.srcObject = this.props.src.mediaStream;
		}
	}

	getDeviceStatus() {
		switch (this.props.status) {
			case ParticipantState.BUSY.type:
				if (this.props.onPatientBusyNurse && this.props.onPatientBusyNurse.name) {
					return `${DeviceStatusMessages.deviceOnCallWithNurse} ${this.props.onPatientBusyNurse.name}.`;
				}
				return DeviceStatusMessages.deviceOnCall;
			case ParticipantState.OFFLINE.type:
				return DeviceStatusMessages.deviceOffline;
			case ParticipantState.NOT_ANSWERING.type:
				return DeviceStatusMessages.notAnswering;
			case ParticipantState.DISCONNECTED.type:
			case ParticipantState.LEFT_CALL.type:
				return this.props.removeReason === ParticipantRemoveReason.FAILED_TO_GET_INFO
					? DeviceStatusMessages.participantInviteFailed
					: DeviceStatusMessages.leftCall;
			case ParticipantState.RECONNECTING.type:
				return DeviceStatusMessages.reconnecting;
			case ParticipantState.REMOVED.type:
				return DeviceStatusMessages.removed;
			case ParticipantState.INVITE_DENIED.type:
				return DeviceStatusMessages.participantInviteDenied;
			case ParticipantState.FAILED_TO_GET_INFO.type:
				return DeviceStatusMessages.participantInviteFailed;
			default:
				return DeviceStatusMessages.initiatingMonitoring;
		}
	}

	setFeedColor = (event, item) => {
		this.props.onSetFeedColor(item.color);
	};

	toggleExpand = () => {
		this.setState(
			{
				isFeedExpanded: !this.state.isFeedExpanded,
			},
			() => {
				this.props.onExpandClick();
			}
		);
	};

	checkIsColorActive = color => {
		return this.props.feedColors && this.props.feedColors.length > 0 && this.props.feedColors.find(item => item === color);
	};

	getTooltipElement = (ref, text) => {
		const toolTipElementProperties = ref.getBoundingClientRect();

		const style = {
			zIndex: 999999,
			position: 'absolute',
			background: 'var(--gray-5)',
			maxWidth: '300px',
			fontSize: '12px',
			color: 'var(--gray-0)',
			boxShadow: '0 1px 2px rgb(0 0 0 / 10%)',
			borderRadius: 'var(--border-radius)',
			fontFamily: 'var(--font-family)',
			right: window.innerWidth - toolTipElementProperties?.right,
			...{ top: toolTipElementProperties?.top + 25 },
		};
		return <PortalTooltip style={style}>{text}</PortalTooltip>;
	};

	render() {
		const showFeed = this.props.src !== null && this.props.status === ParticipantState.CONNECTED.type;
		const showButtons = !this.props.disableButtons && this.props.status === ParticipantState.CONNECTED.type;

		return (
			<VideoFeedWrapper className={this.props.className}>
				<main>
					<header style={{ background: this.props.feedColor }}>
						<h1 title={this.props.title}>{this.props.title}</h1>
						{showButtons && (
							<div className='feed-options-wrapper'>
								<Dropdown position='bottom' icon='more_horiz' closeDropdownOnItemClick={true} isPortal={true}>
									<ListGroup
										className='feed-dropdown'
										lists={[
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.purple }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Suspicious Behavior</span>
														{this.checkIsColorActive(FeedColors.purple) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.purple,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.cyan }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Suicide Watch</span>
														{this.checkIsColorActive(FeedColors.cyan) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.cyan,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.yellow }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Falls</span>
														{this.checkIsColorActive(FeedColors.yellow) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.yellow,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.red }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Isolation</span>
														{this.checkIsColorActive(FeedColors.red) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.red,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.pink }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Seizure</span>
														{this.checkIsColorActive(FeedColors.pink) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.pink,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.black }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Hospice</span>
														{this.checkIsColorActive(FeedColors.black) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.black,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.orange }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Detox</span>
														{this.checkIsColorActive(FeedColors.orange) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.orange,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.lightBlue }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Pulling O2</span>
														{this.checkIsColorActive(FeedColors.lightBlue) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.lightBlue,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.gray }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Impulsive/Confused</span>
														{this.checkIsColorActive(FeedColors.gray) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.gray,
											},
											{
												icon: (
													<i className='material-icons' style={{ color: FeedColors.blue }}>
														fiber_manual_record
													</i>
												),
												title: (
													<>
														<span>Non-redirectable</span>
														{this.checkIsColorActive(FeedColors.blue) && <i className='material-icons'>done</i>}
													</>
												),
												color: FeedColors.blue,
											},
										]}
										onItemClick={this.setFeedColor}
									/>
								</Dropdown>
								<ul>
									{this.props.isMicPrivacyOn && (
										<li>
											<button
												type='button'
												className='mic-cam-btn'
												ref={this.micBtnRef}
												onMouseEnter={() => this.setState({ showMicPortal: true })}
												onMouseLeave={() => this.setState({ showMicPortal: false })}>
												<i className='material-icons' style={{ color: this.props.isMicPrivacyOn ? '#e84118' : '#ffffff' }}>
													mic_off
												</i>
											</button>

											{this.micBtnRef?.current &&
												this.state.showMicPortal &&
												ReactDOM.createPortal(
													this.getTooltipElement(
														this.micBtnRef?.current,
														'You are not hearing the patient because the physical mic privacy button has been enabled on Banyan Bridge. This button can be disabled only manually. Please contact your administrator.'
													),
													document.getElementById('tooltip-root')
												)}
										</li>
									)}
									{this.props.isCameraPrivacyOn && (
										<li>
											<button
												type='button'
												className='mic-cam-btn'
												ref={this.camBtnRef}
												onMouseEnter={() => this.setState({ showCamPortal: true })}
												onMouseLeave={() => this.setState({ showCamPortal: false })}>
												<i className='material-icons' style={{ color: this.props.isCameraPrivacyOn ? '#e84118' : '#ffffff' }}>
													videocam_off
												</i>
											</button>

											{this.camBtnRef?.current &&
												this.state.showCamPortal &&
												ReactDOM.createPortal(
													this.getTooltipElement(
														this.camBtnRef?.current,
														'You are not seeing the patient because the physical camera privacy button has been enabled on Banyan Bridge. This button can be disabled only manually. Please contact your administrator.'
													),
													document.getElementById('tooltip-root')
												)}
										</li>
									)}
									<li>
										<button type='button' onClick={this.props.onToggleNightVision}>
											<i className='material-icons' data-cy='nightVisionBtn'>
												{this.props.isNightVisionActive ? 'wb_sunny' : 'brightness_3'}
											</i>
										</button>
									</li>
									<li>
										<button type='button' onClick={() => this.toggleExpand()}>
											<i className='material-icons' data-cy='expandFeed'>
												{this.props.isFeedExpanded ? 'unfold_less' : 'unfold_more'}
											</i>
										</button>
									</li>
									<li>
										<button type='button' onClick={this.props.onCloseClick}>
											<i className='material-icons' data-cy='closeMonitoringFeed'>
												close
											</i>
										</button>
									</li>
								</ul>
							</div>
						)}
						{!showButtons && (
							<ul>
								<li>
									<button type='button' onClick={this.props.onCloseClick}>
										<i className='material-icons' data-cy='closeMonitoringFeed'>
											close
										</i>
									</button>
								</li>
							</ul>
						)}
					</header>
					{showFeed && (
						<>
							<video ref={video => (this.videoRef = video)} autoPlay />
							{this.props.isAlarmActive && <div className='alarm-blinking' />}
							{this.props.feedColors && this.props.feedColors.length > 0 && (
								<VideoFeedColorsWrapper isAnyFeedExpanded={this.props.isAnyFeedExpanded} isFeedExpanded={this.props.isFeedExpanded}>
									{this.props.feedColors.map(item => (
										<div style={{ backgroundColor: item }} />
									))}
								</VideoFeedColorsWrapper>
							)}
						</>
					)}

					{showButtons && (
						<VideoFeedBottomOptions>
							<li>
								<button
									type='button'
									className={classNames('call-button alarm-btn', this.props.isAlarmActive ? 'active' : '')}
									data-custom-id='StatAlarm'
									data-custom-isactive={!this.props.isAlarmActive}
									data-custom-conference={this.props.conferenceId}
									data-custom-room={this.props.title}
									onClick={this.props.onToggleAlarm}
									data-tooltip='Stat alarm'
									data-position='right'>
									<i className='material-icons-outlined'>{this.props.isAlarmActive ? 'notifications_active' : 'notifications_off'}</i>
								</button>
								<LocalMicBtn
									data-custom-id='SitterMic'
									data-custom-conference={this.props.conferenceId}
									data-custom-room={this.props.title}
									audioTrack={this.props.localAudioTrack}
									toggle={this.props.onToggleMyMicrophone}
								/>
								<button
									type='button'
									className={classNames('call-button', this.props.isPeerSpeakerActive ? 'active' : '')}
									data-custom-id='PatientSpeaker'
									data-custom-isactive={!this.props.isPeerSpeakerActive}
									data-custom-conference={this.props.conferenceId}
									data-custom-room={this.props.title}
									onClick={() => this.props.onTogglePeerSpeaker()}>
									<i className='material-icons' data-cy='usersVolumeBtn'>
										{this.props.isPeerSpeakerActive ? 'volume_up' : 'volume_off'}
									</i>
								</button>
							</li>
						</VideoFeedBottomOptions>
					)}
				</main>
				{<Notes isHidden={!showFeed || (this.props.isAnyFeedExpanded && !this.props.isFeedExpanded)} />}

				{!showFeed && (
					<div className='center-loader'>
						<p> {this.getDeviceStatus()}</p>
						{this.props.status === ParticipantState.CONNECTING.type && <Loader />}
					</div>
				)}
				{this.props.children}
			</VideoFeedWrapper>
		);
	}
}

const VideoFeedWrapper = styled.div`
	background: var(--gray-5);
	position: relative;
	max-height: calc(100vh - 115px);
	display: flex;
	flex-direction: column;

	main {
		flex: 1;
		min-height: 0;
		position: relative;
	}

	li {
		list-style: none;
		display: flex;

		button {
			&::before {
				white-space: normal;
				width: 200px;
			}
		}

		[data-tooltip],
		[data-tooltip] i {
			cursor: default;
		}
	}

	header {
		width: 100%;
		display: flex;
		align-items: flex-start;
		padding: var(--spacing-s) var(--spacing-m);
		height: 30px;
		z-index: 1;
		background: var(--gray-3);

		ul {
			display: flex;

			li {
				button.mic-cam-btn {
					position: relative;
					&::after {
						display: none;
					}
					&:hover {
						&::after {
							display: block;
							content: '';
							background: var(--gray-5);
							padding: 4px;
							position: absolute;
							left: 50%;
							top: calc(100% + var(--spacing-s));
							transform: translate(-50%, -50%) rotate(45deg);
						}
					}
				}
				a {
					width: 100%;
					padding: var(--spacing-m);

					> i {
						padding-right: var(--spacing-s);
					}
				}
			}
		}

		button i,
		.dropdown i {
			color: var(--gray-0);
			cursor: pointer;
			font-size: 18px;
			margin-right: 0 !important;
		}

		.dropdown ul li a span {
			margin-right: var(--spacing-s);
		}

		.dropdown ul li a span + i {
			color: var(--gray-5);
			margin-left: auto;
			font-size: 16px;
		}

		button {
			background: none;
			border: none;
			font-size: 0;
		}
	}

	.center-loader {
		top: calc(50% + 15px);
		width: calc(100% - var(--spacing-m));
	}

	.center-loader p {
		margin: 0;
		padding: 0;
	}

	.center-loader {
		top: calc(50% + 15px);
		width: calc(100% - var(--spacing-m));
	}

	.center-loader p {
		margin: 0;
		padding: 0;
	}

	h1 {
		font-size: 14px;
		color: var(--gray-0);
		padding: 0;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		flex: 1;
		text-align: left;
		text-overflow: ellipsis;
	}

	> div {
		display: flex;
		flex-direction: column;
		margin-left: auto;
	}

	.dropdown__items a > i {
		margin: 0;
	}

	video {
		width: 100%;
		height: calc(100% - 30px);
	}

	.dropdown {
		padding: 0;
	}
`;

const VideoFeedBottomOptions = styled.div`
	position: absolute;
	bottom: var(--spacing-m);
	width: 100%;
	font-size: 0;
	display: flex;
	justify-content: flex-end;
	padding-right: var(--spacing-m);

	button {
		border: none;
		background: var(--red-1);
		padding: var(--spacing-s);
		border-radius: 100%;
		i {
			cursor: pointer;
			color: var(--gray-0) !important;
			font-size: 16px;
			margin-right: 0px;
		}

		&.active {
			background: var(--blue-2);
		}
	}

	button + button {
		margin-right: 0;
	}

	.call-button:not(:last-child) {
		margin-right: var(--spacing-s);
	}
`;

const VideoFeedColorsWrapper = styled.div`
	display: flex;
	height: 5px;
	width: 100%;
	position: absolute;
	z-index: 1;
	bottom: -5px;

	${props =>
		!props.isFeedExpanded &&
		props.isAnyFeedExpanded &&
		css`
			bottom: 0;
		`}

	div {
		flex: 1;
		height: 100%;
	}
`;

const PortalTooltip = styled.div`
	padding: var(--spacing-l);
	background: var(--gray-6);
`;

VideoFeed.propTypes = {
	src: PropTypes.object,
	title: PropTypes.string,
	onCloseClick: PropTypes.func,
	socketState: PropTypes.object,
	onIncreaseBitrate: PropTypes.func,
	isFeedExpanded: PropTypes.bool,
	isAnyFeedExpanded: PropTypes.bool,
};

export default VideoFeed;
