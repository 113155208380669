import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

export function getActiveDirectories({ pageSize = 10, pageIndex = 0, healthSystemId = null, hospitalId = null }) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.get(`v1/healthcare/organizations/${companyId}/configurations/active-directories`, {
				params: {
					pageSize,
					pageIndex,
					healthSystemId,
					hospitalId,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getActiveDirectoriesHistory(id, { pageSize = 10, pageIndex = 0 }) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.get(`v1/healthcare/organizations/${companyId}/configurations/active-directories/${id}/history`, {
				params: {
					pageSize,
					pageIndex,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getADGroupRoles({ pageSize = 10, pageIndex = 0, healthSystemId = null, hospitalId = null }) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.get(`v1/healthcare/organizations/${companyId}/configurations/active-directories/group-roles`, {
				params: {
					pageSize,
					pageIndex,
					healthSystemId,
					hospitalId,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function updateActiveDirectory(id, activeDirectory) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.put(`v1/healthcare/organizations/${companyId}/configurations/active-directories/${id}`, activeDirectory)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function addActiveDirectory(activeDirectory) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.post(`v1/healthcare/organizations/${companyId}/configurations/active-directories`, activeDirectory)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function addGroupRole(groupRole) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;

		const roleId = parseInt(groupRole.roleId, 10);
		const data = {
			hospitalId: groupRole.hospitalId,
			roleId,
			group: groupRole.group,
		};
		gatewayApi
			.post(`v1/healthcare/organizations/${companyId}/configurations/active-directories/group-roles`, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function updateGroupRole(id, groupRole) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;

		const roleId = parseInt(groupRole.roleId, 10);
		const data = {
			hospitalId: groupRole.hospitalId,
			roleId,
			group: groupRole.group,
		};
		gatewayApi
			.put(`v1/healthcare/organizations/${companyId}/configurations/active-directories/group-roles/${id}`, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function deleteActiveDirectory(id) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.delete(`v1/healthcare/organizations/${companyId}/configurations/active-directories/${id}`)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function deleteGroupRole(id) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.delete(`v1/healthcare/organizations/${companyId}/configurations/active-directories/group-roles/${id}`)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}
