import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import axios from 'axios';

export function deleteOrgUnit(level, id) {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/organizations/${APP_CONFIG.companyId}/level/${level}/id/${id}`)
			.then(response => {
				res(response.data.devices);
			})
			.catch(e => rej(e));
	});
}

export function reAssignDevice({ hospitalId, departmentId, floorId, roomId, deviceId }) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(
				`/v1/organizations/${APP_CONFIG.companyId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/devices/${deviceId}/re-assign`
			)
			.then(response => {
				res(response.data.devices);
			})
			.catch(e => rej(e));
	});
}

export function getTreeData(currentHealthSystemId, currentRegionId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/regions/${currentRegionId}`)
			.then(response => {
				res(response.data.organization.healthSystem.hospitals);
			})
			.catch(e => rej(e));
	});
}

export function uploadLogo(fileInput, { x, y, width, height }) {
	const formData = new FormData();
	formData.append('file', fileInput, fileInput.name);
	formData.append('cropOffsetX', x.toFixed());
	formData.append('cropOffsetY', y.toFixed());
	formData.append('cropWidth', width.toFixed());
	formData.append('cropHeight', height.toFixed());

	const accessToken = localStorage.getItem('access_token');
	return new Promise(res => {
		axios({
			method: 'post',
			baseURL: APP_CONFIG.URL.gatewayApiBasePath,
			url: '/v1/media/pictures/organization-logo',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then(({ data }) => res({ data }))
			.catch(error => res({ error }));
	});
}

export async function saveLogo(teamId, data) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(`/v1/companies/${APP_CONFIG.companyId}/teams/${teamId}/picture`, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export async function getLogoUrl(teamId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/${APP_CONFIG.companyId}/teams/${teamId}/picture`)
			.then(response => {
				res(response.data.picture);
			})
			.catch(e => rej(e));
	});
}

export function getHopsitalDepartments(healthSystemId, hospitalId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments`)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export const setRoomDetails = async (healthSystemId, hospitalId, departmentId, floorId, roomId, params) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomDetails = async (healthSystemId, hospitalId, departmentId, floorId, roomId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomLocation = async (healthSystemId, hospitalId, departmentId, floorId, roomId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/locations`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateRoomLocation = async (healthSystemId, hospitalId, departmentId, floorId, roomId, params) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/locations`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteRoomLocation = async (healthSystemId, hospitalId, departmentId, floorId, roomId) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/locations`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemHospitals = async healthSystemId => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${APP_CONFIG.companyId}/healthsystems/${healthSystemId}/hospitals`);
		return data;
	} catch (error) {
		return { error };
	}
};
