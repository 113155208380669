import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { actionCreators as mainLayoutActionCreators } from 'state/mainLayout/actions';
import { withRouter } from 'react-router-dom';
import { UserRoles, PresenceStatusType, PresenceStatusTypeLabels, FeedColors } from 'constants/enums';
import { getUserNotifications, changeNotificationReadStatus } from 'api/notifications';
import { getUserProfile, getUserId, getUserRole } from 'infrastructure/auth';
import { ListGroup, Badge, Breadcrumb, Dropdown, Input, Avatar, Modal, Form } from 'components';
import { updateUserPresence, fetchUserPresence } from 'state/userPresence/actions';
import { fetchNotificationCounter } from 'state/notifications/actions';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { postMessage } from 'infrastructure/helpers/notificationsChannel';
import moment from 'moment';
import { BanyanAppType } from 'constants/global-variables';
import { getCurrentHealthSystemInfo, getNursePoolingStatus } from 'infrastructure/helpers/commonHelpers';
import EditPoolingModal from 'components/EditPoolingModal';
import UserSettingsModal from 'components/UserSettings/UserSettingsModal';

class Header extends Component {
	constructor(props) {
		super(props);
		this.userPresenceDropdownRef = React.createRef();
		this.state = {
			customMessage: '',
			userProfile: {},
			notifications: [],
			notificationsPageNo: 0,
			notificationsPageSize: 10,
			hasMoreNotifications: true,
			isAccountSettingsModalOpen: false,
			isEditPoolingModalOpen: false,
			isVCPRoleModalOpen: false,
			isSwitchPresenceModalVisible: false,
		};
	}

	precautions = [
		{
			title: 'Suspicious Behavior',
			color: FeedColors.purple,
		},
		{
			title: 'Suicide Watch',
			color: FeedColors.cyan,
		},
		{
			title: 'Falls',
			color: FeedColors.yellow,
		},
		{
			title: 'Isolation',
			color: FeedColors.red,
		},
		{
			title: 'Seizure',
			color: FeedColors.pink,
		},
		{
			title: 'Hospice',
			color: FeedColors.black,
		},
		{
			title: 'Detox',
			color: FeedColors.orange,
		},
		{
			title: 'Pulling O2',
			color: FeedColors.lightBlue,
		},
		{
			title: 'Impulsive/Confused',
			color: FeedColors.gray,
		},
		{
			title: 'Non-redirectable',
			color: FeedColors.blue,
		},
	];

	componentDidMount = async () => {
		const userProfile = getUserProfile();
		const user = {
			fullName: `${userProfile.firstName} ${userProfile.lastName}`,
			profilePicUrl: userProfile.profilePicture.url,
			virtualRole: userProfile.jobTitle,
			virtualRoleKey: null,
			userWorkExperienceId: 0,
			locations: [],
		};
		this.setState({
			userProfile: user,
		});
		this.props.fetchNotificationCounter();
	};

	componentWillUnmount() {
		this.props.organizationActions.updateBreadcrumb([]);
	}

	updateUserPresence = key => {
		this.userPresenceDropdownRef.current.toggleDropdown(false);
		if (key === this.props.userPresence.presenceStatusTypeId && this.state.customMessage === this.props.userPresence.customMessage) {
			return;
		}
		this.props.updateUserPresence(getUserId(), this.state.customMessage, key);
	};

	onBreadcrumbClick = (options, breadcrumbIndex) => {
		let breadcrumb = [];
		options.forEach((option, index) => {
			if (breadcrumbIndex + 1 > index) {
				breadcrumb.push(option);
			}
		});
		this.props.organizationActions.updateBreadcrumb(breadcrumb);
	};

	loadUserNotifications = async isDropdownOpen => {
		if (!isDropdownOpen) {
			const userProfile = getUserProfile();

			let unreadNotifications = [];
			this.state.notifications.forEach(i => {
				if (i.isPreview) {
					// isPreview means it hasn't been read yet
					unreadNotifications.push(i.id);
				}
			});

			if (unreadNotifications.length > 0) {
				await changeNotificationReadStatus(userProfile.userId, unreadNotifications);
				this.props.fetchNotificationCounter();
			}
			postMessage('Update notifications');
			this.setState({
				notifications: [],
				hasMoreNotifications: true,
				notificationsPageNo: 0,
			});
		} else {
			await this.getUserNotifications();
			this.setState({
				notificationsPageNo: this.state.notificationsPageNo + 1,
			});
		}
	};

	formatTime = date => {
		let localDate = utcToLocalTime(date);
		let now = new Date();
		now.setHours(0, 0, 0, 0);
		const isNowAfterTheDate = moment(now).isAfter(localDate);
		return isNowAfterTheDate ? moment(localDate).format('dddd, MMMM DD, YYYY - h:mm A') : `Today - ${moment(localDate).format('h:mm A')}`;
	};

	getUserNotifications = async () => {
		const userProfile = getUserProfile();
		const response = await getUserNotifications(userProfile.userId, BanyanAppType, this.state.notificationsPageNo, this.state.notificationsPageSize);

		let { notifications } = this.state;
		let hasMoreNotifications;

		response.notifications.forEach(i => {
			i.timeAgo = this.formatTime(i.dateCreated);
			return i;
		});

		if (response === null) {
			notifications.push({
				title: (
					<div className='notification-group'>
						<div>
							<p>An error ocurred trying to fetch notifications!</p>
						</div>
					</div>
				),
			});
		} else if (response.notifications.length === 0 && this.state.notifications.length === 0) {
			notifications.push({
				title: (
					<div className='notification-group'>
						<div>
							<p>You don't have any notifications!</p>
						</div>
					</div>
				),
			});
		} else {
			hasMoreNotifications = response.total < this.state.notificationsPageNo * 10 ? false : true;
			response.notifications.forEach(n => {
				const el = {
					id: n.id,
					isPreview: n.isPreview,
					title: (
						<div className='notification-group'>
							<Avatar src='https://static.solaborate.com/americanwell/hello2-finallogo.png' fullName={'A B'} size='small' />
							<div>
								{n.isPreview ? (
									<p>
										<b>Missed call from {n.callerName}</b>
									</p>
								) : (
									<p>Missed call from {n.callerName}</p>
								)}
								<small>{n.timeAgo}</small>
							</div>
						</div>
					),
				};
				notifications.push(el);
			});
		}

		this.setState({
			notifications: notifications,
			hasMoreNotifications: hasMoreNotifications,
		});
	};

	checkIfScrolledAtBottom = ({ target }) => {
		const hasScrolledAtBottom = Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight;
		if (!hasScrolledAtBottom || !this.state.hasMoreNotifications) {
			return;
		}
		this.setState({
			notificationsPageNo: this.state.notificationsPageNo + 1,
		});
		this.getUserNotifications();
	};

	onAvailabilityDropdownClick = (evt, { available }) => {
		if (available) {
			const noCallsShouldBeReceived = '2';
			if (
				getUserRole() === UserRoles.NURSE &&
				(!localStorage.getItem('nursePoolingStatus') || localStorage.getItem('nursePoolingStatus') === noCallsShouldBeReceived)
			) {
				this.setState({
					isSwitchPresenceModalVisible: true,
				});
				return;
			}
			this.updateUserPresence(PresenceStatusType.AVAILABLE);
		} else {
			this.updateUserPresence(PresenceStatusType.UNAVAILABLE);
		}
	};

	onUserDropdownClick = (evt, { id }) => {
		if (id === 'account_settings') {
			this.toggleAccountSettingsModal();
		} else if (id === 'log_out') {
			this.signOut();
		}
	};

	onCustomMessageChanged = evt => {
		this.setState({
			customMessage: evt.target.value,
		});
	};

	onCustomMessageKeyUp = evt => {
		if (evt.which === 13) {
			this.props.updateUserPresence(getUserId(), this.state.customMessage, this.props.userPresence.presenceStatusTypeId);
			this.setState({
				customMessage: '',
			});
		}
	};

	toggleAccountSettingsModal = () => {
		this.setState({
			isAccountSettingsModalOpen: !this.state.isAccountSettingsModalOpen,
		});
	};

	toggleEditPoolingModal = () => {
		this.setState({
			isEditPoolingModalOpen: !this.state.isEditPoolingModalOpen,
		});
	};

	signOut = () => {
		this.props.history.push('/logout');
	};

	render() {
		const userRole = getUserRole();
		return (
			<div>
				<header className='header'>
					<div>
						{this.props.isMonitoring && (
							<div className='monitoring-precautions'>
								{this.precautions.map(item => (
									<div>
										<i className='material-icons' style={{ color: item.color }}>
											fiber_manual_record
										</i>
										<span>{item.title}</span>
									</div>
								))}
							</div>
						)}
						{!this.props.isMonitoring && <Breadcrumb links={this.props.organization.breadcrumb} onBreadcrumbClick={this.onBreadcrumbClick} />}
					</div>
					<div>
						<nav className='navigation'>
							<Dropdown
								position='bottom'
								ref={this.userPresenceDropdownRef}
								title={
									<Badge
										variant={this.props.userPresence.presenceStatusTypeId === PresenceStatusType.AVAILABLE ? 'green' : 'red'}
										text={
											this.props.userPresence.customMessage && this.props.userPresence.customMessage.trim().length > 0
												? this.props.userPresence.customMessage
												: PresenceStatusTypeLabels[this.props.userPresence.presenceStatusTypeId]
										}
										icon='arrow_drop_down'
									/>
								}>
								<div className='availability-dropdown'>
									<Input
										type='text'
										name='customMessage'
										placeholder='Custom message'
										value={this.state.customMessage}
										onChange={this.onCustomMessageChanged}
										onKeyUp={this.onCustomMessageKeyUp}
										validationOptions={{}}
										bottomSpace='0'
										width='100px'
									/>
									<ListGroup
										onItemClick={this.onAvailabilityDropdownClick}
										lists={[
											{ title: 'Available', icon: 'fiber_manual_record', available: true },
											{ title: 'Unavailable', icon: 'fiber_manual_record', available: false },
										]}
									/>
								</div>
							</Dropdown>
							{![UserRoles.ADMIN, UserRoles.SUPERUSER, UserRoles.VIRTUALSITTER].includes(userRole) && (
								<div className='notifications-wrapper'>
									<Dropdown
										id='notifications'
										position='bottom'
										icon='notifications'
										isDropdownOpen={isDropdownOpen => this.loadUserNotifications(isDropdownOpen)}>
										<ListGroup className='notifications-list' onScroll={this.checkIfScrolledAtBottom} lists={this.state.notifications} />
									</Dropdown>
									{this.props.notifications.notificationsCounter > 0 && (
										<Badge text={this.props.notifications.notificationsCounter} variant='darkred' size='xsmall' />
									)}
								</div>
							)}

							{userRole === UserRoles.NURSE && getNursePoolingStatus() && (
								<div className='role-wrapper' onClick={this.toggleEditPoolingModal}>
									<span className='material-icons'>assignment_ind</span>
								</div>
							)}

							<Dropdown
								className='user'
								position='bottom'
								title={this.state.userProfile.fullName}
								imageUrl={this.state.userProfile.profilePicUrl}
								icon='keyboard_arrow_down'>
								<ListGroup
									onItemClick={this.onUserDropdownClick}
									lists={[
										{ title: 'Account settings', icon: 'settings', id: 'account_settings' },
										{ title: 'Log out', icon: 'exit_to_app', id: 'log_out' },
									]}
								/>
							</Dropdown>
						</nav>
					</div>
				</header>
				<UserSettingsModal
					{...this.props}
					isAccountSettingsModalOpen={this.state.isAccountSettingsModalOpen}
					toggleAccountSettingsModal={this.toggleAccountSettingsModal}
				/>
				<Modal
					display={this.state.isSwitchPresenceModalVisible}
					position='center'
					submitButtonText='Go to options'
					hideActionButtons={!localStorage.getItem('nursePoolingStatus')}
					onModalSubmit={() => {
						this.setState({
							isSwitchPresenceModalVisible: false,
							isEditPoolingModalOpen: true,
						});
					}}
					onModalClose={() => {
						this.setState({
							isSwitchPresenceModalVisible: false,
						});
					}}>
					<Form title='Cannot switch presence' onSubmit={event => event.preventDefault()}>
						<p>To switch your presence to &ldquo;Available&ldquo; please make sure you select option &ldquo;I will be receiving calls&ldquo;.</p>
					</Form>
				</Modal>

				{this.state.isEditPoolingModalOpen && <EditPoolingModal toggleEditPoolingModal={this.toggleEditPoolingModal} isEdit={true} />}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		organization: state.organization,
		userPresence: state.userPresence,
		notifications: state.notifications,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		mainLayoutActions: bindActionCreators(mainLayoutActionCreators, dispatch),
		fetchUserPresence: () => dispatch(fetchUserPresence()),
		updateUserPresence: (userId, customMessage, presenceStatusTypeId) => dispatch(updateUserPresence(userId, customMessage, presenceStatusTypeId)),
		fetchNotificationCounter: () => dispatch(fetchNotificationCounter()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
