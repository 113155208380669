import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

export const getNtpConfigurations = async ({ pageSize, pageIndex, teamId, teamType }) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${APP_CONFIG.companyId}/configurations/ntp`, {
			params: { pageSize, pageIndex, teamId, teamType },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const addNtpConfiguration = async (teamId, teamType, ntpUrls) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/organizations/${APP_CONFIG.companyId}/teams/${teamId}/team-types/${teamType}/configurations/ntp`, {
			ntpUrls,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteNtpConfiguration = async (teamId, teamType, id) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/healthcare/organizations/${APP_CONFIG.companyId}/teams/${teamId}/team-types/${teamType}/configurations/ntp/${id}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNtpConfigurationHistory = async ({ id, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/configurations/ntp/${id}/history`, {
			params: {
				pageSize,
				pageIndex,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const editNtpConfiguration = async ({ teamId, teamType, id, ntpUrls }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/healthcare/organizations/${APP_CONFIG.companyId}/teams/${teamId}/team-types/${teamType}/configurations/ntp/${id}`,

			{ ntpUrls }
		);
		return data;
	} catch (error) {
		return { error };
	}
};
