import React from 'react';

const SkeletonLoader = props => (
	<div className='skeleton-loader' style={{ padding: props.padding }}>
		{[...Array(props.rows)].map((option, index) => (
			<div key={index}></div>
		))}
	</div>
);

export default SkeletonLoader;
