import { combineReducers } from 'redux';
import { mainLayout } from './mainLayout/reducer';
import organization from './organization/reducer';
import { userPresence } from './userPresence/reducer';
import { notifications } from './notifications/reducer';
import { nursePooling } from './nursePooling/reducer';

export default combineReducers({
	mainLayout,
	organization,
	userPresence,
	notifications,
	nursePooling,
});
