import React from 'react';
import { Route } from 'react-router-dom';
import Loader from 'components/Loader';
import Grid from 'components/Grid';
import { memberExists, getUserRole } from 'infrastructure/auth';
import UserError from 'views/UserError';
import { UserRoles } from 'constants/enums';
import PoolingFlowWrapper from 'views/PoolingFlowWrapper';
import { getNursePoolingStatus } from 'infrastructure/helpers/commonHelpers';
import { AuthConsumer } from '../providers/authProvider';

// eslint-disable-next-line import/prefer-default-export
export const PrivateRoute = ({ component, roles, ...rest }) => {
	const renderFn = Component => props => (
		<AuthConsumer>
			{({ isAuthenticated, signinRedirect }) => {
				if (!!Component && isAuthenticated()) {
					let canAccessRoute = true;
					if (roles) {
						canAccessRoute = roles.some(r => r === getUserRole());
					}
					if ((memberExists() || props.match.url === '/logout') && canAccessRoute) {
						if (getUserRole() === UserRoles.NURSE && !getNursePoolingStatus()) {
							return <PoolingFlowWrapper {...props} />;
						}

						return <Component {...props} />;
					}

					return <UserError />;
				}

				signinRedirect();
				return (
					<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				);
			}}
		</AuthConsumer>
	);

	return <Route {...rest} render={renderFn(component)} />;
};
