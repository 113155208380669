import { gatewayApi, APP_CONFIG, legacyApi, IdentityClientType, localApi } from 'constants/global-variables';
import { SectorTypes, ClientTypes } from 'constants/enums';
import { func } from 'prop-types';

export function getHealthSystems() {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems`)
			.then(response => {
				res(response.data.healthSystems);
			})
			.catch(e => rej(e));
	});
}

export function getOrgTeamChildren(teamId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/teams/${teamId}/children`)
			.then(response => {
				res(response.data.children);
			})
			.catch(e => rej(e));
	});
}

export function getHealthSystemRegions(healthSystemId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}`)
			.then(response => {
				res(response.data.healthSystem.regions);
			})
			.catch(e => rej(e));
	});
}

export function getHealthSystemSubTree(healthSystemId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/full`)
			.then(response => {
				const regions = response.data.regions;
				let treeData = [];
				regions.forEach((region, regionIndex) => {
					treeData.push({
						name: region.name,
						icon: 'local_hospital',
						type: SectorTypes.REGION,
						subOptions: [],
						id: region.id,
					});

					region.hospitals.forEach((hospital, hospitalIndex) => {
						treeData[regionIndex].subOptions.push({
							name: hospital.name,
							icon: 'business',
							type: SectorTypes.HOSPITAL,
							subOptions: [],
							id: hospital.id,
							regionId: region.id,
						});

						hospital.departments.forEach((department, departmentIndex) => {
							treeData[regionIndex].subOptions[hospitalIndex].subOptions.push({
								name: department.name,
								icon: 'account_balance',
								type: SectorTypes.DEPARTMENT,
								subOptions: [],
								id: department.id,
								hospitalId: hospital.id,
								regionId: region.id,
							});

							department.floors.forEach((floor, floorIndex) => {
								treeData[regionIndex].subOptions[hospitalIndex].subOptions[departmentIndex].subOptions.push({
									name: floor.name,
									icon: 'money',
									type: SectorTypes.FLOOR,
									subOptions: [],
									id: floor.id,
									departmentId: department.id,
									hospitalId: hospital.id,
									regionId: region.id,
								});

								floor.rooms.forEach((room, roomIndex) => {
									treeData[regionIndex].subOptions[hospitalIndex].subOptions[departmentIndex].subOptions[floorIndex].subOptions.push({
										name: room.name,
										icon: 'meeting_room',
										type: SectorTypes.ROOM,
										subOptions: [],
										floorId: floor.id,
										departmentId: department.id,
										hospitalId: hospital.id,
										regionId: region.id,
										id: room.id,
										roomIndex: roomIndex,
										status: 'online',
										helloDeviceId: room.helloDeviceId ? Number(room.helloDeviceId) : null,
									});
								});
							});
						});
					});
				});
				res(treeData);
			})
			.catch(e => rej(e));
	});
}

export function getMembers(pageSize = 10, pageIndex = 0, searchValue) {
	let params = {
		pageSize: pageSize,
		pageIndex: pageIndex,
		searchValue: searchValue,
	};

	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/organizations/${APP_CONFIG.companyId}/members`, {
				params: params,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getHealthSystemMembers(healthSystemId, pageSize = 10, pageIndex = 0, searchValue) {
	let params = {
		pageSize: pageSize,
		pageIndex: pageIndex,
		searchValue: searchValue,
	};

	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/healthsystems/${healthSystemId}/members`, {
				params: params,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getInvitedMembers(pageSize = 10, pageIndex = 0, searchValue) {
	let params = {
		pageSize: pageSize,
		pageIndex: pageIndex,
		searchValue: searchValue,
	};

	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/${APP_CONFIG.companyId}/organization-invites`, {
				params: params,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getHealthSystemInvitedUsers(healthSystemId, pageSize = 10, pageIndex = 0, searchValue) {
	let params = {
		pageSize: pageSize,
		pageIndex: pageIndex,
		searchValue: searchValue,
	};

	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/${APP_CONFIG.companyId}/healthsystems/${healthSystemId}/organization-invites`, {
				params: params,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

// sign up, activate and accept invitation
export function registerUser(values, inviteId, companyId) {
	return new Promise((res, rej) => {
		localApi
			.post(`v2.0/api/organization/signup`, {
				Type: 21,
				OrganizationInviteData: {
					CompanyId: companyId,
					InviteId: inviteId,
					ClientType: IdentityClientType,
				},
				LogInData: {
					SignInEmail: values.email,
					SignInPassword: values.password,
				},
				SignUpModel: {
					FirstName: values.firstName,
					LastName: values.lastName,
					Email: values.email,
					Password: values.password,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function createInvite(emails, inviteTypeId) {
	var users = [];

	emails.forEach(email => {
		users.push({ Email: email, InviteTypeId: inviteTypeId });
	});

	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/companies/${APP_CONFIG.companyId}/organization-invites`, {
				InviteInfo: null,
				Users: users,
				ClientType: ClientTypes.BANYAN,
			})
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function createNurseInvite(emails, inviteTypeId, healthSystems) {
	const users = [];
	const channels = [];
	emails.forEach(email => {
		users.push({ Email: email, InviteTypeId: inviteTypeId });
	});
	healthSystems.forEach(hs => {
		channels.push({ Id: hs.value, Name: hs.label });
	});

	const data = {
		Users: users,
		InviteInfo: {
			Channels: channels,
		},
		ClientType: ClientTypes.BANYAN,
	};

	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/companies/${APP_CONFIG.companyId}/organization-invites`, data)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function assignMember(healthSystemId, teamId, companyUserId) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/levels/${teamId}/assign`, {
				CompanyUserId: companyUserId,
			})
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function createAdInvite(emails, inviteTypeId, hospitalId, healthSystem) {
	let users = [];
	const channels = [];

	emails.forEach(email => {
		users.push({ Email: email, InviteTypeId: inviteTypeId });
	});

	channels.push({ Id: healthSystem, Name: '' });

	console.log('channels', channels);

	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/companies/${APP_CONFIG.companyId}/ad-organization-invites`, {
				InviteInfo: {
					Channels: channels,
				},
				Users: users,
				ClientType: ClientTypes.BANYAN,
				hospitalId,
			})
			.then(response => res(response))
			.catch(e => rej(e));
	});
}

export function deleteMember(memberId) {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/companies/${APP_CONFIG.companyId}/members/${memberId}`)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function getInvite(inviteId, email) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/${APP_CONFIG.companyId}/invites/${inviteId}`, {
				params: {
					email: email,
				},
			})
			.then(response => res(response.data))
			.catch(e => rej(e.response.data));
	});
}

export function updateInviteStatus(inviteId, statusId) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(`/v1/companies/${APP_CONFIG.companyId}/invites/${inviteId}/status`, {
				StatusId: statusId,
			})
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export function checkUserAccount(email) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/users/${email}/exists`)
			.then(response => res(response.data.result))
			.catch(e => rej(e));
	});
}

export function checkIfCompanyMemberExists(email) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/${APP_CONFIG.companyId}/members/${email}/exists`)
			.then(response => {
				if (response.data.exists) res(response.data.exists);
				else rej(response.data.exists);
			})
			.catch(e => rej(e));
	});
}

export function acceptInvite(inviteId, email) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(`/v1/companies/${APP_CONFIG.companyId}/organization-invites/${inviteId}/accept`, {
				Email: email,
			})
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export function addToHealthSystem(healthSystemId, memberId) {
	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/organizations/${APP_CONFIG.companyId}/healthsystems/${healthSystemId}/vcp-members`, {
				Id: memberId,
			})
			.then(() => {
				res();
			})
			.catch(e => rej(e));
	});
}

export function deleteHealthSystemMember(healthSystemId, memberId) {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/organizations/${APP_CONFIG.companyId}/healthsystems/${healthSystemId}/vcp-members/${memberId}`)
			.then(() => {
				res();
			})
			.catch(e => rej(e));
	});
}

export function getNurseHealthSystems(memberId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/vcp-members/${memberId}/healthsystems`)
			.then(response => res(response.data.healthSystems))
			.catch(e => rej(e));
	});
}

export function updateNurse(values, userId, guidUserId, roleId) {
	return new Promise((res, rej) => {
		legacyApi
			.put(`v2.1/api/profile/nurse/${userId}`, {
				FirstName: values.firstName,
				LastName: values.lastName,
				CompanyName: 'Banyan',
				JobTitle: values.jobTitle ? values.jobTitle : '',
				WorkExperienceId: roleId,
				NurseUserGuid: guidUserId,
			})
			.then(response => {
				res(response);
			})
			.catch(e => rej(e));
	});
}

export function getNurseProfileInfo(userId) {
	return new Promise((res, rej) => {
		legacyApi
			.get(`/v2.0/api/profiles/${userId}`)
			.then(response => {
				res(response.data.Result);
			})
			.catch(e => rej(e));
	});
}

export function sendOrganizationPasswordRecoveryEmail(email) {
	return new Promise((res, rej) => {
		localApi
			.post('v2.0/api/organization-account/recover', {
				email,
				companyId: APP_CONFIG.companyId,
				recoveryInviteId: null,
				userId: null,
			})
			.then(r => res(r))
			.catch(e => rej(e));
	});
}

export function sendRecoveryEmail(email, userId, recoveryInviteId) {
	return new Promise((res, rej) => {
		legacyApi
			.post('v2.0/api/organization-account/recover', {
				email,
				companyId: APP_CONFIG.companyId,
				recoveryInviteId,
				userId,
			})
			.then(r => res(r.data))
			.catch(e => rej(e));
	});
}

export function sendPasswordRecoveredEmail(email, password, code) {
	return new Promise((res, rej) => {
		localApi
			.post('api/account/password-reset', {
				Email: email,
				Password: password,
				Code: code,
				OrganizationId: APP_CONFIG.companyId,
			})
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function editSuperUserHealthSystem(superCompanyUserId, healthSystemId, pastHealthSystemId) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(`/v1/organizations/${APP_CONFIG.companyId}/super-users/${superCompanyUserId}/health-system`, {
				healthSystemId,
				pastHealthSystemId,
			})
			.then(() => {
				res();
			})
			.catch(e => rej(e));
	});
}

export function getHealthSystemSubTreeBasic(healthSystemId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/full`)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export const getNursesList = async (search = '') => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${APP_CONFIG.companyId}/nurses${search ? `?searchPattern=${search}` : ''}`);
		return data;
	} catch (error) {
		return { error };
	}
};
