import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ type = 'checkbox', name, id, checked = false, onChange }) => (
	<label className='checkbox' key={name}>
		<input type={type} id={id} name={name} checked={checked} onChange={onChange} />
		<span className='checkbox-name'>{name}</span>
	</label>
);

Checkbox.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default Checkbox;
