import React, { Component } from 'react';
import CallButton from 'components/CallButton';
import ListGroup from 'components/ListGroup';
import AudioOutputs from 'components/AudioOutputs';
import { SerialTVCommands, TvBrands, TvControlsEnums } from 'constants/enums';
import { SocketContext } from 'io-client/SocketContext';
import Alert from 'components/Alert';

export class TvControls extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			prevVolumeRange: 0,
			volumeRange: this.props.tvBrand === 0 ? 1 : 0,
			powerTv: false,
			powerToolTip: "Toggle patient's TV power off",
			hdmiIcon: 'looks_one',
			deviceId: 0,
		};

		this.callManager = props.callManagerInstance;

		this.hdmiTypeButtons = [
			{
				title: "Toggle patient's TV to HDMI1",
				icon: 'looks_one',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI1,
			},
			{
				title: "Toggle patient's TV to HDMI2",
				icon: 'looks_two',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI2,
			},
			{
				title: "Toggle patient's TV to HDMI3",
				icon: 'looks_3',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI3,
			},
		];
	}

	componentDidMount() {
		if (this.props.hdmiStatus) {
			this.tvCommandsResponseListener(this.props.hdmiStatus);
		}

		if (this.props.tvStatus) {
			this.tvCommandsResponseListener(this.props.tvStatus);
		}

		if (this.props.volumeStatus) {
			this.tvCommandsResponseListener(this.props.volumeStatus);
		}
		this.setState({ deviceId: this.props.helloDeviceId });
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props.tvStatus) !== JSON.stringify(prevProps.tvStatus)) {
			this.tvCommandsResponseListener(this.props.tvStatus);
		}

		if (JSON.stringify(this.props.hdmiStatus) !== JSON.stringify(prevProps.hdmiStatus)) {
			this.tvCommandsResponseListener(this.props.hdmiStatus);
		}

		if (JSON.stringify(this.props.volumeStatus) !== JSON.stringify(prevProps.volumeStatus)) {
			this.tvCommandsResponseListener(this.props.volumeStatus);
		}
	}

	serialCommandsTv = async (commandType, volumeRange) => {
		switch (commandType) {
			case TvControlsEnums.POWER: {
				let tvPowerStatus = this.state.powerTv ? SerialTVCommands.POWER.POWER_OFF : SerialTVCommands.POWER.POWER_ON;
				await this.callManager.serialCommandsTv(tvPowerStatus, false, this.state.deviceId, this.props.conferenceId, this.props.participantId);

				break;
			}
			case TvControlsEnums.VOLUME: {
				this.setState({
					volumeRange: +volumeRange,
				});

				if (this.volumeTimeout) {
					clearTimeout(this.volumeTimeout);
				}
				// Delays this function call when we adjust the volume to prevent sending many requests
				this.volumeTimeout = setTimeout(() => {
					this.callManager.serialCommandsTv(this.state.volumeRange, true, this.state.deviceId, this.props.conferenceId, this.props.participantId);
				}, 1000);

				break;
			}
			default:
				break;
		}
	};

	changeVolume = increaseVolume => {
		this.setState(
			{
				volumeRange: increaseVolume ? 1 : 0,
			},
			() => {
				this.serialCommandsTv(TvControlsEnums.VOLUME, this.state.volumeRange);
			}
		);
	};

	onHdmiTypeChanged = async (event, item) => {
		await this.callManager.serialCommandsTv(
			item.hdmiType || SerialTVCommands.HDMI.SWITCH_HDMI1,
			false,
			this.state.deviceId,
			this.props.conferenceId,
			this.props.participantId
		);
	};

	tvCommandsResponseListener = data => {
		if (data.isSuccessful) {
			if (!data.tvState.isVolume) {
				switch (data.tvState.tvStatus) {
					case SerialTVCommands.INITIAL_TV_POWER: {
						this.setState({
							powerTv: true,
							powerToolTip: "Toggle patient's TV power off",
						});
						break;
					}

					case SerialTVCommands.POWER.POWER_OFF:
					case SerialTVCommands.POWER.POWER_ON: {
						const tvPowerStatus = data.tvState.tvStatus !== SerialTVCommands.POWER.POWER_OFF;
						const powerToolTip = tvPowerStatus === false ? "Toggle patient's TV power on" : "Toggle patient's TV power off";
						this.setState({
							powerTv: tvPowerStatus,
							powerToolTip: powerToolTip,
						});
						break;
					}

					case SerialTVCommands.HDMI.SWITCH_HDMI1:
					case SerialTVCommands.HDMI.SWITCH_HDMI2:
					case SerialTVCommands.HDMI.SWITCH_HDMI3: {
						const hdmiTypeIcon = this.hdmiTypeButtons.find(item => item.hdmiType === data.tvState.tvStatus).icon;
						this.setState({
							hdmiIcon: hdmiTypeIcon,
						});
						break;
					}

					default:
						break;
				}
			} else {
				this.setState({
					volumeRange: data.tvState.tvStatus,
					prevVolumeRange: this.state.volumeRange,
				});
			}
		} else {
			const prevVolInitPower = {};

			if (data.tvState.isVolume) {
				prevVolInitPower.volumeRange = this.state.prevVolumeRange;
			}
			if (data.tvState.tvStatus === SerialTVCommands.INITIAL_TV_POWER) {
				prevVolInitPower.powerTv = false;
				prevVolInitPower.powerToolTip = "Toggle patient's TV power on";
			}

			this.setState(prevVolInitPower);
		}
	};

	render() {
		return (
			<div>
				<Alert
					display={this.state.volumeRange <= 0 && this.props.tvBrand > 0 && this.props.tvBrand !== TvBrands.CEC_TV}
					persist={true}
					message='TV is muted or OFF. Please make sure the patient can hear you.'
					variant='error'
					position='top'
					hideCloseButton={true}
				/>
				<CallButton
					buttonSelector='TVPower'
					icon='power_settings_new'
					isActive={!this.state.powerTv}
					onClick={() => {
						this.serialCommandsTv(TvControlsEnums.POWER);
					}}
					tooltip={this.state.powerToolTip}
					tooltipPosition='top'
					isDisabled={this.props.isDisabled}
				/>
				{this.props.tvBrand !== TvBrands.CEC_TV && (
					<CallButton
						buttonSelector='patientVolume'
						icon={this.state.volumeRange <= 0 ? 'volume_off' : 'volume_up'}
						isActive={true}
						tooltip="Change patient's volume"
						tooltipPosition='top'
						isDisabled={this.props.isDisabled}>
						<div className='volume-dropdown'>
							<span>{this.state.volumeRange > 0 ? this.state.volumeRange : 0}</span>
							<input
								type='range'
								id='start'
								name='volume'
								min='0'
								max='100'
								value={this.state.volumeRange}
								onChange={event => {
									this.serialCommandsTv(TvControlsEnums.VOLUME, event.target.value);
								}}
							/>
							<i className='material-icons-outlined'>{this.state.volumeRange > 0 ? 'volume_up' : 'volume_off'} </i>
						</div>
					</CallButton>
				)}
				{this.props.tvBrand === TvBrands.CEC_TV && (
					<CallButton
						buttonSelector='patientVolume'
						icon='volume_up'
						isActive={true}
						tooltip="Change patient's volume"
						tooltipPosition='top'
						isDisabled={this.props.isDisabled}>
						<div className='flex volume-dropdown cec-volume-dropdown' onClick={e => e.stopPropagation()}>
							<button type='button' onClick={() => this.changeVolume(false)}>
								<i className='material-icons'>remove</i>
							</button>
							<span>Volume</span>
							<button type='button' onClick={() => this.changeVolume(true)}>
								<i className='material-icons'>add</i>
							</button>
						</div>
					</CallButton>
				)}
				{this.props.tvBrand !== TvBrands.CEC_TV && (
					<CallButton
						buttonSelector='changePatientHDMIBtn'
						icon={this.state.hdmiIcon}
						isActive={true}
						onClick={() => {
							this.onHdmiTypeChanged.bind(this);
						}}
						tooltip="Switch patient's HDMI"
						tooltipPosition='top'
						isDisabled={this.props.isDisabled}>
						<ListGroup lists={this.hdmiTypeButtons} onItemClick={this.onHdmiTypeChanged} />
					</CallButton>
				)}
				{this.props.tvBrand === TvBrands.CEC_TV && (
					<CallButton
						buttonSelector='changePatientHDMIBtn'
						icon='sync_alt'
						isActive={true}
						onClick={async () => {
							await this.callManager.serialCommandsTv(
								SerialTVCommands.HDMI.SWITCH_HDMI1,
								false,
								this.state.deviceId,
								this.props.conferenceId,
								this.props.participantId
							);
						}}
						tooltip='Switch to HELLO Source'
						tooltipPosition='top'
						isDisabled={this.props.isDisabled}
					/>
				)}
			</div>
		);
	}
}

TvControls.contextType = SocketContext;
export default TvControls;
