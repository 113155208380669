import React, { Component } from 'react';
import classNames from 'classnames/bind';

class Tabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentIndex: null,
			links: this.props.links,
		};
	}

	componentDidMount() {
		const componentIndex = this.state.links.findIndex(item => item.active);
		this.setState({
			componentIndex,
		});
	}

	setActiveComponent = componentIndex => {
		const links = this.state.links.map((item, index) => {
			if (item.active) {
				item.active = false;
			}

			if (componentIndex === index) {
				item.active = true;
			}

			return {
				...item,
				active: componentIndex === index,
			};
		});

		this.setState({
			componentIndex: componentIndex,
			links,
		});

		if (this.props.onTabClick) {
			this.props.onTabClick(componentIndex);
		}
	};

	onTabChange = index => {
		this.setActiveComponent(index);
		if (this.props.onTabChange) {
			this.props.onTabChange(index);
		}
	};

	render() {
		return (
			<div>
				<ul className='tabs' data-cy='tabs'>
					{this.state.links.map((tab, index) => (
						<li data-test-id='tab-item' key={index} className={classNames(tab.active ? 'active' : '')} onClick={() => this.onTabChange(index)}>
							<a>{tab.link}</a>
						</li>
					))}
				</ul>
				{this.props.components && this.props.components[this.state.componentIndex]}
			</div>
		);
	}
}

export default Tabs;
