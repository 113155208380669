import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';
import { createNurseInvite, checkIfCompanyMemberExists } from 'api/users';

import { If, Then, Else } from 'react-if';
import Select from 'components/Common/FormElements/Select';
import Alert from 'components/Alert';

class InviteSuperUsers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			extraFields: 0,
		};
	}

	addExtraInputField = () => {
		const updatedExtraFields = this.state.extraFields + 1;
		this.setState({ extraFields: updatedExtraFields });
	};

	displayExtraFields = () => {
		const table = [];
		for (let i = 0; i < this.state.extraFields; i++) {
			table.push(<Field name={'email' + i} type='email' placeholder='Email' component={Input} />);
		}
		return table;
	};

	checkEmail = async val => {
		if (!val) {
			return true;
		}

		let response = false;
		const schema = Yup.string()
			.email()
			.required();
		if (await schema.isValid(val)) {
			try {
				await checkIfCompanyMemberExists(val)
					.then(() => (response = false))
					.catch(() => (response = true));
			} catch (error) {
				return false;
			}
		}
		return response;
	};

	render() {
		return (
			<div>
				<Formik
					validateOnBlur={false}
					initialValues={{
						healthSystem: '',
						email: '',
						email0: '',
						email1: '',
						email2: '',
						email3: '',
					}}
					onSubmit={(values, { resetForm }) => {
						this.props.onLoading();
						const emailObjs = [];
						Object.keys(values).forEach(key => {
							if (key !== 'healthSystem' && values[key] !== '') emailObjs.push(values[key]);
						});
						const healthSystem = this.props.healthSystems.find(hs => hs.id === values.healthSystem);
						createNurseInvite(emailObjs, this.props.inviteTypeId, [{ value: healthSystem.id, label: healthSystem.value }]).then(() => {
							this.props.onSuccess();
							resetForm({
								healthSystem: '',
								email: '',
								email0: '',
								email1: '',
								email2: '',
								email3: '',
							});
						});
					}}
					validationSchema={Yup.object().shape({
						email: Yup.string()
							.required('Email is required!')
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val => this.checkEmail(val)),
						email0: Yup.string()
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val1 => this.checkEmail(val1)),
						email1: Yup.string()
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val => this.checkEmail(val)),
						email2: Yup.string()
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val => this.checkEmail(val)),
						email3: Yup.string()
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val => this.checkEmail(val)),
						healthSystem: Yup.string().required('Health system is required!'),
					})}>
					{formikProps => {
						// bind the submission handler remotely
						this.props.bindSubmitForm(formikProps.submitForm);

						return (
							<Form>
								<h3>Add Super User</h3>
								<Field
									name='healthSystem'
									type='select'
									label='Enter Health System'
									placeholder='Select Health System'
									description={`Please select at least one health system in order to add a super user`}
									items={this.props.healthSystems}
									component={Select}
								/>
								<Field
									name='email'
									type='email'
									label='Enter Email'
									placeholder='Email'
									description={`Please write the emails of the super users you wish to invite.`}
									component={Input}
								/>
								{this.displayExtraFields()}
								<div>
									<If condition={this.state.extraFields === 4}>
										<Then>
											<span>You can only invite a maximum of 5 people at once!</span>
										</Then>
										<Else>
											<a
												className='action'
												data-cy='addNewField'
												onClick={() => this.addExtraInputField()}
												data-tooltip={'Add New Field'}
												data-position='right'>
												<i className='material-icons-outlined'>add_box</i>
											</a>
										</Else>
									</If>
								</div>
								<Alert
									display={this.props.loaded}
									message='The users were successfully invited to Banyan !'
									variant='success'
									onClose={this.props.onCloseAlert}
								/>
								<Alert display={this.props.error} message='Something went wrong! Please try again. ' variant='error' onClose={this.props.onCloseAlert} />
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export default InviteSuperUsers;
