import React, { useEffect, useState, useRef, useContext } from 'react';
import { getUserId, getUserProfile } from 'infrastructure/auth';
import { Alert, Button, Loader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getHealthSystemSubTreeBasic } from 'api/users';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { deleteMemberCallAvailability, addOrUpdateMemberCallAvailability, getNurseHealthSystemAvailabilities } from 'api/nursePooling';
import { NursePoolingOptions, PresenceStatusType } from 'constants/enums';
import { Wrapper, HealthSystemsListItem, TreeViewListWrapper } from 'containers/PoolingFlow/style';
import { getNursePoolingStatus } from 'infrastructure/helpers/commonHelpers';
import SocketEvents from 'constants/socket-events';
import { SocketContext } from 'io-client/SocketContext';

const processTreeData = hospitals => {
	const getNode = (node, icon) => ({
		value: node.id,
		label: node.name,
		icon: <i className='material-icons'>{icon}</i>,
		...(node.children && node.children.length > 0 && { children: node.children || [] }),
	});

	const mapChildren = (nodes, icon) => nodes.map(item => getNode(item, icon));

	return hospitals.map(hospital => {
		const departments = hospital.departments.map(department => ({
			...department,
			children: mapChildren(department.floors, 'money'),
		}));

		return {
			...getNode(hospital, 'business'),
			...(departments && departments.length > 0 && { children: mapChildren(departments, 'account_balance') }),
		};
	});
};

const PoolingFlow = props => {
	const [userProfile] = useState(getUserProfile);
	const [page, setPage] = useState(0);
	const [nursePoolingStatus, setNursePoolingStatus] = useState('');
	const [healthSystems, setHealthSystems] = useState([]);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(null);
	const [nodes, setNodes] = useState([]);
	const [filteredText, setFilteredText] = useState('');
	const [nodesFiltered, setFilteredNodes] = useState([]);
	const [checked, setChecked] = useState([]);
	const [expanded, setExpanded] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [initialValues, setInitialValues] = useState([]);
	const [alertErrorText, setAlertErrorText] = useState(null);

	const organization = useSelector(state => state.organization);
	const dispatch = useDispatch();

	const { current: answerExists } = useRef(getNursePoolingStatus());

	const _socket = useContext(SocketContext);

	useEffect(() => {
		const organizations = [...organization.allHealthSystems];
		const mapped = organizations.map(item => ({
			...item,
			isChecked: false,
		}));
		setHealthSystems(mapped);
	}, [organization.allHealthSystems]);

	useEffect(() => {
		if (answerExists && !props.isEdit) {
			props.changePage();
		}
	}, [props.isEdit, props.changePage]);

	useEffect(() => {
		const fetchData = async () => {
			const res = await getNurseHealthSystemAvailabilities();
			if (res.error) {
				setAlertErrorText('Something went wrong!');
				return;
			}

			setNursePoolingStatus(answerExists);
			setInitialValues(res.nurseAvailabilities);
		};
		const resetData = async () => {
			const deleteRes = await deleteMemberCallAvailability();
			if (deleteRes.error) {
				setAlertErrorText('Something went wrong!');
			}

			setInitialValues([]);
		};
		if (!answerExists && !props.isEdit) {
			resetData();
		}
		if (answerExists && props.isEdit) {
			fetchData();
		}
	}, [props.isEdit]);

	const getTree = async hsList => {
		setIsLoading(true);
		try {
			const tree = await getHealthSystemSubTreeBasic(hsList.id);
			setNodes(processTreeData(tree.regions.reduce((acc, value) => [...acc, ...value.hospitals], [])));
			setFilteredNodes(processTreeData(tree.regions.reduce((acc, value) => [...acc, ...value.hospitals], [])));
		} catch (error) {
			setAlertErrorText('Something went wrong!');
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (initialValues.length > 0) {
			const checkedTeamIds = [...checked];

			setHealthSystems(prevState => {
				const prevHs = [...prevState];

				prevHs.forEach(hs => {
					const initialValue = initialValues.find(val => val.healthSystemId === hs.id);
					// eslint-disable-next-line no-param-reassign
					hs.isChecked = initialValue.teams.length > 0;
					if (hs.isChecked) {
						checkedTeamIds.push({ healthSystemId: initialValue.healthSystemId, teamIds: initialValue.teams });
					}
				});
				return prevHs;
			});
			setChecked(checkedTeamIds);
		}
	}, [initialValues]);

	const filterNodes = (filtered, element) => {
		const children = (element.children || []).reduce(filterNodes, []);

		if (element.label.toLocaleLowerCase().indexOf(filteredText.toLocaleLowerCase()) > -1 || children.length) {
			filtered.push({ ...element, ...(children && children.length > 0 && { children }) });
		}

		return filtered;
	};

	useEffect(() => {
		if (!filteredText) {
			setFilteredNodes([...nodes]);
			return;
		}

		setFilteredNodes([...nodes].reduce(filterNodes, []));
	}, [filteredText]);

	useEffect(() => {
		if (selectedHealthSystem) {
			getTree(selectedHealthSystem);
			setFilteredText('');
		}
	}, [selectedHealthSystem]);

	const setNoReceiveCalls = async () => {
		const deleteRes = await deleteMemberCallAvailability();

		if (deleteRes.error) {
			setAlertErrorText('Something went wrong. Please try again!');
			return;
		}

		localStorage.setItem('nursePoolingStatus', nursePoolingStatus);
		const data = {
			userId: getUserId(),
			presenceStatusTypeId: PresenceStatusType.UNAVAILABLE,
			customMessage: null,
		};

		_socket.emit(SocketEvents.Client.UPDATE_USER_PRESENCE, data);

		props.changePage();
		setChecked([]);
	};

	const setCheckedBasedOnHs = (itemIds, hs) => {
		setChecked(prevState => {
			const prevChecked = [...prevState];

			const checkedItem = prevChecked.find(prev => prev.healthSystemId === hs.id);
			if (!checkedItem) {
				prevChecked.push({
					healthSystemId: hs.id,
					teamIds: itemIds,
				});
			} else {
				checkedItem.teamIds = itemIds;
			}
			return prevChecked;
		});
	};

	const setHealthSystemsList = item => {
		setHealthSystems(prevState => {
			const hsList = [...prevState];

			const hs = hsList.find(hsItem => hsItem === item);
			hs.isChecked = !hs.isChecked;
			if (!hs.isChecked) {
				setCheckedBasedOnHs([], hs);
			}

			return hsList;
		});
	};

	const flatMapArray = arr =>
		arr.flat().map(item => ({
			teamId: item,
			isAvailable: true,
		}));

	const submit = async () => {
		const flattedNewParams = flatMapArray([...checked].map(item => item.teamIds));
		const flattedInitialValues = flatMapArray([...initialValues].map(item => item.teams));
		flattedInitialValues.forEach(item => {
			if (!flattedNewParams.some(elem => elem.teamId === item.teamId)) {
				flattedNewParams.push({
					teamId: item.teamId,
					isAvailable: false,
				});
			}
		});

		if (flattedNewParams.length > 0) {
			const submitRes = await addOrUpdateMemberCallAvailability(flattedNewParams);
			if (submitRes.error) {
				setAlertErrorText('Something went wrong. Please try again!');
				return;
			}
		} else {
			const deleteRes = await deleteMemberCallAvailability();

			if (deleteRes.error) {
				setAlertErrorText('Something went wrong. Please try again!');
				return;
			}
		}

		localStorage.setItem('nursePoolingStatus', nursePoolingStatus);

		const data = {
			userId: getUserId(),
			presenceStatusTypeId: flattedNewParams.length > 0 ? PresenceStatusType.AVAILABLE : PresenceStatusType.UNAVAILABLE,
			customMessage: null,
		};

		_socket.emit(SocketEvents.Client.UPDATE_USER_PRESENCE, data);

		props.changePage();
		setPage(0);
		if (!props.isEdit) {
			setChecked([]);
		}
	};

	const showHealthSystems = () => {
		setPage(prev => prev + 1);
		const checkedHealthSystems = healthSystems.filter(item => item.isChecked);
		if (checkedHealthSystems.length > 0) {
			setSelectedHealthSystem(checkedHealthSystems[0]);
		}
	};

	const goBackToHsSelection = () => {
		setPage(prev => prev - 1);
		setSelectedHealthSystem(null);
		setNodes([]);
		setFilteredNodes([]);
	};

	const canSubmitAvailability = () => flatMapArray([...checked].map(item => item.teamIds)).length > 0;

	const checkedHealthSystems = healthSystems.filter(item => item.isChecked);
	const foundHealthSystemIndex = checkedHealthSystems.findIndex(item => selectedHealthSystem?.id === item.id);

	return (
		<>
			<Wrapper>
				{page === 0 && (
					<div>
						<h3>
							Hi, {userProfile.firstName} {userProfile.lastName}
						</h3>
						<p>Select whether you will be receiving calls from patients.</p>
						<div className='select-wrapper'>
							<select value={nursePoolingStatus} onChange={event => setNursePoolingStatus(event.target.value)}>
								<option value=''>Select options below.</option>
								<option value={NursePoolingOptions.YES}>I will be receiving calls from patients</option>
								<option value={NursePoolingOptions.NO}>I will not be receiving calls from patients</option>
							</select>
						</div>
						{nursePoolingStatus === NursePoolingOptions.YES.toString() && (
							<>
								<h3>Select Health Systems</h3>
								<p>Select the health systems you will be receiving calls from.</p>

								{healthSystems.map(item => {
									return (
										<HealthSystemsListItem>
											{item.name} <input type='checkbox' name={item.name} onChange={() => setHealthSystemsList(item)} checked={item.isChecked} />
										</HealthSystemsListItem>
									);
								})}
							</>
						)}
						{nursePoolingStatus !== NursePoolingOptions.NO.toString() && (
							<Button type='button' isDisabled={checkedHealthSystems.length === 0} onClick={showHealthSystems} text='Continue' />
						)}

						{nursePoolingStatus === NursePoolingOptions.NO.toString() && <Button type='button' onClick={setNoReceiveCalls} text='Submit' />}
					</div>
				)}

				{page === 1 && selectedHealthSystem && (
					<TreeViewListWrapper>
						<h3>
							{checkedHealthSystems.length > 1 && `Health Systems [${foundHealthSystemIndex + 1} / ${checkedHealthSystems.length}]`}
							{checkedHealthSystems.length === 1 && 'Health System'}
						</h3>

						<p>Select the level from which you will be receiving calls. </p>
						{isLoading && (
							<div>
								<Loader />
							</div>
						)}
						{!isLoading && (
							<>
								<h4>{selectedHealthSystem.name}</h4>
								<input
									className='filter-text'
									placeholder='Search...'
									type='text'
									value={filteredText}
									onChange={event => setFilteredText(event.target.value)}
								/>
								<CheckboxTree
									nodes={nodesFiltered}
									checked={checked.find(item => item.healthSystemId === selectedHealthSystem.id)?.teamIds || []}
									expanded={expanded}
									onCheck={itemIds => setCheckedBasedOnHs(itemIds, selectedHealthSystem)}
									onExpand={data => setExpanded(data)}
								/>
								<div className='flex'>
									{foundHealthSystemIndex + 1 === 1 && <Button type='button' variant='white' onClick={goBackToHsSelection} text='Back' />}
									{foundHealthSystemIndex + 1 !== 1 && (
										<Button
											type='button'
											variant='white'
											text='Previous'
											onClick={() => setSelectedHealthSystem(checkedHealthSystems[foundHealthSystemIndex - 1])}
										/>
									)}
									{foundHealthSystemIndex + 1 !== checkedHealthSystems.length && (
										<Button type='button' text='Next' onClick={() => setSelectedHealthSystem(checkedHealthSystems[foundHealthSystemIndex + 1])} />
									)}
									{foundHealthSystemIndex + 1 === checkedHealthSystems.length && (
										<Button isDisabled={!canSubmitAvailability()} type='button' text='Submit' onClick={submit} />
									)}
								</div>
							</>
						)}
					</TreeViewListWrapper>
				)}
			</Wrapper>
			<Alert display={alertErrorText} fixed={true} message={alertErrorText} variant='error' onClose={() => setAlertErrorText(null)} />
		</>
	);
};

export default PoolingFlow;
