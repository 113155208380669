import React from 'react';
import Grid from 'components/Grid';

const ActiveConference = () => (
	<div style={{ textAlign: 'center' }}>
		<Grid className='incoming-calls' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
			<div>
				<p data-test-id='callState-busy' className='incoming-call-text'>
					You&apos;re already in a call or monitoring patients. <br />
					Please try again after you end that session.
				</p>
			</div>
		</Grid>
	</div>
);

export default ActiveConference;
