import React, { Component } from 'react';
import Table from 'components/Table';
import Tabs from 'components/Tabs';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { withRouter } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { Modal, Form, Alert, Input, DescriptionBox, SkeletonLoader, TreeView, Dropdown, ListGroup, Badge } from 'components';
import { DeviceListLevel, DeviceCommands, UserRoles, AndroidVersions } from 'constants/enums';
import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import {
	getUnassociatedDevices,
	deleteDevice,
	sendCommand,
	getLatestAppRelease,
	forceUpdateDevice,
	addDeviceSettings,
	getDeviceList,
	getAllowList,
} from 'api/devices';
import { deleteOrgUnit } from 'api/organization';
import { findSectorById, getParentSector, getHierarchyFromBreadcrumb } from 'infrastructure/helpers/commonHelpers';
import { CSVLink } from 'react-csv';
import EditSectorModal from 'components/Common/EditSectorModal';
import { getUserRole } from 'infrastructure/auth';
import RebootLogExport from 'components/RebootLogExport';
import PullDeviceLogs from 'components/PullDeviceLogs';
import CheckBox from 'components/Common/CheckBox';
import moment from 'moment';

class Room extends Component {
	constructor(props) {
		super(props);
		this.exportAllowlistRef = React.createRef();
		this.exportDeviceDetailsRef = React.createRef();

		this.state = {
			isEditSectorModalOpen: false,
			isDevicesModalOpen: false,
			isVoiceCommandCallingOpen: false,
			isRoomLoaded: false,
			preSelected: null,
			devices: [],
			allowListList: [],
			helloDeviceId: null,
			unassociatedDevicesToSelect: [],
			isAddDeviceModalLoading: true,
			selectedDevice: {},
			selectedOrg: {},
			isDeleteDeviceModalOpen: false,
			isRebootDeviceModalOpen: false,
			isUpdateDeviceModalOpen: false,
			isDeleteOrgUnitModalOpen: false,
			parentSectorName: '',
			rebootReason: '',
			parentSectorType: '',
			isAddDeviceModalSubmitDisabled: true,
			isDeleteDeviceModalLoading: false,
			latestAppRelease: null,
			deviceIdInput: '',
			error: '',
			success: '',
			isVoiceCommandCallingModalLoading: false,
			sectorData: {},
		};

		this.csvDropdownItems = [
			{
				title: 'Device Details CSV',
				icon: 'save',
				id: 'save_device_csv',
			},
			{
				title: 'Endpoints Whitelist CSV',
				icon: 'check_box',
				id: 'save_allowlist_csv',
			},
		];
	}

	async componentDidMount() {
		this.loadRoom();
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { roomId } = this.props.match.params;
		if (
			roomId !== prevProps.match.params.roomId ||
			(!prevProps.showAssignDeviceModalData.assignCompleted && this.props.showAssignDeviceModalData.assignCompleted)
		) {
			this.loadRoom();
		}
	};

	getCodeNameByOsVersion = osVersion => {
		const version = AndroidVersions.find(({ apiLevel }) => apiLevel.includes(+osVersion));
		return version?.codeName ?? 'N/A';
	};

	exportAsCsv() {
		const room = getHierarchyFromBreadcrumb(this.props.breadcrumb);
		return this.state.devices.map(({ serialNumber, macAddress, appVersion, osVersion, firmwareRevision, ipAddress }) => ({
			'Serial Number': serialNumber,
			Room: room,
			'Mac Address': macAddress,
			'App Version:': appVersion,
			'Os Version': firmwareRevision,
			'Android Version': this.getCodeNameByOsVersion(osVersion),
			'Ip Addres': ipAddress,
		}));
	}

	exportAllowlistCsv() {
		return this.state.allowListList.map(({ id, domainUrl, createdDate, isAllowed, protocol, response }) => ({
			ID: id,
			'Domain Url': domainUrl,
			'Reported On': moment(createdDate).format('YYYY/MM/DD - HH:mm'),
			Allowed: isAllowed ? 'Allowed' : 'Denied',
			Protocol: protocol,
			Response: response,
		}));
	}

	loadRoom = async () => {
		const devicesList = [];
		let allowListList = [];
		const fetchUnassociatedDevices = getUnassociatedDevices();
		const fetchDeviceData = getDeviceList(DeviceListLevel.ROOM, this.props.match.params.roomId);
		const fetchLatestAppRelease = getLatestAppRelease(APP_CONFIG.deviceAppId);

		const [deviceData, unassociatedDevicesRes, latestAppRelease] = await Promise.all([fetchDeviceData, fetchUnassociatedDevices, fetchLatestAppRelease]);

		if (deviceData.length > 0) {
			const { solHelloDeviceId } = deviceData[0];
			const response = await getAllowList(solHelloDeviceId);
			if (response.error) {
				this.showErrorMessage(response.error.message);
			} else {
				allowListList = response;
			}
		}

		const currentSector = this.props.currentSectorLocation;
		let preSelected = {};
		if (currentSector.length) {
			preSelected = {
				[currentSector[0].name]: {
					[currentSector[0].subOptions[0].name]: {
						[currentSector[0].subOptions[0].subOptions[0].name]: {
							[currentSector[0].subOptions[0].subOptions[0].subOptions[0]]: {},
						},
					},
				},
			};
		}
		unassociatedDevicesRes.map(device => {
			devicesList.push({
				value: device.uid,
				id: device.solHelloDeviceId,
			});
		});

		this.setState({
			isRoomLoaded: true,
			preSelected,
			devices: deviceData,
			allowListList: allowListList.reverse(),
			unassociatedDevicesToSelect: devicesList,
			isAddDeviceModalLoading: false,
			latestAppRelease,
			isVoiceCommandCallingModalLoading: false,
		});
	};

	displayDevices = () => {
		if (!this.state.devices.length) {
			return [];
		}

		const userRole = getUserRole();
		return this.state.devices.map(
			({ serialNumber, solHelloDeviceId, appVersion, osVersion, firmwareRevision, macAddress, isOnline, voiceCommandCalling, ipAddress }) => {
				return {
					roomName: this.getCurrentRoom()?.roomName ?? 'N/A',
					serialNumber: serialNumber ?? 'N/A',
					macAddress: macAddress ?? 'N/A',
					appVersion: <div className='app-version'>{appVersion || 'N/A'}</div>,
					firmwareRevision: firmwareRevision ?? 'N/A',
					osVersion: this.getCodeNameByOsVersion(osVersion),
					ipAddress,
					voiceCommandCalling: voiceCommandCalling ? 'Active' : 'Not active',
					actions: (
						<div className='wrapped'>
							{userRole === UserRoles.ADMIN && (
								<i
									className='material-icons-outlined boxed-icon pool-icon'
									data-cy='voiceCommandCalling'
									id={solHelloDeviceId}
									data-tooltip='Voice Command Calling'
									data-position='top'
									style={{ backgroundColor: 'var(--yellow-1)' }}
									onClick={() => this.toggleVoiceCommandCallingModal({ deviceId: solHelloDeviceId, serialNumber, voiceCommandCalling })}>
									supervisor_account
								</i>
							)}
							<PullDeviceLogs deviceId={solHelloDeviceId} isOnline={isOnline} />
							<i
								className='material-icons-outlined boxed-icon'
								data-cy='rebootDeviceBtn'
								id={solHelloDeviceId}
								data-tooltip='Reboot device'
								data-position='top'
								style={{ background: '#718093' }}
								onClick={() => this.toggleRebootDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
								refresh
							</i>
							<RebootLogExport solHelloDeviceId={solHelloDeviceId} serialNumber={serialNumber} />
							{userRole === UserRoles.ADMIN && (
								<>
									<i
										className='material-icons boxed-icon'
										data-cy='reassignDeviceBtn'
										id={solHelloDeviceId}
										data-tooltip='Reassign Device'
										data-position='top'
										style={{ backgroundColor: 'var(--blue-2)' }}
										onClick={() => this.props.toggleAssignDeviceModal({ deviceId: solHelloDeviceId, show: true })}>
										swap_vert
									</i>
									<i
										className='material-icons-outlined boxed-icon'
										data-cy='unassignDeviceBtn'
										id={solHelloDeviceId}
										data-tooltip='Unassign device'
										data-position='top'
										onClick={() => this.toggleDeleteDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
										delete
									</i>
								</>
							)}
						</div>
					),
				};
			}
		);
	};

	displayAllowlist = () => {
		if (!this.state.allowListList.length) {
			return [];
		}

		return this.state.allowListList.map(({ id, domainUrl, createdDate, isAllowed, protocol, response }) => ({
			number: id,
			domainUrl,
			createdDate: moment(createdDate).format('YYYY/MM/DD - HH:mm'),
			isAllowed: isAllowed ? 'Allowed' : 'Denied',
			protocol,
			response,
		}));
	};

	unAssignDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			this.setState({
				isDeleteDeviceModalLoading: true,
			});
			await deleteDevice(deviceId);
		}

		this.loadRoom();
		this.toggleDeleteDeviceModal();
		this.props.getTreeData();

		this.setState({
			isDeleteDeviceModalLoading: false,
		});
	};

	rebootDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			await sendCommand(deviceId, DeviceCommands.REBOOT, this.state.rebootReason);
		}

		this.toggleRebootDeviceModal();
	};

	requestAllowlist = async () => {
		const deviceId = this.state.devices[0].solHelloDeviceId;

		if (deviceId) {
			const response = await sendCommand(deviceId, DeviceCommands.REQUEST_ALLOWLIST_CHECK, this.state.allowListReason);
			if (!response.data.hasSucceeded) {
				this.showErrorMessage('Something went wrong');
				return;
			}
		}
		this.showSuccessMessage('Your request for endpoint whitelisting is being processed. Please refresh the page to see the updated version.');
		this.loadRoom();
	};

	forceUpdateDevice = async () => {
		const {
			selectedDevice: { deviceId, appVersion },
			latestAppRelease: { install_url: appInstallUrl, short_version: latestAppVersion },
		} = this.state;

		if (appVersion && latestAppVersion && appVersion !== latestAppVersion) {
			await forceUpdateDevice({
				deviceId,
				dynamicData: appInstallUrl,
			});
		}

		this.toggleUpdateDeviceModal();
	};

	deleteOrgUnitSubmit = async () => {
		await deleteOrgUnit(this.state.selectedOrg.level, this.state.selectedOrg.id);
		this.moveToParentSector(this.state.selectedOrg.id);
		this.toggleDeleteOrgUnitModal();
		this.props.getTreeData();
	};

	moveToParentSector = currentSectorId => {
		const selectedSector = findSectorById(this.props.treeData.tree, currentSectorId);
		const parentSector = getParentSector(this.props.treeData.tree, selectedSector);
		parentSector.isSelected = true;
		this.props.history.push(parentSector.link);
		this.props.updateBreadcrumb(parentSector.breadcrumb);
	};

	toggleDeleteDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isDeleteDeviceModalOpen: !this.state.isDeleteDeviceModalOpen,
		});
	};

	toggleRebootDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isRebootDeviceModalOpen: !this.state.isRebootDeviceModalOpen,
			rebootReason: '',
		});
	};

	toggleVoiceCommandCallingModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isVoiceCommandCallingOpen: !this.state.isVoiceCommandCallingOpen,
		});
	};

	toggleUpdateDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isUpdateDeviceModalOpen: !this.state.isUpdateDeviceModalOpen,
		});
	};

	toggleDeleteOrgUnitModal = (selectedOrg = {}) => {
		this.setState({
			selectedOrg,
			isDeleteOrgUnitModalOpen: !this.state.isDeleteOrgUnitModalOpen,
		});
	};

	findSelectedDeviceById = (devices, id) => {
		let device;
		devices.forEach((item, index) => {
			if (item.value === id) {
				device = {
					id: item.id,
					value: item.value,
					index: index,
				};
			}
		});
		return device;
	};

	setDeviceId = event => {
		const device = this.findSelectedDeviceById(this.state.unassociatedDevicesToSelect, event.target.value);
		if (!device) {
			this.setState({
				helloDeviceId: null,
				isAddDeviceModalSubmitDisabled: false,
				deviceIdInput: event.target.value,
			});
			return;
		}
		this.setState({
			helloDeviceId: device.id,
			isAddDeviceModalSubmitDisabled: false,
			deviceIdInput: event.target.value,
		});
	};

	showErrorMessage = (errorMessage = 'Device ID not found') => {
		this.setState({
			error: errorMessage,
		});

		setTimeout(() => {
			this.setState({
				error: '',
			});
		}, 3000);
	};

	showSuccessMessage = (message = 'Successful ') => {
		this.setState({
			success: message,
		});

		setTimeout(() => {
			this.setState({
				success: '',
			});
		}, 6000);
	};

	assignDevice = async evt => {
		evt.preventDefault();
		if (!this.state.helloDeviceId) {
			this.showErrorMessage();
			return;
		}

		this.setState({
			isAddDeviceModalLoading: true,
		});
		const { hospitalId, departmentId, floorId, roomId } = this.props.match.params;
		const url = `/v1/organizations/${APP_CONFIG.companyId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/devices`;
		await gatewayApi.put(url, {
			devices: [this.state.helloDeviceId],
		});
		this.setState({
			isAddDeviceModalLoading: false,
			deviceIdInput: '',
		});
		this.toggleDevicesModal();
		this.props.getTreeData();
		this.loadRoom();
	};

	voiceCommandCallingSubmit = async () => {
		this.setState({
			isVoiceCommandCallingModalLoading: true,
		});

		const { selectedDevice } = this.state;

		const setting = { solHelloDeviceId: +selectedDevice.deviceId, settingValue: `${selectedDevice.voiceCommandCalling}` };
		await addDeviceSettings([setting]);
		this.loadRoom();
		this.toggleVoiceCommandCallingModal();
	};

	toggleRoomModal = () => {
		this.setState(
			{
				isRoomModalOpen: !this.state.isRoomModalOpen,
			},
			() => {
				if (this.state.isRoomModalOpen) {
					const sector = findSectorById(this.props.treeData.tree, this.props.match.params.roomId);
					const sectorParent = getParentSector(this.props.treeData.tree, sector);
					this.setState({
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
					});
				}
			}
		);
	};

	toggleDevicesModal = () => {
		this.setState(
			{
				isDevicesModalOpen: !this.state.isDevicesModalOpen,
			},
			() => {
				if (this.state.isDevicesModalOpen) {
					this.setState({
						isAddDeviceModalSubmitDisabled: true,
					});
				} else {
					this.setState({
						deviceIdInput: '',
					});
				}
			}
		);
	};

	setInputValues = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	getCurrentRoom() {
		const hospitals = this.props.treeData.tree;
		const room = findSectorById(hospitals, this.props.match.params.roomId);
		return {
			sectorName: room ? room.name : '',
			sectorId: room ? room.roomId : '',
			roomName: room ? room.name : '',
			hospitalId: room ? room.hospitalId : '',
			departmentId: room ? room.departmentId : '',
			floorId: room ? room.floorId : '',
			roomId: room ? room.roomId : '',
			sectorType: room ? room.type : '',
		};
	}

	toggleEditSectorModal = data => {
		this.setState(
			{
				isEditSectorModalOpen: !this.state.isEditSectorModalOpen,
			},
			() => {
				if (this.state.isEditSectorModalOpen) {
					if (!data) {
						return;
					}
					const sector = findSectorById(this.props.treeData.tree, data[`${data.sectorType}Id`]);
					this.props.setCurrentSectorLocation(sector.treeLocation);
					const sectorParent = getParentSector(this.props.treeData.tree, sector);
					this.setState({
						sectorData: data,
						sectorLocation: sector.treeLocation,
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
					});
				}
			}
		);
	};

	updateTree = () => {
		this.props.getTreeData();
		this.toggleEditSectorModal();
	};

	toggleVoiceCommandCalling = () => {
		const { selectedDevice } = this.state;
		selectedDevice.voiceCommandCalling = !selectedDevice.voiceCommandCalling;
		this.setState({ selectedDevice });
	};

	handleCsvDropdownClick = item => {
		if (item.id === 'save_device_csv') {
			this.exportDeviceDetailsRef.current.link.click();
		} else if (item.id === 'save_allowlist_csv') {
			this.exportAllowlistRef.current.link.click();
		}
	};

	render() {
		const room = this.getCurrentRoom();
		const userRole = getUserRole();
		return (
			<div className='users device-details'>
				<If condition={!!this.state.isRoomLoaded}>
					<Then>
						<h3>
							<span>{room.roomName}</span>
							{userRole === UserRoles.ADMIN && (
								<>
									<Button text='Edit Room' size='medium' onClick={() => this.toggleEditSectorModal(room)} />
									<Button
										text='Delete Room'
										size='medium'
										icon='delete'
										variant='red'
										onClick={() => this.toggleDeleteOrgUnitModal({ level: DeviceListLevel.ROOM, id: this.props.match.params.roomId, name: room.roomName })}
									/>
									{!this.state.devices.length && <Button text='Add Device' size='medium' variant='white' onClick={this.toggleDevicesModal} />}
									{this.state.devices.length > 0 && (
										<Button text='Endpoint Whitelisting' size='medium' icon='check' variant='yellow' onClick={this.requestAllowlist} />
									)}
									{this.state.devices.length > 0 && (
										<Dropdown
											closeDropdownOnItemClick
											position='bottom'
											title={<Badge size='large' variant='darkgreen' text='Export as CSV' icon='arrow_drop_down' />}>
											<ListGroup lists={this.csvDropdownItems} onItemClick={(event, item) => this.handleCsvDropdownClick(item)} />
										</Dropdown>
									)}
								</>
							)}
							<CSVLink
								ref={this.exportDeviceDetailsRef}
								data-cy='exportAsCSVBtn'
								data={this.exportAsCsv()}
								filename={`${this.state.devices[0]?.serialNumber}-device_details_${moment().format('YYYY-MM-DD-HH:mm')}.csv`}
							/>
							<CSVLink
								ref={this.exportAllowlistRef}
								data-cy='exportAsCSVBtn'
								data={this.exportAllowlistCsv()}
								filename={`${this.state.devices[0]?.serialNumber}-endpoint_whitelisting_${moment().format('YYYY-MM-DD-HH:mm')}.csv`}
							/>
						</h3>
						<DescriptionBox data={[{ title: 'Enrolled Devices', description: this.state.devices ? this.state.devices.length : 0 }]} />
						<Tabs
							links={[{ link: 'Devices', active: true }, { link: 'Endpoint Whitelisting' }]}
							components={[
								<Table
									headers={[
										{ title: 'Room Name' },
										{ title: 'Serial Number' },
										{ title: 'MAC Address' },
										{ title: 'App Version' },
										{ title: 'OS Version' },
										{ title: 'Android Version' },
										{ title: 'IP Address' },
										{ title: 'Voice Command Calling' },
										{ title: '' },
									]}
									rows={this.displayDevices()}
									isEditable={false}
								/>,
								<Table
									headers={[
										{ title: 'ID' },
										{ title: 'Domain Url' },
										{ title: 'Reported On' },
										{ title: 'Allowed' },
										{ title: 'Protocol' },
										{ title: 'Response' },
									]}
									rows={this.displayAllowlist()}
								/>,
							]}
						/>
					</Then>
					<Else>
						<SkeletonLoader rows={10} padding='20px 0' />
					</Else>
				</If>
				<EditSectorModal
					onEditSector={this.updateTree}
					onModalClose={this.toggleEditSectorModal}
					sectorLocation={this.state.sectorLocation}
					isEditSectorModalOpen={this.state.isEditSectorModalOpen}
					sectorData={this.state.sectorData}
					parentSectorName={this.state.parentSectorName}
					parentSectorType={this.state.parentSectorType}
				/>
				<Modal
					modalSelector='assignDeviceModal'
					isLoading={this.state.isAddDeviceModalLoading}
					display={this.state.isDevicesModalOpen}
					position='right'
					onModalClose={this.toggleDevicesModal}
					onModalSubmit={this.assignDevice}
					isSubmitDisabled={this.state.isAddDeviceModalSubmitDisabled}>
					<Form title='Room Device' onSubmit={this.assignDevice}>
						<div className='input'>
							<p className='label'>Assign Device</p>
							<p>Select a serial number to associate with this room.</p>
							<input value={this.state.deviceIdInput} placeholder='Select Serial Number' list='devices' onChange={this.setDeviceId} />
							<datalist id='devices'>
								{this.state.unassociatedDevicesToSelect.map(({ value, id }) => (
									<option>{value}</option>
								))}
							</datalist>
						</div>
						{this.state.preSelected && <TreeView data={this.props.currentSectorLocation} preSelected={this.state.preSelected} />}
						<Alert
							alertSelector='deviceNotFoundAlertMessage'
							display={this.state.error}
							fixed={true}
							hideCloseButton={true}
							message={this.state.error}
							variant='error'
						/>
					</Form>
				</Modal>

				<Modal
					modalSelector='voiceCommandCallingModal'
					isLoading={this.state.isVoiceCommandCallingModalLoading}
					display={this.state.isVoiceCommandCallingOpen}
					position='right'
					onModalClose={this.toggleVoiceCommandCallingModal}
					onModalSubmit={this.voiceCommandCallingSubmit}>
					<Form title='Voice command calling'>
						<p>
							You can use this option to activate voice command calling in different levels of a hospital. Through this option patient will be able to call
							nurses by saying &quot;Call Nurse&quot; or &quot;Call the nurse&quot;.
						</p>
						<br />
						<CheckBox
							name='Active'
							id={this.state.selectedDevice.deviceId}
							checked={this.state.selectedDevice.voiceCommandCalling}
							onChange={this.toggleVoiceCommandCalling}
						/>
					</Form>
				</Modal>

				<Modal
					modalSelector='unassignDeviceModal'
					display={this.state.isDeleteDeviceModalOpen}
					isLoading={this.state.isDeleteDeviceModalLoading}
					position='center'
					submitButtonText='Unassign'
					onModalSubmit={this.unAssignDevice}
					onModalClose={this.toggleDeleteDeviceModal}>
					<Form title='Unassign device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to unassign device {this.state.selectedDevice.serialNumber}? Neither you or patient won't be able to make any calls.</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='rebootDeviceModal'
					display={this.state.isRebootDeviceModalOpen}
					position='center'
					submitButtonText='Reboot'
					onModalSubmit={this.rebootDevice}
					onModalClose={this.toggleRebootDeviceModal}>
					<Form title='Reboot device' onSubmit={event => event.preventDefault()}>
						<p>Why do you want to reboot device {this.state.selectedDevice.serialNumber}?</p>
						<Input
							type='text'
							value={this.state.rebootReason}
							validationOptions={{}}
							placeholder='Describe the reboot reason. . .'
							onChange={e => this.setState({ rebootReason: e.target.value })}
						/>
					</Form>
				</Modal>

				<Modal
					display={this.state.isUpdateDeviceModalOpen}
					position='center'
					submitButtonText='Update'
					onModalSubmit={this.forceUpdateDevice}
					onModalClose={this.toggleUpdateDeviceModal}>
					<Form title='Force update device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to force update device {this.state.selectedDevice.serialNumber}?</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='deleteSectorModal'
					display={this.state.isDeleteOrgUnitModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteOrgUnitSubmit}
					onModalClose={this.toggleDeleteOrgUnitModal}>
					<Form title='Warning' onSubmit={event => event.preventDefault()}>
						<p>
							Are you sure you want to delete {this.state.selectedOrg.name}? Any devices related to it will be deleted and neither Virtual Care Providers or
							patients won't be able to make any calls.
						</p>
					</Form>
				</Modal>
				<Alert hideCloseButton alertSelector='SuccessRequest' position='top' display={this.state.success} message={this.state.success} variant='success' />
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(Room));
