import React, { Component } from 'react';
import { If, Then, Else } from 'react-if';

import { Grid, Form, Input, Button } from 'components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionCreators } from 'state/organization/actions';
import { bindActionCreators } from 'redux';
import { createHealthSystem } from 'api/healthSystems';
import { treeSectorNameMaxCharacters } from 'constants/global-variables';

class CreateHealthSystem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			healthSystemName: '',
			healthSystemNameError: '',
			healthSystemRegions: [],
			regionNames: [''],
			isHealthSystemCreated: false,
			isLoading: false,
			regionError: '',
		};
	}

	addNewRegion = () => {
		let newRegionNames = [...this.state.regionNames];
		newRegionNames.push('');
		this.setState({
			regionNames: newRegionNames,
		});
	};

	deleteRegion = index => {
		let healthSystemRegions = [...this.state.healthSystemRegions];
		let regionNames = [...this.state.regionNames];
		regionNames[index] = '';
		healthSystemRegions.splice(index, 1);

		if (this.state.regionNames.length > 1) {
			regionNames.splice(index, 1);
		}
		this.setState({
			healthSystemRegions: healthSystemRegions,
			regionNames: regionNames,
			regionError: '',
		});
	};

	setRegions = event => {
		let healthSystemRegions = [...this.state.healthSystemRegions];
		let regionNames = [...this.state.regionNames];

		healthSystemRegions[event.target.name] = event.target.value;
		regionNames[event.target.name] = event.target.value;

		this.setState({
			healthSystemRegions: healthSystemRegions,
			regionNames: regionNames,
		});
	};

	setHealthSystemName = event => {
		this.setState({
			healthSystemName: event.target.value,
		});
	};

	getHealthSystemRegions = () => this.state.healthSystemRegions.map(value => ({ name: value }));

	checkIfHealthSystemExists = hsName => this.props.allHealthSystems.some(healthSystem => healthSystem.name.toLowerCase() === hsName.toLowerCase());

	hasDuplicate = () => this.state.healthSystemRegions.length !== new Set(this.state.healthSystemRegions).size;

	hasRegionsValidLength = () => this.state.healthSystemRegions.every(region => region.length <= treeSectorNameMaxCharacters);

	createHealthSystem = async event => {
		event.preventDefault();
		if (this.checkIfHealthSystemExists(this.state.healthSystemName)) {
			this.setState({
				healthSystemNameError: 'Health system with this name already exists',
			});
			return;
		}

		if (this.state.healthSystemName.length > treeSectorNameMaxCharacters) {
			this.setState({
				healthSystemNameError: `Name can't be longer than ${treeSectorNameMaxCharacters} characters`,
			});
			return;
		}

		if (this.hasDuplicate()) {
			this.setState({
				regionError: `Location(s) name can't be duplicated`,
			});
			return;
		}

		if (!this.hasRegionsValidLength()) {
			this.setState({
				regionError: `Location(s) name can't be longer than ${treeSectorNameMaxCharacters} characters`,
			});
			return;
		}

		this.setState({
			isLoading: true,
		});
		const healthSystemRegions = this.getHealthSystemRegions();
		await createHealthSystem(this.state.healthSystemName, healthSystemRegions);
		this.setState({
			isHealthSystemCreated: true,
			healthSystemNameError: '',
			isLoading: false,
		});
	};

	handleCancelClick = () => {
		this.props.history.push('/');
	};

	render() {
		return (
			<If condition={this.state.isHealthSystemCreated === false}>
				<Then>
					<Grid className='create-hs' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' rows='auto'>
						<Form title='Create a health system profile' onSubmit={event => this.createHealthSystem(event)}>
							<Input
								type='text'
								label='Health system name'
								validationOptions={{ required: true }}
								onKeyUp={this.setHealthSystemName}
								placeholder='Health system name'
								variant='filled'
								bottomSpace='15px'
								error={this.state.healthSystemNameError}
							/>
							<p>Add locations for this health system</p>
							{this.state.regionNames.map((item, index) => (
								<div className='add-remove-region' key={index}>
									<Input
										type='text'
										placeholder='Location name'
										name={index}
										validationOptions={{ required: true }}
										onChange={this.setRegions}
										bottomSpace='15px'
										variant='filled'
										value={this.state.regionNames[index]}
									/>
									<i className='material-icons' onClick={() => this.deleteRegion(index)}>
										delete
									</i>
								</div>
							))}
							<p>{this.state.regionError}</p>
							<div className='create-hs__add' data-cy='addRegion'>
								<Button text='Add new +' variant='transparent' onClick={this.addNewRegion} />
							</div>

							<div className='create-hs__add' data-cy='createHSActions'>
								<Button className='cancel-btn' text='Cancel' variant='white' onClick={this.handleCancelClick} />
								&nbsp;&nbsp;
								<Button className='save-btn' type='submit' text='Save' isLoading={this.state.isLoading} />
							</div>
						</Form>
					</Grid>
				</Then>
				<Else>
					<div className='create-hs-success' data-cy='createHSMessage'>
						<span>
							<i className='material-icons create-hs-success-icon'>done</i>
						</span>
						<h3>Your health system profile has been created successfully.</h3>
						<span>
							<Button text=' Go Back to Banyan App' variant='blue' onClick={this.handleCancelClick} />
						</span>
					</div>
				</Else>
			</If>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(CreateHealthSystem));
