import { NursePoolingActionTypes } from 'constants/action-types';

const initialState = {
	currentHealthSystem: null,
};

export const nursePooling = (state, action) => {
	state = state || initialState;

	if (action.type === NursePoolingActionTypes.SET_CURRENT_HEALTH_SYTEM) {
		return { ...state, currentHealthSystem: action.payload };
	}

	return state;
};
