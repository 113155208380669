import React, { Component } from 'react';
import { Grid, Button, Avatar } from 'components';
import { SocketContext } from 'io-client/SocketContext';
import SocketEvents from 'constants/socket-events';
import { incomingCallSound, stopIncomingCallSound, dropSound } from 'components/CallSounds';
import { connect } from 'react-redux';
import { defaultDocumentTitle } from 'constants/global-variables';
import { getUserProfile, setUserProfile } from 'infrastructure/auth';
import { MediaPermissions, StreamError, MediaTypes } from 'constants/enums';
import { askForPermission, checkForPermission, checkIfMediaDevicesPlugged } from 'infrastructure/helpers/commonHelpers';

class IncomingCall extends Component {
	constructor(props, socket) {
		super(props, socket);
		this.state = {
			showSelf: false,
		};
		this.socket = socket;
		this.incomingConferenceInfo = null;
		this.incomingCallNotification = null;
		this.notificationPermission = {
			GRANTED: 'granted',
			DENIED: 'denied',
		};
		this.micStatus = null;
		this.incomingCallAudio = null;
	}

	async componentDidMount() {
		this.micStatus = await checkForPermission(MediaTypes.MICROPHONE);
		this.micStatus.onchange = this.onDevicePermissionChange;
		this.addOnUnloadEvent();
		this.addSocketListeners();
	}

	componentWillUnmount() {
		this.removeSocketListeners();
	}

	componentDidUpdate() {
		if (this.state.showSelf) {
			document.title = `Patient is calling - ${defaultDocumentTitle}`;
		} else {
			document.title = `${this.props.notifications.notificationsCounter ? `(${this.props.notifications.notificationsCounter})` : ''} ${defaultDocumentTitle}`;
		}
	}

	startIncomingCallTimer = () => {
		this.incomingCallTimer = setTimeout(() => {
			if (this.incomingConferenceInfo) {
				this.socket.emit(SocketEvents.Conference.NOT_ANSWERING, {
					participantId: this.incomingConferenceInfo.participantId,
					conferenceId: this.incomingConferenceInfo.conferenceId,
				});
				this.sendMissedCallNotification();
			}
			this.hideIncomingCall();
		}, 15000);
	};

	addOnUnloadEvent = () => {
		window.addEventListener('beforeunload', () => {
			if (this.incomingConferenceInfo) {
				this.socket.emit(SocketEvents.Conference.DECLINE, {
					conferenceId: this.incomingConferenceInfo.conferenceId,
					participantId: this.incomingConferenceInfo.participantId,
				});
			}
			this.hideIncomingCall();
		});
	};

	onAnsweredElsewhere = () => {
		this.hideIncomingCall();
	};

	onInitiatorLeft = async () => {
		this.hideIncomingCall();
		await dropSound();
	};

	onIncomingCall = async conferenceInfo => {
		if (!this.incomingCallAudio || !this.incomingCallAudio.paused) {
			this.incomingCallAudio = await incomingCallSound();
		}
		this.incomingConferenceInfo = conferenceInfo;
		this.setState({ showSelf: true }, () => {
			this.startIncomingCallTimer();
			this.incomingCallNotification = this.sendIncomingCallNotification();
		});
	};

	addSocketListeners = () => {
		if (!this.socket) return;

		this.socket
			.on(SocketEvents.Conference.ON_ANSWERED_ELSEWHERE, this.onAnsweredElsewhere)
			.on(SocketEvents.Conference.ON_INITIATOR_LEFT, this.onInitiatorLeft)
			.on(SocketEvents.Conference.ON_INCOMING, this.onIncomingCall);
	};

	removeSocketListeners = () => {
		if (!this.socket) return;

		this.socket
			.off(SocketEvents.Conference.ON_ANSWERED_ELSEWHERE, this.onAnsweredElsewhere)
			.off(SocketEvents.Conference.ON_INITIATOR_LEFT, this.onInitiatorLeft)
			.off(SocketEvents.Conference.ON_INCOMING, this.onIncomingCall);
	};

	onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			this.props.setStreamPermissionMessage(null);
		}
	};

	declineIncomingCall = () => {
		if (this.incomingConferenceInfo) {
			this.socket.emit(SocketEvents.Conference.DECLINE, {
				conferenceId: this.incomingConferenceInfo.conferenceId,
				participantId: this.incomingConferenceInfo.participantId,
			});
		}
		this.hideIncomingCall();
	};

	acceptIncomingCall = async () => {
		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (!pluggedDevices.camera || !pluggedDevices.microphone) {
			this.props.setStreamPermissionMessage({
				component: 'modal',
				type: !pluggedDevices.camera ? StreamError.CAMERA_NOT_FOUND.type : StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return;
		}

		let permissionRes = await askForPermission({ audio: true });
		if (permissionRes.error) {
			if (permissionRes.error.name === 'NotReadableError') {
				if (permissionRes.error.message.includes('audio')) {
					this.props.setStreamPermissionMessage({
						component: 'modal',
						type: StreamError.MICROPHONE_NOT_FOUND.type,
					});
				} else {
					this.openCallWindow();
				}
			} else {
				this.props.setStreamPermissionMessage({
					component: 'popup',
					type: StreamError.MICROPHONE_BLOCKED.type,
				});
			}
		} else if (permissionRes.permission === MediaPermissions.GRANTED) {
			this.openCallWindow();
		} else {
			if (this.micStatus.state === MediaPermissions.GRANTED) {
				this.openCallWindow();
				return;
			}

			if (this.micStatus.state === MediaPermissions.PROMPT && this.micStatus.state !== MediaPermissions.DENIED) {
				this.props.setStreamPermissionMessage({
					component: 'modal',
					type: StreamError.MICROPHONE_BLOCKED.type,
				});
			}

			if (this.micStatus.state === MediaPermissions.PROMPT) {
				this.props.setStreamPermissionMessage(null);
			}
		}
	};

	openCallWindow = () => {
		const conferenceName = this.incomingConferenceInfo.conferenceName.split('-')[1].trim();
		localStorage.setItem('incomingConferenceInfo', JSON.stringify(this.incomingConferenceInfo));

		let userInfo = getUserProfile();
		userInfo = { ...userInfo, incomingCallsDisabled: true };
		setUserProfile(userInfo);

		window.open(`call/audio/${this.incomingConferenceInfo.from.objectId}/${encodeURIComponent(conferenceName)}/join`, '_blank');
		this.hideIncomingCall();
		this.incomingCallNotification.then(notification => {
			if (notification) {
				notification.close();
			}
		});
	};

	getDeviceName = () => {
		return this.incomingConferenceInfo.from.name.replace(/[,]/g, ' >').replace(/[-]/g, '>');
	};

	sendIncomingCallNotification = () => {
		return this.sendNotification({
			title: 'Incoming call',
			body: this.getDeviceName(),
			tag: `incoming-${this.incomingConferenceInfo.conferenceId}`,
			renotify: true,
		});
	};

	sendMissedCallNotification = () => {
		this.sendNotification(
			{
				title: 'Missed call',
				body: this.getDeviceName(),
				tag: `missed-${this.incomingConferenceInfo.conferenceId}`,
				renotify: true,
			},
			() => {
				//Its only a temporary solution since solution with redux requires some refactoring
				document.querySelector('#notifications > a').click();
			}
		);
	};

	sendNotification = async (options, onClickCallback) => {
		let newNotification;
		if (Notification.permission !== this.notificationPermission.GRANTED) {
			await Notification.requestPermission();
		}
		if (Notification.permission === this.notificationPermission.GRANTED) {
			newNotification = new Notification(options.title, {
				body: options.body,
				tag: options.tag,
				renotify: options.renotify,
			});
			newNotification.onclick = function() {
				window.focus();
				onClickCallback();
			};
		}
		return newNotification;
	};

	clearIncomingCallTimer = () => {
		if (this.incomingCallTimer) {
			clearTimeout(this.incomingCallTimer);
			this.incomingCallTimer = null;
		}
	};

	hideIncomingCall = () => {
		if (this.incomingConferenceInfo) {
			this.incomingConferenceInfo = null;
			this.clearIncomingCallTimer();
			this.setState({ showSelf: false });
		}

		stopIncomingCallSound();
		this.incomingCallAudio = null;
	};

	render() {
		const { showSelf } = this.state;
		return (
			<div style={{ textAlign: 'center' }}>
				{showSelf && (
					<Grid className='incoming-calls' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
						<div>
							<Avatar src={this.props.src} size='large' fullName='' pulseAnimation='incoming-call-img' />
							<p className='incoming-call-text'>{this.incomingConferenceInfo.from.name}</p>
							<footer>
								<Button onClick={this.declineIncomingCall} icon='call_end' background='red' borderRadius='30px' marginRight='15px' />
								<Button onClick={this.acceptIncomingCall} icon='call' background='#22cb36' borderRadius='30px' />
							</footer>
						</div>
					</Grid>
				)}
			</div>
		);
	}
}

IncomingCall.contextType = SocketContext;

export default connect(state => state)(IncomingCall);
