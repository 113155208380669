import React from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import { editSuperUserHealthSystem } from 'api/users';
import { Alert } from 'components';
import Select from 'components/Common/FormElements/Select';
import { Roles } from 'constants/enums';

const SuperUserHealthSystem = props => (
	<div>
		<Formik
			validateOnBlur={false}
			enableReinitialize={true}
			initialValues={{
				healthSystem: props.member.healthSystems.length !== 0 ? props.member.healthSystems[0].id : '',
			}}
			onSubmit={values => {
				props.onLoading('healthSystemAddition');
				const pastHealthSystem = props.member.healthSystems.length !== 0 ? props.member.healthSystems[0].id : null;
				editSuperUserHealthSystem(props.member.userInfo.id, values.healthSystem, pastHealthSystem).then(res => {
					props.userUpdated(props.member.userInfo.userId, props.member.userInfo.firstName, props.member.userInfo.lastName, Roles.SUPERUSER, [
						props.healthSystems.find(x => x.id === values.healthSystem),
					]);
					props.onSuccess('healthSystemAddition');
				});
			}}
			validationSchema={Yup.object().shape({
				healthSystem: Yup.string().required('Health system is required!'),
			})}>
			{formikProps => {
				// bind the submission handler remotely
				props.bindSubmitForm(formikProps.submitForm);

				return (
					<Form>
						<h3>Edit Super User's Health System</h3>
						<Field
							name='healthSystem'
							type='select'
							label='Enter Health System'
							placeholder='Select Health System'
							description={`Please select the health system for this super user!`}
							items={props.healthSystems}
							component={Select}
						/>

						<Alert
							alertSelector='assignSuperUserToAnotherHSMessage'
							display={props.loaded}
							message='Super user was added successfully to the health system.'
							variant='success'
							onClose={() => props.onCloseAlert('healthSystemAddition')}
						/>
						<Alert
							display={props.error}
							message='Something went wrong! Please try again. '
							variant='error'
							onClose={() => props.onCloseAlert('healthSystemAddition')}
						/>
					</Form>
				);
			}}
		</Formik>
	</div>
);

export default SuperUserHealthSystem;
