const channel = new BroadcastChannel('userIdle');

export const postUserIdleMessage = msg => {
	channel.postMessage(msg);
};

export const onUserIdleMessage = handler => {
	channel.onmessage = handler;
};

export const closeUserIdleChannel = () => {
	if (channel) {
		channel.close();
	}
};
