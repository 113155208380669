import React from 'react';
import { getDeviceRebootLogs } from 'api/devices';
import moment from 'moment';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import CsvExport from './Common/CsvExport';

const hasNameChanges = logs => {
	let hasChanges = {
		user: false,
		device: false,
	};
	logs.forEach(log => {
		if (!hasChanges.user) {
			if (log.user.fullName !== log.user.currentName) {
				hasChanges.user = true;
			}
		}
		if (!hasChanges.device) {
			if (log.device.name !== log.device.currentName) {
				hasChanges.device = true;
			}
		}
	});

	return hasChanges;
};

const exportLogsAsCsv = async deviceId => {
	const logs = await getDeviceRebootLogs(deviceId);
	if (logs.length === 0) {
		return [{ Info: 'This device has no reboot logs yet.' }];
	}
	const hasChanges = hasNameChanges(logs);

	return logs.map(log => {
		const user = log.user.fullName !== log.user.currentName ? `${log.user.fullName}` : '';
		const device = log.device.name !== log.device.currentName ? `${log.device.name}` : '';
		let report = {
			User: log.user.currentName,
		};
		if (hasChanges.user) {
			report['Old user name'] = user;
		}
		report.Device = log.device.currentName;
		if (hasChanges.device) {
			report['Old device name'] = device;
		}

		report['Serial number'] = log.device.sn;
		report.Date = moment(utcToLocalTime(log.createdDate)).format('MM/DD/YYYY-hh:mm A');
		report.Reason = log.reason || 'Not specified';
		return report;
	});
};

const RebootLogExport = ({ solHelloDeviceId, serialNumber }) => (
	<CsvExport asyncExportMethod={() => exportLogsAsCsv(solHelloDeviceId)} fileName={`${serialNumber}-${(+new Date()).toString()}.csv`} className=''>
		<i
			className='material-icons-outlined boxed-icon'
			id={solHelloDeviceId}
			data-tooltip='Export reboot logs'
			data-position='top'
			style={{ backgroundColor: 'var(--green-2)' }}>
			get_app
		</i>
	</CsvExport>
);

export default RebootLogExport;
