import React from 'react';
import Grid from 'components/Grid';

const TimeError = () => (
	<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
		<div className='member-error'>
			<h3>Invalid datetime of token.</h3>
			<img src='https://static.solaborate.com/banyan/undraw_fitting_piece_iilo.svg' alt='member-error' />
			<p>The token seems to be invalid. Please make sure your machine has the current time zone and/or Date and time and try again. </p>
			<span
				className='logout-link'
				onClick={() => {
					window.location.href = '/logout';
				}}>
				Click here to go back to login screen.
			</span>
		</div>
	</Grid>
);

export default TimeError;
