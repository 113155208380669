import { OverviewActionTypes } from '../../constants/action-types';

const initialState = {
	userInfo: null,
	isLefNavigationExpanded: false,
};

export const mainLayout = (state, action) => {
	state = state || initialState;

	if (action.type === OverviewActionTypes.setUserInfo) {
		return { ...state, userInfo: action.payload };
	}

	if (action.type === OverviewActionTypes.toggleLeftNavigation) {
		return { ...state, isLefNavigationExpanded: !state.isLefNavigationExpanded };
	}

	return state;
};
