import React, { Component } from 'react';
import { Form, Modal, Input, Select, Tabs, Avatar, DescriptionBox, Alert } from 'components';
import { getUserProfile, setUserProfile } from 'infrastructure/auth';
import { changePassword } from 'api/user';
import { ChangePasswordUseCases } from 'constants/enums';
import { passwordRegEx } from 'constants/global-variables';

class UserSettingsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userProfile: {},
			virtualRoles: [{ value: 'Virtual Nurse' }, { value: 'Virtual Case Manager' }, { value: 'Virtual Sitter' }, { value: 'Virtual Intensivist' }],
			currentPassword: '',
			newPassword: '',
			newConfirmedPassword: '',
			passwordInfo: ChangePasswordUseCases.passwordInfo,
			passwordAlertVariant: 'neutral',
			isConfirmPasswordChangeModalOpen: false,
			selectedTab: 0,
		};

		this.COMPANY_INFO = {
			STARTED: 'Mon Mar 12 2018 00:00:00',
			NAME: 'Banyan',
		};

		this.TABS = {
			EDIT_PROFILE: 0,
			CHANGE_PASSWORD: 1,
		};
	}

	componentDidMount = () => {
		const locations = this.getUserLocations();
		const userProfile = getUserProfile();
		const virtualRoleKey = this.state.virtualRoles.findIndex(r => r.value === userProfile.jobTitle);
		this.setState({
			userProfile: {
				fullName: `${userProfile.firstName} ${userProfile.lastName}`,
				profilePicUrl: userProfile.profilePicture.url,
				virtualRole: userProfile.jobTitle,
				virtualRoleKey: virtualRoleKey,
				userWorkExperienceId: userProfile.jobId,
				locations: locations,
			},
		});
	};

	getUserLocations() {
		let locations = [];
		this.props.organization.allHealthSystems.forEach(healthSystem => locations.push({ description: healthSystem.name }));
		return locations;
	}

	saveAccountSettings = () => {
		if (this.state.selectedTab === this.TABS.EDIT_PROFILE) {
			this.editProfile();
		} else if (this.state.selectedTab === this.TABS.CHANGE_PASSWORD) {
			this.changePassword();
		}
	};

	editProfile = async () => {
		const userProfile = getUserProfile();
		const { virtualRole, userWorkExperienceId } = this.state.userProfile;
		const data = {
			companyName: this.COMPANY_INFO.NAME,
			fromDate: this.COMPANY_INFO.STARTED,
			jobDescription: virtualRole,
			jobTitle: virtualRole,
			isCurrent: true,
			showCompanyOnPage: true,
			toDate: null,
		};

		let response;
		let userHasRole = userWorkExperienceId !== 0;
		if (userHasRole) {
			response = await this.props.mainLayoutActions.updateUserRole(data);
		} else {
			response = await this.props.mainLayoutActions.addNewUserRole({
				...data,
				idUserCompanyId: userWorkExperienceId,
			});
		}

		setUserProfile({
			...userProfile,
			jobTitle: response.data.entityData.JobTitle,
			jobId: userHasRole ? response.data.entityData.IdUserCompanies : null,
		});
		this.props.toggleAccountSettingsModal();
	};

	changeVirtualRole = (selection, index) => {
		let user = this.state.userProfile;
		this.setState({
			userProfile: {
				...user,
				virtualRole: selection.value,
				virtualRoleKey: index,
			},
		});
	};

	validatePassword = () => {
		let { currentPassword, newPassword, newConfirmedPassword } = this.state;
		let message;
		if (currentPassword === '') {
			message = ChangePasswordUseCases.currentPassEmpty;
		} else if (newPassword === '') {
			message = ChangePasswordUseCases.newPassEmpty;
		} else if (newPassword.length < 8) {
			message = ChangePasswordUseCases.minLengthRequired;
		} else if (newConfirmedPassword === '') {
			message = ChangePasswordUseCases.confirmNewPassEmpty;
		} else if (newPassword !== newConfirmedPassword) {
			message = ChangePasswordUseCases.newPasswordsDoNotMatch;
		} else if (currentPassword === newPassword) {
			message = ChangePasswordUseCases.newPassSameAsCurrent;
		} else if (passwordRegEx.test(newPassword) === false || passwordRegEx.test(newConfirmedPassword) === false) {
			message = ChangePasswordUseCases.strongPasswordCheck;
		}
		return message;
	};

	resetPasswordFields = () => {
		this.setState({
			passwordData: {
				...this.state.passwordData,
				newPassword: '',
				newConfirmedPassword: '',
			},
		});
	};

	changePassword = () => {
		let message = this.validatePassword();
		if (message) {
			if (message === ChangePasswordUseCases.newPasswordsDoNotMatch) {
				this.resetPasswordFields();
			}
			this.setPasswordErrorMessage(message);
		} else {
			this.toggleConfirmPasswordDialog();
		}
	};

	savePasswordChanges = async () => {
		try {
			const response = await changePassword({
				oldpassword: this.state.currentPassword,
				newpassword: this.state.newPassword,
			});
			if (response.HasSucceed) {
				this.setState(
					{
						passwordInfo: ChangePasswordUseCases.newPassSaved,
					},
					() => {
						this.signOut();
					}
				);
			}
		} catch (error) {
			this.setState({
				passwordInfo: ChangePasswordUseCases.currentPasswordIncorrect,
				passwordAlertVariant: 'error',
				currentPassword: '',
				newPassword: '',
				newConfirmedPassword: '',
				isConfirmPasswordChangeModalOpen: false,
			});
		}
	};

	setPasswordErrorMessage = msg => {
		this.setState({
			passwordInfo: msg,
			passwordAlertVariant: 'neutral',
		});
	};

	toggleConfirmPasswordDialog = () => {
		this.setState({
			isConfirmPasswordChangeModalOpen: !this.state.isConfirmPasswordChangeModalOpen,
		});
	};

	setInputValue = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	signOut = () => {
		this.props.history.push('/logout');
	};

	render() {
		return (
			<>
				<Modal
					modalSelector='accountSettingsModal'
					display={this.props.isAccountSettingsModalOpen}
					isLoading={this.state.userProfileLoading}
					position='right'
					onModalSubmit={this.saveAccountSettings}
					onModalClose={this.props.toggleAccountSettingsModal}>
					<Tabs
						links={[{ link: 'Edit Profile', active: true }, { link: 'Change Password' }]}
						onTabClick={index => this.setState({ selectedTab: index })}
						components={[
							<Form id='Edit Profile'>
								<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
									<Avatar src={this.state.userProfile.profilePicUrl} size='medium' fullName={this.state.userProfile.fullName} />
									<p>{this.state.userProfile.fullName}</p>
								</div>
								<Select
									type='text'
									label='Role'
									name='currentRoleId'
									placeholder='Select Role'
									defaultValue={this.state.userProfile.virtualRoleKey}
									items={this.state.virtualRoles}
									onSelect={this.changeVirtualRole}
								/>
								<br />
								<p>Health Systems</p>
								<DescriptionBox data={this.state.userProfile.locations} />
							</Form>,
							<Form id='Change Password' onSubmit={event => event.preventDefault()}>
								<Alert
									alertSelector='changePasswordAlertMessage'
									display={true}
									hideCloseButton={true}
									message={this.state.passwordInfo}
									variant={this.state.passwordAlertVariant}
									onClose={this.props.onCloseAlert}
								/>
								<Input
									type='password'
									label='Current password'
									name='currentPassword'
									placeholder='Enter your current password'
									value={this.state.currentPassword}
									onChange={this.setInputValue}
									validationOptions={{ required: true, minLength: 6 }}
									bottomSpace='20px'
									width='100px'
								/>
								<Input
									type='password'
									label='New password'
									name='newPassword'
									placeholder='Type your new password'
									value={this.state.newPassword}
									onChange={this.setInputValue}
									validationOptions={{ required: true, minLength: 6 }}
									bottomSpace='20px'
									width='100px'
								/>
								<Input
									type='password'
									label='Confirm new password'
									name='newConfirmedPassword'
									placeholder='Retype your new password'
									value={this.state.newConfirmedPassword}
									onChange={this.setInputValue}
									validationOptions={{ required: true, minLength: 6 }}
									bottomSpace='20px'
									width='100px'
								/>
							</Form>,
						]}
					/>
				</Modal>
				<Modal
					modalSelector='confirmPasswordChangeModal'
					display={this.state.isConfirmPasswordChangeModalOpen}
					position='center'
					onModalSubmit={this.savePasswordChanges}
					onModalClose={this.toggleConfirmPasswordDialog}
					closeButtonText='Cancel'
					submitButtonText='Continue'>
					<Form title='Confirm action'>
						<p>Are you sure you want to change your password? You will be logged out of your account and must login with your new credentials.</p>
					</Form>
				</Modal>
			</>
		);
	}
}

export default UserSettingsModal;
