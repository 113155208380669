import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';
import { createNurseInvite, checkIfCompanyMemberExists } from 'api/users';

import { If, Then, Else } from 'react-if';
import Alert from 'components/Alert';
import { InviteTypes } from 'constants/enums';
import MultiSelect from 'components/Common/FormElements/MultiSelect';

class InviteNurses extends Component {
	constructor(props) {
		super(props);

		const healthSystemsArr = this.props.healthSystems.map(hs => {
			return { value: hs.id, label: hs.value };
		});

		this.state = {
			extraFields: 0,
			healthSystemsArr,
		};
	}

	addExtraInputField = () => {
		var updatedExtraFields = this.state.extraFields + 1;
		this.setState({ extraFields: updatedExtraFields });
	};

	displayExtraFields = () => {
		let table = [];
		for (let i = 0; i < this.state.extraFields; i++) {
			table.push(<Field name={'email' + i} type='email' placeholder='Email' component={Input} />);
		}
		return table;
	};

	async checkEmail(val) {
		if (!val) {
			return true;
		}

		let response = false;
		const schema = Yup.string()
			.email()
			.required();
		if (await schema.isValid(val)) {
			try {
				await checkIfCompanyMemberExists(val)
					.then(() => (response = false))
					.catch(() => (response = true));
			} catch (error) {
				return false;
			}
		}
		return response;
	}

	getRole(inviteType) {
		switch (inviteType) {
			case InviteTypes.VIRTUALCAREPROVIDER.type:
				return InviteTypes.VIRTUALCAREPROVIDER.message;
			case InviteTypes.VIRTUALSITTER.type:
				return InviteTypes.VIRTUALSITTER.message;
			case InviteTypes.SUPERUSER.type:
				return InviteTypes.SUPERUSER.message;
			//no default
		}
	}

	render() {
		return (
			<div>
				<Formik
					validateOnBlur={false}
					initialValues={{
						healthSystems: [],
						email: '',
						email0: '',
						email1: '',
						email2: '',
						email3: '',
					}}
					onSubmit={(values, { resetForm }) => {
						this.props.onLoading();
						var emailObjs = [];
						for (const key of Object.keys(values)) {
							if (key !== 'healthSystems' && values[key] !== '') emailObjs.push(values[key]);
						}
						createNurseInvite(emailObjs, this.props.inviteTypeId, values.healthSystems).then(() => {
							this.props.onSuccess();
							resetForm({
								healthSystems: [],
								email: '',
								email0: '',
								email1: '',
								email2: '',
								email3: '',
							});
						});
					}}
					validationSchema={Yup.object().shape({
						email: Yup.string()
							.required('Email is required!')
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val => this.checkEmail(val)),
						email0: Yup.string()
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val1 => this.checkEmail(val1)),
						email1: Yup.string()
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val => this.checkEmail(val)),
						email2: Yup.string()
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val => this.checkEmail(val)),
						email3: Yup.string()
							.email('Invalid email!')
							.test('existing-email', 'This user already exists!', val => this.checkEmail(val)),
						healthSystems: Yup.string()
							.required('Health system is required!')
							.nullable(),
					})}>
					{formikProps => {
						const { values, touched, errors, setFieldValue, setFieldTouched } = formikProps;
						// bind the submission handler remotely
						this.props.bindSubmitForm(formikProps.submitForm);
						const role = this.getRole(this.props.inviteTypeId);

						return (
							<Form>
								<h3>Add {role}</h3>
								<MultiSelect
									name='healthSystems'
									label='Health Systems'
									placeholder='Select Health System'
									description={`Please select at least one health system in order to add a ${role}`}
									options={this.state.healthSystemsArr}
									value={values.healthSystems}
									isMulti={true}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									touched={touched.healthSystems}
									error={errors.healthSystems}
									isClearable={true}
									backspaceRemovesValue={true}
								/>
								<Field
									name='email'
									type='email'
									label='Enter Email'
									placeholder='Email'
									description={`Please write the emails of the ${role}s you wish to invite.`}
									component={Input}
								/>
								{this.displayExtraFields()}
								<div>
									<If condition={this.state.extraFields === 4}>
										<Then>
											<span>You can only invite a maximum of 5 people at once!</span>
										</Then>
										<Else>
											<a
												className='action'
												data-cy='addNewField'
												onClick={() => this.addExtraInputField()}
												data-tooltip={'Add New Field'}
												data-position='right'>
												<i className='material-icons-outlined'>add_box</i>
											</a>
										</Else>
									</If>
								</div>
								<Alert
									display={this.props.loaded}
									message='The users were successfully invited to Banyan !'
									variant='success'
									onClose={this.props.onCloseAlert}
								/>
								<Alert display={this.props.error} message='Something went wrong! Please try again. ' variant='error' onClose={this.props.onCloseAlert} />
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export default InviteNurses;
