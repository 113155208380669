import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

class CallDuration extends Component {
	static defaultProps = {
		format: 'HH:mm:ss',
	};

	constructor(props) {
		super(props);

		let duration = moment.duration(0, 's');
		if (props.callStartTime) {
			const timeDiff = Math.abs(new Date().getTime() - props.callStartTime.getTime());
			duration = moment.duration(Math.floor(timeDiff / 1000), 's');
		}

		this.state = {
			duration,
			callDuration: null,
		};

		this.durationInterval = null;
	}

	componentDidMount() {
		this.startDurationInterval();
	}

	componentWillUnmount() {
		if (this.durationInterval) {
			clearInterval(this.durationInterval);
		}
	}

	getDuration = (initial = false) => {
		let callDuration;

		if (this.props.format === 'HH:mm:ss') {
			if (!initial) {
				this.state.duration.add(1, 'seconds');
			}
			callDuration = moment.utc(this.state.duration.asMilliseconds()).format(this.props.format);
		}
		if (this.props.format === 'MM/DD/YY hh:mm A') {
			callDuration = moment().format(this.props.format);
		}

		return callDuration;
	};

	startDurationInterval = () => {
		this.durationInterval = setInterval(() => {
			this.setState({ callDuration: this.getDuration() });
		}, 1000);
	};

	render() {
		return <p>{this.state.callDuration || this.getDuration(true)}</p>;
	}
}

CallDuration.propTypes = {
	format: PropTypes.string,
};

export default CallDuration;
