import React from 'react';
import Modal from 'components/Modal';
import { StreamError } from 'constants/enums';
import Form from 'components/Form';

const StreamPermissionsModal = ({ setStreamPermissionMessage, reason }) => (
	<Modal
		display={true}
		position='center'
		closeButtonText='Dismiss'
		submitButtonText=''
		onModalClose={() => {
			setStreamPermissionMessage(null);
		}}>
		{(reason.type === StreamError.CAMERA_BLOCKED.type ||
			reason.type === StreamError.MICROPHONE_BLOCKED.type ||
			reason.type === StreamError.CAM_AND_MIC_BLOCKED.type) && (
			<Form
				title={`Allow Banyan to use your ${
					reason.type === StreamError.CAM_AND_MIC_BLOCKED.type
						? 'camera and microphone'
						: (reason.type === StreamError.CAMERA_BLOCKED.type && 'camera') || (reason.type === StreamError.MICROPHONE_BLOCKED.type && 'microphone')
				}`}>
				<p>
					Banyan needs access to your{' '}
					{reason.type === StreamError.CAM_AND_MIC_BLOCKED.type
						? 'camera and microphone'
						: (reason.type === StreamError.CAMERA_BLOCKED.type && 'camera') || (reason.type === StreamError.MICROPHONE_BLOCKED.type && 'microphone')}{' '}
					so patients can hear you. Check the top left of the screen and click Allow.
				</p>
			</Form>
		)}
		{(reason.type === StreamError.MICROPHONE_NOT_FOUND.type || reason.type === StreamError.CAMERA_NOT_FOUND.type) && (
			<Form title={reason.type === StreamError.MICROPHONE_NOT_FOUND.type ? 'Can’t find your microphone' : 'Can’t find your camera'}>
				<p>
					{reason.type === StreamError.MICROPHONE_NOT_FOUND.type
						? 'Please check on your system if a mic has been plugged in and you have allowed mic permissions. If not, plug one in or allow permissions and restart your browser. Patients won’t be able to hear you until you do that.'
						: 'Please check on your system if a camera has been plugged in and you have allowed camera permissions. If not, plug one in or allow permissions and restart your browser. Patients won’t be able to see you until you do that.'}
				</p>
			</Form>
		)}
	</Modal>
);

export default StreamPermissionsModal;
