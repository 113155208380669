import React, { Component } from 'react';
import v4 from 'uuid';
import { SocketContext } from 'io-client/SocketContext';
import CallManager from 'owt/p2p/CallManager';
import { ConferenceInfo, ConferenceParticipant, FromUser } from 'owt/base/conference';
import { isChrome } from 'owt/base/utils';
import { Grid, CallButton, Loader, TvControls, CallEndReason, CallCorrelationInfo, ConnectionStatus, Button } from 'components';
import CameraControls from 'components/Common/CameraControls';
import NightVisionControl from 'components/Common/NightVisionControl';
import CallDuration from 'components/Common/CallDuration';
import ActiveConference from 'components/Call/ActiveConference';
import Alert from 'components/Alert';
import PeerStats from 'containers/PeerStats';
import {
	CallTypes,
	ObjectType,
	ConferenceEndReason,
	SerialTVCommands,
	SocketState,
	RTCPeerConnectionEnum,
	CameraType,
	CameraEventTypes,
	CameraTiltDirection,
	CameraTiltAction,
	ZoomDirection,
	MediaPermissions,
	MediaTypes,
	StreamError,
} from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { getCurrentHealthSystemInfo, checkForPermission, checkIfMediaDevicesPlugged, askForPermission } from 'infrastructure/helpers/commonHelpers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { fetchNotificationCounter } from 'state/notifications/actions';
import { APP_CONFIG } from 'constants/global-variables';
import { getUserProfile } from 'infrastructure/auth';

class PatientFeed extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			callerObjectId: parseInt(props.match.params.patientId, 10),
			roomName: decodeURIComponent(props.match.params.roomName),
			healthSystemPath: null,
			peerSrc: null,
			micActive: false,
			peerAudioMuted: false,
			conferenceId: null,
			participantId: null,
			conferenceEndReason: null,
			socketState: SocketState.CONNECTED,
			tvStatus: null,
			hdmiStatus: null,
			tvBrand: null,
			volumeStatus: null,
			nightVisionMode: false,
			cameraZoomLevel: 0,
			cameraType: CameraType.HELLO,
			isHuddleCamConnected: true,
			isCameraPrivacyOn: false,
			isMicPrivacyOn: false,
			disabledTiltDirections: {},
			callStartTime: null,
			soundPermission: true,
			peerConnectionState: RTCPeerConnectionEnum.CONNECTION_STATE.NEW,
			disableButtons: false,
			hasActiveConference: false,
			onPatientBusyNurse: null, // this is the name of the nurse that the hello is talking already when we get the busy participant event
			shouldShowSwitchToHelloCamError: false,
		};

		this.myAvatarUrl = '';
		this.camStatus = null;
		this.micStatus = null;

		this.callManager = new CallManager(this.context, {
			useCallStats: APP_CONFIG.useCallStats,
			sendCallStatsInterval: APP_CONFIG.sendCallStatsInterval,
		});
		this.videoRef = React.createRef();
	}

	async componentDidMount() {
		this.props.fetchNotificationCounter();
		this.camStatus = await checkForPermission(MediaTypes.CAMERA);
		this.micStatus = await checkForPermission(MediaTypes.MICROPHONE);
		this.micStatus.onchange = this.onDevicePermissionChange;
		this.callManager
			.on('call-started', data => {
				let roomNamePath = this.getRoomNamePath(data);
				this.setState({
					conferenceId: data.conferenceId,
					participantId: data.participantId,
					...roomNamePath,
				});
			})
			.on('feed-call', data => {
				const { localStream } = data;
				if (!!localStream.mediaStream.getAudioTracks().length) {
					localStream.mediaStream.getAudioTracks()[0].enabled = false;
				}
			})
			.on('peer-stream', data => {
				const { peerSrc } = data;
				let newState = {};

				Object.assign(newState, { peerSrc });

				if (!this.state.callStartTime) {
					Object.assign(newState, { callStartTime: new Date() });
				}

				this.setState(newState);
			})
			.on('end-call', data => {
				this.startCloseTabTimeout();
				this.setState({
					peerSrc: null,
					conferenceEndReason: data.endReason,
					hasActiveConference: data.hasActiveConference,
					conferenceId: null,
				});
			})
			.on('toggle-audio', () => {
				this.setState({ micActive: !this.state.micActive });
			})
			.on('tv-commands', data => {
				if (data.tvState.isVolume) {
					this.setState({ volumeStatus: data });
					return;
				}

				switch (data.tvState.tvStatus) {
					case SerialTVCommands.INITIAL_TV_POWER:
					case SerialTVCommands.POWER.POWER_ON:
					case SerialTVCommands.POWER.POWER_OFF:
						this.setState({ tvStatus: data });
						break;
					case SerialTVCommands.HDMI.SWITCH_HDMI1:
					case SerialTVCommands.HDMI.SWITCH_HDMI2:
					case SerialTVCommands.HDMI.SWITCH_HDMI3:
						this.setState({ hdmiStatus: data });
						break;
					default:
						break;
				}
			})
			.on('initial-device-state', data => {
				if (!data) {
					return;
				}

				const {
					cameraType,
					zoomLevel: cameraZoomLevel,
					isNightVision: nightVisionMode,
					isHuddleCamConnected,
					isCameraPrivacyOn,
					isMicPrivacyOn,
					tvHdmiPort,
					tvBrand,
				} = data;
				let hdmiStatus = null;

				if (tvHdmiPort) {
					hdmiStatus = { isSuccessful: true, tvState: { isVolume: false, tvStatus: SerialTVCommands.HDMI[`SWITCH_HDMI${tvHdmiPort}`] } };
				}

				this.setState({
					cameraType,
					cameraZoomLevel,
					nightVisionMode,
					isHuddleCamConnected,
					isCameraPrivacyOn,
					isMicPrivacyOn,
					hdmiStatus,
					tvBrand,
				});
			})
			.on('camera-response', this.cameraResponseListener)
			.on('socket-state', ({ socketState }) => {
				if (this.state.socketState.type === socketState.type) {
					return;
				}

				const newState = { socketState };
				switch (socketState.type) {
					case SocketState.DISCONNECTED.type:
						Object.assign(newState, {
							disableButtons: true,
						});
						break;
					case SocketState.CONNECTED.type:
						Object.assign(newState, {
							disableButtons: false,
						});
						break;
					default:
						break;
				}

				this.setState(newState, () => {
					this.playVideo();
				});
			})
			.on('peer-connection-state', ({ peerConnectionState }) => {
				this.setState({ peerConnectionState }, () => {
					this.playVideo();
				});
			})
			.on('participant-busy', data => {
				const { activeConferences, objectId } = data;
				if (!activeConferences || activeConferences.length === 0) {
					this.callManager.logger.warn('On participant busy - no active conference was found');
					return undefined;
				}

				const conference = activeConferences.find(ac => ac.initialCallType === CallTypes.SECURITYCAM || ac.initialCallType === CallTypes.AUDIO);
				if (!conference) {
					this.callManager.logger.warn('On participant busy - no active patient view or talk to patient conference was found');
					return undefined;
				}

				// only active participants
				// there should be only one active participant in patient view or talk to patient
				if (conference.participants.length > 1) {
					this.callManager.logger.warn('On participant busy - more then one participants were found in the active conference');
					return undefined;
				}

				const onPatientBusyNurse = conference.participants[0];
				this.setState({ onPatientBusyNurse });
			})
			.on('local-audio-error', this.onLocalAudioError)
			.bindSocketEventListeners(false);

		this.bindWindowListeners();
		this.callManager.startConference(await this.prepareStartConferenceInfo());
	}

	onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			this.props.organizationActions.setStreamPermissionMessage(null);
		} else if (this.state.micActive) {
			this.toggleMyMicrophone();
		}
		this.callManager.publishConferenceLog(`Microphone permission state is changed to ${res.target.state}.`);
	};

	beforeUnloadEvent = event => {
		if (this.state.conferenceId) {
			event.preventDefault();
			event.returnValue = '';
		}
	};

	bindWindowListeners() {
		window.addEventListener('beforeunload', this.beforeUnloadEvent);

		window.addEventListener('unload', () => {
			if (this.state.conferenceId) {
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_LEFT });
			}
		});

		window.addEventListener('message', message => {
			if (message.data === 'IN_CALL') {
				window.removeEventListener('beforeunload', this.beforeUnloadEvent);
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_IDLE });
			}
		});
	}

	playVideo = () => {
		if (
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED &&
			this.state.socketState.type === SocketState.CONNECTED.type &&
			this.state.peerSrc &&
			this.videoRef &&
			this.videoRef.current
		) {
			this.videoRef.current.srcObject = null;
			this.videoRef.current.srcObject = this.state.peerSrc.mediaStream;
			// playVideo is called from multiple places for different scenarius
			// make sure the promise to play the video is resolved to trigger the next play
			if (!this.videoPlayPromise) {
				if (this.checkVideoPlayingTimeout) {
					clearTimeout(this.checkVideoPlayingTimeout);
					this.checkVideoPlayingTimeout = null;
				}
				this.checkVideoPlayingTimeout = setTimeout(() => {
					clearTimeout(this.checkVideoPlayingTimeout);
					this.checkVideoPlayingTimeout = null;
					if (this.videoPlayPromise) {
						const message = `PatientFeed: playVideo: Video is taking to long to be played more then 5 sec`;
						this.callManager.publishConferenceLog(message);
					}
				}, 5000);

				this.videoPlayPromise = this.videoRef.current.play();
				this.videoPlayPromise
					.then(() => {
						const message = `PatientFeed: playVideo: Video is playing`;
						this.callManager.publishConferenceLog(message);
					})
					.catch(e => {
						const message = `PatientFeed: playVideo: Can not play video: ${e}`;
						this.callManager.publishConferenceLog(message);
						this.callManager.logger.error(message);
						this.setState({ soundPermission: false });
					})
					.finally(() => {
						clearTimeout(this.checkVideoPlayingTimeout);
						this.checkVideoPlayingTimeout = null;
						this.videoPlayPromise = null;
					});
			}
		}
	};

	componentWillUnmount() {
		this.callManager.unbindSocketEventListeners();
		this.callManager.unbindTimeouts();
		if (this.closeTabTimeout) {
			clearTimeout(this.closeTabTimeout);
			this.closeTabTimeout = null;
		}
	}

	getRoomNamePath = data => {
		let healthSystemPath;
		let { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		this.props.organization.allHealthSystems.forEach(healthSystem => {
			if (healthSystem.id === currentHealthSystemId) {
				healthSystemPath = healthSystem.name + ' > ';

				healthSystem.regions.forEach(region => {
					if (currentRegionId === region.id) {
						healthSystemPath = healthSystemPath + region.name;
					}
				});
			}
		});

		let roomName = data.helloDeviceName.replace(/[,]/g, ' >').replace(/[-]/g, '>');

		return {
			healthSystemPath: healthSystemPath,
			roomName: roomName,
		};
	};

	prepareStartConferenceInfo = async () => {
		const user = getUserProfile();
		const fromUserInfo = new FromUser(`${user.firstName} ${user.lastName}`, user.jobTitle, user.profilePicture.url, undefined);
		const participants = [new ConferenceParticipant(+this.state.callerObjectId, ObjectType.HELLO_DEVICE)];

		this.myAvatarUrl = user.profilePicture.url;
		const devices = await navigator.mediaDevices.enumerateDevices();
		const inputDevices = {
			devices,
			permissions: {
				camera: this.camStatus.state,
				microphone: this.micStatus.state,
			},
		};

		return new ConferenceInfo(
			CallTypes.SECURITYCAM,
			v4(),
			'Patient Feed',
			null,
			fromUserInfo,
			true,
			false,
			false,
			false,
			false,
			false,
			false,
			v4(),
			participants,
			inputDevices,
			true
		);
	};

	toggleAudio = async () => {
		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (!pluggedDevices.microphone) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return;
		}

		if (this.micStatus.state === MediaPermissions.GRANTED) {
			this.toggleMyMicrophone();
		} else if (this.micStatus.state === MediaPermissions.PROMPT) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});

			await askForPermission({ audio: true });
			if (this.micStatus.state === MediaPermissions.GRANTED) {
				const audioStream = this.toggleMyMicrophone();
				if (audioStream && audioStream.error) return;
			}

			this.props.organizationActions.setStreamPermissionMessage(null);
		} else {
			await askForPermission({ audio: true });
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'popup',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
		}
	};

	toggleMyMicrophone = async () => {
		const audioStream = await this.callManager.toggleAudio();
		if (audioStream && audioStream.error) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
		}
		return audioStream;
	};

	toggleHandler = async type => {
		switch (type) {
			case 'mic':
				this.toggleAudio();
				break;
			case 'peer-audio':
				const muted = !this.state.peerAudioMuted;
				this.setState({ peerAudioMuted: muted });

				this.callManager.toggleParticipantTrack(CallTypes.AUDIO, muted);
				break;
			default:
				break;
		}
	};

	endCall = () => {
		this.callManager.endCall({ endReason: ConferenceEndReason.INITIATOR_LEFT });
	};

	startCloseTabTimeout = () => {
		this.closeTabTimeout = setTimeout(() => {
			clearTimeout(this.closeTabTimeout);
			this.closeTabTimeout = null;
			window.close();
		}, 5000);
	};

	setCallStartTime = () => {
		if (!this.state.callStartTime) {
			this.setState({ callStartTime: new Date() });
		}
	};

	handleSoundPermission = () => {
		this.setState({ soundPermission: true }, () => {
			this.videoRef.current.srcObject = null;
			this.videoRef.current.srcObject = this.state.peerSrc.mediaStream;
			this.playVideo();
		});
	};

	shouldShowStateComponents = () => {
		return (
			this.state.hasActiveConference ||
			this.state.conferenceEndReason ||
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED ||
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.FAILED
		);
	};

	sendPanTiltCameraEvent = (direction, action) => {
		const { participantId, conferenceId, callerObjectId } = this.state;
		this.callManager.panTiltCamera({ direction, helloDeviceId: callerObjectId, action, conferenceId, participantId });
	};

	toggleNightvision = () => {
		this.callManager.toggleNightVision(!this.state.nightVisionMode, this.state.callerObjectId, this.state.conferenceId, this.state.participantId);
	};

	cameraResponseListener = ({ event, message, isSuccessful }) => {
		switch (event) {
			case CameraEventTypes.SWITCH:
				if (isSuccessful) {
					this.setState({
						cameraType: message,
						cameraZoomLevel: 0,
					});
				}
				break;
			case CameraEventTypes.ZOOM:
				this.setState({
					cameraZoomLevel: +message,
				});
				break;
			case CameraEventTypes.TILT: {
				const { disabledTiltDirections } = this.state;

				if (isSuccessful) {
					if ([CameraTiltDirection.UP, CameraTiltDirection.DOWN].includes(message)) {
						disabledTiltDirections[CameraTiltDirection.UP] = false;
						disabledTiltDirections[CameraTiltDirection.DOWN] = false;
					} else {
						disabledTiltDirections[CameraTiltDirection.LEFT] = false;
						disabledTiltDirections[CameraTiltDirection.RIGHT] = false;
					}
				} else {
					disabledTiltDirections[message] = true;
					this.sendPanTiltCameraEvent(message, CameraTiltAction.STOP);
				}

				this.setState(disabledTiltDirections);
				break;
			}
			case CameraEventTypes.HUDDLE_CONNECTED_STATE:
				this.setState({ isHuddleCamConnected: isSuccessful });
				break;
			case CameraEventTypes.NIGHT_VISION:
				this.setState({ nightVisionMode: isSuccessful });
				break;
			case CameraEventTypes.HELLO_CAMERA_PRIVACY_STATE:
				this.setState({ isCameraPrivacyOn: isSuccessful });
				break;
			case CameraEventTypes.HELLO_MIC_PRIVACY_STATE:
				this.setState({ isMicPrivacyOn: isSuccessful });
				break;
			default:
		}
	};

	renderCallStates = () => {
		if (this.state.hasActiveConference) {
			return <ActiveConference />;
		}

		if (this.state.conferenceEndReason) {
			return <CallEndReason reason={this.state.conferenceEndReason} onPatientBusyNurse={this.state.onPatientBusyNurse} url={this.myAvatarUrl} />;
		}

		if (this.state.peerConnectionState) {
			return <ConnectionStatus url={this.myAvatarUrl} peerConnectionState={this.state.peerConnectionState} />;
		}
	};

	getPrivacyButtonsErrorMessage = () => {
		const { isMicPrivacyOn, isCameraPrivacyOn, cameraType } = this.state;

		if (isMicPrivacyOn && (!isCameraPrivacyOn || cameraType !== CameraType.HELLO)) {
			return `You are not hearing the patient because the physical mic privacy button has been enabled on Banyan Bridge. This button can be disabled only manually. Please contact your administrator.`;
		}
		if (!isMicPrivacyOn && isCameraPrivacyOn && cameraType === CameraType.HELLO) {
			return `You are not seeing the patient because the physical camera privacy button has been enabled on Banyan Bridge. This button can be disabled only manually. Please contact your administrator.`;
		}

		if (isMicPrivacyOn && isCameraPrivacyOn && cameraType === CameraType.HELLO) {
			return `You are not seeing or hearing the patient because the physical privacy buttons have been enabled on Banyan Bridge. These buttons can be disabled only manually. Please contact your administrator.`;
		}
	};

	shouldShowPrivacyButtonsErrorMessage = () => {
		return this.state.isMicPrivacyOn || (this.state.isCameraPrivacyOn && this.state.cameraType === CameraType.HELLO);
	};

	onToggleCameraSwitch = () => {
		const { participantId, conferenceId, callerObjectId, cameraType, isCameraPrivacyOn } = this.state;

		const notAllowedToSwitchToHelloCam = cameraType === CameraType.HUDDLE && isCameraPrivacyOn;
		if (notAllowedToSwitchToHelloCam) {
			this.setState({ shouldShowSwitchToHelloCamError: true });
			return;
		}

		this.callManager.sendCameraEvent(SocketEvents.HelloDevice.SWITCH_CAMERA, { participantId, conferenceId, helloDeviceId: callerObjectId });
	};

	onLocalAudioError = ({ trackDeviceNotFound, inputDevices }) => {
		if (!trackDeviceNotFound || !inputDevices.length) {
			this.props.organizationActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
		}

		this.setState({ micActive: false });
	};

	render() {
		const {
			callerObjectId,
			cameraType,
			cameraZoomLevel,
			disabledTiltDirections,
			isHuddleCamConnected,
			disableButtons,
			conferenceId,
			participantId,
			isCameraPrivacyOn,
		} = this.state;

		return (
			<Grid className='call-view patient-view' columns='1fr' stretch='100vh'>
				{this.shouldShowStateComponents() && this.renderCallStates()}
				{!this.shouldShowStateComponents() && (
					<>
						{!this.state.peerSrc && (
							<div className='loader__container'>
								<div className='center-loader'>
									<p>Initiating Call</p>
									<Loader />
								</div>
							</div>
						)}
						{this.state.peerSrc && (
							<>
								{!this.state.soundPermission && (
									<div className='loader__container'>
										<div className='center-loader'>
											<p style={{ textAlign: 'center' }}>
												Allow Banyan to play sound on your browser
												<br />
												Please allow permissions for sound so you can hear the patient.
											</p>
											<Button onClick={this.handleSoundPermission} text='Allow Permission ' />
										</div>
									</div>
								)}

								{this.state.soundPermission && (
									<>
										<main>
											<Alert
												alertSelector='privacyButtonsErrorMessage'
												message={this.getPrivacyButtonsErrorMessage()}
												variant='error'
												persist={true}
												display={this.shouldShowPrivacyButtonsErrorMessage()}
												hideCloseButton={true}
												position='top'
											/>
											<Alert
												display={this.state.shouldShowSwitchToHelloCamError}
												onClose={() => {
													this.setState({ shouldShowSwitchToHelloCamError: false });
												}}
												persist={true}
												message={`You can't switch back to Banyan Bridge because the physical privacy buttons have been enabled. These buttons can be disabled only manually. Please contact your administrator.`}
												variant='error'
												position='top'
											/>
											<video ref={this.videoRef} />
										</main>
										<Grid className='call-view__footer' vertAlign='center' columns='33.33% 33.33% 33.33%'>
											<div className='call-view__footer--desc'>
												<h3 data-tooltip={`${this.state.healthSystemPath} > ${this.state.roomName}`} data-position='top'>
													<span>{this.state.roomName}</span>
												</h3>
												<CallDuration format='MM/DD/YY hh:mm A' />
											</div>
											<div>
												<CallButton
													buttonSelector='usersMicBtn'
													icon={this.state.micActive ? 'mic' : 'mic_off'}
													isActive={this.state.micActive}
													onClick={() => {
														this.toggleHandler('mic');
													}}
													tooltip={this.state.micActive ? 'Turn off mic' : 'Turn on mic'}
													tooltipPosition='top'
													isDisabled={this.state.disableButtons}
												/>
												<CallButton
													buttonSelector='endCallBtn'
													icon='call_end'
													name='endcall'
													isActive={true}
													variant='end'
													onClick={this.endCall}
													tooltip='End call'
													tooltipPosition='top'
													isDisabled={this.state.disableButtons}
												/>
												<CallButton
													buttonSelector='usersVolume'
													icon={!this.state.peerAudioMuted ? 'volume_up' : 'volume_off'}
													isActive={!this.state.peerAudioMuted}
													onClick={() => {
														this.toggleHandler('peer-audio');
													}}
													tooltip={!this.state.peerAudioMuted ? 'Mute Audio' : 'Unmute Audio'}
													tooltipPosition='top'
													isDisabled={this.state.disableButtons}
												/>
											</div>
											<div>
												<NightVisionControl
													nightVisionMode={this.state.nightVisionMode}
													toggleNightvisionHandler={this.toggleNightvision}
													isDisabled={this.state.cameraType === CameraType.HUDDLE || disableButtons}
												/>
												<TvControls
													tvStatus={this.state.tvStatus}
													volumeStatus={this.state.volumeStatus}
													hdmiStatus={this.state.hdmiStatus}
													tvBrand={this.state.tvBrand}
													callManagerInstance={this.callManager}
													helloDeviceId={this.state.callerObjectId}
													participantId={this.state.participantId}
													conferenceId={this.state.conferenceId}
													isDisabled={this.state.disableButtons}
												/>
											</div>
										</Grid>
										<CameraControls
											cameraType={cameraType}
											cameraZoomLevel={cameraZoomLevel}
											disabledTiltDirections={disabledTiltDirections}
											isHuddleCamConnected={isHuddleCamConnected}
											isDisabled={disableButtons}
											isCameraPrivacyOn={isCameraPrivacyOn}
											onToggleCameraSwitch={() => this.onToggleCameraSwitch()}
											onZoomCamera={direction => {
												const level = direction === ZoomDirection.INCREASE ? cameraZoomLevel + 20 : cameraZoomLevel - 20;
												this.callManager.sendCameraEvent(SocketEvents.HelloDevice.ZOOM_CAMERA, {
													level,
													helloDeviceId: this.state.callerObjectId,
													conferenceId,
													participantId,
												});
											}}
											onPanTiltCamera={this.sendPanTiltCameraEvent}
											onRebootHuddleCam={() =>
												this.callManager.rebootHuddleCam({
													conferenceId,
													participantId,
													helloDeviceId: callerObjectId,
												})
											}
										/>
										<PeerStats callManager={this.callManager} />
									</>
								)}
							</>
						)}
						<CallCorrelationInfo correlationId={this.state.conferenceId} className={(!this.state.peerSrc || !this.state.soundPermission) && 'out-of-call'} />
					</>
				)}
			</Grid>
		);
	}
}

PatientFeed.contextType = SocketContext;

const mapStateToProps = state => {
	return {
		organization: state.organization,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		fetchNotificationCounter: () => dispatch(fetchNotificationCounter()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientFeed);
