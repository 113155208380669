import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';

class Participant extends Component {
	constructor(props) {
		super(props);

		this.videoRef = null;
		this.videoSrcObjectHandler = this.videoSrcObjectHandler.bind(this);
	}

	componentDidMount() {
		this.videoSrcObjectHandler();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.videoCamActive !== this.props.videoCamActive || prevProps.participantFeed.id !== this.props.participantFeed.id) {
			this.videoSrcObjectHandler();
		}
	}

	videoSrcObjectHandler() {
		if (this.videoRef) {
			this.videoRef.srcObject = this.props.participantFeed.mediaStream;
		}
	}

	render() {
		return (
			<div className='participant'>
				<header>
					<p>{this.props.name}</p>
					<div>{this.props.children}</div>
				</header>
				<main onClick={() => this.props.onClick(this.props.participantFeed.id)}>
					{this.props.participantFeed && this.props.videoCamActive ? (
						<video ref={video => (this.videoRef = video)} autoPlay muted />
					) : (
						<div>
							<Avatar src={this.props.participantAvatar} size='medium' fullName={this.props.avatarName || this.props.name} />
						</div>
					)}
				</main>
			</div>
		);
	}
}

Participant.propTypes = {
	audioMute: PropTypes.bool,
	videoCamActive: PropTypes.bool,
	participantFeed: PropTypes.object,
	onClick: PropTypes.func,
	name: PropTypes.string,
	children: PropTypes.node,
	participantAvatar: PropTypes.string,
};

export default Participant;
