import React from 'react';
import Layout from 'components/Common/Layout';
import { Grid, Table } from 'components';
import Header from 'components/Common/Header';
import Pagination from 'components/Common/Pagination';
import { getUserIdleConfigurationHistory, getDeviceConfigurationHistory } from 'api/userIdleConfigurations';
import moment from 'moment';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { ConfigHistoryTypes, TeamTypes, TelehealthTv } from 'constants/enums';
import { getActiveDirectoriesHistory } from 'api/activeDirectories';
import { getTeamConfigurationProfilesHisotry } from 'api/teamConfigurationProfiles';
import { getNtpConfigurationHistory } from 'api/ntpConfig';

export default class ConfigurationsHistory extends React.Component {
	state = {
		isLoading: true,
		pageSize: { value: 10, label: '10' },
		pageIndex: 0,
		totalConfigs: 0,
		configs: [],
		title: '',
	};

	userIdleHeaders = [{ title: 'Health System' }, { title: 'Role' }, { title: 'Timeout Minutes' }, { title: 'Edited by' }, { title: 'Date Edited' }];

	deviceConfigHeaders = [
		{ title: 'Department' },
		{ title: 'HDMI Port' },
		{ title: 'Default Source' },
		{ title: 'Ringtone Volume' },
		{ title: 'TV' },
		{ title: 'Profile name' },
		{ title: 'Edited by' },
		{ title: 'Date Edited' },
	];

	activeDirectoriesHeaders = [{ title: 'Hospital' }, { title: 'Domain' }, { title: 'Valid Group Name' }, { title: 'Edited by' }, { title: 'Date Edited' }];

	teamProfileHeaders = [
		{ title: 'Health System' },
		{ title: 'Profile Name' },
		{ title: 'URL' },
		{ title: 'Port' },
		{ title: 'Edited by' },
		{ title: 'Date Edited' },
	];

	ntpConfigHeaders = [{ title: 'Name' }, { title: 'Type' }, { title: 'NTP Endpoint' }, { title: 'Edited By' }, { title: 'Date Edited' }];

	endCallSource = {
		0: 'TV Channels',
		1: 'HDMI 1',
		2: 'HDMI 2',
		3: 'HDMI 3',
	};

	activeDirectory = null;

	teamProfile = null;

	ntpConfigurations = null;

	componentDidMount = () => {
		this.getConfigurationHistory();
	};

	getConfigurationHistory = async () => {
		const { id, type } = this.props.match.params;
		const params = { id: id, pageSize: this.state.pageSize.value, pageIndex: this.state.pageIndex };

		if (type === ConfigHistoryTypes.UserIdle) {
			const result = await getUserIdleConfigurationHistory(params);
			this.setState({
				configs: result.history,
				totalConfigs: result.total,
				title: `History for ${result.userIdleConfiguration.team.name}, role ${result.userIdleConfiguration.role.name}`,
				isLoading: false,
			});
		} else if (type === ConfigHistoryTypes.Devices) {
			const result = await getDeviceConfigurationHistory(id, {
				pageSize: this.state.pageSize.value,
				pageIndex: this.state.pageIndex,
				teamTypeId: TeamTypes.DEPARTMENT,
			});

			this.setState({
				configs: result.deviceConfigurations,
				totalConfigs: result.total,
				title: `History for ${result.deviceConfiguration.team.name}`,
				isLoading: false,
			});
		} else if (type === ConfigHistoryTypes.ADs) {
			const result = await getActiveDirectoriesHistory(id, {
				pageSize: this.state.pageSize.value,
				pageIndex: this.state.pageIndex,
			});

			this.activeDirectory = result.activeDirectory;

			this.setState({
				configs: result.history,
				totalConfigs: result.total,
				title: `History for ${result.activeDirectory.team.name}, domain: ${result.activeDirectory.domain}, valid group name: ${result.activeDirectory.validGroupName}`,
				isLoading: false,
			});
		} else if (type === ConfigHistoryTypes.TeamConfigurationProfiles) {
			const result = await getTeamConfigurationProfilesHisotry({
				id,
				pageSize: this.state.pageSize.value,
				pageIndex: this.state.pageIndex,
			});

			this.teamProfile = result.teamConfigurationProfile;
			this.setState({
				configs: result.history,
				totalConfigs: result.total,
				title: `History for ${result.teamConfigurationProfile.team.name}`,
				isLoading: false,
			});
		} else if (type === ConfigHistoryTypes.NtpConfiguration) {
			const result = await getNtpConfigurationHistory({
				id,
				pageSize: this.state.pageSize.value,
				pageIndex: this.state.pageIndex,
			});

			if (!result.error) {
				this.ntpConfigurations = result.teamNetworkNtpConfiguration;
				this.setState({
					configs: result.history,
					totalConfigs: result.total,
					title: `Ntp Configuration history for ${result.teamNetworkNtpConfiguration.team.name}`,
				});
			}
			this.setState({
				isLoading: false,
			});
		}
	};

	onPaginationChange = (pageSize, pageIndex) => {
		this.setState(
			{
				isLoading: true,
				pageSize,
				pageIndex,
			},
			() => {
				this.getConfigurationHistory();
			}
		);
	};

	getClassName = config => {
		if (config.isActive) {
			return 'active-configuration';
		}
		if (config.isDeleted) {
			return 'deleted-configuration';
		}
		return '';
	};

	transformConfigurations = () => {
		const configs = this.state.configs.map(config => {
			if (this.props.match.params.type === ConfigHistoryTypes.UserIdle) {
				return {
					healthSystem: config.team.name,
					role: config.role.name,
					timeoutMinutes: config.timeoutMinutes,
					createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
					dateCreated: moment(utcToLocalTime(config.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
					className: config.isActive ? 'active-configuration' : '',
				};
			}
			if (this.props.match.params.type === ConfigHistoryTypes.Devices) {
				return {
					hospital: config.team.name,
					hdmiPort: config.hdmiPort,
					defaultSource: this.endCallSource[config.defaultSource],
					ringtoneVolume: config.ringtoneVolume,
					tv: config.tv === TelehealthTv.value ? TelehealthTv.label : config.tv,
					profileName: config.teamConfigurationProfile ? config.teamConfigurationProfile.profileName : '',
					createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
					dateCreated: moment(utcToLocalTime(config.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
					className: this.getClassName(config),
				};
			}
			if (this.props.match.params.type === ConfigHistoryTypes.ADs) {
				return {
					hospital: config.team.name,
					domain: config.domain,
					validGroupName: config.validGroupName,
					editedBy: config.userEdited ? `${config.userEdited.firstName} ${config.userEdited.lastName}` : '',
					dateEdited: moment(utcToLocalTime(config.dateEdited)).format('MM/DD/YYYY-hh:mm A'),
					className: '',
				};
			}
			if (this.props.match.params.type === ConfigHistoryTypes.TeamConfigurationProfiles) {
				return {
					healthSystem: config.team.name,
					profileName: config.profileName,
					url: config.url,
					port: config.port,
					createdBy: config.userEdited ? `${config.userEdited.firstName} ${config.userEdited.lastName}` : '',
					dateCreated: moment(utcToLocalTime(config.dateEdited)).format('MM/DD/YYYY-hh:mm A'),
					className: config.isActive ? 'active-configuration' : '',
				};
			}
			if (this.props.match.params.type === ConfigHistoryTypes.NtpConfiguration) {
				return {
					name: config.team.name,
					type: config.team.typeId === TeamTypes.HEALTHSYSTEM ? 'Health System' : 'Hospital',
					ntpUrl: (
						<>
							{config.ntpUrls.map(el => (
								<>
									<span>{el}</span>
									<br />
								</>
							))}
						</>
					),
					createdBy: `${config.userEdited.firstName} ${config.userEdited.lastName}`,
					dateCreated: moment(utcToLocalTime(config.dateEdited)).format('MM/DD/YYYY-hh:mm A'),
					className: this.getClassName(config),
				};
			}
			return null;
		});

		if (this.props.match.params.type === ConfigHistoryTypes.ADs) {
			configs.unshift({
				hospital: this.activeDirectory.team.name,
				domain: this.activeDirectory.domain,
				validGroupName: this.activeDirectory.validGroupName,
				editedBy: `${this.activeDirectory.userCreated.firstName} ${this.activeDirectory.userCreated.lastName}`,
				dateEdited: moment(utcToLocalTime(this.activeDirectory.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
				className: 'active-configuration',
			});
		} else if (this.props.match.params.type === ConfigHistoryTypes.TeamConfigurationProfiles) {
			configs.unshift({
				healthSystem: this.teamProfile.team.name,
				profileName: this.teamProfile.profileName,
				url: this.teamProfile.url,
				port: this.teamProfile.port,
				createdBy: `${this.teamProfile.userCreated.firstName} ${this.teamProfile.userCreated.lastName}`,
				dateCreated: moment(utcToLocalTime(this.teamProfile.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
				className: 'active-configuration',
			});
		} else if (this.props.match.params.type === ConfigHistoryTypes.NtpConfiguration) {
			configs.unshift({
				name: this.ntpConfigurations.team.name,
				type: this.ntpConfigurations.team.typeId === TeamTypes.HEALTHSYSTEM ? 'Health System' : 'Hospital',
				ntpUrl: (
					<>
						{this.ntpConfigurations.ntpUrls.map(el => (
							<>
								<span>{el}</span>
								<br />
							</>
						))}
					</>
				),
				createdBy: `${this.ntpConfigurations.userCreated.firstName} ${this.ntpConfigurations.userCreated.lastName}`,
				dateCreated: moment(utcToLocalTime(this.ntpConfigurations.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
				className: this.getClassName(this.ntpConfigurations),
			});
		}
		return configs;
	};

	getHeaders = () => {
		switch (this.props.match.params.type) {
			case ConfigHistoryTypes.UserIdle:
				return this.userIdleHeaders;
			case ConfigHistoryTypes.Devices:
				return this.deviceConfigHeaders;
			case ConfigHistoryTypes.ADs:
				return this.activeDirectoriesHeaders;
			case ConfigHistoryTypes.TeamConfigurationProfiles:
				return this.teamProfileHeaders;
			case ConfigHistoryTypes.NtpConfiguration:
				return this.ntpConfigHeaders;
			default:
				return [];
		}
	};

	render() {
		return (
			<Layout>
				<div className='users-view'>
					<Grid stretch='100%'>
						<main className='main-view'>
							<Header />
							<section>
								<Grid width='100%' vertAlign='start'>
									<div>
										<span className='configurations-back-btn' data-cy='configurationsBackBtn' onClick={() => window.history.back()}>
											<i className='material-icons'>keyboard_arrow_left</i>
											Go back to configurations
										</span>
										<h3 style={{ marginLeft: 5 }}>{this.state.title}</h3>
									</div>
									<Table isLoading={this.state.isLoading} headers={this.getHeaders()} rows={this.state.isLoading ? [] : this.transformConfigurations()} />
									<Pagination
										totalCount={this.state.totalConfigs}
										pageSize={this.state.pageSize}
										pageIndex={this.state.pageIndex}
										onChange={(pageSize, pageIndex) => this.onPaginationChange(pageSize, pageIndex)}
									/>
								</Grid>
							</section>
						</main>
					</Grid>
				</div>
			</Layout>
		);
	}
}
