/* eslint-disable no-console */
import { APP_CONFIG } from 'constants/global-variables';

class Logger {
	/**
	 * @param {object} data
	 * @param {string} data.name
	 * @param {import('@microsoft/applicationinsights-web').ApplicationInsights} data.appInsights
	 */
	constructor({ name, appInsights }) {
		this.name = name;
		this.appInsights = appInsights;

		this.conferenceData = null;
		this.sendCallClientLogs = APP_CONFIG.sendCallClientLogs === 'true';
		this.printConsole = APP_CONFIG.isDevelopment || !this.sendCallClientLogs;
	}

	/**
	 * @param {{ conferenceId?: string; participantId?: string; }} data
	 */
	setConferenceData(data) {
		this.conferenceData = {
			...this.conferenceData,
			...data,
		};
	}

	/**
	 * @param {'log' | 'info' | 'debug' | 'warn'} type
	 * @param {string} message
	 * @param  {...any} rest
	 */
	consoleMethod = (type, message, ...rest) => {
		const args = rest.filter(val => val !== undefined);

		if (this.appInsights?.appInsights?.isInitialized() && this.sendCallClientLogs) {
			this.appInsights.trackEvent({ name: this.name, properties: { type, message, args, ...(this.conferenceData ? this.conferenceData : null) } });
		}
		if (this.printConsole) {
			console[type](message, ...args);
		}
	};

	log(message, ...rest) {
		this.consoleMethod('log', message, ...rest);
	}

	info(message, ...rest) {
		this.consoleMethod('info', message, ...rest);
	}

	debug(message, ...rest) {
		this.consoleMethod('debug', message, ...rest);
	}

	warn(message, ...rest) {
		this.consoleMethod('warn', message, ...rest);
	}

	error = (message, ...rest) => {
		const args = rest.filter(val => val !== undefined);

		if (this.appInsights?.appInsights?.isInitialized()) {
			this.appInsights.trackException({
				exception: new Error(message),
				properties: { name: this.name, message, args, ...(this.conferenceData ? this.conferenceData : null) },
			});
		}
		if (this.printConsole) {
			console.error(message, ...args);
		}
	};
}

export default Logger;
