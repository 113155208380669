import React, { useState, useEffect, useRef } from 'react';
import propsTypes from 'prop-types';
import { CSVLink } from 'react-csv';

const CsvExport = ({ asyncExportMethod, children, disable, fileName = undefined, className = 'button download' }) => {
	const [csvData, setCsvData] = useState(null);
	const csvInstance = useRef();
	useEffect(() => {
		if (csvData && csvInstance.current && csvInstance.current.link) {
			setTimeout(() => {
				csvInstance.current.link.click();
				setCsvData(null);
			});
		}
	}, [csvData]);
	return (
		<>
			<div
				className={className}
				data-cy='exportAsCSV'
				onClick={async () => {
					if (disable) {
						return;
					}
					const newCsvData = await asyncExportMethod();
					setCsvData(newCsvData);
				}}>
				{children}
			</div>
			{csvData !== null ? <CSVLink data={csvData} ref={csvInstance} filename={fileName} /> : undefined}
		</>
	);
};

export default CsvExport;

CsvExport.defaultProps = {
	children: undefined,
	asyncExportMethod: () => null,
	disable: false,
};

CsvExport.propTypes = {
	children: propsTypes.node,
	asyncExportMethod: propsTypes.func,
	disable: propsTypes.bool,
};
