import { UserPresenceActionTypes } from 'constants/action-types';

const initialState = {
	presenceStatusTypeId: 0,
	customMessage: '',
	defaultMessage: '',

	updatingUserPresence: false,
	fetchingUserPresence: false,
};

export const userPresence = (state, action) => {
	state = state || initialState;

	switch (action.type) {
		case UserPresenceActionTypes.UPDATING_USER_PRESENCE:
			return {
				...state,
				updatingUserPresence: true,
			};
		case UserPresenceActionTypes.UPDATE_USER_PRESENCE_FAILURE:
			return {
				...state,
				updatingUserPresence: false,
			};
		case UserPresenceActionTypes.UPDATE_USER_PRESENCE_SUCCESS:
			return {
				...state,
				updatingUserPresence: false,
				...action.data,
			};
		case UserPresenceActionTypes.FETCHING_USER_PRESENCE:
			return {
				...state,
				fetchingUserPresence: true,
			};
		case UserPresenceActionTypes.FETCH_USER_PRESENCE_FAILURE:
			return {
				...state,
				fetchingUserPresence: false,
			};
		case UserPresenceActionTypes.FETCH_USER_PRESENCE_SUCCESS:
			return {
				...state,
				fetchingUserPresence: false,
				...action.data,
			};
	}

	return state;
};
