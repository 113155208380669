import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { Modal, Form, Input, Alert } from 'components';
import { deleteOrgUnit } from 'api/organization';
import { editRegionName, createNewRegion, editHealthSystemName } from 'api/healthSystems';
import { DeviceListLevel } from 'constants/enums';
import { getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { setTimeout } from 'timers';
import { treeSectorNameMaxCharacters } from 'constants/global-variables';

class EditHealthSystemModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isConfirmDeleteRegionModalOpen: false,
			currentRegions: [],
			healthSystemName: '',
			healthSystemId: '',
			isRegionSelected: false,
			showSuccessAlert: false,
			showErrorAlert: false,
			regionError: '',
			healthSystemNameError: '',
		};
		this.hasPressedEnter = 13;
		this.healthSystemActionsEnum = {
			EDIT_HEALTH_SYSTEM: 0,
			EDIT_REGION_NAME: 1,
			CREATE_NEW_REGION: 2,
			DELETE_HEALTH_SYSTEM: 3,
			DELETE_REGION: 4,
		};
	}

	componentDidUpdate(prevProps) {
		let currentRegionsChanged = JSON.stringify(this.props.currentRegions) !== JSON.stringify(prevProps.currentRegions);
		if (currentRegionsChanged) {
			this.setState({
				currentRegions: this.props.currentRegions,
				healthSystemName: this.props.healthSystemData.currentHealthSystemName,
				healthSystemId: this.props.healthSystemData.currentHealthSystemId,
			});
		}
	}

	editHealthSystemActions = async data => {
		switch (data.action) {
			case this.healthSystemActionsEnum.EDIT_HEALTH_SYSTEM:
				await editHealthSystemName(data.healthSystemId, data.newHealthSystemName);
				break;
			case this.healthSystemActionsEnum.EDIT_REGION_NAME:
				await editRegionName(data.healthSystemId, data.regionId, data.newRegionName);
				break;
			case this.healthSystemActionsEnum.CREATE_NEW_REGION:
				return createNewRegion(data.healthSystemId, data.newRegionName);
			case this.healthSystemActionsEnum.DELETE_HEALTH_SYSTEM:
				await deleteOrgUnit(DeviceListLevel.HEALTHSYSTEM, data.healthSystemId);
				break;
			case this.healthSystemActionsEnum.DELETE_REGION:
				await deleteOrgUnit(DeviceListLevel.REGION, data.regionId);
				break;
			default:
				break;
		}
	};

	setHealthSystemInputValue = evt => {
		this.setState({
			[evt.target.name]: evt.target.value,
		});

		if (evt.which === this.hasPressedEnter) {
			this.saveHealthSystemName(evt.target.value);
		}
	};

	saveHealthSystemName = async healthSystemName => {
		if (this.checkIfHealthSystemExists()) {
			this.setState({
				healthSystemNameError: 'Health system with this name already exists',
			});
			return;
		}

		if (this.state.healthSystemName.length > treeSectorNameMaxCharacters) {
			this.setState({
				healthSystemNameError: `Name can't be longer than ${treeSectorNameMaxCharacters} characters`,
			});
			return;
		}

		let { currentHealthSystemId } = getCurrentHealthSystemInfo();
		await this.editHealthSystemActions({
			action: this.healthSystemActionsEnum.EDIT_HEALTH_SYSTEM,
			healthSystemId: currentHealthSystemId,
			newHealthSystemName: healthSystemName,
		});
		this.props.onHealthSystemNameChange(healthSystemName);
		this.toggleAlert('showSuccessAlert');
		this.setState({
			healthSystemNameError: '',
		});
	};

	setRegionInputValue = async (evt, region) => {
		const keyPressed = evt.which;
		const inputVal = evt.target.value;
		region.name = inputVal;
		this.setState(
			{
				currentRegions: this.state.currentRegions,
				regionError: '',
			},
			() => {
				if (keyPressed === this.hasPressedEnter) {
					if (this.regionExist(region)) {
						this.setState({
							regionError: 'Location with this name already exists.',
						});
					} else if (region.name.length > treeSectorNameMaxCharacters) {
						this.setState({
							regionError: `Location(s) name can't be longer than ${treeSectorNameMaxCharacters} characters`,
						});
					} else {
						this.saveRegionName(region, inputVal);
					}
				}
			}
		);
	};

	checkIfHealthSystemExists = () =>
		this.props.allHealthSystems.some(healthSystem => healthSystem.name.toLowerCase() === this.state.healthSystemName.toLowerCase());

	regionExist = region => {
		return this.state.currentRegions.some(r => r.name === region.name && r.id !== region.id);
	};

	saveRegionName = async (region, regionName) => {
		let { currentHealthSystemId } = getCurrentHealthSystemInfo();
		if (region.isNewRegion) {
			let response = await this.editHealthSystemActions({
				action: this.healthSystemActionsEnum.CREATE_NEW_REGION,
				healthSystemId: currentHealthSystemId,
				newRegionName: regionName,
			});

			region.id = response.id;
			region.isNewRegion = false;
		} else {
			await this.editHealthSystemActions({
				action: this.healthSystemActionsEnum.EDIT_REGION_NAME,
				healthSystemId: currentHealthSystemId,
				regionId: region.id,
				newRegionName: regionName,
			});
		}
		this.toggleAlert('showSuccessAlert');
	};

	removeRegion = regionId => {
		let regions = this.state.currentRegions.filter(currentRegion => currentRegion.id !== regionId);
		this.setState({
			currentRegions: regions,
		});
	};

	addNewRegion = () => {
		let regions = this.state.currentRegions.map(currentRegion => ({ ...currentRegion }));
		regions.push({
			isNewRegion: true,
			name: '',
			id: JSON.stringify(new Date().getTime()),
		});

		this.setState({
			currentRegions: regions,
		});
	};

	deleteSelected = async () => {
		let { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		if (this.state.isRegionSelected) {
			let { isNewRegion } = this.state.currentRegions.find(currentRegion => currentRegion.id === this.state.selectedRegionId);
			if (!isNewRegion) {
				await this.editHealthSystemActions({
					action: this.healthSystemActionsEnum.DELETE_REGION,
					regionId: this.state.selectedRegionId,
				});
			}
			const isCurrentRegion = this.state.currentRegions.some(({ id }) => id === currentRegionId);
			this.removeRegion(this.state.selectedRegionId);
			if (isCurrentRegion) {
				this.moveToAnotherRegion();
			}
		} else {
			await this.editHealthSystemActions({
				action: this.healthSystemActionsEnum.DELETE_HEALTH_SYSTEM,
				healthSystemId: currentHealthSystemId,
			});
			this.props.onHealthSystemDelete();
		}
		this.toggleConfirmDeleteModal();
	};

	moveToAnotherRegion = () => {
		let newHealthSystemInfo = {
			currentHealthSystemId: this.state.healthSystemId,
			currentRegionId: this.state.currentRegions[0].id,
			currentHealthSystemName: this.state.healthSystemName,
		};

		localStorage.setItem('currentHealthSystemInfo', JSON.stringify(newHealthSystemInfo));
		this.props.onCurrentRegionDelete(this.state.currentRegions[0].id, 0);
	};

	toggleConfirmDeleteModal = regionId => {
		if (regionId && this.state.currentRegions.length < 2) {
			this.toggleAlert('showErrorAlert');
		} else {
			this.setState({
				isConfirmDeleteRegionModalOpen: !this.state.isConfirmDeleteRegionModalOpen,
				isRegionSelected: !!regionId,
				selectedRegionId: regionId,
			});
		}
	};

	toggleAlert = type => {
		this.setState({
			[type]: true,
		});

		setTimeout(() => {
			this.setState({
				[type]: false,
			});
		}, 3000);

		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}
	};

	render() {
		return (
			<>
				<Modal
					modalSelector='editHealthSystemModal'
					className='edit-hs'
					display={this.props.isOpen}
					isLoading={this.state.isEditSystemModalLoading}
					onModalSubmit={this.state.isEditSystemModalLoading}
					position='right'
					onModalClose={this.props.onModalClose}
					closeButtonText='Close'>
					<Form title='Manage Health System'>
						<div className='hs-list' data-cy='manageHealthSystem'>
							<Input
								onChange={this.setHealthSystemInputValue}
								onKeyUp={this.setHealthSystemInputValue}
								name='healthSystemName'
								label='Edit health system'
								description='Any changes you make will be applied to this health system. You can rename or delete this health system.'
								error={this.state.healthSystemNameError}
								type='text'
								value={this.state.healthSystemName}
								validationOptions={{}}
								bottomSpace='15px'
							/>
							<a onClick={() => this.toggleConfirmDeleteModal()}>
								<i className='material-icons-outlined delete'>delete</i>
							</a>
						</div>
						<Input
							label='Locations'
							description='These are the available locations on this health system. Create more, rename, or delete a location.'
							bottomSpace='10px'
						/>
						{this.state.currentRegions.map(region => (
							<div className='hs-list' data-cy='manageRegions'>
								<input
									onKeyUp={evt => this.setRegionInputValue(evt, region)}
									onChange={evt => this.setRegionInputValue(evt, region)}
									type='text'
									value={region.name}
								/>
								<a onClick={() => this.toggleConfirmDeleteModal(region.id)}>
									<i className='material-icons-outlined delete'>delete</i>
								</a>
							</div>
						))}
						<p>{this.state.regionError}</p>
						<div className='region-actions' data-cy='addNewRegion'>
							<small style={{ color: '#718093' }}>Press enter to save</small>
							<p>
								<a onClick={this.addNewRegion}>+ Add new location</a>
							</p>
						</div>
						<Alert
							alertSelector='editHealthSystemAlertMessage'
							display={this.state.showSuccessAlert}
							fixed={true}
							hideCloseButton={true}
							message='Changes saved.'
							variant='dark'
						/>
						<Alert
							alertSelector='deleteAllRegionsMessage'
							display={this.state.showErrorAlert}
							fixed={true}
							hideCloseButton={true}
							message='Health systems needs at least one location!'
							variant='error'
						/>
					</Form>
				</Modal>
				<Modal
					modalSelector='healthSystemModalCenter'
					display={this.state.isConfirmDeleteRegionModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteSelected}
					onModalClose={() => this.toggleConfirmDeleteModal()}>
					<Form title='Delete' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to delete {this.state.isRegionSelected ? 'location' : 'health system'}?</p>
					</Form>
				</Modal>
			</>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(EditHealthSystemModal);
