import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

export function getTeamConfigurationProfiles({ pageSize = 10, pageIndex = 0, teamId = null, teamTypeId = 4 }) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.get(`v1/healthcare/organizations/${companyId}/configurations/team-profiles`, {
				params: {
					pageSize,
					pageIndex,
					teamId,
					teamTypeId,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function addTeamConfigurationProfile(teamId, teamType, data) {
	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/teams/${teamId}/team-types/${teamType}/configuration-profiles`, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function updateTeamConfigurationProfile(teamId, teamType, id, data) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/teams/${teamId}/team-types/${teamType}/configuration-profiles/${id}`, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getTeamConfigurationProfilesHisotry({ id, pageSize = 10, pageIndex = 0 }) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/configurations/team-profiles/${id}/history`, {
				params: {
					pageSize,
					pageIndex,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function deleteTeamConfigurationProfile(teamId, teamType, id) {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/teams/${teamId}/team-types/${teamType}/configuration-profiles/${id}`)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}
