import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import { updateNurse, getNurseProfileInfo } from 'api/users';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers';

import { ProfilePicture } from 'components/ProfilePicture';
import { Alert, Loader } from 'components';
import Select from 'components/Common/FormElements/Select';
import Input from 'components/Common/FormElements/Input';
import { UserTypes, Roles } from 'constants/enums';
import { updateRole } from 'api/roles';
import MultiSelect from 'components/Common/FormElements/MultiSelect';

class UserInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			healthSystemInputType: this.healthSystemInputTypes.NONE,
			isMemberTitleEnabled: this.props.currentMemberObj.roles[0].id === UserTypes.VIRTUALCAREPROVIDER,
			role: {},
		};
	}

	healthSystemInputTypes = {
		NONE: 0,
		SINGLE: 1,
		MULTI: 2,
	};

	roleHealthSystemTypes = {
		[Roles.ADMIN.id]: this.healthSystemInputTypes.NONE,
		[Roles.SUPERUSER.id]: this.healthSystemInputTypes.SINGLE,
		[Roles.VIRTUALCAREPROVIDER.id]: this.healthSystemInputTypes.MULTI,
		[Roles.VIRTUALSITTER.id]: this.healthSystemInputTypes.MULTI,
	};

	roles = [Roles.VIRTUALCAREPROVIDER, Roles.VIRTUALSITTER, Roles.ADMIN, Roles.SUPERUSER];

	componentDidMount = async () => {
		let profileInfo = await getNurseProfileInfo(this.props.currentMemberObj.userInfo.userId);
		this.setState({
			loading: false,
			role: profileInfo.workExperience.length !== 0 ? this.getBanyanRole(profileInfo.workExperience) : {},
		});
	};

	getBanyanRole = workExperiences => {
		return workExperiences.find(ex => ex.showOnBC && ex.company === 'Banyan');
	};

	getSelectedHealthSystems = (values, roleId) => {
		if (roleId === UserTypes.ADMIN) return [];

		if (this.state.healthSystemInputType === this.healthSystemInputTypes.SINGLE) {
			return [this.props.healthSystems.find(x => x.id === values.healthSystem)];
		}

		return values.healthSystems.map(x => ({ id: x.value, value: x.label }));
	};

	onSubmit = async values => {
		this.props.onLoading('updateNurse');

		try {
			const roleId = parseInt(values.role, 10);
			let teamIds = null;
			if (this.state.healthSystemInputType === this.healthSystemInputTypes.SINGLE) {
				teamIds = [values.healthSystem];
			} else if (this.state.healthSystemInputType === this.healthSystemInputTypes.MULTI) {
				teamIds = values.healthSystems.map(x => x.value);
			}

			if (parseInt(this.props.currentMemberObj.roles[0].id, 10) !== parseInt(values.role, 10)) {
				await updateRole(this.props.currentMemberObj.memberId, { roleId, teamIds });
			}

			const updateNurseProps = {
				firstName: values.firstName,
				lastName: values.lastName,
				jobTitle: parseInt(values.role, 10) === UserTypes.VIRTUALCAREPROVIDER ? values.jobTitle : '',
				role: values.role,
			};
			await updateNurse(
				updateNurseProps,
				this.props.currentMemberObj.userInfo.userId,
				this.props.currentMemberObj.userInfo.id,
				this.state.role ? this.state.role.workExperienceId : 0
			);

			this.props.userUpdated(
				this.props.currentMemberObj.userInfo.userId,
				values.firstName,
				values.lastName,
				this.roles.find(x => x.id === roleId),
				this.getSelectedHealthSystems(values, roleId)
			);
			this.props.onSuccess('updateNurse');
		} catch (e) {
			this.props.onError('updateNurse');
		}
	};

	getValidationForm = () => {
		let validation = {
			firstName: Yup.string()
				.trim()
				.required('First name cannot be empty')
				.min(2, 'First name must be at least two characters')
				.max(50, 'First name can have a maximum of 50 characters'),
			lastName: Yup.string()
				.trim()
				.required('Last name cannot be empty')
				.min(2, 'Last name must be at least two characters')
				.max(50, 'Last name can have a maximum of 50 characters'),
		};

		if (this.state.healthSystemInputType === this.healthSystemInputTypes.SINGLE) {
			validation.healthSystem = Yup.string().required();
		} else if (this.state.healthSystemInputType === this.healthSystemInputTypes.MULTI) {
			validation.healthSystems = Yup.string().required();
		}

		return Yup.object().shape(validation);
	};

	getRole = id => {
		switch (id) {
			case UserTypes.OWNER: {
				return 'Owner';
			}
			case UserTypes.ADMIN: {
				return 'Admin';
			}
			case UserTypes.VIRTUALCAREPROVIDER: {
				return 'Virtual Care Provider';
			}
			case UserTypes.VIRTUALSITTER: {
				return 'Virtual Sitter';
			}
			case UserTypes.SUPERUSER: {
				return 'Super User';
			}
			default:
				return '';
		}
	};

	render() {
		if (this.state.loading) {
			return <Loader />;
		}

		return (
			<Formik
				enableReinitialize={true}
				initialValues={{
					firstName: decodeHtml(this.props.currentMemberObj.userInfo.firstName),
					lastName: decodeHtml(this.props.currentMemberObj.userInfo.lastName),
					jobTitle: this.state.role ? this.state.role.jobTitle : '',
					role: this.props.currentMemberObj.roles[0].id,
				}}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={this.onSubmit}
				validationSchema={this.getValidationForm}>
				{formikProps => {
					const { values, touched, errors, setFieldValue, setFieldTouched } = formikProps;
					// bind the submission handler remotely
					this.props.bindSubmitForm(formikProps.submitForm);
					return (
						<Form>
							<h3>Modify {this.getRole(this.props.currentMemberObj.roles[0].id)} Information</h3>
							<div className='edit-member-form' style={{ textAlign: 'center' }}>
								<ProfilePicture src={this.props.currentMemberObj.userInfo.profilePicture} size='medium' />
								<p>{decodeHtml(`${this.props.currentMemberObj.userInfo.firstName} ${this.props.currentMemberObj.userInfo.lastName}`)}</p>
							</div>

							{(this.props.currentMemberObj.roles[0].id === UserTypes.VIRTUALCAREPROVIDER ||
								this.props.currentMemberObj.roles[0].id === UserTypes.VIRTUALSITTER) && (
								<>
									<Field
										name='firstName'
										type='text'
										label='Enter First Name'
										placeholder='First Name'
										description="Modify the virtual care provider's first name"
										component={Input}
									/>
									<Field
										name='lastName'
										type='text'
										label='Enter Last Name'
										placeholder='Last Name'
										description="Modify the virtual care provider's last name"
										component={Input}
									/>
								</>
							)}
							<Field
								name='role'
								type='select'
								label='Choose User Role'
								initialValue=''
								placeholder='Select Member Role'
								description='The user role determines what the user will see and the permissions they will have.'
								items={this.roles}
								onChange={e => {
									const value = e.target.value;
									this.setState({
										healthSystemInputType:
											this.props.currentMemberObj.roles[0].id === parseInt(value, 10) ? this.healthSystemInputTypes.NONE : this.roleHealthSystemTypes[value],
										isMemberTitleEnabled: parseInt(value, 10) === UserTypes.VIRTUALCAREPROVIDER,
									});
								}}
								component={Select}
							/>
							{this.state.healthSystemInputType === this.healthSystemInputTypes.SINGLE && (
								<Field
									name='healthSystem'
									type='select'
									label='Health Systems'
									initialValue='Virtual Sitter'
									placeholder='Select Health System'
									description='Please select a health system'
									items={this.props.healthSystems}
									component={Select}
								/>
							)}
							{this.state.healthSystemInputType === this.healthSystemInputTypes.MULTI && (
								<MultiSelect
									name='healthSystems'
									label='Health Systems'
									placeholder='Select Health System'
									description='Please select at least one health system'
									options={this.props.healthSystems.map(hs => {
										return { value: hs.id, label: hs.value };
									})}
									value={values.healthSystems}
									isMulti={true}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									touched={touched.healthSystems}
									error={errors.healthSystems}
									isClearable={true}
									backspaceRemovesValue={true}
								/>
							)}

							{this.state.isMemberTitleEnabled && (
								<Field
									name='jobTitle'
									type='select'
									label='Select Member Title'
									initialValue='Virtual Sitter'
									placeholder='Select Member Title'
									description='Please assign a title for the virtual care provider.'
									items={[
										{ id: 'Virtual Nurse', value: 'Virtual Nurse' },
										{ id: 'Virtual Case Manager', value: 'Virtual Case Manager' },
										{ id: 'Virtual Sitter', value: 'Virtual Sitter' },
										{ id: 'Virtual Intensivist', value: 'Virtual Intensivist' },
									]}
									component={Select}
								/>
							)}

							<Alert
								alertSelector='editMemberAlertMessage'
								display={this.props.loaded}
								message='The member was successfully modified !'
								variant='success'
								onClose={() => this.props.onCloseAlert('updateNurse')}
							/>
							<Alert
								display={this.props.error}
								message='Something went wrong! Please try again. '
								variant='error'
								onClose={() => this.props.onCloseAlert('updateNurse')}
							/>
						</Form>
					);
				}}
			</Formik>
		);
	}
}

export default UserInformation;
