import React from 'react';
import Grid from 'components/Grid';
import { StreamError } from 'constants/enums';
import { isMacintosh } from 'infrastructure/helpers/commonHelpers';

const StreamPermissions = props => (
	<div style={{ textAlign: 'center' }}>
		<Grid className='incoming-calls stream-permissions' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
			<div>
				{props.reason.type === StreamError.PERMISSION_DISMISSED.type && (
					<div>
						<h2>Allow Banyan to use your microphone</h2>
						<br />
						<p>Banyan needs access to your microphone so patients can hear you. Check the top left of the screen and click Allow.</p>
					</div>
				)}

				{props.reason.type === StreamError.STREAM_NOT_ALLOWED.type && (
					<div>
						<h3>Turn on Microphone and Camera</h3>
						<br />
						<p>To make a call on Banyan, you need to allow permissions for microphone and camera.</p>
					</div>
				)}

				{props.reason.type === StreamError.DEVICE_NOT_FOUND.type && (
					<div>
						<h2>Device not found</h2>
						<br />
						<p>
							To make a video call on Banyan, you need to turn on your camera. For
							<br />
							an audio call, you need to turn on your microphone. Try again by refreshing
							<br />
							the page.
						</p>
					</div>
				)}

				{props.reason.type === StreamError.NOT_READABLE_ERROR.type && (
					<div>
						<h2>Device may be in use</h2>
						<br />
						<p>
							Camera or microphone may be in use by another application.
							<br />
							Please close the other application and refresh the page.
						</p>
					</div>
				)}

				{props.reason.type === StreamError.MICROPHONE_NOT_FOUND.type && (
					<div>
						<h2>Can’t find your microphone</h2>
						<br />
						<p>
							Please check on your system if a mic has been plugged in and you have allowed mic permissions. If not, plug one in or allow permissions and
							restart your browser. Patients won’t be able to hear you until you do that.
						</p>
					</div>
				)}

				{props.reason.type === StreamError.CAMERA_BLOCKED.type && (
					<div>
						<img src='https://static.solaborate.com/banyan/banyan-camera-permission.svg' alt='Camera permission' />
						<h3>Your Camera is blocked</h3>
						<br />
						<p>
							• Click the Camera blocked icon on the top right of your screen
							<br />• Allow access and refresh the page
						</p>
					</div>
				)}

				{props.reason.type === StreamError.MICROPHONE_BLOCKED.type && (
					<div>
						<img
							src={`https://static.solaborate.com/banyan/${isMacintosh() ? 'banyan-microphone-permission.svg' : 'banyan-camera-permission.svg'}`}
							alt='Microphone permission'
						/>
						<h3>Your Microphone is blocked</h3>
						<br />
						<p>
							• Click the Microphone blocked icon on the top right of your screen
							<br />• Allow access and refresh the page
						</p>
					</div>
				)}

				{props.reason.type === StreamError.CAM_AND_MIC_BLOCKED.type && (
					<div>
						<img src='https://static.solaborate.com/banyan/banyan-camera-permission.svg' alt='Microphone and camera permission' />
						<h3>Your Camera and Microphone are blocked</h3>
						<br />
						<p>
							• Click the camera blocked icon on the top right of your screen
							<br />• Allow access and refresh the page
						</p>
					</div>
				)}
			</div>
		</Grid>
	</div>
);

export default StreamPermissions;
