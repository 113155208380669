// eslint-disable-next-line max-classes-per-file
import { APP_CONFIG } from 'constants/global-variables';

export class Offer {
	constructor(actioneeParticipantId, callType, conferenceId, forScreenShare, isAudio, isVideo, isScreen, toScreenSharingSocket, participantId) {
		this.actioneeParticipantId = actioneeParticipantId;

		this.callType = callType;

		this.conferenceId = conferenceId;

		this.forScreenShare = forScreenShare;

		this.isAudio = isAudio;

		this.isVideo = isVideo;

		this.isScreen = isScreen;

		this.toScreenSharingSocket = toScreenSharingSocket;

		this.participantId = participantId;
	}
}

export class ConferenceInfo {
	constructor(
		callType,
		conferenceId,
		conferenceName,
		conversationId,
		fromUser,
		isAudio,
		isChat,
		isDirectToHello,
		isMeetingRoom,
		isMultiparty,
		isScreen,
		isVideo,
		participantId,
		participants,
		inputDevices,
		isSecurityCam = false,
		source = 'WEB'
	) {
		this.callType = callType;

		this.conferenceId = conferenceId;

		this.conferenceName = conferenceName;

		this.conversationId = conversationId;

		this.from = fromUser;

		this.isAudio = isAudio;

		this.isChat = isChat;

		this.isDirectToHello = isDirectToHello;

		this.isMeetingRoom = isMeetingRoom;

		this.isMultiparty = isMultiparty;

		this.isScreen = isScreen;

		this.isVideo = isVideo;

		this.participantId = participantId;

		this.participants = participants;

		this.inputDevices = inputDevices;

		this.isSecurityCam = isSecurityCam;

		this.source = source;

		this.tenantId = APP_CONFIG.companyId;
	}
}

export class FromUser {
	constructor(name, jobTitle, picture, objectGuidId, friendlyUrl) {
		this.name = name;

		this.jobTitle = jobTitle;

		this.picture = picture;

		this.objectGuidId = objectGuidId;

		this.friendlyUrl = friendlyUrl;
	}
}

export class ConferenceParticipant {
	constructor(objectId, objectType) {
		this.objectId = objectId;

		this.objectType = objectType;
	}
}
