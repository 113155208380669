import { gatewayApi } from 'constants/global-variables';

export function getUserPresence(userId) {
	return new Promise((res, rej) => {
		const url = `/v1/users/${userId}/presence`;
		gatewayApi
			.get(url)
			.then(response => {
				res(response.data.presence);
			})
			.catch(e => {
				console.log(e);
				rej(e);
			});
	});
}

export function putUserPresence(userId, customMessage, presenceStatusTypeId) {
	return new Promise((res, rej) => {
		const url = `/v1/users/${userId}/presence`;
		gatewayApi
			.put(url, {
				customMessage: customMessage,
				presenceStatusTypeId: presenceStatusTypeId,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}
