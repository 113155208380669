import React from 'react';
import PropTypes from 'prop-types';

import CallButton from 'components/CallButton';

const NightVisionControl = props => {
	return (
		<>
			{props.showButton && (
				<CallButton
					buttonSelector='nightVisionBtn'
					className='night-vision'
					icon='brightness_3'
					isActive={!props.nightVisionMode}
					onClick={!props.isDisabled ? props.toggleNightvisionHandler : null}
					tooltip={props.nightVisionMode ? 'Disable night vision' : 'Enable night vision'}
					tooltipPosition='top'
					isDisabled={props.isDisabled}
				/>
			)}
		</>
	);
};

NightVisionControl.defaultProps = {
	showButton: true,
};

NightVisionControl.propTypes = {
	nightVisionMode: PropTypes.bool,
	showButton: PropTypes.bool,
	isDisabled: PropTypes.bool,
	toggleNightvisionHandler: PropTypes.func,
};

export default NightVisionControl;
