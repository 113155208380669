import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import Callback from 'components/auth/callback';
import { Logout } from 'components/auth/logout';
import { LogoutCallback } from 'components/auth/logoutCallback';
import { PrivateRoute } from 'routes/privateRoute';
// import { Register } from "components/auth/register";
import { SilentRenew } from 'components/auth/silentRenew';
import Organization from 'views/Organization/Organization';
import Monitoring from 'views/Monitoring';
import CallLogs from 'views/CallLogs';
import Call from 'views/Call';
import PatientFeed from 'views/PatientFeed';
import Users from 'views/Users';
import Login from 'views/Login';
import CreateHealthSystem from 'views/CreateHealthSystem';
import CompleteProfile from 'views/CompleteProfile';
import Help from 'views/Help';
import Recover from 'views/Recover';
import ResetPassword from 'views/ResetPassword';
import { UserRoles } from 'constants/enums';
import Configurations from 'views/Configurations';
import ConfigurationsHistory from 'views/ConfigurationsHistory';
import JWTAuthenticatedRoute from 'routes/jwtAuthenticatedRoute';
import TimeError from 'views/TimeError';

// eslint-disable-next-line import/prefer-default-export
export const Routes = (
	<Switch>
		<Route exact={true} path='/signin-oidc' component={Callback} />
		<Route exact={true} path='/logout' component={Logout} />
		<Route path='/time-error' component={TimeError} />
		<Route exact={true} path='/logout/callback' component={LogoutCallback} />
		<Route exact={true} path='/silent-renew' component={SilentRenew} />
		<Route exact={true} path='/recover' component={Recover} />
		<Route exact={true} path='/validate/recoverPassword' component={ResetPassword} />
		<Route path='/complete-profile' component={CompleteProfile} />
		<Route path='/help' component={Help} />

		<PrivateRoute path='/health-system/:healthSystem/department/:department/floor/:floor/room/:room' component={Organization} />
		<PrivateRoute path='/health-system/:healthSystem/department/:department/floor/:floor' component={Organization} />
		<PrivateRoute path='/health-system/:healthSystem/department/:department' component={Organization} />
		<PrivateRoute path='/health-system/:healthSystem' component={Organization} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.NURSE, UserRoles.SUPERUSER]} path='/health-system' component={Organization} />
		<PrivateRoute roles={[UserRoles.NURSE, UserRoles.VIRTUALSITTER]} path='/monitoring' component={Monitoring} />
		<PrivateRoute exact path='/' component={Login} />
		<PrivateRoute roles={[UserRoles.ADMIN]} path='/create' component={CreateHealthSystem} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPERUSER]} path='/users' component={Users} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPERUSER]} path='/calllogs' component={CallLogs} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPERUSER]} exact path='/configurations' component={Configurations} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPERUSER]} exact path='/configurations/:id/type/:type' component={ConfigurationsHistory} />
		<JWTAuthenticatedRoute path='/call/:callType/:patientId/:roomName/:incoming' component={Call} />
		<PrivateRoute path='/patient-feed/:patientId/:roomName' component={PatientFeed} />
	</Switch>
);
