import React from 'react';
import io from 'socket.io-client';
import _ from 'lodash';
import SocketEvents from 'constants/socket-events';
import { PresenceStatusType, SocketState, DeviceStatus, ParticipantState, UserRoles } from 'constants/enums';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { isAuthenticated, getAccessToken, getUserId, hasUserProfile, getUserProfile, memberExists, getUserRole } from 'infrastructure/auth';
import { fetchNotificationCounter } from 'state/notifications/actions';
import { fetchUserPresence, fetchUserPresenceSucceeded, userPresenceUpdateSucceeded } from 'state/userPresence/actions';
import { APP_CONFIG, BanyanClientType, BanyanAppType } from 'constants/global-variables';
import { SocketContext } from './SocketContext';
import { findDeviceById } from 'infrastructure/helpers/commonHelpers';

class Socket extends React.Component {
	constructor(props) {
		super(props);

		this.connect();
	}

	connect = () => {
		if (!isAuthenticated() || !memberExists()) {
			return;
		}

		const signalingUrl = `${
			process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? process.env.REACT_APP_SIGNALING_URL : window.__env__.REACT_APP_SIGNALING_URL
		}banyan`;

		this._socket = io.connect(signalingUrl, {
			secure: true,
			transports: ['websocket'],
		});

		this.initSocketListeners();
	};

	initSocketListeners = () => {
		const { _socket } = this;
		let myClientInfo = null;
		let mySocketId = null;

		_socket.on(SocketEvents.Client.ON_CONNECT, () => {
			// change socket state to SocketState.CONNECTED only if it's from re-connection
			if (mySocketId) {
				this.props.onConnectionStateChange(SocketState.CONNECTED);
			}

			myClientInfo = {
				token: getAccessToken(),
				clearConferences: false,
				clientType: BanyanClientType,
				appType: BanyanAppType,
				versionName: APP_CONFIG.buildNumber,
				oldSocketId: mySocketId,
				incomingCallsDisabled: this.props.shouldDisableIncomingCalls(),
			};

			mySocketId = _socket.id;
			_socket.emit(SocketEvents.Client.AUTHORIZE, myClientInfo, this.handleUserPresence);
		});

		_socket.on(SocketEvents.Client.ON_DISCONNECT, reason => {
			if (reason === 'io server disconnect') {
				// the disconnection was initiated by the server, you need to reconnect manually
				_socket.connect();
			}

			this.props.onConnectionStateChange(SocketState.DISCONNECTED);
		});

		_socket.on(SocketEvents.Client.ON_RECONNECTING, () => {
			this.props.onConnectionStateChange(SocketState.RECONNECTING);
		});

		_socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, _data => {
			if (!this.props.organization.treeData.tree) {
				return;
			}

			this.setStatusDevice(_data.helloDeviceId, false);
		});

		_socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, _data => {
			if (!this.props.organization.treeData.tree) {
				return;
			}
			this.setStatusDevice(_data.helloDeviceId, true);
		});

		_socket.on(SocketEvents.Client.ON_UPDATED_USER_PRESENCE, data => {
			const isCurrentUser = data.objectId === getUserProfile().userId;
			if (isCurrentUser) {
				this.props.fetchUserPresenceSucceeded(data);
			}
			this.props.userPresenceUpdateSucceeded(getUserId(), data.customMessage, data.presenceStatusTypeId);
		});

		_socket.on(SocketEvents.HelloDevice.ON_UPDATE, _data => {
			if (!this.props.organization.treeData.tree) {
				return;
			}
			this.setStatusDevice(_data.id, _data.status);
		});

		_socket.on('error', function(err) {
			console.error(`Socket.IO error: ${err}`);
		});

		_socket.on(SocketEvents.User.NOTIFICATIONS_UPDATED, () => {
			this.props.fetchNotificationsCounter();
		});

		_socket.on(SocketEvents.User.ON_PASSWORD_CHANGED, () => {
			window.location.href = '/logout';
		});

		_socket.on(SocketEvents.HelloDevice.ON_CALL_STATE_CHANGED, data => {
			this.setDeviceCallState(data);
		});
	};

	setStatusDevice = (deviceId, isOnline) => {
		const newTree = JSON.parse(JSON.stringify(this.props.organization.treeData.tree));
		const room = findDeviceById(newTree, deviceId);

		if (room) {
			room.status = isOnline ? DeviceStatus.ONLINE : DeviceStatus.OFFLINE;
			if (!isOnline) {
				room.deviceBusy = false;
				room.activeConferences = [];
			}
			this.props.organizationActions.setTreeData({
				tree: newTree,
				preSelected: this.props.organization.treeData.preSelected,
			});
		}
	};

	setDeviceCallState = ({ deviceId, activeConferences }) => {
		const tree = _.cloneDeep(this.props.organization.treeData.tree);
		const room = findDeviceById(tree, deviceId);

		if (!room) {
			return;
		}

		room.deviceBusy = activeConferences.length > 0;
		room.activeConferences = activeConferences;
		this.props.organizationActions.setTreeData({
			tree,
			preSelected: this.props.organization.treeData.preSelected,
		});
	};

	handleUserPresence = data => {
		const noCallsShouldBeReceived = '2';
		if (
			getUserRole() === UserRoles.NURSE &&
			(!localStorage.getItem('nursePoolingStatus') || localStorage.getItem('nursePoolingStatus') === noCallsShouldBeReceived)
		) {
			this.changeUserPresence(PresenceStatusType.UNAVAILABLE);
			return;
		}
		if (data && data.userSocketsLength === 1) {
			const presenceStatusTypeId = localStorage.getItem('presenceStatusTypeId');
			const notFirstConnect = sessionStorage.getItem('notFirstConnect');

			if (presenceStatusTypeId && notFirstConnect) {
				const a = JSON.parse(presenceStatusTypeId);

				this.changeUserPresence(a);
			} else {
				this.changeUserPresence(PresenceStatusType.AVAILABLE);
				if (presenceStatusTypeId) {
					localStorage.removeItem('presenceStatusTypeId');
				}
			}
			sessionStorage.setItem('notFirstConnect', 'true');
		} else {
			this.props.fetchUserPresence();
		}
	};

	changeUserPresence = presenceStatusTypeId => {
		const data = {
			userId: getUserId(),
			presenceStatusTypeId: presenceStatusTypeId,
			customMessage: null,
		};
		this._socket.emit(SocketEvents.Client.UPDATE_USER_PRESENCE, data);
	};

	render() {
		return <SocketContext.Provider value={this._socket}>{this.props.children}</SocketContext.Provider>;
	}
}

const mapStateToProps = state => {
	return {
		organization: state.organization,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		fetchNotificationsCounter: () => dispatch(fetchNotificationCounter()),
		fetchUserPresence: () => dispatch(fetchUserPresence()),
		fetchUserPresenceSucceeded: () => dispatch(fetchUserPresenceSucceeded()),
		userPresenceUpdateSucceeded: (userId, customMessage, presenceStatusTypeId) =>
			dispatch(userPresenceUpdateSucceeded(userId, customMessage, presenceStatusTypeId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Socket);
