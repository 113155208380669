import React from 'react';
import { Modal } from 'components';
import PoolingFlow from 'containers/PoolingFlow/index';
import styled from 'styled-components';

const EditPoolingModal = props => (
	<ModalWrapper>
		<Modal display={true} position='right' onModalClose={props.toggleEditPoolingModal}>
			<PoolingFlow changePage={props.toggleEditPoolingModal} isEdit={props.isEdit} />
		</Modal>
	</ModalWrapper>
);

const ModalWrapper = styled.div`
	.modal.right {
		.modal__content {
			height: 100vh;
			background: var(--gray-1);
			padding-bottom: var(--spacing-m);
		}
	}

	.modal__actions {
		display: none;
	}
`;

export default EditPoolingModal;
