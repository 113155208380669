import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';
import Select from 'components/Common/FormElements/Select';
import { Form, Modal, Alert } from 'components';
import Loader from 'components/Loader';
import { addActiveDirectory, addGroupRole, updateActiveDirectory, updateGroupRole } from 'api/activeDirectories';

export default class ActiveDirectoryForm extends React.Component {
	state = {
		isSuccessfulAlertOn: false,
		isErrorsAlertOn: false,
		isPasswordChanged: false,
	};

	handleSubmitMyForm = async (values, { setSubmitting }) => {
		setSubmitting(true);
		try {
			const hospitalIds = [values.hospital];
			let res = null;
			if (this.props.activeTab === 0) {
				const { domain, validGroupName, adClientSecret, adClientId } = values;
				if (this.props.initialValues) {
					res = await updateActiveDirectory(this.props.initialValues.id, {
						domain,
						validGroupName,
						username: adClientId,
						password: this.state.isPasswordChanged ? adClientSecret : this.props.initialValues.fullPassword,
					});
				} else {
					const [hospitalId] = hospitalIds;
					res = await addActiveDirectory({ hospitalId, domain, validGroupName, username: adClientId, password: adClientSecret });
				}
				if (res.hasSucceeded) {
					this.setState({
						isSuccessfulAlertOn: true,
					});
				}
			} else {
				const [roleId] = [values.role];
				const { group } = values;

				if (this.props.initialValues) {
					res = await updateGroupRole(this.props.initialValues.id, { roleId, group });
				} else {
					const [hospitalId] = hospitalIds;
					res = await addGroupRole({ hospitalId, roleId, group });
				}
				if (!res.exists) {
					this.setState({
						isSuccessfulAlertOn: true,
					});
				}
			}
			this.props.onSucceeded();
		} catch (e) {
			this.setState({
				isErrorsAlertOn: true,
			});
		}
		setSubmitting(false);
	};

	onCloseModal = () => {
		this.setState({
			isSuccessfulAlertOn: false,
			isErrorsAlertOn: false,
			isPasswordChanged: false,
		});

		this.props.toggleModal();
	};

	getValidationSchema = () => {
		const validation = {};
		if (!this.props.initialValues) {
			validation.healthSystem = Yup.string().required('Health system is required');
			validation.hospital = Yup.string().required('Hospital is required');
		}
		if (this.props.activeTab === 0) {
			validation.domain = Yup.string().required('Domain is required');
			validation.validGroupName = Yup.string().required('Valid group name is required');
			validation.adClientId = Yup.string().required('ClientId is required');
			validation.adClientSecret = Yup.string().required('Client Secret is required');
		} else {
			validation.role = Yup.string().required('Role is required');
			validation.group = Yup.string().required('Group is required');
		}

		return validation;
	};

	render() {
		return (
			<Formik
				enableReinitialize={true}
				initialValues={
					this.props.initialValues || { healthSystem: '', hospital: '', domain: '', validGroupName: '', adClientId: '', adClientSecret: '', roles: '' }
				}
				validationSchema={Yup.object().shape(this.getValidationSchema())}
				onSubmit={this.handleSubmitMyForm}>
				{props => {
					const { handleSubmit, isSubmitting, setFieldValue } = props;
					return (
						<Modal
							modalSelector='activeDirectoryModal'
							display={this.props.isModalOpen}
							position='right'
							onModalSubmit={handleSubmit}
							onModalClose={this.onCloseModal}
							isLoading={isSubmitting}>
							<Form>
								<h3>{this.props.activeTab === 0 ? 'Active directory' : 'Group roles'}</h3>
								{!this.props.initialValues ? (
									<>
										<Field
											name='healthSystem'
											type='select'
											label='Select Health System'
											placeholder='Select Health System'
											description='Select health system to apply active directory'
											onChange={this.props.onHealthSystemChange}
											items={this.props.healthSystems}
											component={Select}
										/>
										{this.props.isHospitalLoading ? (
											<div style={{ textAlign: 'center' }}>
												<Loader />
											</div>
										) : (
											<>
												<Field
													name='hospital'
													type='select'
													label='Choose Hospital'
													placeholder='Select Hospital'
													description='Select Hospital to apply active directory'
													items={this.props.hospitals}
													component={Select}
												/>
											</>
										)}
									</>
								) : (
									<>
										<Field name='hospital' label='Hospital' description='Select Hospital to apply active directory' disabled={true} component={Input} />
									</>
								)}

								{this.props.activeTab === 0 ? (
									<>
										<Field
											name='domain'
											label='Set Azure AD Url'
											placeholder='Endpoint Url'
											description='https://login.microsoftonline.com/ + tenantId'
											component={Input}
										/>
										<Field
											name='validGroupName'
											label='Set valid group name'
											placeholder='Group name'
											description='Active directory valid group name'
											component={Input}
										/>

										<Field
											name='adClientId'
											label='Client Id'
											placeholder='Client Id'
											description='Set active directory client id'
											type='text'
											component={Input}
										/>
										<Field
											name='adClientSecret'
											label='Client Secret'
											placeholder='Client Secret'
											description='Set active directory client secret'
											type={this.props.initialValues && !this.state.isPasswordChanged ? 'text' : 'password'}
											component={Input}
											autoComplete='new-password'
											onChange={e => {
												const { value } = e.target;
												if (!this.state.isPasswordChanged) {
													this.setState({
														isPasswordChanged: true,
													});
												}
												setFieldValue('adClientSecret', !this.state.isPasswordChanged ? value[value.length - 1] : value);
											}}
										/>
									</>
								) : (
									<>
										<Field
											name='role'
											type='select'
											label='Choose Role'
											placeholder='Select Role'
											description='Select Role to apply active directory'
											items={this.props.roles}
											component={Select}
										/>
										<Field name='group' label='Set group' placeholder='Group' description='Active directory role group' component={Input} />
									</>
								)}

								<Alert
									alertSelector='activeDirectoryMessage'
									display={this.state.isSuccessfulAlertOn || this.state.isErrorsAlertOn}
									message={this.state.isSuccessfulAlertOn ? 'Configuration successfully added' : 'Something went wrong! Please try again.'}
									variant={this.state.isSuccessfulAlertOn ? 'success' : 'error'}
									onClose={() => this.setState({ isSuccessfulAlertOn: false, isErrorsAlertOn: false })}
								/>
							</Form>
						</Modal>
					);
				}}
			</Formik>
		);
	}
}
