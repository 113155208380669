import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Toggle, Tilt } from 'components';
import { CameraType } from 'constants/enums';
import Modal from 'components/Modal';
import Form from 'components/Form';

class CameraControls extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			isRebootCameraModalOpen: false,
		};
	}

	onRebootHuddleCamClick = async () => {
		if (!this.state.isRebootCameraModalOpen) {
			return;
		}

		this.props.onRebootHuddleCam();

		this.setState({ isRebootCameraModalOpen: false });
	};

	render() {
		const { state, props } = this;
		const shouldDisableTilt = props.isDisabled || (props.isCameraPrivacyOn && props.cameraType === CameraType.HELLO);

		return (
			<>
				{props.showPTZ && (
					<div className='patient-view-control'>
						<Tilt
							tiltHandler={props.onPanTiltCamera}
							cameraZoomLevel={props.cameraZoomLevel}
							cameraType={props.cameraType}
							updateZoomLevel={props.onZoomCamera}
							disabledDirections={props.disabledTiltDirections}
							disabled={shouldDisableTilt}
							onHuddleCamReboot={() => this.setState({ isRebootCameraModalOpen: true })}
						/>
						<Toggle
							className='toggle-feed'
							toggleOnText='Bridge'
							toggleOffText='Huddle'
							toggledOn={props.cameraType === CameraType.HELLO}
							onToggle={props.onToggleCameraSwitch}
							disabled={!props.isHuddleCamConnected || props.isDisabled}
						/>
						{props.cameraType === CameraType.HUDDLE && (
							<Modal
								display={state.isRebootCameraModalOpen}
								position='center'
								submitButtonText='Reboot'
								onModalSubmit={this.onRebootHuddleCamClick}
								onModalClose={() => this.setState({ isRebootCameraModalOpen: false })}>
								<Form title='Reboot camera' onSubmit={event => event.preventDefault()}>
									<p>Are you sure you want to reboot huddle cam?</p>
								</Form>
							</Modal>
						)}
					</div>
				)}
			</>
		);
	}
}

CameraControls.defaultProps = {
	showPTZ: true,
	cameraType: CameraType.HELLO,
	cameraZoomLevel: 0,
	isHuddleCamConnected: true,
};

CameraControls.propTypes = {
	showPTZ: PropTypes.bool,
	cameraType: PropTypes.string,
	cameraZoomLevel: PropTypes.number,
	onToggleCameraSwitch: PropTypes.func,
	onZoomCamera: PropTypes.func,
	onPanTiltCamera: PropTypes.func,
	onRebootHuddleCam: PropTypes.func,
	disabledTiltDirections: PropTypes.object,
	isHuddleCamConnected: PropTypes.bool,
};

export default CameraControls;
