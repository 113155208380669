import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

class LeftNavigation extends Component {
	render() {
		return (
			<ul className={classNames('left-navigation', this.props.expanded ? 'expanded' : '')} data-cy='leftNavigation'>
				<li className='logo'>
					<a>
						<img src='https://static.solaborate.com/temp/banyan.png' alt='left-nav-logo' />
					</a>
				</li>
				{this.props.links.map((item, index) => (
					<li key={index}>
						<NavLink
							to={item.linkTo}
							exact={item.exact}
							activeClassName='active'
							onClick={event => (item.linkTo === '#' ? this.props.onItemClicked(item, event) : null)}>
							<i className='material-icons-outlined'>{item.icon}</i>
							<span>{item.title}</span>
						</NavLink>
					</li>
				))}
			</ul>
		);
	}
}

export default LeftNavigation;
