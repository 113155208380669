import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { withRouter, Route } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { UserRoles } from 'constants/enums';

import Grid from 'components/Grid';
import Layout from 'components/Common/Layout';
import Header from 'components/Common/Header';
import SectorList from 'components/Common/SectorList';

import AssignDeviceModal from 'components/Common/AssignDeviceModal';

import CallPatient from 'views/Organization/Partials/CallPatient';
import Hospital from 'views/Organization/Partials/Hospital';
import Department from 'views/Organization/Partials/Department';
import Floor from 'views/Organization/Partials/Floor';
import Room from 'views/Organization/Partials/Room';
import UnassignedNurse from 'views/UnassignedNurse';

import { getUserRole } from 'infrastructure/auth';

class Organization extends Component {
	hasHealthSystems = () => {
		return this.props.allHealthSystems.length > 0;
	};

	isRoomURL = () => {
		let pathName = window.location.pathname.split('/');
		return pathName.some((path, index) => path.toLowerCase() === 'room' && pathName[index + 1]);
	};

	render() {
		if (!this.hasHealthSystems()) {
			return <UnassignedNurse />;
		}

		const userRole = getUserRole();
		return (
			<Layout twoColumns={true}>
				<aside className='hello-list' data-cy='sectorsList'>
					<SectorList />
				</aside>
				<main className='main-view'>
					<Header />
					<section data-cy='treeNavSection'>
						<Grid width='100%' vertAlign={[UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole) ? 'start' : 'center'} horizAlign='center' rows='auto'>
							<If condition={[UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole)}>
								<Then>
									<Route exact path='/health-system/:hospitalId' component={Hospital} />
									<Route exact path='/health-system/:hospitalId/department/:departmentId' component={Department} />
									<Route exact path='/health-system/:hospitalId/department/:departmentId/floor/:floorId' component={Floor} />
									<Route exact path='/health-system/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId' component={Room} />
								</Then>
								<Else>
									{!this.isRoomURL() && (
										<p>
											In order to communicate with a room, <br />
											please open a hospital, choose the department, <br />
											click on a floor, and select a room.
										</p>
									)}
									{this.props.treeData.tree.length > 0 && (
										<Route exact path='/health-system/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId' component={() => <CallPatient />} />
									)}
								</Else>
							</If>
						</Grid>
						<AssignDeviceModal {...this.props} />
					</section>
				</main>
			</Layout>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(Organization));

export { Organization }; //Exported for Unit Tests only!
