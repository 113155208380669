import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

export const deleteMemberCallAvailability = async () => {
	try {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/members/call-availability`;
		const { data } = await gatewayApi.delete(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addOrUpdateMemberCallAvailability = async teams => {
	try {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/members/call-availability`;
		const { data } = await gatewayApi.post(url, { teams });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNurseHealthSystemAvailabilities = async () => {
	try {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/members/call-availability`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};
