import React, { useState } from 'react';
import { getDeviceLogsCommand } from 'api/devices';
import Modal from 'components/Modal';

const PullDeviceLogs = ({ deviceId, isOnline }) => {
	const [modal, setModal] = useState({
		isOpen: false,
		hasErrors: null,
	});

	const sendPullLogsCommand = async () => {
		if (isOnline) {
			const response = await getDeviceLogsCommand(deviceId);
			setModal({
				isOpen: true,
				hasErrors: !!response.error,
			});
		} else {
			setModal({
				isOpen: true,
				hasErrors: null,
			});
		}
	};

	const getModalText = () => {
		if (!isOnline) {
			return (
				<span>
					{' '}
					Failed to get device logs. <br /> Device is offline.
				</span>
			);
		}
		if (modal.hasErrors) {
			return <span>Something went wrong, please try again.</span>;
		}

		return (
			<span>
				Device logs registered on Solaborate system. <br /> You can close this message.
			</span>
		);
	};

	return (
		<>
			<i
				className='material-icons-outlined boxed-icon'
				data-cy='getDeviceLogsBtn'
				id={deviceId}
				data-tooltip='Get device logs'
				data-position='top'
				style={{ background: '#CBC3E3' }}
				onClick={sendPullLogsCommand}>
				assessment
			</i>
			<Modal
				modalSelector='deviceLogsModal'
				className='centered-modal'
				display={modal.isOpen}
				position='center'
				hideActionButtons={true}
				onModalClose={() => setModal({ isOpen: false, hasErrors: null })}>
				<div>
					<h3>Device logs</h3>
					{getModalText()}
				</div>
			</Modal>
		</>
	);
};

export default PullDeviceLogs;
